export interface CustomerPortal {
  idPortal: string | null
  namePortal: string | null
  userPortal: string | null
  emailPortal: string | null
  statePortal: string | null
  statePortalText: string | null
}

export interface CustomerPortalState {
  customerPortalFormData: CustomerPortal[]
}

export enum StatePortal {
  ACCESS_NOT_SENT = '0',
  ACCESS_NOT_ACTIVATED = '1',
  ACCESS_ACTIVATED = '2',
  ACCESS_BLOCKED = '3'
}

export const customerPortalMutationTypes = {
  CLEAR_CUSTOMER_PORTAL: 'CLEAR_CUSTOMER_PORTAL',
  FETCH_CUSTOMER_PORTAL: 'FETCH_CUSTOMER_PORTAL',
  SAVE_CUSTOMER_PORTAL: 'SAVE_CUSTOMER_PORTAL'
}
export interface CustomerFormData {
  activated: string
  domain: string
  id: string
  lawFirm: string
  subDomain: string
}
