<template let-data lang="pug">

div(class="card-template-container")

  span(:class="[checkActionIconHelper(data.idActionType), 'template-icon', 'lf-icon__md']")

  div(class="info-container")
    span(class="ellipsis-text subject") {{ data.subject }}
    span(class="responsible ellipsis-text") {{ data.responsible }}
    span(v-if="data.description" class="template-row ellipsis-text")  {{ data.description }}

    div(class="template-row ellipsis-text")
      span(class="row-label") {{ $t('components.lexon_kanban.start_date') }}:
      span(class="row-value") {{ data.dateActionAt }}

    div(
      v-for="(row, index) in data.rows"
      :key="index"
      class="template-row"
    )
      span(class="row-label") {{ row.label }}:
      span(class="row-value") {{ row.value }}

    //- div(class="tags-row")
    //-   span(class="tag") {{ data.responsible }}
    //-   span(:class="[renderHasDocumentsIcon, 'template-icon']")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { checkActionIcon } from '@/store/modules/actions/actionsTypes'
import { KanbanCardItem } from '@/components/LexonKanban/types/LexonKanbanTypes'

@Component
export default class LexonKanbanCardTemplate extends Vue {
  data: KanbanCardItem | {} = {}

  hasDocumentsIcon: string = Icons.DOCUMENT_ATTACHMENT

  checkActionIconHelper = checkActionIcon

  get renderHasDocumentsIcon(): string {
    return (this as any).data.hasDocuments ? this.hasDocumentsIcon : ''
  }
}
</script>

<style lang="scss" scoped>
.card-template-container {
  display: flex;
  align-items: flex-start;
  font-family: $secondary-font;
  font-size: 13px;
  padding: $spacing-xs;
  border-radius: $cornerRadius-sm;

  .ellipsis-text {
    @include ellipsis;
  }

  .template-row {
    width: 100%;
    height: 25px;

    .row-label {
      @include milano-bold-14;
      margin-right: 4px;
      color: $neutral-grey-800;
    }

    .row-value {
      @include milano-regular-14;
      color: $main-1000;
      color: $neutral-grey-800;
    }
  }

  .template-icon {
    color: $main-1000;
    padding-right: 2px;
    margin-right: 4px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    width: 90%;

    .subject {
      @include milano-bold-16;
      color: $main-1000;
      padding-top: 2px;
    }

    .responsible {
      @include arial-regular-12;
      display: block;
      color: $neutral-grey-800;
      text-align: left;
      border-radius: $spacing-xxs;
      background: $main-050;
      padding: $spacing-xxxs;
      margin-top: 2px;
    }
  }

  .tag {
    @include ellipsis;
    @include flex;
    display: block;
    height: 34px;
    color: $white-01;
    background-color: $blue-04;
    padding: 0 14px;
    border-radius: 25px;
  }
}
</style>
