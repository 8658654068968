import { capitalizeFirstLetter, PdfExportProperties } from "@syncfusion/ej2-vue-grids"
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export'
import { mttMilano } from '@/fonts/mtt-milano'
import { Ref } from 'vue'
import { GridComponent as EjsGrid, PdfExportCompleteArgs, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-vue-grids'

interface PropsUseGridPdf {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
  exportName?: string
}

interface ResponseUseGridPdf {
  onPdfExportComplete: (args: PdfExportCompleteArgs) => void
  onPdfQueryCellInfo: (args: PdfQueryCellInfoEventArgs) => void
  exportToPDF: () => void
}

export const getPdfExportProperties = (title: string): PdfExportProperties => {
  return {
    pageSize: 'A4',
    pageOrientation: 'Landscape',
    exportType: 'CurrentPage',
    fileName: title,
    header: {
      fromTop: 0,
      height: 30,
      contents: [
        {
          type: 'Text',
          value: title,
          position: { x: 0, y: 10 },
          style: { textBrushColor: '#000000', fontSize: 16 }
        }
      ]
    },
    theme: {
      caption: { font: new PdfTrueTypeFont(mttMilano, 12) },
      header: { font: new PdfTrueTypeFont(mttMilano, 10) },
      record: { font: new PdfTrueTypeFont(mttMilano, 9) }
    }
  }
}

export default function useGridPdf(props: PropsUseGridPdf): ResponseUseGridPdf {
  const { gridRef, exportName = 'export' } = props

  const onPdfExportComplete = async (args: PdfExportCompleteArgs) => {
    const { promise } = args
    const { blobData } = await promise as any
    const blobURL = URL.createObjectURL(blobData)
    window.open(blobURL)
  }

  const onPdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs) => {
    if (args.column?.format === 'C2' && args.value === '') {
      args.value = '00,00 €'
    }
  }

  const exportToPDF = () => {
    if (gridRef.value) {
      const pdfExportProperties = getPdfExportProperties(capitalizeFirstLetter(exportName))
      ; (gridRef.value as any).pdfExport(pdfExportProperties, null, null, true)
    }
  }

  return {
    onPdfExportComplete,
    onPdfQueryCellInfo,
    exportToPDF
  }
}
