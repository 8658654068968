selectedRegisterId
<script lang="ts">
import moment from 'moment'
import store from '@/store/store'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionFields } from '@/views/actions/Actions/types/ActionDetailViewTypes'
import { ActionTypes, Action as ActionInterface } from '@/store/modules/actions/actionsTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { entity, Entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { URLS } from '@/router/routes/urlRoutes'
import { ChronometerNewActionInfo } from '@/store/modules/actions/actionsTypes'
import {
  indirectCostsFieldNamesForCalculation,
  ProfitabilityFormFields
} from '@/components/forms/fields/Profitability/types/ProfitabilityComponentTypes'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import { ActionEntityId } from '@/general/entityIds'
import { getArrayIndex } from '@/helpers/array'

const actionsModule: string = ModuleNamespaces.ACTIONS
const authModule: string = ModuleNamespaces.AUTH
const endpointModule: string = ModuleNamespaces.ENDPOINT
const entitiesModule: string = ModuleNamespaces.ENTITIES
const formsModule = ModuleNamespaces.FORMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component
export default class ActionsMixin extends Mixins(GridContextMenuOptionsMixin) {
  @Prop({
    type: String,
    default: ContextName.DEFAULT
  })
  context!: string

  @Prop({
    type: String
  })
  actionType!: string

  @Prop({
    type: String
  })
  submenuName!: string

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void
  @Action('saveRegisterFormData', { namespace: formsModule })
  saveRegisterFormData: ({}) => void
  @Action('saveSelectedActionType', { namespace: actionsModule })
  saveSelectedActionType: (type: string) => void
  @Action('getActionByCode', { namespace: actionsModule })
  getActionByCode: (params: object) => void
  @Action('fetchParentAction', { namespace: actionsModule })
  fetchParentAction: (subAction: ActionInterface) => Promise<void>

  @Getter('getCompanyAppointmentsVersion', { namespace: authModule })
  getAppointmentsVersion: number
  @Getter('getCompanyMailVersion', { namespace: authModule })
  companyMailVersion: number
  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity
  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object
  @Getter('getSelectedActionType', { namespace: actionsModule })
  getSelectedActionType: string
  @Getter('getChronometerNewActionInfo', { namespace: actionsModule })
  getChronometerNewActionInfoGetter: ChronometerNewActionInfo
  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}
  @Getter('getParentAction', { namespace: actionsModule })
  getParentAction: ActionInterface

  @Mutation('RESET_REGISTER_FORM_DATA', { namespace: formsModule })
  resetFormData: () => void

  isSavingForm: boolean = false

  isNewRegister: boolean = false

  actionContext: string = this.context

  rate: any = null

  contextMenuSelectedRegister: any = null

  flagGetCodeInProcess: boolean = false
  flagLoadEconomicEstimate: boolean = true

  icons = {
    actionCall: Icons.CALL,
    actionEmail: Icons.EMAIL,
    actionMeeting: Icons.MEETING,
    actionOther: Icons.OTHERS_F,
    actionProcedure: Icons.PROCEDURES,
    addNew: Icons.ADD,
    download: Icons.DOWNLOAD,
    internalManagement: Icons.INTERNAL_MANAGEMENT,
    lexnet: Icons.LEXNET,
    task: Icons.TASK
  }

  get actionsContextMenuItems() {
    return [
      ...(this.checkEntityPermissionsGetter(entity.actions.calls.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.call'),
              iconCss: this.icons.actionCall,
              actionType: ActionTypes.CALLS,
              entityId: ActionEntityId.CALL
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.meetings.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.meeting'),
              iconCss: this.icons.actionMeeting,
              actionType: ActionTypes.MEETINGS,
              entityId: ActionEntityId.MEETING
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.procedures.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.procedure'),
              iconCss: this.icons.actionProcedure,
              actionType: ActionTypes.PROCEDURES,
              entityId: ActionEntityId.PROCEDURE
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.lexnet.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.lexnet'),
              iconCss: this.icons.lexnet,
              actionType: ActionTypes.LEXNET,
              entityId: ActionEntityId.LEXNET
            }
          ]
        : []),
      ...(this.companyMailVersion === 2 && this.checkEntityPermissionsGetter(entity.actions.emails.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.email'),
              iconCss: this.icons.actionEmail,
              actionType: ActionTypes.EMAILS,
              entityId: ActionEntityId.EMAIL
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.task.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.task'),
              iconCss: this.icons.task,
              actionType: ActionTypes.TASK,
              entityId: ActionEntityId.TASK
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.internalManagement.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.internal_management'),
              iconCss: this.icons.internalManagement,
              actionType: ActionTypes.INTERNAL_MANAGEMENT,
              entityId: ActionEntityId.GEST
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.others.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.other_f'),
              iconCss: this.icons.actionOther,
              actionType: ActionTypes.OTHERS,
              entityId: ActionEntityId.OTHER
            }
          ]
        : [])
    ]
  }

  get contextMenuItemsActionsAllMixin() {
    return [
      {
        text: this.contextMenuOptionsTexts.editItem,
        iconCss: Icons.EDIT
      },
      {
        text: this.contextMenuOptionsTexts.removeItem,
        iconCss: Icons.REMOVE,
        action: this.showDialog
      },
      {
        text: this.contextMenuOptionsTexts.open,
        iconCss: Icons.OPEN_WINDOW
      },
      {
        text: this.contextMenuOptionsTexts.openLexnet,
        iconCss: Icons.LEXNET
      }
    ]
  }

  get openMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.open,
      iconCss: Icons.OPEN_WINDOW
    }
  }

  get openWindowMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.openWindow,
      iconCss: Icons.OPEN_WINDOW
    }
  }

  get associateActionItem() {
    return {
      text: this.contextMenuOptionsTexts.associateAction,
      iconCss: Icons.FOLDER_OPEN
    }
  }

  get moveActionItem() {
    return {
      text: this.contextMenuOptionsTexts.moveAction,
      iconCss: Icons.FOLDER
    }
  }

  get openLfMailMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.openLfMail,
      iconCss: Icons.EMAIL
    }
  }

  get openLexnetMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.openLexnet,
      iconCss: Icons.LEXNET
    }
  }

  get actionsContextMenu() {
    return this.actionsContextMenuItems
  }

  get contextMenu() {
    return this.contextMenuItemsActionsAllMixin
  }

  get isActionNewViewFromTimer() {
    return (
      this.$route.params.selectedRegisterId === `${URLS.NEW}` && this.getChronometerNewActionInfoGetter.start !== ''
    )
  }

  get fromAllActions() {
    return this.$route.params.from === `${URLS.ACTION_ALL}`
  }

  get isActionNewView() {
    if (this.selectedRegisterData(this.actionContext) && this.entity(this.actionContext)) {
      const selectedRegister = (this as any).selectedRegisterData(this.actionContext)[
        this.entity(this.actionContext).keyId
      ]
      return selectedRegister === undefined ? true : false
    }
    return false
  }

  async onCreatedForm(params: any) {
    const { schema, formData } = params
    const action = this.selectedRegisterData(this.context) as ActionInterface
    const isSubaction = Boolean(action.parentId)
    const indexToDelete: number[] = []
    //Eliminamos el nodo typeId que no contenga nuestro ActionType
    schema.forEach((node: any, index: number) => {
      if (node.id === ActionFields.ACTION_TYPE && node.propItems && this.getSelectedActionType) {
        const hasLEXN = node.propItems.some((item: any) => item.id === this.getSelectedActionType)
        if (!hasLEXN) {
          indexToDelete.push(index)
        }
      }

      if (this.getSelectedActionType !== ActionTypes.LEXNET && node.fieldsetId === ActionFields.LEXNET) {
        indexToDelete.push(index)
      }

      if (!isSubaction && (node.id === ActionFields.SUBACTION_ALERT_INFO || node.id === ActionFields.PARENT_ACTION)) {
        indexToDelete.push(index)
      }

      if (isSubaction && (node.id === ActionFields.BILLABLE || node?.fieldsetId === ActionFields.BILLED_FIELDS)) {
        indexToDelete.push(index)
      }
    })

    indexToDelete.sort((a, b) => b - a)
    indexToDelete.forEach((index) => {
      schema.splice(index, 1)
    })

    if (ContextName.EXPEDIENT_ACTIONS !== this.actionContext) {
      const index = getArrayIndex(schema, 'name', ActionFields.STAGE)
      if (index >= 0) {
        schema.splice(index, 1)
      }
    }

    if (!this.isActionNewView) {
      const index = getArrayIndex(schema, 'id', ActionFields.CODE)
      if (index >= 0) {
        schema.splice(index, 1)
      }
    }

    if (isSubaction) {
      await this.fetchParentAction(action)
      const parentAction = this.getParentAction
      const index = getArrayIndex(schema, 'id', ActionFields.SUBACTION_ALERT_INFO)
      schema[index].alert.message = String.format(
        schema[index].alert.message,
        action.subject,
        parentAction?.subject ?? '--'
      )
      schema[getArrayIndex(schema, 'id', ActionFields.PARENT_ACTION)].rowData = [parentAction]
      const expedientIdField: any = schema.find(({ id }: any) => id === ActionFields.EXPEDIENT_ID)
      if (expedientIdField) {
        expedientIdField.disabled = true
      }
      formData[ActionFields.EXPEDIENT_ID] = parentAction.idExpedient
    }

    schema.map((item: any) => {
      if (this.getSelectedActionType === ActionTypes.OTHERS && item.name === ActionFields.CATEGORY) {
        item.hidden = false
        item.disabled = false
      }
    })

    this.removeAgendaFieldsCheckingPermissions(schema)

    this.checkFormPermissions(schema, this.entity(this.actionContext).type)
  }

  removeAgendaFieldsCheckingPermissions(schema: any) {
    const hasAgendaPermissions = this.checkEntityPermissionsGetter(entity.agenda.type).canView
    if (!hasAgendaPermissions) {
      const hasAppointmentIndex = schema.findIndex((item: any) => item.name === ActionFields.APPOINTMENT)

      if (hasAppointmentIndex >= 0) {
        schema.splice(hasAppointmentIndex, 2)
      }
    }
  }

  async onInitForm(params: any) {
    const { isNewForm, formData, schema } = params

    if (isNewForm) {
      this.isNewRegister = isNewForm
      formData[ActionFields.USER_ID] = (store.state as any).authModule.authData.user.id
      formData[ActionFields.ACTION_TYPE] = this.getSelectedActionType
      formData[ActionFields.APPOINTMENT] = { idUser: (store.state as any).authModule.authData.user.id }
    }

    if (this.isActionNewViewFromTimer) {
      const { start, end, duration } = this.getChronometerNewActionInfoGetter
      formData[ActionFields.START] = start
      formData[ActionFields.END] = end
      formData[ActionFields.DURATION] = duration
    }

    this.checkIfDisableBillingFields(formData[ActionFields.BILLABLE], schema)
  }

  checkIfDisableBillingFields(billingFieldValueschema: undefined | number, schema: any) {
    if (!billingFieldValueschema) {
      const billedFields = schema.find((item: any) => {
        return item.fieldsetId === ActionFields.BILLED_FIELDS
      })
      billedFields?.fields.map((item: any) => (item.disabled = true))
    }
  }

  async onFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (this.isSavingForm) {
      return
    }
    const entityType = (this as any).selectedRegisterData(this.actionContext).idEntity
    const { canSave } = this.checkEntityPermissionsGetter(entityType)

    const billableField = schema.find((item: any) => {
      return item.id === ActionFields.BILLABLE
    })

    if (formData[ActionFields.RATE_ID] === undefined && this.rate !== undefined) {
      this.rate = null
    }
    if (ActionFields.ACTION_TYPE === field.id && canSave) {
      schema.map((item: any) => {
        if (item.name === ActionFields.CATEGORY) {
          item.hidden = item.disabled = !(value === ActionTypes.OTHERS)
        }
      })
    }

    if (ActionFields.SCHEDULE_LEXBOX === field.id) {
      schema.map((item: any) => {
        if (item.name === ActionFields.CODE) {
          item.hidden = item.disabled = true
        }
      })
    }

    if (
      (ActionFields.RATE_ID === field.id && value) ||
      (ActionFields.DURATION === field.id && !billableField?.disabled)
    ) {
      if (ActionFields.DURATION === field.id && !this.rate) {
        this.$set(formData, ActionFields.ECONOMIC_ESTIMATE, 0)
        return
      }

      if (ActionFields.RATE_ID === field.id) {
        this.rate = value
      }

      const duration = formData[ActionFields.DURATION]
      const rate: any = this.rate
      let price = rate.fixedPrice

      if (duration && rate.hourlyPrice > 0) {
        price = (rate.hourlyPrice * (duration / 60)).toFixed(2)
      }

      if (!isNaN(price) && this.flagLoadEconomicEstimate) {
        this.$set(formData, ActionFields.ECONOMIC_ESTIMATE, price)
      }
    }

    if (ActionFields.BILLABLE === field.id) {
      const disabled = '0' === value
      const billedFields = schema.find((item: any) => {
        return item.fieldsetId === ActionFields.BILLED_FIELDS
      })
      billedFields.fields.map((item: any) => (item.disabled = disabled || !Boolean(canSave)))
      if (disabled) {
        this.$set(formData, ActionFields.ECONOMIC_ESTIMATE, null)
      }
    }

    if ((ActionFields.START === field.id || ActionFields.END === field.id) && !value) {
      this.$set(formData, ActionFields.DURATION, null)
    }

    if ((ActionFields.START === field.id || ActionFields.END === field.id) && value && !this.isActionNewViewFromTimer) {
      const start = formData[ActionFields.START]
      const end = formData[ActionFields.END]
      if (end) {
        const dateStart = moment(start, 'YY-MM-DD hh:mm')
        const dateEnd = moment(end, 'YY-MM-DD hh:mm')
        if (dateStart <= dateEnd) {
          const minutes = dateEnd.diff(dateStart, 'minutes')
          this.$set(formData, ActionFields.DURATION, minutes)
        }
      }
    }

    if (ActionFields.CODE === field.id && !this.flagGetCodeInProcess) {
      const code: string = formData[ActionFields.CODE]
      if (code === null) {
        this.resetFormData()
      } else if (field.origin !== ContextName.MAINTENANCE && code !== '') {
        this.flagGetCodeInProcess = true
        const predefinedAction: any = await this.getActionByCode({ code })
        this.flagGetCodeInProcess = false
        Object.keys(predefinedAction[0]).forEach((key: string) => {
          if (key !== ActionFields.CODE) {
            if (key === ActionFields.RELATIONS || key === ActionFields.COLOR) {
              predefinedAction[0][key] = JSON.parse(predefinedAction[0][key])
            }
            if (key === ActionFields.RATE_ID) {
              this.flagLoadEconomicEstimate = false
              predefinedAction[0][key] = JSON.parse(predefinedAction[0][key], (k, v) => {
                if (k === 'id') {
                  return String(v)
                }
                return v
              })
            }
            if (key === ActionFields.START) {
              this.$set(formData, ActionFields.END, null)
            }
            if (key === ActionFields.ECONOMIC_ESTIMATE) {
              setTimeout(() => {
                this.$set(formData, ActionFields.ECONOMIC_ESTIMATE, predefinedAction[0][key])
                this.flagLoadEconomicEstimate = true
              })
            }
            this.$set(formData, key, predefinedAction[0][key])
          }
        })
      }
    }

    if (indirectCostsFieldNamesForCalculation.includes(field.id)) {
      const totalIndirectCostsCalculation = this.calculateTotalIndirectCosts(formData)
      const hourCostsCalculation = this.calculateHoursCosts(formData)
      Vue.set(formData, ProfitabilityFormFields.TOTAL_INDIRECT_COSTS, totalIndirectCostsCalculation)
      Vue.set(formData, ProfitabilityFormFields.HOUR_COST, hourCostsCalculation)
    }

    if (field.id === ProfitabilityFormFields.DIRECT_COST || field.id === ProfitabilityFormFields.WORK_HOURS) {
      const hourCostsCalculation = this.calculateHoursCosts(formData)
      Vue.set(formData, ProfitabilityFormFields.HOUR_COST, hourCostsCalculation)
    }
  }

  calculateTotalIndirectCosts(formData: any) {
    let calculation = 0
    Object.keys(formData).forEach((key) => {
      if (indirectCostsFieldNamesForCalculation.includes(key as ProfitabilityFormFields)) {
        calculation += formData[key] * 12 || 0
      }
    })
    return calculation
  }

  calculateHoursCosts(formData: any) {
    const totalIndirectCosts = this.calculateTotalIndirectCosts(formData)
    const directCosts = formData[ProfitabilityFormFields.DIRECT_COST] || 0
    const workHours = formData[ProfitabilityFormFields.WORK_HOURS] || 1
    const hoursCostCalculation = (totalIndirectCosts + directCosts) / workHours
    return hoursCostCalculation
  }

  async save(redirectToGridTable = true, resolve: any = null) {
    if (this.isActionNewViewFromTimer || this.fromAllActions) {
      redirectToGridTable = false
    }
    this.isSavingForm = true

    this.regularizeFieldsOnSave(this.actionType)

    await this.$nextTick()

    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.actionContext).type).save,
      idSelectedRegister: this.entity(this.actionContext).id,
      goToURL: redirectToGridTable
        ? this.submenuName
        : (this.isActionNewViewFromTimer || this.fromAllActions) && !resolve
        ? -1
        : null,
      context: this.actionContext
    })

    this.isSavingForm = false

    if (resolve) {
      resolve()
    }
  }

  regularizeFieldsOnSave(actionType: string) {
    const formData: any = this.getFormData

    const rate = formData[ActionFields.RATE_ID]
    if (rate && rate.id) {
      formData[ActionFields.RATE_ID] = rate.id
    }

    if (typeof formData[ActionFields.ACTION_TYPE] === 'undefined') {
      formData[ActionFields.ACTION_TYPE] = actionType
    }

    this.prepareFormData(formData)
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'add') {
      this.toggleContextMenu(args.originalEvent)
      args.cancel = true
    }
  }

  toggleContextMenu(event: MouseEvent) {
    if (event) {
      const btn: EventTarget | null = event.target
      if (btn) {
        const rect: ClientRect | DOMRect = (btn as HTMLHtmlElement).getBoundingClientRect()
        const elem: any = document.getElementById('expedientActionsContextMenuComponent')
        if (null !== elem) {
          elem.ej2_instances[0].open(rect.top + 43, rect.left + 40)
        }
      }
    }
  }

  renderEditOrLookText(contextMenuModule: any, contextMenuGridObject: any, canSave: number) {
    if (canSave) {
      contextMenuModule.items[0].text = this.contextMenuOptionsTexts.editItem
      contextMenuGridObject.items[0].text = this.contextMenuOptionsTexts.editItem
      contextMenuModule.items[0].iconCss = Icons.EDIT
      contextMenuGridObject.items[0].iconCss = Icons.EDIT
    } else {
      contextMenuModule.items[0].text = this.contextMenuOptionsTexts.lookItem
      contextMenuGridObject.items[0].text = this.contextMenuOptionsTexts.lookItem
      contextMenuModule.items[0].iconCss = Icons.VISIBLE
      contextMenuGridObject.items[0].iconCss = Icons.VISIBLE
    }
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, selectedRegister: any) {
    if (!selectedRegister) {
      return
    }

    const selectedContactTypePermission = this.checkEntityPermissionsGetter(selectedRegister.idEntity)
    const { canSave, canDelete } = selectedContactTypePermission

    const editOrLookText = this.renderEditOrLookOptionInContextMenu(contextMenuModule, contextMenuGridObject, canSave)

    const { open, openLexnet, openLfMail, openWindow, removeItem, associateAction, moveAction } =
      this.contextMenuOptionsTexts

    this.contextMenuSelectedRegister = selectedRegister

    const itemsToRemove = [open, openWindow, removeItem, openLexnet, openLfMail, associateAction, moveAction]
    itemsToRemove.forEach((item) => {
      if (contextMenuGridObject.items.findIndex((menuItem: any) => menuItem.text === item) >= 0) {
        contextMenuModule.removeItems([item])
        contextMenuGridObject.removeItems([item])
      }
    })

    const contextMenuActions = {
      [ActionTypes.EMAILS]: () => {
        this.renderRemoveOptionInContextMenu(contextMenuModule, contextMenuGridObject, canDelete, editOrLookText)
        contextMenuModule.insertAfter([this.openLfMailMenuItem], editOrLookText, false)
        contextMenuGridObject.insertAfter([this.openLfMailMenuItem], editOrLookText, false)
        contextMenuModule.insertAfter([this.openWindowMenuItem], editOrLookText, false)
        contextMenuGridObject.insertAfter([this.openWindowMenuItem], editOrLookText, false)
        if (ContextName.EXPEDIENT_ACTIONS === this.actionContext) {
          contextMenuModule.insertAfter([this.associateActionItem], openLfMail, false)
          contextMenuGridObject.insertAfter([this.associateActionItem], openLfMail, false)
          contextMenuModule.insertAfter([this.moveActionItem], associateAction, false)
          contextMenuGridObject.insertAfter([this.moveActionItem], associateAction, false)
        }
      },
      [ActionTypes.LEXNET]: () => {
        contextMenuModule.insertAfter([this.openLexnetMenuItem], editOrLookText, false)
        contextMenuGridObject.insertAfter([this.openLexnetMenuItem], editOrLookText, false)
        if (ContextName.EXPEDIENT_ACTIONS !== this.actionContext) {
          contextMenuModule.insertAfter([this.openWindowMenuItem], editOrLookText, false)
          contextMenuGridObject.insertAfter([this.openWindowMenuItem], editOrLookText, false)
        } else {
          contextMenuModule.insertAfter([this.associateActionItem], openLexnet, false)
          contextMenuGridObject.insertAfter([this.associateActionItem], openLexnet, false)
          contextMenuModule.insertAfter([this.moveActionItem], associateAction, false)
          contextMenuGridObject.insertAfter([this.moveActionItem], associateAction, false)
        }
      },
      default: () => {
        this.renderEditOrLookText(contextMenuModule, contextMenuGridObject, canSave)
        this.renderEditOrLookText(contextMenuModule, contextMenuGridObject, canSave)
        this.renderRemoveOptionInContextMenu(contextMenuModule, contextMenuGridObject, canDelete, editOrLookText)
        if (ContextName.EXPEDIENT_ACTIONS !== this.actionContext) {
          contextMenuModule.insertAfter([this.openWindowMenuItem], editOrLookText, false)
          contextMenuGridObject.insertAfter([this.openWindowMenuItem], editOrLookText, false)
        } else {
          contextMenuModule.insertAfter([this.associateActionItem], editOrLookText, false)
          contextMenuGridObject.insertAfter([this.associateActionItem], editOrLookText, false)
          contextMenuModule.insertAfter([this.moveActionItem], associateAction, false)
          contextMenuGridObject.insertAfter([this.moveActionItem], associateAction, false)
        }
      }
    }

    const actionFunction =
      contextMenuActions[selectedRegister.typeId as keyof typeof contextMenuActions] || contextMenuActions.default

    actionFunction()
  }

  openLexnetInNewWindow() {
    const routeData = this.$router.resolve({ name: `${URLS.LEXNET}` })
    window.open(routeData.href, '_blank')
  }
}
</script>
