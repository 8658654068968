import { ref, onMounted, onUnmounted, Ref } from 'vue'

interface PropsUseEasterEgg {
  code: string
  eventCallback?: () => void
  timeout?: number
}

interface ResponseUseEasterEgg {
  isValid: Ref<boolean>
  pressedKeys: Ref<string[]>
}

export default function useEasterEgg(props: PropsUseEasterEgg): ResponseUseEasterEgg {
  const { code, eventCallback = () => {}, timeout = 4000 } = props
  const pressedKeys = ref<string[]>([])
  const isValid = ref<boolean>(false)
  const timeoutId = ref<number | null>(null)

  const resetKeys = () => {
    pressedKeys.value = []
  }

  const onKeyDown = ({ key }: KeyboardEvent) => {
    pressedKeys.value.push(key)

    if (pressedKeys.value.length > code.length) {
      pressedKeys.value.shift()
    }

    if (pressedKeys.value.join('') === code) {
      eventCallback()
      resetKeys()
      validatedTimer()
    } else {
      if (timeoutId.value !== null) {
        clearTimeout(timeoutId.value)
      }
      timeoutId.value = window.setTimeout(resetKeys, timeout)
    }
  }
  const validatedTimer = () => {
    isValid.value = true
    setTimeout(() => {
      isValid.value = false
    }, timeout)
  }

  onMounted(() => {
    window.addEventListener('keydown', onKeyDown)
  })

  onUnmounted(() => {
    window.removeEventListener('keydown', onKeyDown)
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value)
    }
  })

  return {
    isValid,
    pressedKeys
  }
}
