import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'

export const setPageTitleGuard = (to: Route, _from: Route, next: any): void => {
  const getTitle = (text: string) => {
    const routeNameCapitalized = text.charAt(0).toUpperCase() + text.slice(1)
    const routeNameWithoutDashes = routeNameCapitalized.replace(
      new RegExp('-', 'g'),
      ' '
    )
    return `${ URLS.BASE_TITLE } | ${ routeNameWithoutDashes }`
  }
  const title = to.meta?.title
  document.title = title ? getTitle(title) : URLS.BASE_TITLE
  next()
}


