<template lang="pug">

section(class="lexon-view-container")
  GridPivotTableComponent(
    v-if="report && report.config && report.config.dataSource && reportsList.length"
    :dataSourceSettings="report"
    :title="$t('views.reports.title')"
    :showHeader="showHeader"
    :itemsList="reportsList"
    :fetchSettings="fetchReport"
    :fetchData="fetchReportData"
    :fetchList="fetchListReports"
    :saveSettings="postReports"
    :removeItem="deleteReport"
    :resetItem="resetReports"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
const reportsModule: string = ModuleNamespaces.REPORTS
const entitiesModule: string = ModuleNamespaces.ENTITIES
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridPivotTableComponent from '@/components/GridPivotTable/GridPivotTableComponent.vue'
import { URLS } from '@/router/routes/urlRoutes'

@Component({
  components: {
    AlertComponent,
    GridPivotTableComponent
  }
})
export default class ReportsView extends mixins(ListViewMixin) {
  @Action('fetchReport', { namespace: reportsModule })
  fetchReport: (id: any) => []

  @Action('fetchReportData', { namespace: reportsModule })
  fetchReportData: (id: any) => []

  @Action('fetchListReports', { namespace: reportsModule })
  fetchListReports: () => []

  @Action('postReports', { namespace: reportsModule })
  postReports: (report: any) => void

  @Action('deleteReport', { namespace: reportsModule })
  deleteReport: (id: any) => void

  @Getter('getReport', { namespace: reportsModule }) report: {}

  @Getter('getReportsListWithFormattedDates', { namespace: reportsModule }) reportsList: []

  @Action('resetReports', { namespace: reportsModule }) resetReports: () => void

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  showHeader = true
  componentWhereIsRenderedAlertComponent = 'top'
  frozenCols = 1
  checkboxFilter: object = {
    type: 'CheckBox'
  }

  getCustomAttributes(customAttributes: object) {
    return customAttributes ? customAttributes : null
  }

  getTemplate(templateName: string) {
    return templateName ? (this as any)[templateName] : false
  }

  get context() {
    return ContextName.REPORTS
  }

  async created() {
    await this.resetReports()
    await this.fetchListReports()
    await this.fetchReport(this.$route.params.selectedRegisterId)

    if (!this.reportData && !(this.report as any).name) {
      this.$router.push({ name: URLS.REPORTS })
    }
  }
}
</script>

<style lang="scss">
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
@import '~@syncfusion/ej2-pivotview/styles/material.css';
</style>
