<template>
  <div class="lf-simple-grid-field">
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="listItems"
      :toolbarOptions="toolbarOptions"
      :toolbarClick="onToolbarClick"
      :contextMenuItems="contextMenuItems"
      :commandClick="onCommandClick"
      :allowPaging="allowPaging"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>

    <PromptDialog
      :title="modalTitleParsed"
      :isOpened="openedPrompt"
      :context="context"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :width="promptWidth"
      @execute-action="onExecuteAction"
    ></PromptDialog>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'
import { computed, ref } from 'vue'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import {
  CommandClickEventArgs,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { Icons } from '@/icons/icons'
import { useI18n } from 'vue-i18n-composable'
import { MainService } from '@/services/MainService'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import { DialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import useRouter from '@/composables/useRouter'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { CommandModel } from '@/components/grids/LfGrid/LfGridTypes'

const router = useRouter()
const { t } = useI18n()

const props = defineProps({
  aliasSchema: {
    type: String
  },
  listName: {
    type: String,
    required: true
  },
  id: {
    type: String
  },
  endPointCreate: {
    type: String,
    required: true
  },
  endPointRead: {
    type: String,
    required: true
  },
  endPointUpdate: {
    type: String,
    required: true
  },
  endPointDelete: {
    type: String,
    required: true
  },
  modalTitle: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  promptWidth: {
    type: Number,
    default: 940
  },
  allowPaging: {
    type: Boolean,
    default: false
  }
})

const texts = {
  modal: {
    add: `${t('action_buttons.add').toString()}`,
    edit: `${t('action_buttons.edit').toString()}`
  },
  contextMenuItems: {
    edit: t('components.context_menu.edit').toString(),
    addPartialbankdraft: t('components.context_menu.add_partialbankdraft').toString(),
    remove: t('components.context_menu.remove').toString()
  },
  dialog: {
    removeConfirm: t('components.dialog.lexon_simple_grid_delete_message', {
      name: props.modalTitle
    }).toString(),
    removeOk: t('components.dialog.lexon_simple_grid_delete_ok', {
      name: props.modalTitle
    }).toString(),
    removeError: t('components.dialog.lexon_simple_grid_delete_error', {
      name: props.modalTitle
    }).toString(),
    saveOk: t('components.dialog.lexon_simple_grid_add_ok', {
      name: props.modalTitle
    }).toString(),
    saveError: t('components.dialog.lexon_simple_grid_add_error', {
      name: props.modalTitle
    }).toString()
  },
  button: {
    remove: t('action_buttons.remove').toString(),
    add: t('action_buttons.add').toString(),
    new: t('action_buttons.new').toString()
  }
}

const context = ref(`${props.id}-${props.aliasSchema}`)
const modalTitleParsed = ref('')
const openedPrompt = ref(false)
const formData = ref({})
const listItems = ref([])

const commandBtns = computed<CommandModel[]>(() => {
  const { canDelete } = permissions.value
  return canDelete
    ? [
        {
          title: texts.button.remove,
          type: 'None',
          buttonOption: {
            iconCss: Icons.REMOVE,
            cssClass: 'custombutton'
          }
        }
      ]
    : []
})

const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: context.value,
  commandButtons: commandBtns
})

// COMPUTED
const entityType = computed(() => {
  return router.route?.meta.entityType
})

const toolbarOptions = computed((): ToolbarItemModel[] => {
  return [
    {
      id: ActionName.TITLE,
      text: props.label,
      cssClass: 'lf-title',
      align: 'Left'
    },
    {
      id: ActionName.ADD,
      text: texts.button.add,
      cssClass: 'lf-btn-model4',
      disabled: !isValidForm.value,
      align: 'Right'
    }
  ]
})

const contextMenuItems = computed((): ContextMenuItemModel[] => {
  const { canDelete } = permissions.value
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

// STORE
async function getFormConfiguration() {
  await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentViewConfiguration`, {
    objectType: ConfigurationTypes.VIEW,
    alias: props.aliasSchema,
    context: context.value
  })
}

async function saveRegister() {
  await store.dispatch(`${ModuleNamespaces.FORMS}/saveRegisterFormData`, {
    endpoint: endpoints.value.save,
    idSelectedRegister: entity.value.id,
    goToUrl: null,
    context: selectedEntityName.value
  })
}

function showDialog({ type, message, mainButtonText, secondaryButtonText, action }: DialogData) {
  store.dispatch(`${ModuleNamespaces.DIALOG}/showDialog`, {
    type,
    message,
    mainButtonText,
    secondaryButtonText,
    action
  })
}

const formSchema = computed(() =>
  store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentViewConfiguration`](context.value)
)
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])
const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)
const permissions = computed(() => store.getters[`${ModuleNamespaces.AUTH}/getPermission`](entityType.value))
const entity = computed((): Entity => store.getters[`${ModuleNamespaces.ENTITIES}/getEntity`](selectedEntityName.value))
const endpoints = computed(
  (): Endpoint => store.getters[`${ModuleNamespaces.ENDPOINT}/getEndpoints`](entity.value.type)
)
const isValidForm = computed(() =>
  store.getters[`${ModuleNamespaces.FORMS}/checkIfFormIsValid`](selectedEntityName.value)
)

// EVENTS
async function onExecuteAction(actionName: string) {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    await save()
    openedPrompt.value = false
    read(selectedRegisterId.value)
  }
}

function onToolbarClick({ item }: ClickEventArgs) {
  const { id } = item
  if (id === ActionName.ADD) {
    modalTitleParsed.value = `${texts.modal.add} ${props.modalTitle}`
    formData.value = {}
    openedPrompt.value = true
  }
}

function onCommandClick(args: CommandClickEventArgs) {
  const { rowData } = args
  const { id } = rowData as any
  confirmDelete(id)
}

function onRowClick(args: RecordClickEventArgs) {
  const { rowData } = args
  modalTitleParsed.value = `${texts.modal.edit} ${props.modalTitle}`
  formData.value = rowData as object
  openedPrompt.value = true
}

function onContextMenuClicked({ rowInfo, item }: ContextMenuClickEventArgs) {
  const { rowData } = rowInfo as any
  const { id } = item
  if (id === ActionName.REMOVE) {
    confirmDelete(rowData.id)
    return
  }
  if (id === ActionName.EDIT) {
    formData.value = rowData
    openedPrompt.value = true
  }
}

// CRUD
async function save() {
  const id = (formData.value as any).id ? (formData.value as any).id : '0'
  try {
    if (selectedRegisterId.value === '0') {
      await saveRegister()
    }
    if (id === '0') {
      const endPoint = props.endPointCreate.replace(/\{(.+?)\}/g, selectedRegisterId.value)
      await new MainService().postFormData(endPoint, formData.value as any)
    } else {
      const endPoint = props.endPointUpdate.replace(/\{(.+?)\}/g, id)
      await new MainService().putFormData(endPoint, formData.value as any)
    }
    showDialog({
      type: DialogTypes.SUCCESS,
      message: texts.dialog.saveOk
    })
  } catch (error) {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.saveError
    })
  }
}
async function read(id: string) {
  if (id === '0') {
    return
  }
  const endPoint = props.endPointRead.replace(/\{(.+?)\}/g, id)
  try {
    const { data } = await new MainService().getData(endPoint)
    listItems.value = data
  } catch (error) {
    listItems.value = []
  }
}
async function remove(id: string) {
  try {
    const endPoint = props.endPointDelete.replace(/\{(.+?)\}/g, id)
    await new MainService().deleteData(endPoint)
    read(selectedRegisterId.value)
    openedPrompt.value = false
    showDialog({
      type: DialogTypes.SUCCESS,
      message: texts.dialog.removeOk
    })
  } catch (error) {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.removeError
    })
  }
}

// METHODS
function confirmDelete(id: string) {
  showDialog({
    type: DialogTypes.INFO,
    message: texts.dialog.removeConfirm,
    mainButtonText: t('action_buttons.remove'),
    secondaryButtonText: t('action_buttons.cancel'),
    action: () => {
      remove(id)
    }
  })
}

// HOOKS
getFormConfiguration()
read(selectedRegisterId.value)
</script>
<style lang="scss" scoped>
::v-deep .e-grid.grid-table .e-toolbar {
  background-color: $neutral-grey-050;
  margin: $spacing-sm 0px;
}
</style>
