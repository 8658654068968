<template>
  <div class="card">
    <div class="title-container">{{ text.title }}</div>
    <div class="text-container">
      <div class="info-container">
        <span :class="[icon.email, 'lf-icon__xl']"></span>
        <a :href="emaiURL" class="email">{{ text.email }}</a>
      </div>
      <div class="info-container">
        <span :class="[icon.phone, 'lf-icon__xl']"></span>
        <div class="phone-container">
          <span class="phone">{{ text.phones }}</span>
          <span class="timetable">{{ text.timetable }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import { externalURLS } from '@/router/routes/urlRoutes'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  email: t('components.dialog.support_service.contact_us.email'),
  phones: t('components.dialog.support_service.contact_us.phones'),
  timetable: t('components.dialog.support_service.contact_us.timetable'),
  title: t('components.dialog.support_service.contact_us.title')
}

// DATA
const icon = {
  email: Icons.EMAIL,
  phone: Icons.CALL
}
const emaiURL = externalURLS.help_center_email
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  padding: $spacing-sm;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;
  background: $neutral-white-000;

  .title-container {
    @include milano-bold-24;
    padding: $spacing-xs;
    color: $highlight-200;
    text-align: center;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-xxl;
    color: $main-1000;
    height: 270px;

    .info-container {
      display: flex;
      padding: $spacing-xs;
      flex-direction: column;
      align-items: center;
      gap: $spacing-xs;
      align-self: stretch;

      .email {
        @include milano-medium-16;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
      }

      .phone-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-xxs;

        .phone {
          @include milano-medium-18;
        }

        .timetable {
          @include milano-regular-16;
          color: $neutral-grey-600;
        }
      }
    }
  }
}
</style>
