<template lang="pug">

div(class="custom-fields-playground")

  div(class="row-title")
    span(class="row-text") {{ texts.title }}

  div(class="playground-area")
    CustomFieldsListComponent(
      v-if="config.customFields && config.customFields.length"
      v-model="customFields"
    )

    div(v-else class="empty-playground")
      span(:class="[emptyPlaygroundIcon, 'empty-icon', 'lf-icon__xl']")
      div(class="texts-container")
        p(class="empty-title") {{ texts.emptyTitle }}
        p(class="empty-message") {{ texts.emptyMessage }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Component } from 'vue-property-decorator'
import CustomFieldsListComponent from '@/components/customFields/customFieldsPlayground/CustomFieldsList/CustomFieldsListComponent.vue'
import { Getter } from 'vuex-class'
import { CustomFieldsConfig } from '@/store/modules/customFields/customFieldsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS

@Component({
  components: {
    CustomFieldsListComponent
  }
})
export default class CustomFieldsPlaygroundComponent extends Vue {
  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  config: CustomFieldsConfig

  emptyPlaygroundIcon: string = Icons.SPECIAL_AREA

  get texts() {
    return {
      title: this.$t('components.custom_fields_playground.title'),
      emptyTitle: this.$t('components.custom_fields_playground.empty_title'),
      emptyMessage: this.$t('components.custom_fields_playground.empty_message')
    }
  }

  get customFields() {
    return this.config.customFields
  }
}
</script>

<style lang="scss" scoped>
.custom-fields-playground {
  display: flex;
  padding: $spacing-xs $spacing-md $spacing-md $spacing-md;
  flex-direction: column;
  gap: $spacing-xl;
  border-radius: $spacing-xxxs;
  background: $neutral-grey-050;

  .playground-area {
    padding: 0 20px;
  }

  .row-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $spacing-xs;

    .row-text {
      @include milano-bold-18;
      color: $main-1000;
    }
  }

  .empty-playground {
    display: flex;
    padding: $spacing-xs $spacing-xl;
    align-items: center;
    gap: $spacing-xs;
    border-radius: $cornerRadius-sm;

    .empty-icon {
      color: $neutral-grey-600;
    }

    .texts-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Spacing-xxxs, 2px);

      p {
        margin: 0;
        color: $neutral-grey-600;
      }

      .empty-title {
        @include milano-medium-16;
      }

      .empty-message {
        @include milano-regular-14;
      }
    }
  }
}
</style>
