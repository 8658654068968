<template lang="pug">

div(v-if="loadedData" :class="['widget-base-component', customClass]")
  div(v-resize="calculateMainHeight" class="main-section")
    header(v-if="showHeader")
      slot(name="header")
    main
      slot(name="body")
  footer(v-if="showFooter")
    slot(name="footer")

SpinnerLayerComponent(v-else class="spinner-layer")

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

@Component({
  components: {
    SpinnerLayerComponent
  }
})
export default class WidgetBaseComponent extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  loadedData!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showFooter!: boolean

  @Prop({
    type: String
  })
  customClass!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showHeader!: boolean

  calculateMainHeight() {
    setTimeout(() => {
      const height = this.$el.clientHeight
      const elementWithScroll: HTMLElement | null = this.$el.querySelector('.element-with-scroll')

      if (elementWithScroll) {
        elementWithScroll.style.height = `${height - 120}px`
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.widget-base-component {
  display: flex;
  width: 100%;
  padding: $spacing-sm $spacing-md;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-xxs;
  align-self: stretch;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;
  background: $neutral-white-000;

  .main-section {
    width: 100%;

    header {
      display: flex;
      align-self: stretch;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: $neutral-grey-800;
      padding: 8px 0;

      h2 {
        @include milano-bold-18;
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    main {
      margin-top: 10px;
    }
  }
}

.spinner-layer {
  background-color: $white-01;
  border-radius: $widget-border-radius;
}
</style>
