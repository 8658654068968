<template lang="pug"></template>
<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { TranslateResult } from 'vue-i18n'
import { extend } from 'vee-validate'
import { ContactTypeId } from '@/store/modules/contacts/contactsTypes'
import { customValidatorTypes } from '@/store/modules/forms/formsTypes'
import { goToPreviousPage } from '@/helpers/routes'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'

const formsModule: string = ModuleNamespaces.FORMS
const dialogModule = ModuleNamespaces.DIALOG
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const entitiesModule = ModuleNamespaces.ENTITIES

@Component
export default class DniValidatorMixin extends Vue {
  @Action('contactsValidate', { namespace: formsModule })
  contactsDniValidate: ({}) => {}

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  selectedRelatedData: any

  @Getter('getCustomValidateField', { namespace: formsModule })
  getCustomValidateField: (key: string) => any

  @Getter('getSelectedEntityName', { namespace: entitiesModule })
  selectedEntityName: string

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  @Emit()
  executeAction(value: string) {
    return value
  }

  mounted() {
    extend('uniqueDNI', {
      validate: (value: string) => {
        return this.validateUniqueDni(value)
      },
      message: () => {
        return ''
      }
    })
    extend('uniqueDNInoPrevPage', {
      validate: (value: string) => {
        return this.validateUniqueDni(value, false)
      },
      message: () => {
        return ''
      }
    })
  }

  async validateUniqueDni(value: string, noPrevPage: boolean = true): Promise<boolean> {
    const dni: string = this.getCustomValidateField(customValidatorTypes.DNI)
    if (value === dni) {
      return true
    }

    const id = this.selectedRegisterId(this.selectedEntityName)
    const data: any = await this.contactsDniValidate({
      params: {
        id: id || this.selectedRelatedData.id,
        dni: value,
        idType: ContactTypeId.CONTACTS_ALL
      }
    })

    return this.checkIfContinueToCustomersGrid(data[0], noPrevPage)
  }

  closeAndGoToPreviousPage(noPrevPage: boolean = true) {
    this.closeCustomDialog()
    this.executeAction(ActionName.CLOSE)
    if (noPrevPage) {
      goToPreviousPage()
    }
  }

  checkIfContinueToCustomersGrid(value: any, noPrevPage: boolean = true): boolean {
    if (!value || value.length === 0) {
      return true
    }

    this.showDialog({
      type: DialogTypes.WARNING,
      message: this.$t('components.dialog.dni_validation', {
        contactType: value.type,
        name: value.name
      }) as TranslateResult,
      mainButtonText: this.$t('action_buttons.no'),
      secondaryButtonText: this.$t('action_buttons.yes'),
      action: () => this.closeAndGoToPreviousPage(noPrevPage)
    })
    return true
  }
}
</script>
