import { createElement } from '@syncfusion/ej2-base'
import { TextBox } from '@syncfusion/ej2-inputs'
import { i18n } from '@/plugins/vue-i18n'
import { IFilter } from '@syncfusion/ej2-grids'
import { ref } from 'vue'

interface PropsUseCustomInputFilter {
  setParsedFilterSettingsFlag: (value: boolean) => void
}

interface ResponseUseCustomInputFilter {
  customInputFilter: IFilter
}

export default function useCustomInputFilter(props: PropsUseCustomInputFilter): ResponseUseCustomInputFilter {
  const { setParsedFilterSettingsFlag } = props

  const inputInstance = ref<TextBox>()

  const customInputFilter: IFilter = {
    ui: {
      create: (args: any) => {
        const flValInput = createElement('input', { className: 'flm-input' })
        flValInput.setAttribute('placeholder', i18n.t('components.grid_table.enter_search').toString())
        args.target.appendChild(flValInput)
        inputInstance.value = new TextBox({})
        inputInstance.value.appendTo(flValInput)
      },
      write: (args: any) => {
        inputInstance.value = args.filteredValue ? args.filteredValue : ''
      },
      read: (args: any) => {
        setParsedFilterSettingsFlag(true)
        if (args.fltrObj.value && args.fltrObj.value.length > 0) {
          args.fltrObj.clearFiltering([args.column.field])
        }
        args.fltrObj.filterByColumn(args.column.field, args.operator, inputInstance.value)
      }
    }
  }

  return {
    customInputFilter
  }
}
