<template lang="pug">
  div(class="document-form")
    TabHeaderComponent(
      @saveForm="onSaveForm"
      @closeForm="onCloseForm"
      :buttons="buttons"
      :title="title"
    )
    TabsComponent(
      ref="tabComponent"
      v-if="tabs && tabs.length"
      :tabs="tabs"
      :context="context"
      @changed-tab="tabChanged"
      class="tabs-secondary-styles"
    )
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { RepositoryService } from '@/services/RepositoryService'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { EditDocumentMode } from '@/store/modules/fileManager/fileManagerTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const formsModule: string = ModuleNamespaces.FORMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const tabsModule: string = ModuleNamespaces.TABS

@Component({
  components: {
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue')
  }
})
export default class FileManagerEditFolderComponent extends Vue {
  @Prop({
    type: Number
  })
  idDocument!: number

  @Prop({
    type: Number
  })
  idEntity!: number

  @Prop({
    type: Number
  })
  idEntityType!: number

  @Prop({
    type: String
  })
  nameDocument!: string

  @Prop({
    type: String
  })
  basePath!: string

  @Prop({
    type: Number
  })
  editDocumentMode: EditDocumentMode

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => {}

  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  saveSelectedRegisterData: ({}) => void
  @Mutation('CHANGE_DISABLED_TABS', { namespace: tabsModule })
  changeDisplayTabs: (status: boolean) => void

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []
  @Getter('checkIfFormIsValid', { namespace: formsModule }) checkIfFormIsValid: (context: string) => boolean
  @Getter('getRegisterFormData', { namespace: formsModule }) registerFormData: any
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number
  @Getter('getDocument', { namespace: fileManagerModule }) document: string

  context = ContextName.FOLDER
  documentContext = ContextName.DOCUMENT
  editMode = false

  get tabs() {
    return this.getCurrentViewTabs(this.context)
  }

  get title() {
    const title: string = (this as any).document.name
    return title
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        enabled: true,
        variant: LabelButtonVariant.TEXT
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: true
      }
    ]
  }

  async tabChanged(_tabIndex: number, _objectType: string, resolve: any) {
    const document = this.document
    await this.saveSelectedRegisterData({
      selectedRegisterData: document,
      context: ContextName.DOCUMENT
    })

    typeof resolve === 'function' && resolve()
    this.$emit('showForm')
  }

  onCloseForm() {
    this.$emit('close')
  }

  async onSaveForm() {
    const folder = this.registerFormData
    folder.id = this.idDocument
    folder.idEntity = this.idEntity
    folder.idEntityType = this.idEntityType
    const formData = new FormData()
    formData.append('folder', JSON.stringify(folder))
    const response = await new RepositoryService().updateFolder(formData)
    const { data } = response

    if (data.result) {
      this.$emit('on-save-document-ok', this.nameDocument)
    } else {
      this.$emit('on-save-document-fail')
    }
  }

  async created() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.FOLDER_PROPERTIES,
      context: this.context
    })
  }

  destroyed() {
    this.changeDisplayTabs(false)
  }
}
</script>

<style lang="scss" scoped>
.document-form {
  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
