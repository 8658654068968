import { ref, Ref } from 'vue'
import { GridComponent as EjsGrid, FilterSettings, IFilter } from '@syncfusion/ej2-vue-grids'
import useCustomInputFilter from '../customFilters/useCustomInputFilter'
import { ColumnModel } from '../LfGridTypes'
import useCustomDateFilter from '../customFilters/useCustomDateFilter'
import { computed } from 'vue'

interface PropsUseGridFilters {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
  defaultFilterSettings: FilterSettings
}

interface ResponseUseGridFilters {
  hasFilters: Ref<boolean>
  clearFilter: () => void
  getCustomFilter: (column: ColumnModel) => IFilter
  setParsedFilterSettingsFlag: (value: boolean) => void
  deleteDateFilterValue: (key: string) => void
  filterDateColumn: (args: any) => void
}

export default function useGridFilters(props: PropsUseGridFilters): ResponseUseGridFilters {
  const { gridRef, defaultFilterSettings } = props

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  const parsedFilterSettingsFlag = ref(false)
  const setParsedFilterSettingsFlag = (value: boolean) => {
    parsedFilterSettingsFlag.value = value
  }

  const { customInputFilter } = useCustomInputFilter({ setParsedFilterSettingsFlag })
  const { customFilterDate, deleteDateFilterValue, clearDateFilterValue, filterDateColumn } = useCustomDateFilter({ setParsedFilterSettingsFlag, gridRef })

  const getCustomFilter = (column: ColumnModel): IFilter => {
    if (column.isCheckboxType) {
      return { type: 'CheckBox' }
    }
    switch (column.filterModule) {
      case 'customFiler': return customInputFilter // TODO - need test
      // case 'customFilerDuration': return getCustomFilerDuration()
      case 'customFilterDate': return customFilterDate
      // case 'customBooleanFilter': return getCustomBooleanFilter()
      default:
        return defaultFilterSettings
    }
  }

  const clearFilter = () => {
    if (gridRef.value) {
      ; (gridRef.value as any).clearFiltering()
      clearDateFilterValue()
      setParsedFilterSettingsFlag(false)
    }
  }

  const filters = computed(() => {
    if (gridRef.value) {
      const { columns } = (gridInstance.value as any)?.filterSettings
      return columns
    }
    return []
  })

  const hasFilters = computed((): boolean => {
    return Boolean(filters.value.length)
  })

  return {
    hasFilters,
    setParsedFilterSettingsFlag,
    getCustomFilter,
    deleteDateFilterValue,
    clearFilter,
    filterDateColumn
  }
}
