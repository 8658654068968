import { Icons } from '@/icons/icons'
import {
  ActionEntityId,
  actionsEntityId,
  agendaEntityId,
  billEntityId, ExpedientEntityId,
  expedientEntityId,
  igualasEntityId, InvoiceEntityId,
  lexnetEntityId
} from '@/general/entityIds'
import { URLS } from '@/router/routes/urlRoutes'

export interface Pagination {
  numPage: number,
  totalRecs: number
}

export interface AlertMessageList {
  data: AlertMessage[],
  status: ListStatus,
  pagination: Pagination
}

export interface AlertsMessagesState {
  alertsMessagesList: AlertMessageList,
  filteredList: AlertMessageList,
  filter: AlertMessageFilter,
  hasAlert: boolean
}

export interface AlertMessage {
  id: string,
  type: AlertMessageType,
  typeId: AlertMessageTypeId,
  icon: Icons,
  message: string,
  createAt: string,
  expireAt: string,
  url: string,
  visibility: AlertMessageVisibility
  entityId: string
}

export enum AlertMessageVisibility {
  READ ='READ',
  NO_READ = 'NO_READ',
  DONE = 'DONE' // DONE state only for change, is not visible state
}

export enum AlertMessageType {
  ALL = 'ALL',
  EXPEDIENT = 'EXPEDIENT',
  LEXNET = 'LEXNET',
  IGUALAS = 'IGUALAS',
  ACTIONS = 'ACTIONS',
  BILL = 'BILL',
  AGENDA = 'AGENDA'
}

export enum AlertMessageTypeId {
  ALL = 0,
  EXPEDIENT = expedientEntityId,
  LEXNET = lexnetEntityId,
  IGUALAS = igualasEntityId,
  ACTIONS = actionsEntityId,
  BILL = billEntityId,
  AGENDA = agendaEntityId
}

export const AlertsMessagesIcons: { [key in AlertMessageType]: Icons } = {
  [AlertMessageType.ALL]: Icons.ACTIONS,
  [AlertMessageType.EXPEDIENT]: Icons.EXPEDIENT,
  [AlertMessageType.LEXNET]: Icons.LEXNET,
  [AlertMessageType.IGUALAS]: Icons.MONEY_BAG,
  [AlertMessageType.ACTIONS]: Icons.ACTIONS,
  [AlertMessageType.BILL]: Icons.BILL,
  [AlertMessageType.AGENDA]: Icons.CONTACTS,
}

export const AlertsMessageVisibilityIcons: { [key in AlertMessageVisibility]: Icons } = {
  [AlertMessageVisibility.READ]: Icons.VISIBLE,
  [AlertMessageVisibility.NO_READ]: Icons.NOT_VISIBLE,
  [AlertMessageVisibility.DONE]: Icons.VISIBLE
}

export const AlertsMessageToggleVisibility: { [key in AlertMessageVisibility]: AlertMessageVisibility } = {
  [AlertMessageVisibility.READ]: AlertMessageVisibility.NO_READ,
  [AlertMessageVisibility.NO_READ]: AlertMessageVisibility.READ,
  [AlertMessageVisibility.DONE]: AlertMessageVisibility.READ
}

export enum AlertMessageOrderEnum {
  CRONO = 'crono',
  EXPIR = 'expira',
}

export const alertsMessagesMutationTypes = {
  UPDATE_ALERTS_MESSAGES: 'UPDATE_ALERTS_MESSAGES',
  UPDATE_FILTERED_LIST: 'UPDATE_FILTERED_LIST',
  SET_FILTER: 'SET_FILTER',
  SET_HAS_ALERTS: 'SET_HAS_ALERTS',
  SET_LOAD_ALERT_MESSAGES_LIST_ERROR: 'SET_LOAD_ALERT_MESSAGES_LIST_ERROR',
  SET_LOAD_FILTERED_LIST_ERROR: 'SET_LOAD_FILTERED_LIST_ERROR',
  RESET_ALERT_MESSAGES_LIST: 'RESET_ALERT_MESSAGES_LIST',
  RESET_FILTERED_LIST: 'RESET_FILTERED_LIST'
}

export interface AlertMessageFilter {
  type: AlertMessageType,
  order: AlertMessageOrderEnum,
  page: number
}

export enum ListStatus {
  EMPTY = 'EMPTY',
  OK = 'OK',
  ERROR = 'ERROR',
  LOADING = 'LOADING'
}

export const alertMessagesRouterNames: { [key in number]: string } = {
  [agendaEntityId]: `${URLS.AGENDA}-${URLS.EDIT}`,
  [ExpedientEntityId.EXPEDIENTS]: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
  [ActionEntityId.ALL]: `${URLS.ACTIONS}-${URLS.ACTION_ALL}`,
  [ActionEntityId.CALL]: `${URLS.ACTIONS}-${URLS.ACTION_CALLS}`,
  [ActionEntityId.MEETING]: `${URLS.ACTIONS}-${URLS.ACTION_MEETINGS}`,
  [ActionEntityId.PROCEDURE]: `${URLS.ACTIONS}-${URLS.ACTION_PROCEDURES}`,
  [ActionEntityId.OTHER]: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
  [ActionEntityId.EMAIL]: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
  [ActionEntityId.LEXNET]: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
  [ActionEntityId.TASK]: `${URLS.ACTIONS}-${URLS.ACTION_TASKS}`,
  [ActionEntityId.GEST]: `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`,
  [InvoiceEntityId.CUSTOMER_BAKDRAFTS]: `${URLS.BILLING}-${URLS.CUSTOMER_BAKDRAFTS}`,
  [InvoiceEntityId.CUSTOMER_INVOICES]: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
  [InvoiceEntityId.PROFORMA_INVOICES]: `${URLS.BILLING}-${URLS.PROFORMA_INVOICES}`,
  [InvoiceEntityId.PROVISIONS]: `${URLS.BILLING}-${URLS.PROVISIONS}`,
  [InvoiceEntityId.PROVIDER_BANKDRAFTS]: `${URLS.BILLING}-${URLS.PROVIDER_BANKDRAFTS}`,
  [InvoiceEntityId.PROVIDER_INVOICES]: `${URLS.BILLING}-${URLS.PROVIDER_INVOICES}`,
  [InvoiceEntityId.ORDER_FORMS]: `${URLS.BILLING}-${URLS.ORDER_FORMS}`,
}

export const defaultList: AlertMessageList = {
  data: [],
  status: ListStatus.LOADING,
  pagination: {
    numPage: 0,
    totalRecs: 0
  }
}

export const defaultFilter: AlertMessageFilter = {
  type: AlertMessageType.ALL,
  order: AlertMessageOrderEnum.CRONO,
  page: 1
}

