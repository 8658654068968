<template>
  <div class="loading-docs-ia-dialog-container">
    <p class="loading-docs-ia-text">{{ t('components.file_manager.dialogs.docs_IA_message') }}</p>
    <SpinnerLayerComponent />
  </div>
</template>

<script setup lang="ts">
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
$spinner-size: 32px;

.loading-docs-ia-dialog-container {
  width: 100%;

  .loading-docs-ia-text {
    padding: $spacing-xxs $spacing-md;
    margin-bottom: $spacing-xl;
  }

  ::v-deep(.spinner-lefebvre) {
    width: $spinner-size;
    height: $spinner-size;
    border: 0.19em solid $corporate-color;
    border-right-color: transparent;
    margin-bottom: $spacing-xl;
  }
}
</style>
