<template>
  <div class="alerts-list-container">
    <p class="alert-title">{{ texts.alerts }}</p>
    <v-list v-if="ListStatus.OK === status" class="alert-list">
      <AlertListItemComponent v-for="(alert, index) in alertsMessagesList" :alert="alert" :key="index" />
    </v-list>
    <section v-if="ListStatus.EMPTY === status" class="no-data-container">
      <span :class="[icon.noData, 'lf-icon__xl', 'no-data-icon']" />
      <div class="texts">
        <p class="no-data-text">{{ texts.noData }}</p>
        <i18n :path="path" tag="div" class="link-text">
          <template v-slot:link>
            <router-link :to="alertConfigPanelURL" class="actions-link">{{ texts.link }}</router-link>
          </template>
        </i18n>
      </div>
    </section>
    <section v-if="ListStatus.ERROR === status" class="no-data-container">
      <span :class="[icon.error, 'lf-icon__xl', 'no-data-icon']" />
      <div class="texts">
        <p class="no-data-text">{{ texts.loadError }}</p>
      </div>
    </section>
    <LfLabelButtonComponent
      class="alerts-btn"
      :disabled="!alertsMessagesList.length"
      :text="texts.viewMoreAlerts"
      :action="() => goToAlertsMessagesView()"
      :variant="LabelButtonVariant.OUTLINED"
    />
  </div>
</template>

<script lang="ts" setup>
import useRouter from '@/composables/useRouter'
import { Icons } from '@/icons/icons'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { useI18n } from 'vue-i18n-composable'
import { URLS } from '@/router/routes/urlRoutes'
import AlertListItemComponent from './AlertListItemComponent.vue'
import useActionsAlertsMessages from '@/components/Navigation/AppBarToolbarItems/contextMenus/composables/useActionsAlertsMessages'
import { ListStatus } from '@/store/modules/alertsMessages/alertsMessagesTypes'

// ROUTER
const { goToRoute } = useRouter()

// TRANSLATIONS
const { t } = useI18n()
const texts = {
  alerts: t('navigation.alerts_menu.alerts'),
  loadError: t('navigation.alerts_menu.load_error'),
  viewMoreAlerts: t('navigation.alerts_menu.more_alerts'),
  link: t('navigation.alerts_menu.no_data.link'),
  noData: t('navigation.alerts_menu.no_data.text')
}

// DATA
const icon = {
  noData: Icons.ARCHIVE_EMPTY,
  error: Icons.CLOSE_ROUND
}
const alertConfigPanelURL = `/panel-configuracion/alertas`
const path = 'navigation.alerts_menu.no_data.link_text'

// COMPOSABLES
const { status, alertsMessagesList } = useActionsAlertsMessages()

// METHODS
function goToAlertsMessagesView() {
  goToRoute({
    name: URLS.ALERT_MESSAGES
  })
}
</script>

<style lang="scss" scoped>
.alerts-list-container {
  .alert-list {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
  }

  .alert-title {
    @include milano-bold-18;
    color: $neutral-grey-800;
  }

  .alerts-btn {
    float: right;
    margin-top: 16px;
  }

  .no-data-container {
    display: flex;
    padding: $spacing-lg $spacing-xl;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xs;
    height: 397px;

    .no-data-icon {
      color: $main-400;
    }

    .texts {
      .no-data-text {
        @include milano-medium-16;
        color: $neutral-grey-600;
        margin-bottom: 0;
      }

      .link-text {
        @include milano-regular-14;
        color: $neutral-grey-600;

        .actions-link {
          color: $main-800;
          text-decoration-line: underline;
        }
      }
    }
  }

  .legal-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    position: relative;

    ::v-deep .pagination-component {
      display: flex;
      width: 300px;
      .pagination-item {
        button {
          width: auto;
          &.icon {
            font-size: 10px;
            width: auto;
            min-width: 24px;
          }
        }
      }
    }

    ::v-deep .legal-select-num-recs {
      display: flex;
      width: 70px;
      max-width: 70px;
      margin: 0;
      padding: 0;

      .v-select__selection {
        font-family: $secondary-font;
        font-size: 14px;
        color: $corporate-color;
        position: relative;
        top: -3px;
      }

      .v-text-field {
        margin: 0 20px 0 0;
        padding: 4px 0;
        height: 20px;
      }

      .lf-icon-angle-down {
        padding-bottom: 0px;
        top: 4px;
        position: relative;

        &::before {
          content: '';
          border-width: 8px;
          border-style: solid;
          border-right: 8px solid transparent;
          border-color: $corporate-color transparent transparent;
          width: 0;
          height: 0;
        }
      }

      .v-input__slot {
        height: 20px;
        &::before,
        &::after {
          border: none;
        }
      }
    }

    ::v-deep .legal-page-info {
      font-family: $corporate-font;
      font-size: 14px;
      color: $corporate-color;
      display: flex;
      align-self: end;
    }

    ::v-deep .recs-per-page {
      font-family: $corporate-font;
      font-size: 14px;
      color: $corporate-color;
      display: flex;
    }

    ::v-deep .select-wrapper {
      display: flex;
    }
  }
}
</style>
