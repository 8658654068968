import { reportsEntityId } from '@/general/entityIds'
import { URLS } from '@/router/routes/urlRoutes'
import ReportsList from '@/views/Reports/ReportsList.vue'
import ReportsView from '@/views/Reports/ReportsView.vue'

export const reportsRoutes = [
  {
    path: `/${URLS.REPORTS}`,
    name: URLS.REPORTS,
    component: ReportsList,
    meta: {
      requiresAuth: true,
      title: URLS.REPORTS,
      parentUrl: URLS.REPORTS,
      entityType: reportsEntityId
    },
    props: true
  },
  {
    path: `/${URLS.REPORTS}/:selectedRegisterId`,
    name: URLS.REPORT,
    component: ReportsView,
    meta: {
      requiresAuth: true,
      title: URLS.REPORTS,
      parentUrl: URLS.REPORTS,
      entityType: reportsEntityId
    },
    props: true
  },
  {
    path: `/${URLS.REPORTS_LIST}`,
    name: URLS.REPORTS_LIST,
    component: () => import('@/views/Reports/ReportsListView.vue'),
    meta: {
      requiresAuth: true,
      title: URLS.REPORTS_LIST,
      parentUrl: URLS.REPORTS,
      entityType: reportsEntityId
    },
    props: true
  },
  {
    path: `/${URLS.REPORTS_LIST}/:selectedReportId`,
    name: URLS.REPORT_DETAIL,
    component: () => import('@/views/Reports/ReportDetailView.vue'),
    meta: {
      requiresAuth: true,
      title: URLS.REPORT_DETAIL,
      parentUrl: URLS.REPORTS,
      entityType: reportsEntityId
    },
    props: true
  }
]
