<template>
  <div class="custom-fields-info-text-container">
    <p class="instructions">
      {{ text.instructions }}
    </p>

    <div v-if="props.showInfoText" class="info-text-container">
      <span :class="[icon.info, 'lf-icon__md', 'info-icon']" />
      <p class="info-text">
        {{ text.infoText }}
      </p>
      <v-spacer />
      <span :class="[icon.close, 'lf-icon__sm', 'close-icon']" @click="closeInfoText" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Icons } from '@/icons/icons'
import { useI18n } from 'vue-i18n-composable'
import { PropType } from 'vue/types/v3-component-props'

// PROPS
const props = defineProps({
  showInfoText: {
    type: Boolean as PropType<boolean>,
    default: true
  }
})

// TRANSLATIONS
const { t } = useI18n()
const text = {
  instructions: t('components.custom_fields_info_texts.instructions'),
  infoText: t('components.custom_fields_info_texts.info_text')
}

// DATA
const icon = {
  close: Icons.CLOSE,
  info: Icons.INFO_FULL
}

// EVENTS
const emit = defineEmits(['hide-info'])

// METHODS
function closeInfoText() {
  emit('hide-info')
}
</script>

<style lang="scss" scoped>
.custom-fields-info-text-container {
  .instructions {
    @include milano-regular-14;
    color: $neutral-grey-800;
  }

  .info-text-container {
    display: flex;
    align-items: center;
    padding: $spacing-xs $spacing-sm;
    gap: $spacing-xs;
    border-radius: $cornerRadius-sm;
    background: $tertiary-byzantineBlue-050;
    margin-bottom: 12px;

    .info-icon,
    .close-icon {
      color: $tertiary-byzantineBlue-200;
    }

    .close-icon {
      font-weight: bold;
      cursor: pointer;
    }

    .info-text {
      @include flex;
      @include milano-regular-14;
      margin: 0;
      color: $neutral-grey-800;
    }
  }
}
</style>
