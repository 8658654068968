<template>
  <div class="filters-container">
    <v-chip
      v-for="filter in parsedFilters" :key="filter.field"
      class="ma-2"
      x-small
      close
      label
      @click:close="removeFilter(filter)"
    >
      {{filter.field}}: <b> {{filter.value}}</b>
    </v-chip>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

export interface Filter {
  value: string
  field: string
}

const emit = defineEmits(['removeFilter'])

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  filters: {
    default: () => []
  }
})
// COMPOSABLES   ======================================================================================================
const parsedFilters = computed(() => {
  return props.filters.map(({value, field}) => ({value, field}))
})

// METHODS ============================================================================================================
const removeFilter = (filter: Filter) => {
  emit('removeFilter', {filter})
}

</script>
<style lang="scss" scoped>
.filters-container {
  display: flex;
}
</style>
