<template lang="pug">

div(:class="[{ 'hide-border': hideTabs }, 'tabs-container']")
  v-tabs(
      v-model="selectedTabIndex"
      background-color="transparent"
      hide-slider
      show-arrows
      :next-icon="icons.doubleArrowRight"
      :prev-icon="icons.doubleArrowLeft"
      :class="[{ 'hide-tabs': hideTabs }, 'tabs']"
    )
    v-tab(
      v-for="(tab, tabIndex) in tabs"
      @change="onTabChange(tabIndex, tab)"
      :key="`tab-${tabIndex}`"
      :ripple="false"
      active-class="active"
      :class="[{ 'change-tab-styles': $vuetify.breakpoint.mdAndUp, 'disabled': disabledTabs, 'hidden-tab': tab.hiddenTab, 'disabled-tab': tab.disabledTab }, 'tab']"
      :disabled="setDisabledTab(tabIndex)"
    )
      span(class="tab-label") {{ tab.label }}
      span(v-if="tab.total" class="tab-total") ({{ tab.total }})

  //- TAB SELECTOR COMPONENT
  TabSelectorComponent(
    v-if="renderTabSelectorComponent"
    :idEntity="entity.type"
    :disabled="disabledTabs"
    @beforeSave="onBeforeSaveTabsVisibility"
    @afterSave="onAfterSaveTabsVisibility"
    @onTabSelectorOpen="onBeforeOpenTabSelector"
  )

  v-tabs-items(v-model="selectedCurrentTabIndex")
    v-tab-item(
      v-for="(tab, contentIndex) in tabs"
      class="tab-content"
      :key="`tab-${contentIndex}`"
      :transition="false"
      :reverse-transition="false"
    )
      SpinnerLayerComponent(
        v-if="showTabSpinner"
        class="spinner-layer-container"
      )
      div(
        class="dynamic-component-wrapper"
        v-for="(component, componentIndex) in tab.items"
        :key="`component-${componentIndex}`"
      )
        component(
          ref="currentRenderedComponent"
          :is="renderTypeOfComponent(component.idSubTypeObject)"
          v-if="selectedCurrentTabIndex === contentIndex && everythingLoaded"
          v-bind="setPropsDependingOnComponentType(component, tab, selectedCurrentTabIndex)"
          v-model="component.idSubTypeObject === componentType.form ? formData : false"
          @saveFormData="onSaveFormData"
          @gridAction="onGridAction"
          @simpleGridActionNew="onSimpleGridActionNew"
          @simpleGridActionEdit="onSimpleGridActionEdit"
          @simpleGridActionRemove="onSimpleGridActionRemove"
          @openForm="onOpenForm"
          @closeForm="onCloseForm"
          @saveForm="onSaveForm"
          @isValidForm="onFormIsValid"
          @createdForm="onFormCreated"
          @mountedForm="onFormMounted"
          @disabledTabs="disabledTabsMethod"
          @enabledTabs="toggleStatusTabs"
          @setDisabledTabsMessage="setDisabledTabMessage"
          @removeItem="onTabHeaderRemoveItem"
          @set-tandem-filter="setTandemFilter"
          @change-breadcrumbs="changeBreadcrumbs"
          @expedientGlobalVisionComponentMounted="onExpedientGlobalVisionMounted"
          :key="componentKey"
        )
      //- Related Form from simplegrid
      RelatedForm(
        v-if="selectedCurrentTabIndex === contentIndex && showRelatedForm(context)"
        :selectedRegisterId="currentId || ''"
        :context="context"
        :endpoints="relatedEndpoints"
        :currentTabName="currentTabName"
        :editMode="editMode"
        :maintenanceType="currentComponent.maintenanceType"
        :permissionsEntityProp="permissionsEntityProp ? permissionsEntityProp : null"
        @field-changed="onFieldChanged"
        @closeForm="onCloseForm"
      )

  AlertComponent(
    :whereIsRendered="componentWhereIsRendered"
  )
</template>

<script lang="ts">
import { Component, Emit, Inject, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import CustomFieldsContainerComponent from '@/components/customFields/CustomFieldsContainer/CustomFieldsContainerComponent.vue'
import CustomFieldsTabComponent from '@/components/customFields/CustomFieldsTab/CustomFieldsTabComponent.vue'
import CustomerPortalComponent from '@/components/CustomerPortal/CustomerPortalComponent.vue'
import EmailTemplatesCustomerPortalTabComponent from '@/components/maintenance/customerPortal/EmailTemplatesCustomerPortalTabComponent.vue'
import ExpedientActionsComponent from '@/components/expedients/ExpedientActions/ExpedientActionsComponent.vue'
import ExpedientAgendaComponent from '@/components/expedients/ExpedientAgenda/ExpedientAgendaComponent.vue'
import ExpedientCourtsComponent from '@/components/expedients/ExpedientCourts/ExpedientCourtsComponent.vue'
import ExpedientEconomicDataComponent from '@/components/expedients/ExpedientEconomicData/ExpedientEconomicDataComponent.vue'
import ExpedientGlobalVisionComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientGlobalVisionComponent.vue'
import ExpedientInsurersComponent from '@/components/expedients/ExpedientInsurers/ExpedientInsurersComponent.vue'
import ExpedientInvoicesComponent from '@/components/expedients/ExpedientInvoices/ExpedientInvoicesComponent.vue'
import ExpedientPeriodicBillingComponent from '@/components/expedients/ExpedientPeriodicBilling/ExpedientPeriodicBillingComponent.vue'
import FileManagerComponent from '@/components/FileManager/FileManagerComponent.vue'
import FormAlertComponent from '@/components/Alert/FormAlertComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import InvoicesTabComponent from '@/components/billing/InvoicesTab/InvoicesTabComponent.vue'
import NumerationComponent from '@/components/Numeration/NumerationComponent.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import TabSelectorComponent from '@/components/tabs/TabSelector/TabSelectorComponent.vue'
import TemplateGeneratorComponent from '@/components/template/TemplateGenerator/TemplateGeneratorComponent.vue'
import UsersMaintenanceComponent from '@/components/maintenance/Users/UsersMaintenanceComponent.vue'
import SubActionsComponent from '@/components/actions/SubActions/SubActionsComponent.vue'
import BankdraftTabComponent from '@/components/billing/BankdraftTab/BankdraftTabComponent.vue'
import UsersMaintenanceCustomPermissionsComponent from '@/components/maintenance/Users/components/UsersMaintenanceCustomPermissionsComponent.vue'
import {
  ConfigurationAlias,
  ConfigurationElements,
  ConfigurationTypes,
  ListConfigurationType
} from '@/store/modules/configuration/configurationTypes'
import { Getter, Action, Mutation } from 'vuex-class'
import { ModuleNamespaces, ContextName } from '@/store/types/storeGlobalTypes'
import { i18n } from '@/plugins/vue-i18n'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import RelatedForm from '@/components/forms/Related/RelatedForm.vue'
import RelatedFormMixin from '@/mixins/RelatedFormMixin.vue'
import LegalSearchComponent from '@/components/search/LegalSearch/LegalSearchComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import TandemSearchComponent from '@/components/search/TandemSearch/TandemSearchComponent.vue'
import { Endpoint, endpoints } from '@/store/modules/endpoint/endpointTypes'
import { getObjectByKey } from '@/helpers/object'
import { CustomerPortal } from '@/store/modules/customerPortal/customerPortalTypes'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import { SearchTab, Tab } from '@/store/modules/tabs/tabsTypes'
import { ContextMenuItemModel } from '@syncfusion/ej2-vue-grids'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import { ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import ContactContactsComponent from '@/components/contacts/ContactContacts/ContactContactsComponent.vue'
import ContactExpedientsComponent from '@/components/contacts/ContactExpedients/ContactExpedientsComponent.vue'
import ContactActionsComponent from '@/components/contacts/ContactActions/ContactActionsComponent.vue'
import ContactProvisionsComponent from '@/components/contacts/ContactProvisions/ContactProvisionsComponent.vue'
import ContactInvoicesComponent from '@/components/contacts/ContactInvoices/ContactInvoicesComponent.vue'
import ContactBankdraftsComponent from '@/components/contacts/ContactBankdrafts/ContactBankdraftsComponent.vue'
import AlertsMessagesComponent from '@/components/AlertsMessages/AlertsMessagesComponent.vue'
import ActionClassificationComponent from '@/components/actions/ActionClassification/ActionClassificationComponent.vue'
import ProfessionalAccessComponent from '@/components/maintenance/Users/components/ProfessionalAccessComponent.vue'
import EditDocumentIntervenerComponent from '@/components/FileManager/components/editDocumentIntervener/EditDocumentIntervenerComponent.vue'
import { ExpedientEntityId } from '@/general/entityIds'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import { capitalizeFirstLetter } from '@/helpers/strings'
import useParentFields from '@/composables/useParentFields'
// import schemaMock from '../Mocks/schema'

// TODO FIGMA - QUITAR ESTO?
// :class="['tab-content', { 'no-padding': removeTabPadding }]"

const alertsModule: string = ModuleNamespaces.ALERTS
const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const contactsModule: string = ModuleNamespaces.CONTACTS
const customerPortalModule = ModuleNamespaces.CUSTOMER_PORTAL
const endpointModule: string = ModuleNamespaces.ENDPOINT
const formsModule: string = ModuleNamespaces.FORMS
const maintenanceModule: string = ModuleNamespaces.MAINTENANCE
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const listItemsModule: string = ModuleNamespaces.LIST_ITEMS
const tabsModule: string = ModuleNamespaces.TABS

@Component({
  components: {
    AlertComponent,
    ActionClassificationComponent,
    ContactContactsComponent,
    ContactExpedientsComponent,
    ContactActionsComponent,
    ContactInvoicesComponent,
    ContactProvisionsComponent,
    ContactBankdraftsComponent,
    AlertsMessagesComponent,
    CustomFieldsContainerComponent,
    CustomFieldsTabComponent,
    CustomerPortalComponent,
    EmailTemplatesCustomerPortalTabComponent,
    ExpedientActionsComponent,
    ExpedientAgendaComponent,
    ExpedientCourtsComponent,
    ExpedientEconomicDataComponent,
    ExpedientGlobalVisionComponent,
    ExpedientInsurersComponent,
    ExpedientIntervenersComponent: () =>
      import('@/components/expedients/ExpedientInterveners/ExpedientIntervenersComponent.vue'),
    ExpedientInvoicesComponent,
    ExpedientPeriodicBillingComponent,
    FileManagerComponent,
    FormAlertComponent,
    FormGeneratorComponent,
    GridTableComponent,
    InvoicesTabComponent,
    LegalSearchComponent,
    LfGridComponent,
    NumerationComponent,
    ProfessionalAccessComponent,
    RelatedForm,
    SimpleGridTableComponent,
    SpinnerLayerComponent,
    TabHeaderComponent,
    TabSelectorComponent,
    TandemSearchComponent,
    TemplateGeneratorComponent,
    UsersMaintenanceComponent,
    UsersMaintenanceCustomPermissionsComponent,
    SubActionsComponent,
    EditDocumentIntervenerComponent,
    BankdraftTabComponent,
    StaticAlertComponent,
    SearchExpedients: () => import('@/views/Search/components/SearchExpedientsComponent.vue'),
    SearchContacts: () => import('@/views/Search/components/SearchContactsComponent.vue'),
    SearchActions: () => import('@/views/Search/components/SearchActionsComponent.vue'),
    SearchInvoice: () => import('@/views/Search/components/SearchInvoiceComponent.vue'),
    SearchDocumentation: () => import('@/views/Search/components/SearchDocumentComponent.vue'),
    SearchAgenda: () => import('@/views/Search/components/SearchAgendaComponent.vue')
  }
})
export default class TabsComponent extends Mixins(
  RelatedFormMixin,
  GridToolbarOptionsMixin,
  GridContextMenuOptionsMixin
) {
  @Inject({
    default: false
  })
  saveOnBeforeOpenTabSelector!: boolean

  @Prop({
    type: Array,
    required: true
  })
  tabs!: []

  @Prop({
    type: Number,
    default: 0
  })
  selectedTab!: number

  @Prop({
    type: Boolean
  })
  allDataLoaded!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  autoRefreshForm!: boolean

  @Prop({
    type: Function
  })
  beforeSetComponentProperties: (component: any, tab: any, tabIndex: number) => {}

  @Prop({
    type: Function
  })
  beforeSetInitialTab: () => {}

  @Prop({
    type: Number
  })
  permissionsEntityProp: number

  @Prop({
    type: Array
  })
  simpleGridContextMenuItemsProp: ContextMenuItemModel[] | ContextMenuItem[]

  @Prop({
    type: Boolean,
    default: true
  })
  forceSaveProp!: boolean

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('fetchRelatedFormFields', { namespace: configurationModule })
  fetchRelatedFormFields: (formName: string) => []

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Action('fetchContactsContact', { namespace: contactsModule })
  fetchContactsContact: ({}) => []

  @Action('fetchCustomerPortal', { namespace: customerPortalModule })
  fetchCustomerPortal: (customerId: string | number) => CustomerPortal[]

  @Action('saveRegisterFormData', { namespace: formsModule })
  saveRegisterFormData: ({}) => void

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => void

  @Action('cancelPendingRequests', { namespace: authModule })
  cancelPendingRequests: () => {}

  @Mutation('CLEAR_CUSTOMER_PORTAL', { namespace: customerPortalModule })
  clearCustomerPortal: () => void

  @Mutation('VALIDATE_FORM', { namespace: formsModule })
  emitSignToValidateForm: () => void

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => []

  @Mutation('RESET_CURRENT_LIST_CONFIGURATION', { namespace: configurationModule })
  resetListConfiguration: () => {}

  @Mutation('RESET_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  resetSelectedRegister: (context: string) => void

  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  setSelectedRegister: ({}) => void

  @Mutation('RESET_CUSTOM_VALIDATE_FIELD', { namespace: formsModule })
  resetCustomValidateField: () => void

  @Mutation('SET_CURRENT_LIST_CONFIGURATION', { namespace: configurationModule })
  setCurrentListConfiguration: (data: any) => void

  @Mutation('FETCH_SELECTED_RELATED_DATA', { namespace: selectedRegisterModule })
  saveRelatedData: ({}) => void

  @Mutation('SET_CURRENT_TAB_INFO', { namespace: tabsModule })
  setCurrentTabInfo: (tabInfo: SearchTab) => void

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('getCurrentListConfigurationContext', { namespace: configurationModule })
  listConfigurationContext: (context: string) => []

  @Getter('getInfoViewTabs', { namespace: configurationModule })
  tabsConfiguration: (context: string) => []

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: object

  @Getter('getDisabledTabs', { namespace: tabsModule })
  checkDisabledTabs: boolean

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getMaintenanceStageState', { namespace: maintenanceModule })
  maintenanceStageState: []

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  componentType = {
    customFieldsContainer: ConfigurationElements.CUSTOM_FIELDS_CONTAINER,
    customFieldsTab: ConfigurationElements.CUSTOM_FIELDS_TAB,
    customerPortal: ConfigurationElements.CUSTOMER_PORTAL,
    expedientActions: ConfigurationElements.EXPEDIENT_ACTIONS,
    expedientAgenda: ConfigurationElements.EXPEDIENT_AGENDA,
    expedientCourts: ConfigurationElements.EXPEDIENT_COURTS,
    expedientEconomicData: ConfigurationElements.EXPEDIENT_ECONOMIC_DATA,
    expedientGlobalVision: ConfigurationElements.EXPEDIENT_GLOBAL_VISION,
    expedientInsurers: ConfigurationElements.EXPEDIENT_INSURERS,
    expedientInterveners: ConfigurationElements.EXPEDIENT_INTERVENERS,
    expedientInvoices: ConfigurationElements.EXPEDIENT_INVOICES,
    expedientPeriodiBilling: ConfigurationElements.EXPEDIENT_PERIODIC_BILLING,
    fileManager: ConfigurationElements.FILE_MANAGER,
    form: ConfigurationElements.FORM,
    formAlert: ConfigurationElements.FORM_ALERT,
    grid: ConfigurationElements.GRID,
    invoicesTab: ConfigurationElements.INVOICES_TAB,
    legalSearch: ConfigurationElements.LEGAL_SEARCH,
    list: ConfigurationElements.LIST,
    numeration: ConfigurationElements.NUMERATION,
    tabHeaders: ConfigurationElements.TAB_HEADER,
    tandemSearch: ConfigurationElements.TANDEM_SEARCH,
    templateGenerator: ConfigurationElements.TEMPLATE_GENERATOR,
    usersMaintenance: ConfigurationElements.USERS_MAINTENANCE,
    usersMaintenanceCustomPermissions: ConfigurationElements.USERS_MAINTENANCE_CUSTOM_PERMISSIONS,
    emailTemplatesCustomerPortalTabComponent: ConfigurationElements.EMAIL_TEMPLATES_CUSTOMER_PORTAL_TAB,
    subActions: ConfigurationElements.SUB_ACTIONS,
    lfGrid: ConfigurationElements.LFGRID,
    contactContacts: ConfigurationElements.CONTACT_CONTACS,
    contactExpedients: ConfigurationElements.CONTACT_EXPEDIENTS,
    contactActions: ConfigurationElements.CONTACT_ACTIONS,
    contactProvisions: ConfigurationElements.CONTACT_PROVISIONS,
    contactInvoices: ConfigurationElements.CONTACT_INVOICES,
    contactBankdrafts: ConfigurationElements.CONTACT_BANKDRAFTS,
    alertsMessages: ConfigurationElements.ALERTS_MESSAGES,
    actionClassification: ConfigurationElements.ACTION_CLASSIFICATION,
    professionalAccess: ConfigurationElements.PROFESSIONAL_ACCESS,
    editDocumentIntervener: ConfigurationElements.EDIT_DOCUMENT_INTERVENER,
    invoicesBankdraftTab: ConfigurationElements.INVOICES_BANKDRAFT_TAB,
    staticAlert: ConfigurationElements.STATIC_ALERT,
    searchExpedients: ConfigurationElements.SEARCH_EXPEDIENTS,
    searchContacts: ConfigurationElements.SEARCH_CONTACTS,
    searchActions: ConfigurationElements.SEARCH_ACTIONS,
    searchInvoice: ConfigurationElements.SEARCH_INVOICES,
    searchDocumentations: ConfigurationElements.SEARCH_DOCUMENTATIONS,
    searchAgenda: ConfigurationElements.SEARCH_AGENDA
  }

  selectedTabIndex = this.selectedTab

  selectedCurrentTabIndex = this.selectedTab

  simpleGridContextMenuItems = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    }
  ]

  gridContextMenuItems: ContextMenuItem[] = [
    {
      text: i18n.t('components.context_menu.edit'),
      iconCss: Icons.EDIT
    },
    {
      text: i18n.t('components.context_menu.open_window'),
      iconCss: Icons.OPEN_WINDOW
    },
    {
      text: i18n.t('components.context_menu.remove'),
      iconCss: Icons.REMOVE
    }
  ]

  icons = {
    doubleArrowLeft: Icons.DOUBLE_ARROW_LEFT,
    doubleArrowRight: Icons.DOUBLE_ARROW_RIGHT
  }

  currentFormRendered = ''

  disabledTabs = false
  disabledTabsMessage = ''

  relatedEndpoints: Endpoint = { get: '', save: '', delete: '', list: '' }

  currentComponent: any = null

  currentTabName = ''

  currentTab: any = null

  editMode = false

  hideAlert = false

  everythingLoaded = this.allDataLoaded

  lastObjectType = ''

  tabRefreshPromises: any[] = []

  lastRejects: any[] = []

  isInitializing = true

  listConfigurations: ListConfigurationType = {}

  isFirstLoad = true

  loadedSecondary = false

  componentKey = 0

  componentWhereIsRendered = ComponentWhereIsRendered.TABS_COMPONENT

  showTabSpinner = true
  canceledPromise = false

  @Watch('allDataLoaded')
  changeEverythingLoaded(value: boolean) {
    this.everythingLoaded = value
  }

  @Watch('isShowingRelatedForm')
  checkShowRelatedForm(value: boolean) {
    this.disabledTabs = value
  }

  @Watch('checkDisabledTabs')
  changeDisabledTabs(value: boolean) {
    this.disabledTabs = value
  }

  @Watch('expedientStageState')
  checkStageChange() {
    const currentTabData = (this as any).getCurrentViewTabs(this.context)[this.selectedCurrentTabIndex].items[0]
    if (
      this.componentType.fileManager !== currentTabData.idSubTypeObject &&
      currentTabData.module &&
      currentTabData.loadMethod
    ) {
      this.loadListData(currentTabData)
    }
  }

  @Watch('maintenanceStageState')
  checkMaintenanceStageChange() {
    const currentTabData = (this as any).getCurrentViewTabs(this.context)[this.selectedCurrentTabIndex].items[0]
    if (
      this.componentType.fileManager !== currentTabData.idSubTypeObject &&
      currentTabData.module &&
      currentTabData.loadMethod
    ) {
      this.loadListData(currentTabData)
    }
  }

  get gridConfiguration() {
    return JSON.parse((this as any).listConfiguration['Config'])
  }

  get currentId() {
    if (this.selectedRegisterData(this.context)) {
      return (this as any).selectedRegisterData(this.context)[this.entity.keyId]
    }
    return 0
  }

  get currentName() {
    if (this.selectedRegisterData(this.context)) {
      if (this.context === ContextName.CONTACT) {
        const { name, firstSurname, secondSurname } = (this as any).selectedRegisterData(this.context)
        const fullName = [name, firstSurname, secondSurname].filter(Boolean).join(' ')
        return fullName
      }
      return (this as any).selectedRegisterData(this.context)[this.entity.keyName]
    }
    return ''
  }

  get isExpedientEconomicDataComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientEconomicData
    }
    return false
  }

  get isTemplateGeneratorComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.templateGenerator
    }
    return false
  }

  get isGridTableComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      const { idSubTypeObject } = this.currentComponent
      return idSubTypeObject === this.componentType.list || idSubTypeObject === this.componentType.grid
    }
    return false
  }

  get isExpedientActionsComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientActions
    }
    return false
  }

  get isExpedientCourtComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientCourts
    }
    return false
  }

  get isExpedientIntervenerComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientInterveners
    }
    return false
  }

  get isExpedientAgendaComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientAgenda
    }
    return false
  }

  get isExpedientInvoicesComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.expedientInvoices
    }
    return false
  }

  get isFilemanagerComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.fileManager
    }
    return false
  }

  get isUsersMaintenanceComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.usersMaintenance
    }
    return false
  }

  get isEmailTemplatesCustomerPortalTabComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.emailTemplatesCustomerPortalTabComponent
    }
    return false
  }

  get isNumerationComponent(): boolean {
    if (this.currentComponent?.idSubTypeObject) {
      return this.currentComponent.idSubTypeObject === this.componentType.numeration
    }
    return false
  }

  // get removeTabPadding() {
  //   return (
  //     this.isExpedientEconomicDataComponent ||
  //     this.isExpedientCourtComponent ||
  //     this.isExpedientIntervenerComponent ||
  //     this.isTemplateGeneratorComponent ||
  //     this.isGridTableComponent ||
  //     this.isExpedientActionsComponent ||
  //     this.isNumerationComponent ||
  //     this.isExpedientAgendaComponent ||
  //     this.isExpedientInvoicesComponent ||
  //     this.isFilemanagerComponent ||
  //     this.isUsersMaintenanceComponent ||
  //     this.isEmailTemplatesCustomerPortalTabComponent
  //   )
  // }

  get formSchema() {
    let schema = []
    const items = (this as any).tabs[this.selectedCurrentTabIndex].items

    if (items) {
      const form = items.filter((component: { idSubTypeObject: ConfigurationElements; name: string }) => {
        return component.idSubTypeObject === this.componentType.form && component.name === this.currentFormRendered
      })
      if (form && form.length) {
        schema = form[0].fields
      }
    }
    return schema
  }

  get tabHeaderButtons() {
    return (this as any).tabs[this.selectedCurrentTabIndex].items[0].buttons
  }

  get firstTab() {
    return (this as any).tabs[0]
  }

  get isShowingRelatedForm(): boolean {
    return this.showRelatedForm(this.context)
  }

  get hideTabs(): boolean {
    return this.tabs.length === Number(1)
  }

  get initialBasePath(): string {
    if (this.$route.query.basePath) {
      const basePath: string = this.$route.query.basePath.toString()
      return atob(decodeURI(basePath))
    }
    return ''
  }

  get initialPath(): string {
    if (this.$route.query.path) {
      const path: string = this.$route.query.path.toString()
      delete this.$route.query.tab
      return atob(decodeURI(path))
    }
    return ''
  }

  get selectedRegisterId() {
    return this.selectedRegisterIdGetter(this.context)
  }

  get renderTabSelectorComponent() {
    return this.context === ContextName.EXPEDIENTS && !this.isPortalUser
  }

  async mounted() {
    this.disabledTabs = true

    let firstTab = this.firstTab

    if (this.$route.query.tab) {
      const firstTabs: string[] = [
        this.componentType.form,
        this.componentType.invoicesTab,
        this.componentType.expedientPeriodiBilling,
        this.componentType.expedientGlobalVision
      ]

      const firstTabIsForm: boolean = firstTabs.includes(firstTab.items[0].idSubTypeObject)
      firstTab = this.tabs.find((tab: any) => tab.name === this.$route.query.tab)

      if (firstTab) {
        if (firstTabIsForm) {
          await this.refreshForm(this.componentType.form)
        }

        this.isFirstLoad = true
        this.selectedTabIndex = this.tabs.map((tab: any) => tab.name).indexOf(this.$route.query.tab)
        delete this.$route.query.tab

        this.beforeSetInitialTab()
      } else {
        firstTab = this.firstTab
      }
    }

    await this.onTabChange(this.selectedTabIndex, firstTab)

    this.isFirstLoad = false
  }

  beforeDestroy() {
    this.resetFormData()
    this.resetCustomValidateField()
    this.resetSelectedRegister(this.context)
    this.cancelPendingRequests()
  }

  destroyed() {
    if (this.tabRefreshPromises.length) {
      this.tabRefreshPromises.map((promise) => (promise ? promise.reject() : null))
    }
  }

  setDisabledTab(tabIndex: number) {
    if (tabIndex !== this.selectedCurrentTabIndex) {
      return this.isInitializing
    }
    return false
  }

  onFieldChanged(schema: [], formData: object, field: object, value: any) {
    this.$emit('field-changed', schema, formData, field, value)
  }

  renderTypeOfComponent(idSubTypeObject: string) {
    switch (idSubTypeObject) {
      case this.componentType.customFieldsContainer:
        return 'CustomFieldsContainerComponent'
      case this.componentType.customFieldsTab:
        return 'CustomFieldsTabComponent'
      case this.componentType.customerPortal:
        return 'CustomerPortalComponent'
      case this.componentType.expedientActions:
        return 'ExpedientActionsComponent'
      case this.componentType.expedientAgenda:
        return 'ExpedientAgendaComponent'
      case this.componentType.expedientCourts:
        return 'ExpedientCourtsComponent'
      case this.componentType.expedientEconomicData:
        return 'ExpedientEconomicDataComponent'
      case this.componentType.expedientGlobalVision:
        return 'ExpedientGlobalVisionComponent'
      case this.componentType.expedientInsurers:
        return 'ExpedientInsurersComponent'
      case this.componentType.expedientInterveners:
        return 'ExpedientIntervenersComponent'
      case this.componentType.expedientInvoices:
        return 'ExpedientInvoicesComponent'
      case this.componentType.expedientPeriodiBilling:
        return 'ExpedientPeriodicBillingComponent'
      case this.componentType.fileManager:
        return 'FileManagerComponent'
      case this.componentType.form:
        return 'FormGeneratorComponent'
      case this.componentType.formAlert:
        return 'FormAlertComponent'
      case this.componentType.grid:
        return 'GridTableComponent'
      case this.componentType.invoicesTab:
        return 'InvoicesTabComponent'
      case this.componentType.legalSearch:
        return 'LegalSearchComponent'
      case this.componentType.list:
        return 'SimpleGridTableComponent'
      case this.componentType.numeration:
        return 'NumerationComponent'
      case this.componentType.tabHeaders:
        return 'TabHeaderComponent'
      case this.componentType.tandemSearch:
        return 'TandemSearchComponent'
      case this.componentType.templateGenerator:
        return 'TemplateGeneratorComponent'
      case this.componentType.usersMaintenance:
        return 'UsersMaintenanceComponent'
      case this.componentType.emailTemplatesCustomerPortalTabComponent:
        return 'EmailTemplatesCustomerPortalTabComponent'
      case this.componentType.usersMaintenanceCustomPermissions:
        return 'UsersMaintenanceCustomPermissionsComponent'
      case this.componentType.subActions:
        return 'SubActionsComponent'
      case this.componentType.lfGrid:
        return 'lfGridComponent'
      case this.componentType.contactContacts:
        return 'ContactContactsComponent'
      case this.componentType.contactExpedients:
        return 'ContactExpedientsComponent'
      case this.componentType.contactActions:
        return 'ContactActionsComponent'
      case this.componentType.contactProvisions:
        return 'ContactProvisionsComponent'
      case this.componentType.contactInvoices:
        return 'ContactInvoicesComponent'
      case this.componentType.contactBankdrafts:
        return 'ContactBankdraftsComponent'
      case this.componentType.alertsMessages:
        return 'AlertsMessagesComponent'
      case this.componentType.actionClassification:
        return 'ActionClassificationComponent'
      case this.componentType.professionalAccess:
        return 'ProfessionalAccessComponent'
      case this.componentType.editDocumentIntervener:
        return 'EditDocumentIntervenerComponent'
      case this.componentType.invoicesBankdraftTab:
        return 'BankdraftTabComponent'
      case this.componentType.staticAlert:
        return 'StaticAlertComponent'
      case this.componentType.searchExpedients:
        return 'SearchExpedients'
      case this.componentType.searchContacts:
        return 'SearchContacts'
      case this.componentType.searchActions:
        return 'SearchActions'
      case this.componentType.searchInvoice:
        return 'SearchInvoice'
      case this.componentType.searchDocumentations:
        return 'SearchDocumentation'
      case this.componentType.searchAgenda:
        return 'SearchAgenda'
      default:
        return false
    }
  }

  getListContextMenuItems({
    disableContextMenu,
    allowDelete,
    label,
    permissionsEntity
  }: {
    label: string
    disableContextMenu: boolean
    allowDelete: boolean
    permissionsEntity: number
  }) {
    if (disableContextMenu) {
      return []
    }

    if (label === this.$t('navigation.drawer-menu-items.interveners')) {
      return this.intervenersTabcontextMenuItems
    }
    if (label === this.$t('navigation.drawer-menu-items.classification')) {
      return this.classificationTabcontextMenuItems
    }

    const contextMenuItems = allowDelete ? this.contactsTabcontextMenuItems : this.simpleGridContextMenuItems

    return this.parsedItemsByPermissions(permissionsEntity, contextMenuItems)
  }

  getGridContextMenuItems(permissionsEntity?: number): ContextMenuItem[] {
    if (this.context === ContextName.MAINTENANCE) {
      return this.maintenanceTabContextMenuItems
    }
    if (this.context === ContextName.MAINTENANCE_USERS_RELATED_FORM) {
      return this.maintenanceUsersTabComponentProfessionalRelatedForm
    }
    if (permissionsEntity) {
      return this.parsedItemsByPermissions(permissionsEntity, this.gridContextMenuItems)
    }
    return this.gridContextMenuItems
  }

  getDatabaseContextMenuItems(databaseContextMenuItems: any, permissionsEntity?: number) {
    if (permissionsEntity) {
      const { canSave } = this.checkEntityPermissionsGetter(permissionsEntity)
      if (canSave === 0) {
        return databaseContextMenuItems.map((item: string) => (item === 'EDIT' ? 'SEE' : item))
      }
    }
    return databaseContextMenuItems
  }

  setPropsDependingOnComponentType(component: any, tab: any, tabIndex: number) {
    const {
      alias,
      alert,
      allowDelete,
      basePath,
      databaseContextMenuItems,
      disabledGridCellClicked,
      endpointType,
      idSubTypeObject,
      maintenanceType,
      message,
      name,
      permissionsEntity,
      renderRelatedForm,
      showButtons,
      showFilters,
      showRequiredFieldsAdvice,
      validateOnLoad
    } = component

    // Comprueba si el permiso de entidad le llega por el JSON de BBDD o por una prop
    const checkedPermisisonEntity = permissionsEntity || this.permissionsEntityProp

    const { label } = tab
    this.currentTabName = this.tabs[tabIndex]['name']

    if (typeof this.beforeSetComponentProperties === 'function') {
      this.beforeSetComponentProperties(component, tab, tabIndex)
    }
    if (this.currentComponent === null) {
      this.currentComponent = { alias: '' }
    }

    let haveRelatedForm = false
    let gridContextMenuItems
    let parsedContextMenuItems
    const { onClick } = component
    let componentProps: any
    const { isFrozenColsMode, disableContextMenu, activeOnClickCell } = component
    let contextMenuItems

    switch (idSubTypeObject) {
      case this.componentType.grid:
        if (renderRelatedForm !== undefined) {
          haveRelatedForm = true
          if (this.currentComponent.alias !== component.alias) {
            this.fetchRelatedFormFields(renderRelatedForm)
          }
          if (endpointType !== undefined) {
            const entityEndpoints: any = getObjectByKey(endpoints, endpointType)
            this.relatedEndpoints = entityEndpoints as Endpoint
          }
        }
        this.currentComponent = component

        gridContextMenuItems = this.getGridContextMenuItems(permissionsEntity)

        parsedContextMenuItems = this.getDatabaseContextMenuItems(databaseContextMenuItems, permissionsEntity)

        componentProps = {
          context: this.context,
          contextMenuItems: gridContextMenuItems,
          databaseContextMenuItems: parsedContextMenuItems || null,
          disabledGridCellClicked,
          gridConfiguration: this.listConfigurationContext(alias),
          haveRelatedForm,
          isInTab: true,
          itemsData: this.listItems,
          listName: (this as any).listConfiguration['Alias'],
          maintenanceType,
          onClick: onClick || null,
          permissionsEntity,
          showHeader: false,
          title: label,
          toolbarOptions: this.getToolbarOptions(component.toolbarOptions)
        }
        this.$set(componentProps, 'showGrid', !this.showRelatedForm(this.context))
        return componentProps
      case this.componentType.list:
        if (renderRelatedForm !== undefined) {
          if (this.currentComponent.alias !== component.alias) {
            this.fetchRelatedFormFields(renderRelatedForm)
          }
          if (endpointType !== undefined) {
            const entityEndpoints: any = getObjectByKey(endpoints, endpointType)
            this.relatedEndpoints = entityEndpoints as Endpoint
          }
        }
        this.currentComponent = component

        contextMenuItems =
          this.simpleGridContextMenuItemsProp && this.simpleGridContextMenuItemsProp.length
            ? this.simpleGridContextMenuItemsProp
            : this.getListContextMenuItems({
                disableContextMenu,
                permissionsEntity: checkedPermisisonEntity,
                label,
                allowDelete
              })

        return {
          activeOnClickCell,
          columns: (this as any).listConfiguration['Config'],
          contextMenuItems,
          databaseContextMenuItems: databaseContextMenuItems || null,
          isFrozenColsMode,
          itemsData: this.listItems,
          showButtons: showButtons === '1',
          showGrid: !this.showRelatedForm(this.context),
          title: label,
          permissionsEntity: checkedPermisisonEntity
        }
      case this.componentType.form:
        this.currentComponent = component
        this.currentFormRendered = name
        this.$emit('renderForm', tabIndex, name)

        return {
          context: this.context,
          fieldValues: this.formFieldsData(this.context, name),
          permission: { canSave: 1 },
          permissionsEntity: checkedPermisisonEntity,
          schema: this.formSchema,
          showRequiredFieldsAdvice: showRequiredFieldsAdvice ?? true,
          validateOnLoad
        }
      case this.componentType.fileManager:
        this.currentComponent = component
        return {
          basePath: `${basePath}:${this.currentId}`,
          initialBasePath: this.initialBasePath,
          initialPath: this.initialPath,
          mainFolderDescription: this.currentName,
          idMainEntity: this.entity.id,
          idMainEntityType: this.entity.type,
          context: this.context,
          permissionsEntity: checkedPermisisonEntity,
          canDropFilesOnMainFolder:
            component.canDropFilesOnMainFolder !== undefined ? component.canDropFilesOnMainFolder : true
        }
      case this.componentType.tabHeaders:
        return {
          buttons: this.tabHeaderButtons,
          title: label
        }
      case this.componentType.customerPortal:
        this.currentComponent = component
        return {
          permissionsEntity: checkedPermisisonEntity,
          context: this.context
        }
      case this.componentType.expedientActions:
        this.currentComponent = component
        return {
          expedientId: this.currentId,
          itemsData: this.listItems,
          gridContextMenuItems:
            allowDelete === true ? this.contactsTabcontextMenuItems : this.simpleGridContextMenuItems,
          title: label,
          showFilters,
          context: ContextName.EXPEDIENT_ACTIONS,
          columns: this.listConfigurationContext(alias),
          listName: alias,
          showButtons: showButtons === '1' ? true : false,
          showGrid: this.showRelatedForm(this.context) ? false : true
        }
      case this.componentType.expedientAgenda:
        this.currentComponent = component
        return {
          expedientId: this.currentId,
          itemsData: this.listItems,
          gridContextMenuItems:
            allowDelete === true ? this.contactsTabcontextMenuItems : this.simpleGridContextMenuItems,
          title: label,
          showFilters,
          columns: this.listConfigurationContext(alias),
          listName: (this as any).listConfiguration['Alias'],
          showButtons: showButtons === '1' ? true : false,
          showGrid: this.showRelatedForm(this.context) ? false : true
        }
      case this.componentType.expedientCourts:
        this.currentComponent = component
        return {
          listName: alias,
          context: alias,
          formAlias: component.formAlias,
          modalWidth: component.modalWidth
        }

      case this.componentType.expedientInterveners:
        this.currentComponent = component
        return {
          listName: alias,
          context: alias,
          formAlias: component.formAlias,
          modalWidth: component.modalWidth
        }
      case this.componentType.expedientInvoices:
        this.currentComponent = component
        return {
          expedientId: this.currentId,
          itemsData: this.listItems,
          gridContextMenuItems:
            allowDelete === true ? this.contactsTabcontextMenuItems : this.simpleGridContextMenuItems,
          title: label,
          showFilters,
          columns: this.listConfigurationContext(alias),
          listName: (this as any).listConfiguration['Alias'],
          showButtons: showButtons === '1' ? true : false,
          showGrid: this.showRelatedForm(this.context) ? false : true
        }
      case this.componentType.subActions:
        this.currentComponent = component
        return {
          columns: (this as any).listConfiguration['Config'],
          listName: (this as any).listConfiguration['Alias'],
          parentActionId: this.selectedRegisterId,
          title: label,
          context: alias,
          gridContextMenuItems: this.contactsTabcontextMenuItems,
          showFilters
        }
      case this.componentType.legalSearch:
        return {}
      case this.componentType.invoicesTab:
        this.$emit('renderForm', tabIndex, name)
        return {
          name
        }
      case this.componentType.customFieldsTab:
        return {
          permissionsEntity: checkedPermisisonEntity,
          context: this.context
        }
      case this.componentType.formAlert:
        return {
          message
        }
      case this.componentType.customFieldsContainer:
        return {
          idEntityType: ExpedientEntityId.EXPEDIENTS
        }
      case this.componentType.templateGenerator:
      case this.componentType.expedientInsurers:
      case this.componentType.expedientPeriodiBilling:
      case this.componentType.expedientEconomicData:
      case this.componentType.numeration:
      case this.componentType.usersMaintenance:
      case this.componentType.usersMaintenanceCustomPermissions:
      case this.componentType.emailTemplatesCustomerPortalTabComponent:
        this.currentComponent = component
        break
      case this.componentType.expedientGlobalVision:
        this.currentComponent = component
        if (!this.selectedRegisterId) {
          this.goToTab(1)
        }
        break
      case this.componentType.contactContacts:
        const { formSchema } = component
        return {
          listName: alias,
          formSchema
        }
      case this.componentType.contactExpedients:
      case this.componentType.contactActions:
      case this.componentType.contactProvisions:
      case this.componentType.contactInvoices:
      case this.componentType.contactBankdrafts:
        return {
          listName: alias
        }
      case this.componentType.actionClassification:
        return {
          listName: alias,
          formAlias: 'actionsRelationsForm',
          context: this.context
        }
      case this.componentType.professionalAccess:
        return {
          listName: alias,
          formAlias: renderRelatedForm,
          context: 'ContextName.PROFESSIONAL_ACCESS'
        }
      case this.componentType.editDocumentIntervener:
        return {
          listName: alias,
          formAlias: ConfigurationAlias.DOCUMENT_PROPERTIES_INTERVENERS,
          context: ContextName.DOCUMENT_INTERVENERS,
          documentContext: ContextName.DOCUMENT
        }
      case this.componentType.staticAlert:
        return {
          alert
        }
      case this.componentType.searchExpedients:
      case this.componentType.searchContacts:
      case this.componentType.searchActions:
      case this.componentType.searchInvoice:
      case this.componentType.searchDocumentations:
      case this.componentType.searchAgenda:
        // console.log({alias, label, component, context: this.context})
        return {
          listName: alias,
          title: label,
          itemsData: this.listItems,
          context: `${this.context}${capitalizeFirstLetter(label)}`
        }
      default:
        return {}
    }
  }

  showTab() {
    this.everythingLoaded = true
  }

  hideTab() {
    this.everythingLoaded = false
  }

  async onTabChange(tabIndex: number, tab: any) {
    if (!this.disabledTabs && tabIndex !== this.selectedTabIndex) {
      this.showTabSpinner = true
    }

    const objectType: string = tab.items[0].idSubTypeObject

    if (tabIndex !== this.selectedTabIndex || this.isFirstLoad) {
      this.setCurrentTabInfo({ name: tab.name, index: tabIndex })

      this.everythingLoaded = false

      try {
        await this.processTabChange(tabIndex, objectType, tab)
          .then(() => {
            this.tabRefreshPromises.shift()
            this.lastRejects.shift()
            this.everythingLoaded = true
            if (this.isInitializing) {
              this.disabledTabs = false
            }
            this.isInitializing = false
          })
          .catch((reason) => {
            if (reason === undefined) {
              this.canceledPromise = true
            }
            this.tabRefreshPromises.shift()
            this.lastRejects.shift()
          })
      } finally {
        !this.canceledPromise ? (this.showTabSpinner = false) : (this.canceledPromise = false)
      }
    }
  }

  waitForListConfiguration(): Promise<any> {
    return new Promise((resolve) => {
      const unwatch = this.$store.watch(
        (state) => state.configurationModule.currentListConfiguration,
        (newValue) => {
          if (Object.keys(newValue).length > 0) {
            unwatch()
            resolve(newValue)
          }
        }
      )
    })
  }

  async processTabChange(tabIndex: number, objectType: string, tab: any): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.disabledTabs && !this.isInitializing && tabIndex !== this.selectedCurrentTabIndex) {
        this.showCantChangeTabMessage()
        this.everythingLoaded = true
      } else {
        if (this.selectedRegisterId) {
          this.cancelPendingRequests()
        }
        if (tabIndex === this.selectedCurrentTabIndex) {
          this.disabledTabs = false
        }
        if (!this.entity || !this.entity.alias) {
          await this.$nextTick()
          this.selectedCurrentTabIndex = this.selectedTabIndex
          this.$emit('changed-tab', tabIndex, objectType)
          if (this.componentType.grid === objectType) {
            await this.waitForListConfiguration()
          }
          resolve()
        } else {
          this.refreshTab(tabIndex, tab, resolve)
        }
      }
    })
  }

  async emit(eventName: string, value: any, idObjectType: string) {
    return new Promise((resolve, _reject) => {
      this.$emit(eventName, value, resolve, idObjectType)
      this.$nextTick()
    })
  }

  async refreshTab(tabIndex: number, tab: any, resolve: any) {
    await this.$nextTick()

    const objectType: string = tab.items[0].idSubTypeObject
    const reloadListConfiguration = !!tab.items[0].reloadListConfiguration

    this.currentTab = tab

    this.selectedCurrentTabIndex = this.selectedTabIndex

    let forceSave = false

    if (
      (this.lastObjectType === this.componentType.form && this.forceSaveProp) ||
      this.lastObjectType === this.componentType.invoicesTab ||
      this.lastObjectType === this.componentType.customFieldsTab ||
      this.lastObjectType === this.componentType.expedientPeriodiBilling ||
      this.lastObjectType === this.componentType.expedientInsurers
    ) {
      await this.emit('save-on-tab-change', false, this.lastObjectType)
      forceSave = true
    }

    this.lastObjectType = objectType

    this.refreshFileManager(objectType)
    await this.refreshCustomerPortal(objectType)
    await this.refreshList(tabIndex, objectType, reloadListConfiguration)

    if (this.autoRefreshForm) {
      await this.refreshForm(objectType, forceSave)
    }

    this.refreshParentsFormsValues()
    await this.$emit('changed-tab', tabIndex, objectType, forceSave)

    typeof resolve === 'function' && resolve()
  }

  async refreshForm(objectType: string, forceSave = false) {
    if (!this.entity || (this.entity && !this.entity.id)) {
      this.resetSelectedRegister(this.context)
    }
    if (this.componentType.form === objectType || forceSave) {
      if (this.entity && this.endpoints(this.entity.type)) {
        if (this.entity.id) {
          await this.resetFormData()
          await this.fetchSelectedRegisterData({
            endpoint: this.endpoints(this.entity.type).get,
            context: this.context,
            post: this.isPortalUser ? { source: 'form', userType: 2 } : null
          })
        } else {
          this.resetSelectedRegister(this.context)
        }
      }
    }
  }

  refreshParentsFormsValues() {
    const { setParentFieldValues, setParentFormContext } = useParentFields()
    setParentFieldValues(null)
    setParentFormContext(null)
  }

  refreshComponentManually() {
    this.componentKey += 1
  }

  checkContext() {
    return this.context !== ContextName.MAINTENANCE_USERS_RELATED_FORM
      ? this.context
      : ContextName.MAINTENANCE_USERS_CONFIG
  }

  async refreshList(tabIndex: number, objectType: string, reloadListConfiguration: boolean) {
    if (
      this.componentType.list === objectType ||
      this.componentType.grid === objectType ||
      this.componentType.expedientActions === objectType ||
      this.componentType.expedientAgenda === objectType ||
      this.componentType.expedientEconomicData === objectType ||
      this.componentType.expedientInvoices === objectType ||
      this.componentType.numeration === objectType
    ) {
      if (this.entity && this.entity.alias) {
        this.removeListItems()
        const currentTabData = (this as any).getCurrentViewTabs(this.checkContext())[tabIndex].items[0]
        const { alias } = currentTabData
        if (reloadListConfiguration) {
          this.resetListConfiguration()
          await this.loadListData(currentTabData)
          await this.fetchCurrentListConfiguration(alias)
        } else {
          if (this.listConfigurations[alias] && Object.keys(this.listConfigurations[alias]).length) {
            this.setCurrentListConfiguration(this.listConfigurations[alias])
          } else {
            this.resetListConfiguration()
          }
          await this.loadListData(currentTabData)
          if (!this.listConfigurations[alias] || Object.keys(this.listConfigurations[alias]).length === 0) {
            await this.fetchCurrentListConfiguration(alias)
            this.listConfigurations[alias] = this.listConfiguration
          }
        }
      }
    }
  }

  async refreshCustomerPortal(objectType: string) {
    if (this.componentType.customerPortal === objectType) {
      this.clearCustomerPortal()
      await this.fetchCustomerPortal(this.currentId || this.selectedRegisterId)
    }
  }

  refreshFileManager(objectType: string) {
    if (this.componentType.fileManager === objectType && this.$refs.currentRenderedComponent) {
      const fileManager: any = (this as any).$refs.currentRenderedComponent[0]
      if (fileManager) {
        fileManager.refresh()
      }
    }
  }

  async saveFormData() {
    if (this.entity && this.endpoints(this.entity.type)) {
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity.type).save,
        idSelectedRegister: this.entity.id,
        goToURL: null,
        context: this.context
      })
    }
  }

  async loadListData(currentTabData: any) {
    const { module, loadMethod, alias } = currentTabData

    if (module === ModuleNamespaces.CONTACTS) {
      await this.fetchContactsContact({
        contactType: this.entity.alias,
        idContact: this.entity.id
      })
    } else if (module === ModuleNamespaces.ACTIONS) {
      await this.$store.dispatch(`${module}/${loadMethod}`, {
        listName: alias,
        contactType: this.entity.alias,
        selectedRegisterId: this.entity.id
      })
    } else if (module === ModuleNamespaces.EXPEDIENTS) {
      await this.$store.dispatch(`${module}/${loadMethod}`, {
        listName: alias,
        contactType: this.entity.alias,
        selectedRegisterId: this.entity.id,
        filter: {
          ...((this as any).expedientStageState && { idStage: (this as any).expedientStageState })
        }
      })
    } else if (module === ModuleNamespaces.MAINTENANCE) {
      await this.$store.dispatch(`${module}/${loadMethod}`, {
        maintenanceType: currentTabData.maintenanceType
      })
    } else if (module === ModuleNamespaces.NUMERATIONS) {
      await this.$store.dispatch(`${module}/${loadMethod}`)
    } else if (module === ModuleNamespaces.USERS) {
      await this.$store.dispatch(`${module}/${loadMethod}`, {
        usersType: currentTabData.usersType
      })
    } else {
      if (this.entity && this.entity.id) {
        await this.$store.dispatch(`${module}/${loadMethod}`, {
          selectedRegisterId: this.entity.id,
          listName: alias
        })
      }
    }
  }

  async showCantChangeTabMessage() {
    let alertMessage = this.disabledTabsMessage
    if (!alertMessage) {
      alertMessage = this.$t('components.alerts.required_fields').toString()
      if (this.showRelatedForm(this.context) || this.checkDisabledTabs) {
        alertMessage = this.$t('components.alerts.complete_related_form').toString()
      }
    }
    await this.$nextTick()
    this.selectedTabIndex = this.selectedCurrentTabIndex
    if (!this.hideAlert) {
      this.showAlert({
        type: AlertsTypes.WARNING,
        message: alertMessage,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
    this.emitSignToValidateForm()
  }

  onFormCreated(params: any) {
    this.$emit('form-created', params)
  }

  onFormMounted(params: any) {
    this.disabledTabs = params.isNewForm
    this.$emit('form-mounted', params)
  }

  onFormIsValid(isValid: boolean) {
    this.disabledTabs = !isValid
  }

  onOpenForm() {
    this.$emit('form-open')
  }

  onCloseForm() {
    this.$emit('form-close')
  }

  onSaveForm() {
    this.$emit('form-save')
  }

  onExpedientGlobalVisionMounted() {
    this.$emit('expedientGlobalVisionMounted')
  }

  onGridAction(e: any) {
    switch (e.type) {
      case 'add': {
        if (this.currentComponent.renderRelatedForm) {
          this.editMode = false
          this.setSelectedRegister({ selectedRegisterData: { id: 0, name: '' }, context: this.context })
          this.saveSelectedRegisterId({ id: 0, context: this.context })
          this.onOpenNewGridRelatedForm()
          this.$emit('gridActionNew')
        }
        break
      }
      case 'edit': {
        if (this.currentComponent.renderRelatedForm) {
          this.editMode = true
          this.setSelectedRegister({ selectedRegisterData: e.register, context: this.context })
          this.saveSelectedRegisterId({ id: e.registerId, context: this.context })
          this.saveRelatedData(e.register)
          this.onOpenEditRelatedForm(e.register)
          this.$emit('gridActionEdit')
        }
        break
      }
      case 'remove': {
        if (this.currentComponent.renderRelatedForm) {
          this.onRemoveRelatedItem(e.register, this.currentTabName)
        }
        this.$emit('gridActionRemove')
        break
      }
    }
  }
  // Eliminar cuando se cambien los grid
  async onSimpleGridActionNew() {
    if (this.currentComponent.renderRelatedForm) {
      if (this.loadedSecondary) {
        await this.fetchRelatedFormFields(this.currentComponent.renderRelatedForm)
        const entityEndpoints: any = getObjectByKey(endpoints, this.currentComponent.endpointType)
        this.relatedEndpoints = entityEndpoints as Endpoint
      }
      this.editMode = false
      this.onOpenNewRelatedForm()
    }
    this.$emit('simpleGridActionNew')
  }

  // Eliminar cuando se cambien los grid
  async onSimpleGridActionEdit(gridRow: any, relatedForm: any | null = null) {
    if (relatedForm) {
      let entityEndpoints: any
      if (relatedForm.principal !== undefined && relatedForm.principal === true) {
        await this.fetchRelatedFormFields(this.currentComponent.renderRelatedForm)
        entityEndpoints = getObjectByKey(endpoints, this.currentComponent.endpointType)
        gridRow.principal = true
        this.loadedSecondary = false
      } else {
        await this.fetchRelatedFormFields(relatedForm.formFields)
        entityEndpoints = getObjectByKey(endpoints, relatedForm.endpoints)
        gridRow.principal = false
        this.loadedSecondary = true
      }
      this.relatedEndpoints = entityEndpoints as Endpoint
    }
    if (this.currentComponent.renderRelatedForm) {
      this.editMode = relatedForm.principal
      this.onOpenEditRelatedForm(gridRow)
    }
    this.$emit('simpleGridActionEdit', gridRow)
  }

  // Eliminar cuando se cambien los grid
  onSimpleGridActionRemove(gridRow: any) {
    if (this.currentComponent.renderRelatedForm) {
      this.onRemoveRelatedItem(gridRow, this.currentTabName)
    }
    this.$emit('simpleGridActionRemove', gridRow)
  }

  onTabHeaderRemoveItem() {
    this.$emit('onTabHeaderRemoveItem')
  }

  goToTab(tabIndex: number, hideAlert = false) {
    this.hideAlert = hideAlert
    this.selectedCurrentTabIndex = tabIndex
    this.selectedTabIndex = tabIndex
    const currentTab: any = this.tabs[tabIndex]
    this.onTabChange(tabIndex, currentTab)
  }

  setDisabledTabMessage(message: string) {
    this.disabledTabsMessage = message
  }

  disabledTabsMethod(hideTabsMessageAlert = false) {
    this.disabledTabs = true
    if (hideTabsMessageAlert) {
      this.hideAlert = true
    }
  }

  @Emit()
  setTandemFilter(metadata: object) {
    return metadata
  }

  @Emit()
  changeBreadcrumbs(value: boolean): boolean {
    return value
  }

  onBeforeSaveTabsVisibility() {
    this.$emit('onBeforeSaveTabsVisibility', this.currentTab)
  }

  @Emit()
  async onAfterSaveTabsVisibility() {
    // this.afterSaveTabsVisibility()
    this.$emit('onAfterSaveTabsVisibility', this.currentTab)
  }

  // Código a ejecutar cada vez que se cambia la configuración de las pestañas
  // si la clase cliente no quiere tocar (toca mediante el evento onAfterSaveTabsVisibility)
  async afterSaveTabsVisibility() {
    // Apagar el renderizado de los componentes
    this.everythingLoaded = false

    // Pedir la configuración reactiva
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW, // TODO: Añadir ConfigurationType a las propiedades de este componente
      alias: ConfigurationAlias.EXPEDIENT, // TODO: Añadir ConfigurationType a las propiedades de este componente
      context: ContextName.EXPEDIENTS
    })

    // Obtener el índice de la nueva configuración
    let index = this.tabsConfiguration(this.context).findIndex((tab: Tab) => tab.name === this.currentTab!.name)

    if (index < 0) {
      // La pestaña actual no es visible
      index = 0

      // @TODO: Añadir propiedad al componente que indique el alias de la pestaña por defecto
      //        (la que nunca se oculta por configuración)
    }

    // Situar la pestaña actual en el nuevo índice
    this.setSelectedTabIndex(index)
    this.setSelectedCurrentTabIndex(index)

    // Establecer la pestaña actual
    const tab: Tab = this.getCurrentViewTabs(this.context)[index]
    this.setCurrentTab(tab)

    this.$nextTick()

    // Encender el renderizado de los componentes
    this.everythingLoaded = true
  }

  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index
  }
  setSelectedCurrentTabIndex(index: number) {
    this.selectedCurrentTabIndex = index
  }
  setEverythingLoaded(everythingLoaded: boolean) {
    this.everythingLoaded = everythingLoaded
  }
  setCurrentTab(tab: Tab) {
    this.currentTab = tab
  }
  async onBeforeOpenTabSelector() {
    if (this.saveOnBeforeOpenTabSelector) {
      if (
        this.lastObjectType === this.componentType.form ||
        this.lastObjectType === this.componentType.expedientInsurers ||
        this.lastObjectType === this.componentType.invoicesTab ||
        this.lastObjectType === this.componentType.customFieldsTab ||
        this.lastObjectType === this.componentType.expedientPeriodiBilling ||
        this.lastObjectType === this.componentType.expedientGlobalVision
      ) {
        await this.emit('save-on-tab-change', false, this.lastObjectType)
      }
      await this.refreshForm(this.lastObjectType, true)
      this.refreshComponentManually()
    }
  }

  toggleStatusTabs(status: boolean) {
    this.disabledTabs = status
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/tabs/TabsComponent/tabsComponentStyles';
</style>
