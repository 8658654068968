import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'
import { Entity, entity } from '@/store/modules/entities/entitiesTypes'
import { createEntityByType } from '@/store/modules/entities/entitiesActions'
import { TabNames } from '@/store/modules/tabs/tabsTypes'

export const getFileExtension = (fileName: string): string => {
  if (!fileName) {
    return ''
  }
  if (fileName.includes('.')) {
    return fileName.split('.').pop()?.toLowerCase() || ''
  }
  return ''
}

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const goToDocumentRoute = (document: any, pathInfo: DocumentPathInfo) => {
  const documentData = {
    entityType: document.idEntityType,
    entityId: document.idEntity,
    path: pathInfo.path,
    basePath: pathInfo.basePath,
    fileName: document.initialFile
  }

  return getEntityURL(documentData)
}

const getEntityURL = (documentData: any) => {
  const currentEntity: Entity = getEntity(Number(documentData.entityType), Number(documentData.entityId))
  const basePath = encodeURI(btoa(documentData.basePath))
  const path = encodeURI(btoa(documentData.path))
  const fileName = encodeURIComponent(documentData.fileName)

  let tab: string = ''
  switch (currentEntity.type) {
    case entity.template.type:
      tab = TabNames.TEMPLATES
      break
    case entity.general.type:
      tab = ''
      break
    default:
      tab = TabNames.DOCUMENTS
      break
  }

  return currentEntity.getRoute({ basePath, path, tab, fileName })
}

const getEntity = (entityType: number, entityId: number = 0): Entity => {
  return createEntityByType(entityType, entityId)
}
