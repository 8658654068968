import { GetterTree } from 'vuex'
import { ActionItem, DashboardWidgetsState, ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { CounterInfo } from '@/components/counters/types/CounterTypes'

export const getters: GetterTree<DashboardWidgetsState, RootState> = {
  getComeFromDashboardWidgetFlag(state): boolean {
    return state.comeFromDashboardWidgetFlag
  },
  getActionWidgetList(state): ActionItem [] {
    return state.actionWidget.list
  },
  getActionWidgetStatus(state): ListStatus {
    return state.actionWidget.status
  },
  getCounterWidgetList(state): CounterInfo [] {
    return state.counterWidget.list
  },
  getCounterWidgetStatus(state): ListStatus {
    return state.counterWidget.status
  }
}
