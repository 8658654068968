import { AxiosRequestConfig } from 'axios'
import { http, onAccessTokenRefreshed } from '@/plugins/axios'
import { sanitizeHtml, purifyHtml } from '@/helpers/html'

const defaultConfig = { headers: { 'Content-Type': 'application/json' } }
export class MainService {
  constructor() {}

  getData(url: string, config: AxiosRequestConfig = {}) {
    return http.get(url, config)
  }

  postData(url: string, data: object | null = null, sanitizeAll: boolean = true) {
    if (data) {
      const params = new URLSearchParams()
      const stringData = JSON.stringify(data)
      const sanitizeData = sanitizeAll ? sanitizeHtml(stringData) : purifyHtml(stringData)
      params.append('json', sanitizeData)
      return http.post(url, params)
    } else {
      return http.post(url)
    }
  }

  postFormData(url: string, formData: FormData) {
    return http.post(url, formData)
  }

  postBody(url: string, data: object | null = null, config: AxiosRequestConfig = {}) {
    if (data) {
      return http.post(url, data, config)
    } else {
      return http.post(url)
    }
  }

  putData(url: string, data: object, config: AxiosRequestConfig = defaultConfig) {
    return http.put(url, data, config)
  }

  putJsonData(url: string, data: object | null = null, sanitizeAll: boolean = true) {
    if (data) {
      const params = new URLSearchParams()
      const stringData = JSON.stringify(data)
      const sanitizeData = sanitizeAll ? sanitizeHtml(stringData) : purifyHtml(stringData)
      params.append('json', sanitizeData)
      return this.putFormData(url, params)
    } else {
      return this.putFormData(url, null)
    }
  }

  putFormData(url: string, formData: FormData | null) {
    return http.post(url, formData, {headers:{'X-HTTP-Method-Override': 'PUT'}})
  }

  deleteData(url: string) {
    return http.delete(url)
  }

  refreshAccessToken(accessToken: string) {
    onAccessTokenRefreshed(accessToken)
    return this
  }
}
