import RememberPasswordView from '@/views/RememberPassword/RememberPasswordView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { Route } from 'vue-router'

export const loginRoutes = [
  {
    path: `/${URLS.LOGIN}`,
    name: URLS.LOGIN,
    component: () => import('@/views/Login/LoginView.vue'),
    meta: {
      title: URLS.LOGIN
    }
  },
  {
    path: `/${URLS.NAVISION_LOGIN}/:navisionToken`,
    name: URLS.NAVISION_LOGIN,
    component: () => import('@/views/Login/NavisionLoginView.vue'),
    props: ({ params }: Route) => ({ navisionToken: params.navisionToken }),
    meta: {
      title: URLS.NAVISION_LOGIN
    }
  },
  {
    path: `/${URLS.SELECT_COMPANY}/`,
    name: URLS.SELECT_COMPANY,
    props: true,
    component: () => import('@/views/SelectCompany/SelectCompanyView.vue'),
    meta: {
      title: URLS.SELECT_COMPANY
    }
  },
  {
    path: `/${URLS.REMEMBER_PASSWORD}`,
    name: URLS.REMEMBER_PASSWORD,
    component: RememberPasswordView,
    meta: {
      title: URLS.REMEMBER_PASSWORD
    }
  },
  {
    path: `/${URLS.DOUBLE_OPTIN}/:uuid`,
    name: URLS.DOUBLE_OPTIN,
    component: () => import('@/views/Login/DoubleOptinAdviseView.vue'),
    props: ({ params }: Route) => ({ uuid: params.uuid }),
    meta: {
      title: URLS.DOUBLE_OPTIN
    }
  },
]
