<template lang="pug">
div(class="contact-mini-dialog-container")

  //- HEADER
  CustomDialogHeaderComponent(:headerTitle="texts.title")
  StaticAlertComponent(
    v-if="showAdvice"
    :alert="alert"
    class="static-alert"
  )

  FormGeneratorComponent(
    :schema="formSchema"
    :fieldValues="formData"
    :context="context"
    formTag="selectForm"
    class="form-generator"
    showRequiredFieldsAdvice
    @saveFormData="onSaveFormData"
  )

  //- BUTTONS
  CustomDialogActionButtonsComponent(
    :disabledButtons="formIsValid"
    :mainAction="saveItems"
    :primaryButtonText="texts.primaryButtonText"
  )

  //- SPINNER
  SpinnerLayerComponent(v-if="loadingSpinner" class="spinner-layer" :loadingText="texts.spinnerText")
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { AlertsData, AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { MainService } from '@/services/MainService'
import { ContactFields } from '@/views/contacts/Contacts/types/ContactFieldTypes'
import { ContactPersonTypeId } from '@/store/modules/contacts/contactsTypes'

const alertsModule: string = ModuleNamespaces.ALERTS
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const formsModule = ModuleNamespaces.FORMS

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    FormGeneratorComponent: () => import('@/components/forms/FormGenerator/FormGeneratorComponent.vue'),
    ReceiverFieldSelectComponent: () =>
      import('@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue'),
    StaticAlertComponent,
    LfActionsBarComponent
  }
})
export default class ContactMiniFormComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: { formAlias: string; saveEndPoint: string }

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeDialog: () => {}

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Mutation('LOAD_NEW_REGISTER', { namespace: formsModule })
  newRegister: ({}) => void

  context = this.componentProps.formAlias
  formData: any = {}
  formSchema = []
  loadingSpinner = true
  showAdvice = false // TODO: Desactivado siempre, a la espera de pasar los datos de este form a contactos en FASE2

  texts = {
    completeInfo: this.$t('action_buttons.complete_info'),
    infoText: this.$t('components.alerts.contacts_disclaimer'),
    new: this.$t('views.selected_register.new').toString(),
    primaryButtonText: this.$t('action_buttons.save'),
    savedOk: this.$t('components.alerts.contacts_new_added'),
    savedError: this.$t('components.alerts.contacts_new_error'),
    spinnerText: this.$t('components.spinner.sending_data'),
    title: this.$t('fields.customerfact.new'),
    understand: this.$t('action_buttons.understand'),
    nameNatural: this.$t('components.contact.name_natural'),
    nameLegal: this.$t('components.contact.name_legal')
  }

  alert: AlertsData = {
    type: AlertsTypes.INFO,
    message: this.texts.infoText,
    acceptLink: {
      tooltip: this.texts.completeInfo,
      text: this.texts.completeInfo,
      callback: () => this.goToContacts()
    },
    cancelLink: {
      tooltip: this.texts.understand,
      text: this.texts.understand,
      callback: () => this.closeAdvice()
    }
  }

  async created() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: this.componentProps.formAlias,
      context: this.context
    })
    this.formSchema = this.getCurrentViewConfiguration(this.context)
    this.loadingSpinner = false
  }

  async onSaveFormData(schema: [], _formData: any, field: any, value: any) {
    if (ContactFields.PERSON_TYPE === field.id) {
      const nameItem: any = schema.find((item: any) => item.id === ContactFields.NAME)
      if (nameItem) {
        nameItem.label = value === ContactPersonTypeId.NATURAL ? this.texts.nameNatural : this.texts.nameLegal
      }

      const legalTypeItem: any = schema.find((item: any) => item.id === ContactFields.LEGAL_TYPE)
      if (legalTypeItem) {
        legalTypeItem.hidden = value === ContactPersonTypeId.NATURAL
      }

      const otherItems: any = schema.filter(
        (item: any) =>
          item.id === ContactFields.FIRST_SURNAME ||
          item.id === ContactFields.SECOND_SURNAME ||
          item.id === ContactFields.BIRTHDAY
      )
      otherItems.forEach((item: any) => {
        item.hidden = value !== ContactPersonTypeId.NATURAL
      })
    }
  }

  saveItems = async () => {
    const { id } = await this.save(this.formData)
    const newItem = {
      id,
      ...this.formData
    }
    newItem.name = `${newItem.name} ${newItem.firstSurname || ''}`
    this.newRegister({
      data: newItem,
      context: this.context
    })
    this.showAlert({
      type: AlertsTypes.SUCCESS,
      message: this.texts.savedOk,
      componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
    })
    this.closeDialog()
  }

  async save(json: any) {
    try {
      const { data } = await new MainService().postData(this.componentProps.saveEndPoint, json)
      return data
    } catch (error) {
      this.closeDialog()
      this.showAlert({
        type: AlertsTypes.ERROR,
        message: this.texts.savedError,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
  }

  goToContacts() {
    switch (this.formData.idPersonType) {
      case 0:
        this.$router.push({
          name: `${URLS.CONTACTS}-${URLS.CONTACT}`,
          params: { selectedRegisterId: this.texts.new }
        })
        break
      case 1:
        this.$router.push({
          name: `${URLS.CONTACTS}-${URLS.CONTACT}`,
          params: { selectedRegisterId: this.texts.new }
        })
        break
      default:
        this.$router.push({
          name: `${URLS.CONTACTS}`
        })
        break
    }
    this.closeDialog()
  }

  closeAdvice() {
    this.showAdvice = false
  }

  get formIsValid() {
    return !this.checkIfFormIsValid(this.context)
  }
}
</script>
<style lang="scss" scoped>
.contact-mini-dialog-container {
  ::v-deep .form-generator {
    @include scroll-styles;
    display: block;
    height: 475px;
    padding-bottom: 10px;
    margin-bottom: 24px;

    .required-fields-advice {
      display: block;
      width: 100%;
      text-align: right;

      &:before {
        right: 138px;
      }
    }
  }

  .spinner-layer {
    --spinner-layer-min-height: 95%;
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    background-color: $white-01;
  }
  .static-alert-container {
    margin-bottom: 10px;
  }
}
</style>
