<template>
  <div>
    <Grid
      :alias="props.listName"
      :commandButtons="commandButtons"
      :itemData="courts"
      :contextMenuItems="contextMenuItems"
      :toolbarOptions="toolbarItemsOptions"
      :toolbarClick="onToolbarClick"
      :commandClick="onCommandClick"
      :exportName="texts.exportName"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>
    <PromptDialog
      :title="parseModalTitle"
      :isOpened="openedPrompt"
      :context="formAlias"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :width="modalWidth"
      @execute-action="onExecuteAction"
      @changeFormField="onChangeFormField"
    ></PromptDialog>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridPersistedComponent.vue'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import { ActionName, CommandClickEventArgs, removeButton } from '@/components/grids/LfGrid/LfGridTypes'
import useFormSchema from '@/composables/useFormSchema'
import { ConfigurationAlias } from '@/store/modules/configuration/configurationTypes'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import { ContextMenuClickEventArgs, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import useSelectedRegister from '@/composables/useSelectedRegister'
import useExpedientCourts from './composables/useExpedientCourts'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import {
  columnsChooserToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { Icons } from '@/icons/icons'
import usePermissions from '@/composables/usePermissions'
import useDialog from '@/composables/useDialog'
import useFeedback from '@/composables/useFeedback'
import useStageSelector from '@/components/StageSelector/composable/useStageSelector'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ExpedientCourtsFields } from './types/ExpedientCourtsFieldsTypes'
import useUser from '@/composables/useUser'

const { t } = useI18n()

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    required: true
  },
  formAlias: {
    type: String,
    default: ConfigurationAlias.EXPEDIENT_COURTS_FORM
  },
  modalWidth: {
    type: Number,
    default: 850
  }
})

const texts = {
  buttons: {
    remove: t('action_buttons.remove').toString(),
    print: t('action_buttons.print').toString(),
    export: t('action_buttons.export').toString(),
    addCourts: 'Añadir'
  },
  modal: {
    title_new: 'Nuevo',
    title_edit: 'Editar'
  },
  alert: {
    save: 'Los datos han sido guardados correctamente'
  },
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    remove: t('action_buttons.remove').toString(),
    openNewTab: t('action_buttons.open_new_window').toString()
  },
  exportName: t('entity.courts').toString()
}

const openedPrompt = ref(false)
const formData = ref({})
const parseModalTitle = ref(texts.modal.title_new)
const commandButtons = [removeButton]

// COMPOSABLES   ======================================================================================================
const { isPortalUser } = useUser()

const { formSchema, getFormSchema } = useFormSchema({
  formAlias: props.formAlias,
  context: props.context
})

const { getDialogInfo, getDialogSuccess, getDialogError } = useDialog()
const { showFeedbackSuccess } = useFeedback()

const { selectedRegisterData } = useSelectedRegister({ entityName: ContextName.EXPEDIENTS })

const { viewPermission } = usePermissions()

const { courts, read, save, remove } = useExpedientCourts()

const { fecthStages, setSelectedStage, selectedStage } = useStageSelector()
// STORE GETTERS ======================================================================================================

// COMPUTEDS ==========================================================================================================
const toolbarItemsOptions = computed(
  (): ToolbarItemModel[] =>
    [
      searchToolbarItem,
      ...(!isPortalUser.value
        ? [
            {
              id: ActionName.ADD,
              text: texts.buttons.addCourts,
              align: 'Right',
              tooltipText: texts.buttons.addCourts,
              cssClass: 'lf-btn-model4'
            }
          ]
        : []),
      columnsChooserToolbarItem,
      kebakMenuToolbarItem
    ] as ToolbarItemModel[]
)

const contextMenuItems = computed(() => {
  if (isPortalUser.value) {
    return []
  }
  const { canDelete } = viewPermission.value
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

watch(selectedStage, () => {
  read()
})

watch(courts, () => {
  setSelectedStage(hasIdStage(courts.value) ? selectedStage.value || '0' : '')
})

// EVENTS =============================================================================================================
const onToolbarClick = ({ item }: ClickEventArgs) => {
  const { id } = item
  if (ActionName.ADD === id) {
    formData.value = {}
    openPrompt()
    parseModalTitle.value = texts.modal.title_new
    return
  }
}

const onRowClick = (args: RecordClickEventArgs) => {
  if (isPortalUser.value) {
    return
  }
  formData.value = args.rowData as any
  openPrompt()
  parseModalTitle.value = texts.modal.title_edit
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    read()
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    await saving(formData.value)
    read()
    fecthStages()
    openedPrompt.value = false
  }
}

const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { id, name } = rowData as any
  confirmDelete(id, name)
}

const onContextMenuClicked = ({ item, rowInfo }: ContextMenuClickEventArgs) => {
  const { id: itemId } = item
  const { rowData } = rowInfo as any
  const { id, name } = rowData as any

  if (itemId === ActionName.REMOVE) {
    confirmDelete(id, name)
    return
  }

  if (itemId === ActionName.EDIT) {
    formData.value = rowData
    openPrompt()
    parseModalTitle.value = texts.modal.title_edit
  }
}

async function onChangeFormField(schema: any, formData: any, field: any, value: any) {
  if (ExpedientCourtsFields.ID_STAGE === field.id) {
    schema.forEach((node: any) => {
      if (node.id === ExpedientCourtsFields.CURRENT_STAGE) {
        node.disabled = value == null // dejamos == porque queremos tener en cuenta undefined y null
        if (node.disabled) {
          formData[ExpedientCourtsFields.CURRENT_STAGE] = 0
        }
      }
    })
  }
}

// METHODS ============================================================================================================
function confirmDelete(id: string, name: string) {
  const { description } = selectedRegisterData.value
  getDialogInfo({
    message: t('components.dialog.courts_remove', { courtsName: name, expedientName: description }).toString(),
    action: async () => {
      await removing(id, name)
      fecthStages()
      read()
    },
    mainButtonText: t('action_buttons.remove'),
    secondaryButtonText: t('action_buttons.cancel')
  })
}

async function removing(id: string, name: string) {
  const response = await remove(id)
  if (!response) {
    getDialogError({
      message: t('components.dialog.courts_save_error').toString()
    })
  }
  getDialogSuccess({
    message: t('components.dialog.courts_remove_success', { courtsName: name }).toString()
  })
}

async function saving(data: any) {
  const response = await save(data)
  if (!response) {
    getDialogError({
      message: t('components.dialog.courts_save_error').toString()
    })
    return
  }
  showFeedbackSuccess({
    message: texts.alert.save,
    componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
  })
}
function openPrompt() {
  formSchema.value.forEach((node: any) => {
    if (node.id === ExpedientCourtsFields.CURRENT_STAGE) {
      node.disabled = (formData as any).value[ExpedientCourtsFields.ID_STAGE] == null // dejamos == porque queremos tener en cuenta undefined y null
    }
  })
  openedPrompt.value = true
}

function hasIdStage(courts: any) {
  return courts.some((court: any) => court.idStage !== null)
}

async function init() {
  if (!isPortalUser.value) {
    await getFormSchema()
  }
}

// HOOKS =============================================================================================================
init()
</script>
