<template lang="pug">

transition(name="fade" appear)
  div(class="custom-fields-empty-message-container")
    div(class="empty-message-title") {{ texts.title }}
    i18n(:path="texts.text" tag="div" class="empty-message-text")
      template(v-slot:link)
        router-link(:to="configurationPanelUrl") {{ texts.configurationPanel }}

</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'

@Component
export default class CustomFieldsEmptyMessageComponent extends Vue {
  icon = Icons.NEWS
  configurationPanelUrl = `/${URLS.CONFIGURATION_PANEL}/${URLS.EXPEDIENTS}/campos-personalizados`

  get texts() {
    return {
      text: 'components.custom_fields_tab.text_message',
      title: this.$t('components.custom_fields_tab.title_message'),
      configurationPanel: this.$t('components.custom_fields_tab.configuration_panel')
    }
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.custom-fields-empty-message-container {
  @include flex($flex-direction: column);
  font-family: $corporate-font;
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 100px;

  .empty-message-title {
    @include milano-medium-16;
    color: $neutral-grey-600;
    text-align: left;
    max-width: 100%;
    width: 500px;
  }

  .empty-message-text {
    @include milano-regular-14;
    color: $neutral-grey-600;
    text-align: left;
    max-width: 100%;
    width: 500px;
    margin-top: 2px;
  }
}
</style>
