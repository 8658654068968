<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class GridToolbarOptionsMixin extends mixins(PortalUserMixin, PermissionsMixin) {
  @Getter('getSelectedIdEntityType', { namespace: entitiesModule })
  selectedIdEntityType: number

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: ({}) => {}

  toolbarOptionsTooltipTexts = {
    add: this.$t('components.grid_table.tooltip.new_action'),
    archived: this.$t('components.grid_table.tooltip.archived'),
    exportText: this.$t('components.grid_table.tooltip.export'),
    filter: this.$t('components.grid_table.tooltip.clean_filter'),
    filters: this.$t('components.grid_table.tooltip.filter'),
    print: this.$t('components.grid_table.tooltip.print')
  }

  get toolbarOptions() {
    const { add } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(this.showAddNewToolbarButton
        ? [
            {
              id: ActionName.ADD,
              tooltipText: add,
              cssClass: 'lf-btn-model2',
              align: 'Right'
            }
          ]
        : []),
      columnsChooserToolbarItem,
      kebakMenuToolbarItem
    ]
  }

  get showAddNewToolbarButton() {
    return !this.isPortalUser && this.viewPermission.canSave
  }

  get toolbarOptionsWithoutAddNewButton() {
    return this.toolbarOptions.filter((item) => item.id !== 'add')
  }

  getToolbarOptions(options?: any) {
    const filterToolbarOptions = this.getFilterToolbarOptions(options)
    return filterToolbarOptions || this.toolbarOptions
  }

  getFilterToolbarOptions(options?: any) {
    if (!options) {
      return null
    }
    return this.toolbarOptions.filter((item: any) => {
      if (item.text === ActionName.COLUMNS_CHOOSER) {
        return options.includes(item.text)
      }
      return item.id && options.includes(item.id)
    })
  }
}
</script>
