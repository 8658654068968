<template lang="pug">

  div(class="status-template-container")
    span(v-if="renderOnlyOneStatus" :class="className") {{ texts.sentToFirma }}
    ul(v-else class="status-list")
      li(
        :class="[multipleStatusClassName(status), 'audit-grid-item']"
        v-for="(status, index) in multipleStatus"
        :key="index"
      ) {{ multipleStatus[index] }}

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { compareObjects } from '@/helpers/sort'
import { FirmaRequestStatus, ParsedFirmaAudit } from '@/store/modules/firma/firmaTypes'

enum multipleStatusType {
  COMPLETED = 'completed',
  READY = 'ready'
}

@Component({
  name: 'monitoringDialogStatusColumnTemplate'
})
export default class MonitoringDialogStatusColumnTemplateComponent extends Vue {
  data: ParsedFirmaAudit

  translationToken = 'components.firma_dialogs.monitoring_and_audits.grid_columns'

  texts = {
    sentToFirma: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.sent_to_firma'),
    pendingSignature: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.pending_signature'),
    signed: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.signed'),
    signedWithAudit: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.signed_with_audit'),
    cancel: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.cancel'),
    emailSended: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.email_sended'),
    emailCertified: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.email_certified'),
    smsSended: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.sms_sended'),
    smsCertified: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.sms_certified'),
    documentCertified: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.document_certified')
  }

  get renderOnlyOneStatus() {
    // Renderiza uno si el estado es 0 (info petición Sergio) .  Cualqier otro estado (info evento segunda petición)
    const { idStatus } = this.data
    return idStatus === FirmaRequestStatus.SENT_TO_FIRMA
  }

  get renderedText() {
    const { idStatus } = this.data
    switch (idStatus) {
      case FirmaRequestStatus.SENT_TO_FIRMA:
        return this.texts.sentToFirma
      case FirmaRequestStatus.PENDING_SIGNATURE:
        return this.texts.pendingSignature
      case FirmaRequestStatus.SIGNED:
        return this.texts.signed
      case FirmaRequestStatus.SIGNED_WITH_AUDIT:
        return this.texts.signedWithAudit
      case FirmaRequestStatus.CANCEL:
        return this.texts.cancel
      case FirmaRequestStatus.EMAIL_SENDED:
        return this.texts.emailSended
      case FirmaRequestStatus.EMAIL_CERTIFIED:
        return this.texts.emailCertified
      case FirmaRequestStatus.SMS_SENDED:
        return this.texts.smsSended
      case FirmaRequestStatus.SMS_CERTIFIED:
        return this.texts.smsCertified
      case FirmaRequestStatus.DOCUMENT_CERTIFIED:
        return this.texts.documentCertified
      default:
        return idStatus
    }
  }

  get className() {
    switch (this.data.idStatus) {
      case FirmaRequestStatus.SIGNED:
      case FirmaRequestStatus.SIGNED_WITH_AUDIT:
        return 'green status'
      case FirmaRequestStatus.CANCEL:
        return 'red status'
      default:
        return 'yellow status'
    }
  }

  get multipleStatus() {
    const status: any = []
    const { documents } = (this as any).data
    if (documents) {
      const sortedDocumentsByRecipient = documents.sort((a: any, b: any) => compareObjects(a, b, 'email'))
      sortedDocumentsByRecipient.forEach((item: any) => {
        const text = item.status
          ? this.$t(`components.firma_dialogs.monitoring_and_audits.grid_columns.${item.status}`)
          : this.renderedText
        status.push(text)
      })
    } else {
      this.data.recipients.forEach((_recipient) => status.push(this.renderedText))
    }
    return status
  }

  multipleStatusClassName(status: multipleStatusType) {
    switch (status) {
      case multipleStatusType.COMPLETED:
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.document_signed'):
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.document_certified'):
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.email_certified'):
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.sms_certified'):
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.document_opened'):
        return 'green status'
      case this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.document_canceled'):
        return 'red status'
      default:
        return 'yellow status'
    }
  }
}
</script>

<style lang="scss" scoped>
.status-template-container {
  .status-list {
    @include list;

    li {
      margin-bottom: 8px
    }
  }
}
</style>
