<template>
  <div class="widget-container">
    <WidgetBaseComponent
      ref="widget"
      class="expedient-reminder-notes-widget"
      :loadedData="allDataLoaded && !showSpinner"
    >
      <template v-slot:header>
        <div class="header-bar">
          <h2>{{ text.header }}</h2>
          <button v-if="canSave" :class="[icons.addButton, 'add-button']" @click="newNote" />
        </div>
      </template>

      <template v-slot:body>
        <div class="widget-body">
          <ReminderNotesContainerComponent
            v-if="parsedNotes.length"
            :notes="parsedNotes"
            @edit-note="editNote"
            @show-remove-dialog="showRemoveDialog"
          />
          <EmptyNotesComponent v-else />
        </div>
      </template>
    </WidgetBaseComponent>
    <PromptDialogComponent
      :width="940"
      :title="'Nueva nota'"
      :isOpened="isOpenedDialog"
      :formSchema="formSchema"
      context="sso-payments"
      :formFieldValues="formFieldValues"
      @execute-action="executeDialogAction"
    >
    </PromptDialogComponent>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, onBeforeUnmount, watch } from 'vue'
import store from '@/store/store'
import { useI18n } from 'vue-i18n-composable'
import useStageSelector from '@/components/StageSelector/composable/useStageSelector'
import usePermissions from '@/composables/usePermissions'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import EmptyNotesComponent from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/EmptyNotesComponent.vue'
import ReminderNotesContainerComponent from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/ReminderNotesContainerComponent.vue'
import { ActionName } from '@/components/CustomContextMenu/types/ActionBarComponentTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import {
  ExpedientReminderNotesWidgetData,
  Note,
  NoteInfo,
  SaveNoteObject
} from './ExpedientReminderNotesComponentTypes'
import { DialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { initialState } from '@/store/modules/notes/notesTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const globalModule: string = ModuleNamespaces.GLOBAL
const notesModule: string = ModuleNamespaces.NOTES
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

// COMPOSABLE
const permissionsComposable = usePermissions()
const { selectedStage } = useStageSelector()

// TRANSLATIONS
const { t } = useI18n()

const text = {
  cancel: t('action_buttons.cancel'),
  header: t('components.widgets.expedient_reminder_notes.title'),
  inputNoteName: t('components.widgets.expedient_reminder_notes.input_note_name'),
  inputNoteSubject: t('components.widgets.expedient_reminder_notes.input_note_subject'),
  remove: t('action_buttons.remove')
}

// DATA
const contexts = {
  expedientReminderNotes: ContextName.EXPEDIENT_REMINDER_NOTES_WIDGET,
  expedientContext: ContextName.EXPEDIENTS
}

const icons = {
  addButton: Icons.ADD,
  note: Icons.TASKS,
  remove: Icons.REMOVE
}
const isOpenedDialog = ref(false)
const formFieldValues = ref<NoteInfo | {}>({})
const selectedNoteId = ref<null | number>(null)

const formSchema = [
  {
    id: 'title',
    name: 'title',
    type: 'text',
    label: text.inputNoteName,
    fieldType: 'LexonTextFieldComponent',
    validationRules: 'required',
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    'offset-xs': 0,
    'offset-sm': 0,
    'offset-md': 0,
    'offset-lg': 0,
    'offset-xl': 0
  },
  {
    id: 'subject',
    name: 'subject',
    type: 'text',
    label: text.inputNoteSubject,
    fieldType: 'LexonTextAreaComponent',
    maxlength: 300,
    validationRules: 'max:300',
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    'offset-xs': 0,
    'offset-sm': 0,
    'offset-md': 0,
    'offset-lg': 0,
    'offset-xl': 0
  }
]

const showSpinner = ref(true)

// COMPUTED
const canSave = computed(() => {
  const { canSave } = permissionsComposable.checkEntityPermissionsGetter(entity.expedients.type)
  return canSave
})

const allDataLoaded = computed(() => {
  return !!(widgetDataGetter.value && Object.keys(widgetDataGetter.value).length)
})

const parsedNotes = computed(() =>
  widgetDataGetter.value.notes.map((item: any) => {
    return {
      ...item,
      note: JSON.parse(item.note)
    }
  })
)

// GETTERS
const selectedExpedientIdGetter = computed(() =>
  store.getters[`${selectedRegisterModule}/getSelectedRegisterId`](contexts.expedientContext)
)

const widgetDataGetter = computed<ExpedientReminderNotesWidgetData>(() => store.getters[`${notesModule}/getNotes`])

// WATCH
watch(selectedStage, () => {
  fetchWidgetDataAction()
})

// HOOKS
onMounted(() => {
  fetchWidgetDataAction()
})

onBeforeUnmount(() => {
  resetModuleInitialStateAction()
})

// METHODS
function newNote() {
  formFieldValues.value = {
    title: `Nota ${parsedNotes.value.length + 1}`,
    subject: ''
  }
  toggleDialog()
}

function editNote(note: Note) {
  selectedNoteId.value = note.id
  formFieldValues.value = {
    title: note.note.title,
    subject: note.note.subject
  }
  toggleDialog()
}

function toggleDialog() {
  isOpenedDialog.value = !isOpenedDialog.value
}

async function fetchWidgetDataAction() {
  showSpinner.value = true
  await store.dispatch(`${notesModule}/fetchNotes`, {
    idEntity: selectedExpedientIdGetter.value,
    idEntityType: entity.expedients.type,
    source: contexts.expedientReminderNotes,
    idStage: selectedStage.value
  })

  showSpinner.value = false
}

async function saveNoteAction(note: SaveNoteObject): Promise<void> {
  await store.dispatch(`${notesModule}/saveNote`, note)
}

async function saveNote() {
  await saveNoteAction({
    idEntity: selectedExpedientIdGetter.value,
    idEntityType: entity.expedients.type,
    note: formFieldValues.value,
    // Se le añade el id de la nota seleccionada si estamos en modo edición
    ...(selectedNoteId.value && { id: selectedNoteId.value })
  })
  await fetchWidgetDataAction()
  closeDialog()
}

function closeDialog() {
  selectedNoteId.value = null
  formFieldValues.value = {}
  toggleDialog()
}

function executeDialogAction(actionName: string) {
  switch (actionName) {
    case ActionName.CLOSE:
      return closeDialog()
    case ActionName.SAVE:
      return saveNote()
  }
}

async function removeNote(note: Note) {
  await store.dispatch(`${notesModule}/deleteNote`, note.id)
  await store.dispatch(`${dialogModule}/showDialog`, {
    type: DialogTypes.SUCCESS,
    message: t('components.widgets.expedient_reminder_notes.remove_confirmation_text', {
      title: note.note.title
    }) as string,
    notMoveToTop: true
  })
  await fetchWidgetDataAction()
}

function showRemoveDialog(note: Note) {
  const dialogData: DialogData = {
    action: () => removeNote(note),
    mainButtonText: text.remove,
    message: t('components.widgets.expedient_reminder_notes.remove_warning_text', {
      title: note.note.title
    }) as string,
    notMoveToTop: true,
    secondaryButtonText: text.cancel,
    type: DialogTypes.INFO
  }
  store.dispatch(`${dialogModule}/showDialog`, dialogData)
}

function resetModuleInitialStateAction() {
  store.dispatch(`${globalModule}/resetModuleInitialState`, {
    initialState,
    moduleName: ModuleNamespaces.NOTES
  })
}
</script>

<style lang="scss" scoped>
.expedient-reminder-notes-widget {
  display: flex;
  height: 366px;
  width: 100%;
  padding: $spacing-sm;
  flex-direction: column;
  align-items: center;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;

  .header-bar {
    @include flex($justify-content: space-between);
    width: 100%;

    .add-button {
      @include borders;
      display: flex;
      padding: $spacing-xxs;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: $cornerRadius-sm;
    }
  }

  .widget-body {
    @include flex($flex-direction: column);
    width: 100%;
  }
}
</style>
