<template>
  <div class="expedient-insurers-container">
    <FormGeneratorComponent
      :context="context"
      :schema="formSchema"
      :fieldValues="parsedFormData"
    ></FormGeneratorComponent>

    <LfGridComponent
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="expedientInsurers"
      :toolbarOptions="toolbarItemsOptions"
      :toolbarClick="onToolbarClick"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
      :commandClick="onCommandClick"
      :contextMenuItems="contextMenuItems"
      :exportName="texts.exportName"
    ></LfGridComponent>

    <PromptDialogComponent
      v-if="!isPortalUser"
      :title="modalTitle"
      :isOpened="openedPrompt"
      :formSchema="parsedModalFormSchema"
      :formFieldValues="modalFormData"
      :context="contextModal"
      :width="modalWidth"
      @execute-action="onExecuteAction"
    ></PromptDialogComponent>
  </div>
</template>
<script lang="ts" setup>
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import useFormSchema from '@/composables/useFormSchema'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import { computed, ref } from 'vue'
import useExpedientInsurers from './composables/useExpedientInsurers'
import { RenderedForm } from './types/ExpedientInsurersComponentTypes'
import { ContextMenuClickEventArgs, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import useDialog from '@/composables/useDialog'
import { useI18n } from 'vue-i18n-composable'
import { kebakMenuToolbarItem } from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { Icons } from '@/icons/icons'
import { watch } from 'vue'
import useStageSelector from '@/components/StageSelector/composable/useStageSelector'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import useUser from '@/composables/useUser'

// DEFINE PROPS  ======================================================================================================
const { t } = useI18n()

const texts = {
  buttons: {
    add: 'Añadir',
    print: 'Print',
    export: 'Export',
    title: 'Aseguradoras'
  },
  modal: {
    titleNew: 'Añadir aseguradora',
    titleEdit: 'Editar aseguradora'
  },
  dialog: {
    success: (insurerName: string) =>
      t('components.expedient_insurers.dialog_save_success', { insurerName }).toString(),
    deleteSuccess: (insurerName: string) =>
      t('components.expedient_insurers.dialog_remove_insurer_success', { insurerName }) as string,
    delete: (insurerName: string, expedient: string) =>
      t('components.expedient_insurers.dialog_remove_insurer', { insurerName, expedient }) as string
  },
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    remove: t('action_buttons.remove').toString()
  },
  exportName: t('entity.insurers').toString()
}

const commandButtons: CommandModel[] = [
  {
    id: ActionName.REMOVE,
    type: 'None',
    title: 'Eliminar',
    buttonOption: {
      iconCss: 'lf-icon-trash',
      cssClass: 'custombutton'
    }
  }
]

const contextModal = 'expedientInsurer'
const context = 'listExpedientInsurers'

const modalWidth = 960
const modalTitle = ref(texts.modal.titleNew)
const openedPrompt = ref(false)
const modalFormData = ref({})

// COMPOSABLES   ======================================================================================================
const { isPortalUser } = useUser()
const { getDialogSuccess, getDialogInfo } = useDialog()

const { gridConfiguration } = useGridConfiguration({
  listName: 'listExpedientInsurers',
  context,
  commandButtons: isPortalUser.value ? [] : commandButtons
})

const { formSchema, getFormSchema } = useFormSchema({
  formAlias: RenderedForm.EXPEDIENT_INSURER,
  context: `${ context }-main`
})

const { formSchema: modalFormSchema, getFormSchema: getModalFormSchema } = useFormSchema({
  formAlias: RenderedForm.EXPEDIENT_INSURER_FORM,
  context
})

const { expedientData, fetchExpedientInsurers, expedientInsurers, saveInsurer, removeInsurer, saveExpedient } =
  useExpedientInsurers()

const { selectedStage, expedientHasStages } = useStageSelector()

// COMPUTEDS ==========================================================================================================

const toolbarItemsOptions = computed(() => {
  return [
      {
        id: ActionName.TITLE,
        text: texts.buttons.title,
        cssClass: 'lf-title',
        align: 'Left'
      },
      ...(!isPortalUser.value
        ? [
          {
            id: ActionName.ADD,
            text: texts.buttons.add,
            align: 'Right',
            tooltipText: texts.buttons.add,
            cssClass: 'lf-btn-model4'
          }
        ] : []),
      kebakMenuToolbarItem
    ] as ToolbarItemModel []
})

const contextMenuItems = computed(() => {
  return isPortalUser.value
    ? []
    : [
      {
        id: ActionName.EDIT,
        text: texts.contextMenuItems.edit,
        iconCss: Icons.EDIT
      },
      {
        id: ActionName.REMOVE,
        text: texts.contextMenuItems.remove,
        iconCss: Icons.REMOVE
      }
    ]
})

const parsedFormData = computed(() => {
  return expedientData.value
})

const parsedModalFormSchema = computed(() => {
  const stageField: any = modalFormSchema.value.find(({ id }) => id === ExpedientFields.STAGE)
  if (stageField) {
    stageField.hidden = !expedientHasStages.value
  }

  return modalFormSchema.value
})

// EVENTS =============================================================================================================
const onToolbarClick = ({ item }: ClickEventArgs) => {
  const { id } = item
  if (ActionName.ADD === id) {
    modalFormData.value = {}
    openedPrompt.value = true
    modalTitle.value = texts.modal.titleNew
    saveExpedient(parsedFormData.value)
  }
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    const { idInsurer } = modalFormData.value as any
    const { success } = await saveInsurer(modalFormData.value)
    if (success) {
      getDialogSuccess({
        message: texts.dialog.success(idInsurer.name)
      })
    }
    openedPrompt.value = false
    fetchExpedientInsurers()
  }
}

const onRowClick = ({ rowData }: RecordClickEventArgs) => {
  if(isPortalUser.value) {
    return
  }
  openedPrompt.value = true
  saveExpedient(parsedFormData.value)
  modalFormData.value = rowData as any
  modalTitle.value = texts.modal.titleEdit
}

const onCommandClick = ({ rowData }: CommandClickEventArgs) => {
  const { id, insurer: insurerName } = rowData as any
  const { idOwnFile } = expedientData.value
  confirmDelete({ id, insurerName, idOwnFile })
}

const onContextMenuClicked = ({ item, rowInfo }: ContextMenuClickEventArgs) => {
  const { id: itemId } = item
  const { rowData } = rowInfo as any
  const { id, insurer } = rowData as any
  if (itemId === ActionName.REMOVE) {
    const { idOwnFile } = expedientData.value
    confirmDelete({ id, insurerName: insurer, idOwnFile })
    return
  }
  if (itemId === ActionName.EDIT) {
    saveExpedient(parsedFormData.value)
    modalFormData.value = rowData as any
    modalTitle.value = texts.modal.titleEdit
    openedPrompt.value = true
  }
}

watch(selectedStage, () => {
  fetchExpedientInsurers()
})

// METHODS ============================================================================================================
function confirmDelete({ id, insurerName, idOwnFile }: { id: string; insurerName: string; idOwnFile: string }) {
  getDialogInfo({
    message: texts.dialog.delete(insurerName, idOwnFile),
    mainButtonText: t('action_buttons.remove'),
    secondaryButtonText: t('action_buttons.cancel'),
    action: async () => {
      await removing({ id, insurerName })
      fetchExpedientInsurers()
    }
  })
}

async function removing({ id, insurerName }: { id: string; insurerName: string }) {
  const response = await removeInsurer(id)
  if (response) {
    getDialogSuccess({
      message: texts.dialog.deleteSuccess(insurerName)
    })
  }
}

async function init() {
  if (!isPortalUser.value) {
    await getModalFormSchema()
  }
  await getFormSchema()
  await fetchExpedientInsurers()
}

// HOOKS =============================================================================================================

init()
</script>
<style lang="scss" scoped>
::v-deep .e-grid.grid-table .e-toolbar {
  background-color: $neutral-grey-050;
  margin: $spacing-sm 0px;
}
</style>
