<template>
  <LfGridComponent
    v-if="gridConfiguration"
    ref="mainGrid"
    v-bind="props"
    :gridConfiguration="gridConfiguration"
    @rowClick="onRowClick"
    @contextMenuClicked="onContextMenuClicked"
    @toolbarClicked="onToolbarClicked"
    @rowsSelected="onRowsSelected"
    @contextMenuBeforeOpen="onContextMenuBeforeOpen"
  />
</template>
<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, PropType, ref } from 'vue'
import LfGridComponent from './LfGridComponent.vue'

import { ClickEventArgs, CommandModel } from './LfGridTypes'
import useGridConfig from './composables/useGridConfig'
import useGridMethods from './composables/useGridMethods'
import { gridProps } from './LfGridProps'
import { gridEmits } from './LfGridEmits'
import { ContextMenuClickEventArgs, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'

const emit = defineEmits(gridEmits)

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  alias: {
    type: String,
    required: true
  },
  commandButtons: {
    type: Array as PropType<CommandModel[]>,
    default: () => []
  },
  ...gridProps
})

const mainGrid = ref<InstanceType<typeof LfGridComponent> | null>(null)
const gridRef = computed(() => mainGrid.value?.gridRef)

// COMPOSABLES   ======================================================================================================
const { refreshColumns } = useGridMethods({ gridRef })
const { saveGridDB, getGridConfiguration, gridConfiguration } = useGridConfig({
  gridRef,
  alias: props.alias,
  rememberUserConfig: props.rememberUserConfig,
  commandButtons: props.commandButtons
})

// EVENTS
const onRowClick = (args: RecordClickEventArgs) => {
  emit('rowClick', args)
}

const onContextMenuClicked = (args: ContextMenuClickEventArgs) => {
  emit('contextMenuClicked', args)
}

const onToolbarClicked = (args: ClickEventArgs) => {
  emit('toolbarClicked', args)
}

const onRowsSelected = (args: any) => {
  emit('rowsSelected', args)
}

const onContextMenuBeforeOpen = (args: any) => {
  emit('contextMenuBeforeOpen', args)
}

onMounted(async () => {
  await getGridConfiguration()
  refreshColumns()
})

onBeforeUnmount(() => {
  saveGridDB()
})
</script>
