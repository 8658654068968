<template lang="pug">
  v-dialog(
    v-if="showDialog"
    v-model="showDialog"
    persistent
    scrollable
    max-width="600px"
  )
    template(v-slot:default)
      v-card(
        class="modal-add-folder"
      )
        header(class="header")
          CustomDialogHeaderComponent(:headerTitle="texts.title")
        v-card-text(
          style="height: 450px;"
        )

          TemplateSelectorTreeComponent(
            :idEntityType="idEntityType"
            @templateSelected="getTemplateSelected"
            :loading="treeLoading"
            :key="componentKey"
            :generateTemplate="generatingTemplate"
          )

        v-card-actions(
          class="modal-footer"
        )
          div(
            class="right"
          )
            button(
              @click.stop="close"
              class="button secondary-action-button"
              title="texts.cancel"
            ) {{ texts.cancel }}
            button(
              v-if="hasSelectedTemplate"
              @click.stop="generate"
              class="button main-action-button"
              :title="texts.generate"
            ) {{ texts.generate }}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ITemplate } from '@/store/modules/template/templateTypes'
import NoResultsComponent from '@/components/search/NoResults/NoResultsComponent.vue'
import TemplateSelectorTreeComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorTreeComponent.vue'

@Component({
  components: {
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    SpinnerLayerComponent,
    NoResultsComponent,
    TemplateSelectorTreeComponent
  }
})
export default class TemplateSelectorComponent extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  showDialog!: boolean

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  generatingTemplate: boolean = false

  templateSelected: ITemplate | null = null

  texts = {
    title: this.$t('components.file_manager.dialogs.select_template'),
    cancel: this.$t('action_buttons.cancel'),
    generate: this.$t('action_buttons.generate')
  }

  icons = {
    document: Icons.TEMPLATE,
    folder: Icons.FOLDER,
    folderOpen: Icons.FOLDER_OPEN,
    on: Icons.RADIO_ACTIVE,
    off: Icons.CIRCLE_O,
    close: Icons.CLOSE
  }

  componentKey: number = 0

  treeLoading: boolean = false

  async updateComponent() {
    return (this.componentKey += 1)
  }

  getTemplateSelected(template: any) {
    this.templateSelected = template
  }

  get hasSelectedTemplate() {
    return this.templateSelected !== null
  }

  close() {
    this.templateSelected = null
    this.$emit('closeTemplateDialog')
  }

  generate() {
    this.$emit('generateTemplateDialog', this.templateSelected)
    this.templateSelected = null
    this.generatingTemplate = true
    this.treeLoading = true
    this.updateComponent().then(() => {
      this.treeLoading = false
      this.generatingTemplate = false
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/partials/components/modal/main';
</style>
