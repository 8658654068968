<template>
  <div class="radios-container">
    <div v-for="option in options" class="radio-item" :key="option[props.itemKey]">
      <input
        class="radio"
        type="radio"
        :name="option[props.itemText]"
        :id="option[props.itemText]"
        :value="option[props.itemKey]"
        v-model="selectedValue"
        @change="handleRadioChange"
      />
      <label :for="option[props.itemText]" class="radio-label"> {{ option[props.itemText] }} </label>
      <slot :item="option" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, defineProps, ref, watch } from 'vue'

// PROPS
const props = defineProps({
  options: {
    type: Array as PropType<any[]>,
    default: () => []
  },
  value: {
    type: [Number, String] as PropType<number | string>
  },
  itemKey: {
    type: String as PropType<string>,
    required: true
  },
  itemText: {
    type: String as PropType<string>,
    required: true
  }
})

// DATA
const selectedValue = ref(props.value)

// WATCH
watch(
  () => props.value,
  (newValue) => {
    selectedValue.value = newValue
  }
)

// EVENTS
const emit = defineEmits(['update-value'])

// METHODS
const handleRadioChange = () => {
  emit('update-value', selectedValue.value)
}
</script>

<style lang="scss" scoped>
.radios-container {
  align-self: flex-start;
  color: $corporate-color;
  width: 100%;
}

.radio-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $input-size;
  width: 100%;
  padding: $spacing-xs;

  .radio-label {
    @include ellipsis;
    padding-left: 7px;
  }
}

//- Radio button styles
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  @include arial-regular-14;
  display: inline-block;
  position: relative;
  color: $neutral-grey-800;
  padding-left: 26px;
  cursor: pointer;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  @include borders($color: $neutral-grey-800);
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 18px;
  height: 18px;
  background-color: $white-01;
  border-radius: 100%;
}
[type='radio']:checked + label:before {
  border-color: $main-1000;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  position: absolute;
  top: 3px;
  left: 3px;
  content: '';
  width: 12px;
  height: 12px;
  background-color: $main-1000;
  border-radius: 100%;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
</style>
