import { ReportList } from '@/store/modules/reports/reportsTypes'

export function adaptReportsList(data: any): asserts data is ReportList {
  if (!Array.isArray(data)) {
    throw new Error('Data is not an array')
  }

  data.forEach((item) => {
    adaptReports(item)
  })
}

export function adaptReports(item: any): asserts item is ReportList {
    if (typeof item.id !== 'string'
      || typeof item.alias !== 'string'
      || typeof item.configFilters !== 'string'
      || typeof item.aliasSysList !== 'string'
      || typeof item.category !== 'string'
      || typeof item.name !== 'string'
      || typeof item.description !== 'string'
    ) {
      throw new Error('Invalid report format')
    }
}

