import { ActionTree } from 'vuex'
import { BillingState, billingMutationTypes } from '@/store/modules/billing/billingTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { AxiosError } from 'axios'

export const actions: ActionTree<BillingState, RootState> = {
  async fetchBillingData({ commit }, { billingType, filter = {} }) {
    try {
      const url = `/invoices/${billingType}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchOrderFormList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/order-form/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchProvisionList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/provision/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchProformaInvoiceList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/proforma-invoice/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchCustomerInvoiceList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/customer-invoice/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchCustomerBankdraftList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/customer-bankdraft/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchProviderInvoiceList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/provider-invoice/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchProviderBankdraftList({ commit }, filter: {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData('/v2/provider-bankdraft/filtered', formData)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchInvoiceData({ commit }, { invoiceType, filter = {} }) {
    try {
      const url = `/invoices/${invoiceType}/list`
      const { data } = await new MainService().postData(url, filter)
      commit(billingMutationTypes.FETCH_BILLING_DATA, data)
      commit(billingMutationTypes.SET_LAST_LISTBILLING_FILTER, filter)
    } catch (error) {
      commit(billingMutationTypes.RESET_BILLING_DATA)
    }
  },
  async fetchOrderFormTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/order-form/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchProvisionTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/provision/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchProformaInvoiceTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/proforma-invoice/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchProviderBankdraftTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/provider-bankdraft/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchCustomerInvoiceTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/customer-invoice/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchCustomerBankdraftTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/customer-bankdraft/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async fetchProviderInvoiceTotals({ commit }, filter = {}) {
    try {
      const formData: FormData = new FormData()
      formData.append('filter', JSON.stringify(filter))
      const { data } = await new MainService().postFormData(
        '/v2/provider-invoice/filtered/summary',
        formData
      )
      commit(billingMutationTypes.FETCH_TOTALS, data)
    } catch (error) {
      commit(billingMutationTypes.RESET_TOTALS)
    }
  },
  async removeOrderForm({}, id) {
    try {
      const url = `/order-form/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return { success: data.result === 'OK'}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc}
    }
  },
  async removeProvision({}, id) {
    try {
      const url = `expedients/economic/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return { success: data.result === 'OK'}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc}
    }
  },
  async removeProformaInvoice({}, id) {
    try {
      const url = `/invoices/proforma/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return { success: data.result === 'OK'}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc}
    }
  },
  async billingRemove({}, url) {
    try {
      const { data } = await new MainService().deleteData(url)
      return { success: data.result === 'OK'}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc}
    }
  }
}
