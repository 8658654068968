<template lang="pug">

  div(class="expedient-global-vision-container")

    ExpedientGlobalVisionTopBarComponent

    ExpedientSummaryWidget

    ExpedientNextEventsWidget(v-if="hasAgendaPermission")

    div(class="row")
      ExpedientLastActionsWidget
      ExpedientReminderNotesComponent

    div(class="row")
      ExpedientActionsResumeWidget
      ExpedientProfitabilityWidget

  </template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ExpedientGlobalVisionTopBarComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientGlobalVisionTopBarComponent.vue'
import ExpedientLastActionsWidget from '@/components/widgets/ExpedientWidgets/ExpedientLastActions/ExpedientLastActionsWidget.vue'
import ExpedientNextEventsWidget from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidget.vue'
import ExpedientProfitabilityWidget from '@/components/widgets/ExpedientWidgets/ExpedientProfitabilityWidget.vue'
import ExpedientReminderNotesComponent from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/ExpedientReminderNotesComponent.vue'
import ExpedientSummaryWidget from '@/components/widgets/ExpedientWidgets/ExpedientSummaryWidget.vue'
import ExpedientActionsResumeWidget from '@/components/widgets/ExpedientWidgets/ExpedientActionsResume/ExpedientActionsResumeWidget.vue'
import { Getter } from 'vuex-class'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'

const authModule: string = ModuleNamespaces.AUTH

@Component({
  components: {
    ExpedientGlobalVisionTopBarComponent,
    ExpedientLastActionsWidget,
    ExpedientNextEventsWidget,
    ExpedientProfitabilityWidget,
    ExpedientReminderNotesComponent,
    ExpedientSummaryWidget,
    ExpedientActionsResumeWidget
  }
})
export default class ExpedientGlobalVisionComponent extends Vue {
  @Getter('getPermission', { namespace: authModule })
  checkEntityPermissionsGetter: (entityId: number) => PermissionVerbs

  get hasAgendaPermission() {
    return this.checkEntityPermissionsGetter(entity.agenda.type).canView
  }

  mounted() {
    this.$emit('expedientGlobalVisionComponentMounted')
  }
}
</script>

<style lang="scss" scoped>
.expedient-global-vision-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;

  .row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    justify-content: space-between;

    div {
      width: calc(50% - 8px);
      max-width: calc(50% - 8px);
      min-width: calc(50% - 8px);
    }
  }
}
</style>
