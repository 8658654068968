<template lang="pug">

WidgetBaseComponent(
  ref="widget"
  class="actions-resume-container"
  :loadedData="allDataLoaded && !showSpinner"
)
  //- HEADER
  template(v-slot:header)
    div(class="header-bar")
      h2 {{ widgetData.title }}
      button(
        :class="[icons.openWindow, 'open-window-button', 'lf-icon__md']"
        @click="goToActionList"
      )

  //- BODY
  template(v-slot:body)
    div(class="resumes-container")
      LfWhiteCounterComponent(v-for="(counter, index) in widgetData.actions" :key="`counter-${index}`" :info="counter")

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import LfWhiteCounterComponent from '@/components/counters/LfWhiteCounterComponent.vue'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

type Resume = {
  qty: number
  title: string
}

type WidgetData = {
  title: string
  actions: Resume[]
}

@Component({
  components: {
    WidgetBaseComponent,
    LfWhiteCounterComponent
  }
})
export default class ExpedientActionsResumeWidget extends Vue {
  @Action('fetchExpedientLastActionsWidgetData', { namespace: expedientsModule })
  fetchExpedientActionsResumeWidgetDataAction: ({}) => WidgetData

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  $refs!: {
    widget: any
  }

  widgetData: WidgetData = {
    title: '',
    actions: []
  }

  icons = {
    openWindow: Icons.OPEN_WINDOW
  }

  contexts = {
    actionCounters: ContextName.EXPEDIENT_ACTIONS_RESUME_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  showSpinner = true

  get selectedExpedientId(): number {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get allDataLoaded() {
    return !!this.widgetData.title
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.fetchActionCounters()
  }

  created() {
    this.fetchActionCounters()
  }

  async fetchActionCounters() {
    this.showSpinner = true

    this.widgetData = await this.fetchExpedientActionsResumeWidgetDataAction({
      selectedExpedientId: this.selectedExpedientId,
      filter: {
        source: this.contexts.actionCounters,
        idStage: this.expedientStageStateGetter
      }
    })

    this.showSpinner = false
  }

  goToActionList() {
    this.$router.push({
      name: URLS.ACTION_ALL
    })
  }
}
</script>

<style lang="scss" scoped>
.actions-resume-container {
  --lf-counter-height: auto;
  --lf-counter-padding-top: $spacing-xs;
  min-height: 150px;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;

  .header-bar {
    @include flex($justify-content: space-between);
    width: 100%;

    .open-window-button {
      color: $main-1000;
    }
  }

  .resumes-container {
    display: flex;
    width: 100%;
    gap: 8px;
  }
}

@include desktopSuperLarge {
  .actions-resume-container {
    --lf-counter-flex: auto;

    .resumes-container {
      flex-wrap: wrap;

      ::v-deep .lf-counter-wrapper {
        width: calc(25% - 8px);
      }
    }
  }
}
</style>
