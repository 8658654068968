<template>
  <v-list-item :class="['list-alert', { unread: isNotReadAlert }]">
    <div class="alert-info" @click="goToAlert(props.alert)">
      <span :class="[props.alert.icon, 'lf-icon__lg', 'icon']" />
      <div class="item-info">
        <span class="alert-text">{{ props.alert.message }}</span>
        <span class="alert-date">{{ texts.notificationDate }}: {{ props.alert.createAt }}</span>
        <!-- <span class="alert-date">{{ props.alert.createAt }}. (expira el: {{ props.alert.expireAt }})</span> -->
      </div>
    </div>
    <div class="icons-container">
      <LexonTooltipComponent
        :icon="AlertsMessageVisibilityIcons[props.alert.visibility]"
        :message="isNotReadAlert ? texts.read : texts.noRead"
        position="top"
        :action="() => onClickToggleRead(props.alert)"
      />
      <LexonTooltipComponent
        :icon="Icons.CHECK_ROUND"
        :message="texts.realized"
        position="top"
        :action="() => onClickDone(props.alert)"
      />
    </div>
  </v-list-item>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import useActionsFilteredAlertsMessages from '@/components/AlertsMessages/composables/useActionsFilteredAlertsMessages'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import {
  AlertMessage,
  AlertMessageVisibility,
  AlertsMessageToggleVisibility,
  AlertsMessageVisibilityIcons
} from '@/store/modules/alertsMessages/alertsMessagesTypes'
import useDialog from '@/composables/useDialog'
import useFeedback from '@/composables/useFeedback'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

// PROPS
const props = defineProps({
  alert: {
    type: Object as PropType<AlertMessage>,
    required: true
  }
})

// COMPOSABLE
const { changeVisibility, goToAlertsMessagesURL } = useActionsFilteredAlertsMessages()
const { getDialogSuccess, getDialogError, getDialog } = useDialog()
const { showFeedbackSuccess } = useFeedback()

// TRANSLATIONS
const { t } = useI18n()
const texts = {
  alerts: t('navigation.alerts_menu.alerts'),
  changeVisibilityError: t('navigation.alerts_menu.change_visibility_error'),
  chronologicalOrder: t('navigation.alerts_menu.chronological_order'),
  expirationOrder: t('navigation.alerts_menu.expiration_order'),
  link: t('navigation.alerts_menu.no_data.link'),
  loadError: t('navigation.alerts_menu.load_error'),
  noData: t('navigation.alerts_menu.no_data.text'),
  noRead: t('navigation.alerts_menu.no_read'),
  notificationDate: t('navigation.alerts_menu.notification_date'),
  notificationRead: t('components.alerts.notification_read'),
  notificationUnread: t('components.alerts.notification_unread'),
  read: t('navigation.alerts_menu.read'),
  realized: t('navigation.alerts_menu.realized'),
  viewMoreAlerts: t('navigation.alerts_menu.more_alerts')
}

// COMPUTED
const isNotReadAlert = computed(() => props.alert.visibility === AlertMessageVisibility.NO_READ)

// METHODS
function goToAlert(alert: AlertMessage) {
  goToAlertsMessagesURL(alert)
}

function showFeedbackText(alertVisibility: AlertMessageVisibility) {
  const feeedbackText =
    alertVisibility === AlertMessageVisibility.NO_READ ? texts.notificationUnread : texts.notificationRead
  showFeedbackSuccess({
    message: feeedbackText as string,
    componentWhereIsRendered: ComponentWhereIsRendered.LF_HEADER_TITLE
  })
}

async function onClickToggleRead(alertMessage: AlertMessage) {
  try {
    if (alertMessage.visibility === AlertMessageVisibility.NO_READ) {
      alertMessage.visibility = AlertMessageVisibility.READ
    } else {
      alertMessage.visibility = AlertMessageVisibility.NO_READ
    }
    await changeVisibility(alertMessage)
    showFeedbackText(alertMessage.visibility)
  } catch (error) {
    getDialogError({ message: texts.changeVisibilityError as string })
    alertMessage.visibility = AlertsMessageToggleVisibility[alertMessage.visibility]
  }
}

async function onClickDone(alertMessage: AlertMessage) {
  getDialog({
    type: DialogTypes.INFO,
    message: t('components.dialog.alert_message_done_prompt', { message: alertMessage.message }).toString(),
    action: () => doneAlert(alertMessage)
  })
}

async function doneAlert(alertMessage: AlertMessage) {
  try {
    alertMessage.visibility = AlertMessageVisibility.DONE
    await changeVisibility(alertMessage)
    getDialogSuccess({
      message: t('components.dialog.alert_message_done_success', { message: alertMessage.message }).toString()
    })
  } catch (error) {
    getDialogError({ message: texts.changeVisibilityError as string })
  }
}
</script>

<style scoped lang="scss">
.list-alert {
  display: flex;
  height: 73px;
  padding: $spacing-xs $spacing-sm;
  align-items: flex-start;
  gap: $spacing-sm;
  justify-content: space-between;

  &.unread {
    .item-info .alert-text {
      color: $main-1000;
    }

    .alert-info .icon {
      color: $main-1000;
      cursor: pointer;
    }
  }

  &:hover {
    .icons-container {
      opacity: 1;
    }
  }

  .icons-container {
    display: flex;
    opacity: 0;

    .icon {
      color: $neutral-grey-800;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .alert-info {
    display: flex;
    flex: 1;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-xs;

    .alert-text {
      @include milano-medium-14;
      color: $neutral-grey-800;
    }

    .alert-date {
      @include milano-regular-14;
      color: $neutral-grey-800;
      margin-top: 2px;
    }
  }

  &:hover {
    background-color: $neutral-grey-050;
  }
}
</style>
