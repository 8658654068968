<template lang="pug">

  div(class="move-action-dialog-container")
    CustomDialogHeaderComponent(:headerTitle="text.title" class="header")

    SpinnerLayerComponent(v-if="!loadedSelectItems" class="spinner-layer")

    LexonSelectComponent(
      v-show="loadedSelectItems"
      id="expedient"
      name="expedient"
      class="lexon-select"
      v-model="expedientId"
      :label="text.label"
      keyName="description"
      keyValue="idExpedient"
      :returnObject="false"
      :tableHeaders="tableHeaders"
      :endPointEntity="expedientEndpointEntity"
      @emit-data-length="removeSpinner"
    )

    CustomDialogActionButtonsComponent(
      v-if="loadedSelectItems"
      class="actions"
      :primaryButtonText="text.save"
      :mainAction="moveAction"
      :disabledButtons="!expedientId"
    )

</template>

<script lang="ts">
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { MoveExpedientActionRequest } from '@/store/modules/actions/actionsTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const actionsModule: string = ModuleNamespaces.ACTIONS
const dialogModule: string = ModuleNamespaces.DIALOG
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const alertsModule: string = ModuleNamespaces.ALERTS

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class MoveActionDialogComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: {
    selectedActionIds: number[],
    filter: any
  }

  @Action('fetchExpedientsActions', { namespace: expedientsModule })
  fetchExpedientsActions: (payload: any) => void

  @Action('moveActionToAnotherExpedientAction', { namespace: actionsModule })
  moveActionToAnotherExpedientAction: (request: MoveExpedientActionRequest) => Promise<void>

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  text = {
    title: this.$t('components.move_action_dialog.move_action'),
    label: this.$t('components.move_action_dialog.expedient_list'),
    save: this.$t('action_buttons.save'),
    error: this.$t('components.alerts.move_expedient_action_error')
  }

  tableHeaders = ['idOwnFile', 'description', 'customers']

  expedientId: null | number = null

  loadedSelectItems = false

  get selectedExpedientId() {
    return this.selectedRegisterId(ContextName.EXPEDIENTS)
  }

  get endpoint() {
    return `expedients/listExpedients`
  }

  get expedientEndpointEntity() {
    return {
      url: this.endpoint,
      method: 'POST',
      params: []
    }
  }

  removeSpinner() {
    this.loadedSelectItems = true
  }

  async moveAction() {
    if (!this.expedientId || !this.selectedExpedientId
      || this.expedientId.toString() === this.selectedExpedientId.toString()
      || 1 > this.componentProps.selectedActionIds.length
    ) {
        this.showAlert(this.text.error)
        this.closeCustomDialog()
        return
    }
    const moveRequest: MoveExpedientActionRequest = {
      fromExpedientId: this.selectedExpedientId,
      toExpedientId: this.expedientId,
      actionIdLot: this.componentProps.selectedActionIds
    }

    await this.moveActionToAnotherExpedientAction(moveRequest)

    this.fetchExpedientsActions({
      selectedRegisterId: moveRequest.fromExpedientId,
      listName: 'listExpedientsActions',
      filter: this.componentProps.filter
    })

    this.closeCustomDialog()
  }

  showAlert(message: any) {
    this.$store.dispatch(
      `${alertsModule}/showAlert`,
      {
        type: AlertsTypes.ERROR,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      },
      { root: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.move-action-dialog-container {
  @include flex($flex-direction: column, $justify-content: space-between);
  height: 200px;

  .header,
  .lexon-select,
  .actions {
    width: 100%;
  }

  .lexon-select {
    padding-bottom: 30px;
  }

  .spinner-layer {
    --spinner-layer-min-height: 150px;
  }
}
</style>
