import { ContextMenuClickEventArgs, ContextMenuOpenEventArgs } from "@syncfusion/ej2-vue-grids"
import { computed } from "vue"

interface PropsUseGridContextMenu {
  gridRef: any
}

interface ResponseUseGridContextMenu {
  contextMenuBeforeOpen: (args: any) => void
  contextMenuClicked: (args: ContextMenuClickEventArgs) => void
  getItemPositionByTarget: (target: any) => { top: number, left: number }
}
const MARGIN_TOP_CONTEXT_MENU = 8
export default function useGridContextMenu(props: PropsUseGridContextMenu): ResponseUseGridContextMenu {
  const { gridRef } = props

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  function contextMenuBeforeOpen(args: ContextMenuOpenEventArgs) {
    if (!args.column) {
      args.cancel = true
      return
    }
    return { ...args, hideItems, showAllItems }
  }

  const hideItems = (itemTexts: string[]) => {
    const contextmenu = gridInstance.value!.contextMenuModule.contextMenu
    const contextMenuElemenent = contextmenu.element

    showAllItems()
    contextmenu.enableItems(itemTexts, false)

    const disabledItems = contextMenuElemenent.querySelectorAll('.e-disabled')
    disabledItems.forEach((item: any) => {
      item.classList.add('hidden-contextmenu-option')
    })
  }

  const showAllItems = () => {
    const contextmenu = gridInstance.value!.contextMenuModule.contextMenu
    const contextMenuElemenent = contextmenu.element

    const allItems = contextMenuElemenent.querySelectorAll('.e-menu-item')
    allItems.forEach((item: any) => {
      item.classList.remove('hidden-contextmenu-option')
      item.classList.remove('e-disabled')
    })
  }

  const contextMenuClicked = (args: ContextMenuClickEventArgs) => {
    const { event, item, rowInfo } = args
    return { event, item, rowInfo }
  }

  const getItemPositionByTarget = (target: any) => {
    const rect = (target as any).getClientRects()[0] as DOMRect
    const top = rect.top + window.scrollY + rect.height + MARGIN_TOP_CONTEXT_MENU
    const left = rect.left + rect.width
    return { top, left }
  }

  return {
    getItemPositionByTarget,
    contextMenuBeforeOpen,
    contextMenuClicked
  }
}
