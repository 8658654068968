import { GetterTree } from 'vuex'
import { SpinnerState } from '@/store/modules/spinner/spinnerTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<SpinnerState, RootState> = {
  getSpinnerObject(state: SpinnerState): object {
    return state.spinner
  },
  getShowSpinnerLayer(state: SpinnerState): boolean {
    return state.showSpinnerLayer
  }
}
