<template>
  <div v-show="props.showDialog" class="dialog-container" @click.stop="checkIfHideDialog">
    <div class="dialog-message-container">
      <span :class="[icon.close, 'close-icon', 'lf-icon__sm']" @click.stop="hideDialog" />
      <section class="cards-container">
        <ContactUsCardComponent />
        <ChatOnlineCardComponent />
        <HelpCenterCardComponent />
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Icons } from '@/icons/icons'
import { PropType } from 'vue'
import ContactUsCardComponent from '@/components/SupportServiceDialog/ContactUsCardComponent.vue'
import ChatOnlineCardComponent from '@/components/SupportServiceDialog/ChatOnlineCardComponent.vue'
import HelpCenterCardComponent from '@/components/SupportServiceDialog/HelpCenterCardComponent.vue'

// PROPS
const props = defineProps({
  showDialog: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})

// DATA
const icon = {
  close: Icons.CLOSE
}

// EVENTS
const emit = defineEmits(['hide-dialog'])

// METHODS
function hideDialog() {
  emit('hide-dialog', false)
}

function checkIfHideDialog($event: Event) {
  const elementClicked = $event.target as HTMLElement

  if (elementClicked.classList.contains('dialog-container')) {
    hideDialog()
  }
}
</script>

<style lang="scss" scoped>
.center-text {
  text-align: center;
}

.dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;

  .dialog-message-container {
    display: flex;
    width: 940px;
    padding: $spacing-sm $spacing-xl $spacing-xl $spacing-xl;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xs;
    border-radius: $cornerRadius-md;
    background: $neutral-white-000;
    box-shadow: $shadow-md;

    .close-icon {
      font-size: 14px;
      color: $neutral-grey-600;
      align-self: flex-end;
      cursor: pointer;
    }

    .cards-container {
      display: flex;
      padding: $spacing-nule;
      align-items: flex-start;
      gap: $spacing-md;
      align-self: stretch;
      background: $neutral-white-000;
    }
  }
}
</style>

<style>
body.dialog-open {
  overflow: hidden;
  height: 100vh;
}
</style>
