import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ComputedRef } from 'vue'
import { ActionItem, ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

interface UseActionsWidgetInterface {
  widgetList: ComputedRef<ActionItem[]>,
  status: ComputedRef<ListStatus>,
  loadWidgets: () => Promise<void>
}

export default function useActionsWidget(): UseActionsWidgetInterface {
  const widgetList: ComputedRef<ActionItem []> = computed(() => store.getters[`${ ModuleNamespaces.DASHBOARD_WIDGETS }/getActionWidgetList`])
  const status: ComputedRef<ListStatus> = computed(() => store.getters[`${ ModuleNamespaces.DASHBOARD_WIDGETS }/getActionWidgetStatus`])
  const loadWidgets = () => store.dispatch(`${ ModuleNamespaces.DASHBOARD_WIDGETS }/loadActionsWidget`)
  return {
    widgetList,
    status,
    loadWidgets
  }
}
