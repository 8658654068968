<template lang="pug">

section(v-if="!showSpinnerLayer" class="lexon-view-container")
  GridTable(
    ref="grid"
    v-if="renderGrid && parseGridConfiguration"
    :itemsData="isPortalUser ? listItems : expGridData"
    :itemsDataExport="serverSideDataExport"
    :gridConfiguration="parseGridConfiguration"
    :title="$t('views.expedients.title')"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="expedientGridContextMenuItems"
    :listName="listConfiguration['Alias']"
    :useServerPagination="useServerPagination"
    :context="context"
    :commandClick="commandClick"
    allowGrouping
    :archivedFilter="archivedSelectedOption"
    @cellClicked="onCellClicked"
    @contextMenuClicked="onContextMenuClicked"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    @toolbarClicked="onToolbarClicked"
  )
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { UserType } from '@/store/modules/auth/authTypes'
import { formatNumberColumnsGrid } from '@/helpers/numeric'
import { Icons } from '@/icons/icons'
import { resetLoadedCustomMultiselectFlags } from '@/components/grids/CustomFilters'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { Expedient } from '@/store/modules/expedients/expedientsTypes'
import { CustomDialogComponentName, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const entitiesModule: string = ModuleNamespaces.ENTITIES
const dashboardWidgetsModule = ModuleNamespaces.DASHBOARD_WIDGETS
const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    GridTable
  }
})
export default class ExpedientsView extends mixins(ListViewMixin, ArchivedFilterMixin) {
  @Action('fetchListExpedients', { namespace: expedientsModule })
  fetchListExpedients: (type: {}) => []

  @Action('fetchExpedients', { namespace: expedientsModule })
  fetchUserPortalExpedients: (type: {}) => []

  @Action('removeExpedient', { namespace: expedientsModule })
  removeExpedient: (id: string) => { success: boolean; message: string }

  @Action('toggleComeFromDashboardWidgetFlag', { namespace: dashboardWidgetsModule })
  toggleComeFromDashboardWidgetFlagAction: (status: boolean) => Promise<void>

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  idContextMenu: string = 'customToolbarContextMenuComponent'

  useServerPagination = true

  expGridData: DataResult | object = []

  serverSideDataExport: object = []

  get context() {
    return ContextName.EXPEDIENTS
  }

  get expedientDataParsed() {
    return formatNumberColumnsGrid(
      this.expedientsDataFormatedDates,
      JSON.parse((this as any).listConfiguration.Config)['columns']
    )
  }

  get expedientGridContextMenuItems() {
    const updatedContextMenuItems = this.contextMenuItemsDefault.map((obj: any) => {
      if (obj.text === 'Eliminar') {
        return {
          ...obj,
          action: this.showDialog
        }
      }
      return obj
    })

    if (this.viewPermission.canSave) {
      return [
        ...updatedContextMenuItems.slice(0, 2),
        {
          id: ActionName.DUPLICATE_EXPEDIENT,
          text: this.$t('action_buttons.duplicate_expedient'),
          iconCss: Icons.DOCUMENTS,
          action: this.showCustomDialog
        },
        ...updatedContextMenuItems.slice(2)
      ]
    } else {
      return updatedContextMenuItems
    }
  }

  get toolbarOptionsCustom() {
    const { canSave } = this.viewPermission
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(canSave
        ? [
            {
              id: ActionName.ADD,
              text: this.$t('components.grid_table.tooltip.new_expedient'),
              align: 'Right',
              tooltipText: this.$t('components.grid_table.tooltip.new_expedient'),
              cssClass: 'lf-btn-model2'
            }
          ]
        : [{}]),
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectExpedientArchiveOptions
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get parseGridConfiguration() {
    if (!(this as any).listConfiguration?.Config) {
      return false
    }

    const config = JSON.parse((this as any).listConfiguration.Config)
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_KEBAK_MENU,
        type: 'None',
        title: 'Open',
        buttonOption: {
          iconCss: Icons.KEBAB,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.EXPEDIENTS)
    const config = JSON.parse((this as any).listConfiguration['Config'])
    this.archivedSelectedOption = Number(config.hasOwnProperty('archived') ? config.archived : 0)
    await this.fetchGridItems()
    this.saveMenuParentCategoryName(ParentCategory.EXPEDIENTS)
    this.saveSelectedEntityName('expedients')
    this.renderGrid = true
    this.hideSpinnerLayerAction()
    await this.$nextTick()
    this.initializeArchivedSelectedOption({ selectedOption: 0, contextMenu: this.idContextMenu })
  }

  beforeDestroy() {
    resetLoadedCustomMultiselectFlags(['fileCategory', 'fileSituation', 'fyleType'])
    this.toggleComeFromDashboardWidgetFlagAction(false)
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchUserPortalExpedients({
        archived: 2,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.CUSTOMER_EXPEDIENTS
      })
    } else if (this.useServerPagination) {
      const jsonConf = this.parseUserConfig()
      await this.fetchListExpedients(JSON.parse(jsonConf))
      this.expGridData = this.formatDataPaginated(this.expedientsData)
    } else {
      const jsonConf = {
        archived: this.archivedSelectedOption,
        search: (this as any).lastListConfiguration.searchSettings
      }
      await this.fetchListExpedients(jsonConf)
      this.expGridData = formatNumberColumnsGrid(
        this.expedientsDataFormatedDates,
        JSON.parse((this as any).listConfiguration.Config)['columns']
      )
    }
  }

  parseUserConfig() {
    let pageSettings
    let sortSettings
    let searchSettings
    let filterSettings
    const sortColumns: any = []
    const jsonConfig: any = {}

    if (Object.keys(this.lastListConfiguration).length) {
      pageSettings = (this as any).lastListConfiguration.pageSettings
      sortSettings = (this as any).lastListConfiguration.sortSettings
      searchSettings = (this as any).lastListConfiguration.searchSettings
      filterSettings = (this as any).lastListConfiguration.filterSettings
      jsonConfig['search'] = searchSettings
    } else {
      pageSettings = JSON.parse((this as any).listConfiguration['Config'])['pageSettings']
      sortSettings = JSON.parse((this as any).listConfiguration['Config'])['sortSettings']
      searchSettings = JSON.parse((this as any).listConfiguration['Config'])['searchSettings']
      filterSettings = JSON.parse((this as any).listConfiguration['Config'])['filterSettings']
    }
    jsonConfig['archived'] = this.archivedSelectedOption
    jsonConfig['pageSize'] = pageSettings && pageSettings.pageSize ? pageSettings.pageSize : 10
    jsonConfig['page'] = this.rememberUserConfig && pageSettings ? pageSettings.currentPage : 0
    jsonConfig['filter'] = this.rememberUserConfig ? filterSettings : []
    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach((sortColumn: any) => {
        const column: any = {}
        column['column'] = sortColumn.field
        column['order'] = sortColumn.direction === 'Ascending' ? 'asc' : 'desc'
        sortColumns.push(column)
      })
    }
    jsonConfig['columnOrder'] = sortColumns

    if (searchSettings !== undefined && this.rememberUserConfig) {
      jsonConfig['search'] = searchSettings
    }
    jsonConfig['paginated'] = true
    return JSON.stringify(jsonConfig)
  }

  async gridActionChange(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchListExpedients(ssp)
    this.expGridData = this.formatDataPaginated(this.expedientsData)
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchListExpedients(ssp)
    this.serverSideDataExport = this.formatData(this.expedientsData)
  }

  onCustomToolbarContextMenuClick(args: any) {
    const { item } = args
    const { id } = item

    if (id === ActionName.ADD) {
      trackEvent(TrackerEvents.CREATE_EXPEDIENT)
    }

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onExpedientsArchivedClick(args)
      return
    }
  }

  async onExpedientsArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
  }

  commandClick({ commandColumn, rowData }: CommandClickEventArgs): void {
    const action = commandColumn?.id
    const { idExpedient, description } = rowData as Expedient
    switch (action) {
      case ActionName.REMOVE:
        trackEvent(TrackerEvents.REMOVE_EXPEDIENT)
        this.confirmDelete(idExpedient, description)
        break
      case ActionName.OPEN_NEW_TAB:
        trackEvent(TrackerEvents.EDIT_EXPEDIENT)
        this.open(idExpedient)
        break
      case ActionName.DUPLICATE_EXPEDIENT:
        trackEvent(TrackerEvents.DUPLICATE_EXPEDIENT)
        this.duplicate(idExpedient, description)
        break
      default:
        break
    }
  }

  onCellClicked() {
    trackEvent(TrackerEvents.EDIT_EXPEDIENT)
  }

  onContextMenuClicked(args: any) {
    const { item } = args

    if (item.id === ActionName.EDIT || item.id === ActionName.OPEN_WINDOW) {
      trackEvent(TrackerEvents.EDIT_EXPEDIENT)
    }

    if (item.id === ActionName.DUPLICATE_EXPEDIENT) {
      trackEvent(TrackerEvents.DUPLICATE_EXPEDIENT)
    }

    if (item.id === ActionName.REMOVE) {
      trackEvent(TrackerEvents.REMOVE_EXPEDIENT)
    }
  }

  open(selectedRegisterId: string) {
    const { href } = this.$router.resolve({
      name: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
      params: { selectedRegisterId }
    })
    window.open(href, '_blank')
  }

  duplicate(id: string, name: string) {
    this.showCustomDialog({
      name: CustomDialogComponentName.DUPLICATE_EXPEDIENT,
      props: {
        id,
        name
      },
      style: {
        width: '940px'
      }
    })
  }

  confirmDelete(id: string, name: string) {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.expedients_literal')
      }),
      action: async () => {
        await this.removingExpedient(id, name)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async removingExpedient(id: string, name: string) {
    const { success } = await this.removeExpedient(id)
    if (success) {
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.expedients_literal'),
          register: name
        })
      })
      return
    }
    this.showDialog({
      type: DialogTypes.ERROR,
      message: this.$t('components.dialog.error_remove_expedient')
    })
  }

  onToolbarClicked(args: any) {
    const { item } = args
    const { id } = item

    if (id === ActionName.ADD) {
      trackEvent(TrackerEvents.CREATE_EXPEDIENT)
    }
  }
}
</script>
