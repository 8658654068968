import { MutationTree } from 'vuex'
import { SubActionsState } from '@/store/modules/sub-actions/subActionsTypes'

export const mutations: MutationTree<SubActionsState> = {
  FETCH_SUB_ACTION_LIST(state, data) {
    state.subActionlist = data
  },
  FETCH_ACTION_LIST(state, data) {
    state.actionlist = data
  },
  FETCH_SUB_ACTION(state, action) {
    state.subAction = action
  },
  FETCH_PARENT_ACTION(state, action) {
    state.parentAction = action
  },
  RESET_SUB_ACTION_LIST(state) {
    state.subActionlist = null
  },
  RESET_ACTION_LIST(state) {
    state.actionlist = null
  },
  SAVE_SELECTED_TYPE(state, actionType = '') {
    state.selectedSubActionType = actionType
  },
  SET_CHRONOMETER_NEW_ACTION_INFO(state, data) {
    state.chronometerNewActionInfo = data
  },
  RESET_CHRONOMETER_NEW_ACTION_INFO(state) {
    state.chronometerNewActionInfo = {
      start: '',
      end: '',
      duration: 0
    }
  },
  RESET_SUB_ACTION(state) {
    state.subAction = null
  },
}
