import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ComputedRef } from 'vue'
import {
  AlertMessage,
  AlertMessageFilter,
  AlertMessageList,
  AlertMessageOrderEnum,
  alertMessagesRouterNames,
  AlertMessageType,
  AlertMessageVisibility,
  AlertsMessageToggleVisibility,
  ListStatus
} from '@/store/modules/alertsMessages/alertsMessagesTypes'
import useRouter from '@/composables/useRouter'

interface AlertsMessagesActions {
  setFilter:  (filter: any) => Promise<void>,
  changeVisibility:  (alertMessage: AlertMessage) => Promise<void>,
  filteredList: ComputedRef<AlertMessageList>,
  selectedOrder: ComputedRef<AlertMessageOrderEnum>,
  status: ComputedRef<ListStatus>,
  loadFilteredList: () => Promise<void>
  goToAlertsMessagesURL: (alert: AlertMessage) => Promise<void>
}

export default function useActionsFilteredAlertsMessages(): AlertsMessagesActions {
  const filteredList = computed(() => store.getters[`${ ModuleNamespaces.ALERTS_MESSAGES }/getFilteredList`])
  const selectedOrder = computed(() => store.getters[`${ ModuleNamespaces.ALERTS_MESSAGES }/getSelectedOrder`])
  const status: ComputedRef<ListStatus> = computed(() => store.getters[`${ ModuleNamespaces.ALERTS_MESSAGES }/getFilteredListStatus`])

  // ROUTER
  const { goToRoute, openRouteInNewWindow } = useRouter()

  const setFilter = async (filter: AlertMessageFilter) => {
    await store.dispatch(`${ModuleNamespaces.ALERTS_MESSAGES}/setFilter`, filter)
  }

  const loadFilteredList = async () => {
    await store.dispatch(`${ModuleNamespaces.ALERTS_MESSAGES}/fetchFilteredList`)
  }

  const changeVisibility = async (alertMessage: AlertMessage) => {
    await store.dispatch(`${ModuleNamespaces.ALERTS_MESSAGES}/changeVisibility`, alertMessage)
  }

  function createURL(alert: AlertMessage) {
    const alertTypeId:number = Number(alert.typeId)
    return alertTypeId in alertMessagesRouterNames
      ? alertMessagesRouterNames[alertTypeId]
      : ''
  }

  const  goToAlertsMessagesURL = async (alert: AlertMessage) => {
    // TODO FIGMA: Solo redirige a actuaciones
    if (alert.visibility === AlertMessageVisibility.NO_READ) {
      alert.visibility = AlertsMessageToggleVisibility[alert.visibility]
      await changeVisibility(alert)
    }
    if (alert.type === AlertMessageType.AGENDA){
      openRouteInNewWindow({
        name: createURL(alert),
        params: { selectedRegisterId: alert.entityId }
      })
    }else{
      goToRoute({
        name: createURL(alert),
        params: { selectedRegisterId: alert.entityId }
      })
    }
  }

  return {
    setFilter,
    changeVisibility,
    selectedOrder,
    status,
    filteredList,
    loadFilteredList,
    goToAlertsMessagesURL
  }
}
