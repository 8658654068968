import { ActionTree } from 'vuex'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { subActionsMutationTypes, SubActionsState, SubAction } from '@/store/modules/sub-actions/subActionsTypes'
import { MainService } from '@/services/MainService'
import { ActionsService } from '@/services/ActionsService'

const subActionsModule: string = ModuleNamespaces.SUB_ACTIONS

export const actions: ActionTree<SubActionsState, RootState> = {
  async fetchActionList({ commit }, { parentActionId }) {
    try {
      const url = `v2/action/${ parentActionId }/relationships/action`
      const { data } = await new MainService().getData(url)
      commit(subActionsMutationTypes.FETCH_ACTION_LIST, data)
    } catch (error) {
      commit(subActionsMutationTypes.RESET_ACTION_LIST)
    }
  },
  async fetchSubActionList({ commit }, { parentActionId }) {
    try {
      const url = `v2/action/${ parentActionId }/relationships/sub-action`
      const { data } = await new MainService().getData(url)
      commit(subActionsMutationTypes.FETCH_SUB_ACTION_LIST, data)
    } catch (error) {
      commit(subActionsMutationTypes.RESET_SUB_ACTION_LIST)
    }
  },
  async fetchSubAction({ commit }, subActionId) {
    const url = `v2/sub-action/${subActionId}`
    const { data } = await new MainService().getData(url)
    commit(subActionsMutationTypes.FETCH_SUB_ACTION, data)
  },
  async fetchParentAction({ commit }, subAction: SubAction) {
    const { data } = await new ActionsService().getData(`actions/action/${subAction.parentId}/get`)
    commit(subActionsMutationTypes.FETCH_PARENT_ACTION, data)
  },
  async createSubAction({ dispatch }, { subActionForm, parentActionId }) {
    if (existSubAction(subActionForm)) {
      throw Error('La actuación ya existe')
    }
    await new MainService().postData(`v2/action/${ parentActionId }/sub-action`, subActionForm)
    dispatch(
      `${ subActionsModule }/fetchSubActionList`,
      { parentActionId },
      { root: true }
    )
  },
  async updateSubAction({ dispatch }, { subActionForm }) {
    if (!existSubAction(subActionForm)) {
      throw Error('La actuación no existe')
    }
    await new MainService().putJsonData(`v2/sub-action/${ subActionForm.id }`, subActionForm)
    dispatch(
      `${ subActionsModule }/fetchSubActionList`,
      { parentActionId: subActionForm.parentId },
      { root: true }
    )
  },
  async deleteSubAction({ dispatch }, subAction: SubAction) {
    await new MainService().deleteData(`v2/sub-action/${ subAction.id }`)
    dispatch(
      `${ subActionsModule }/fetchSubActionList`,
      { parentActionId: subAction.parentId },
      { root: true }
    )
  },
  async relateSubAction({ dispatch }, { parentActionId, subActionId }) {
    await new MainService().postData(`v2/action/${ parentActionId }/relationships/sub-action/${ subActionId }`)
    dispatch(
      `${ subActionsModule }/fetchSubActionList`,
      { parentActionId },
      { root: true }
    )
  },
  async unrelateSubAction({ dispatch }, { parentActionId, subActionId }) {
    await new MainService().deleteData(`v2/action/${ parentActionId }/relationships/sub-action/${ subActionId }`)
    dispatch(
      `${ subActionsModule }/fetchSubActionList`,
      { parentActionId },
      { root: true }
    )
  },
  async resetSubAction({ commit }): Promise<void> {
    commit(subActionsMutationTypes.RESET_SUB_ACTION)
  },
}

function existSubAction(subActionForm: any): boolean {
  return subActionForm && subActionForm.hasOwnProperty('id') && 0 < subActionForm.id
}
