// https://www.npmjs.com/package/@hotjar/browser

if (Boolean(process.env.VUE_APP_HOTJAR_ID)) {
  const Hotjar = require('@hotjar/browser').default

  const siteId = process.env.VUE_APP_HOTJAR_ID
  const hotjarVersion = 6

  Hotjar.init(siteId, hotjarVersion)
}
