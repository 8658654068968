import { Module } from 'vuex'
import { getters } from '@/store/modules/alertsConfiguration/alertsConfigurationGetters'
import { actions } from '@/store/modules/alertsConfiguration/alertsConfigurationActions'
import { mutations } from '@/store/modules/alertsConfiguration/alertsConfigurationMutations'
import { AlertsConfigurationState } from '@/store/modules/alertsConfiguration/alertsConfigurationTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AlertsConfigurationState = {
  configuration: {
    agenda: 0,
    expedient:0,
    idTimeExpedient:4,
    igualaEndDate:0,
    idTimeIgualaEndDate:1,
    igualaEndCharge:1,
    idTimeIgualaEndCharge:1,
    igualaNumHours:1,
    idNumHours:1,
    actions:1,
    idTimeActions:1,
    provisionsDueDate:1,
    idTimeProvisions:1,
    customerInvoicesDueDate:1,
    idTimeCustomerInvoices:1,
    providerInvoicesDueDate:1,
    idTimeProviderInvoices:1,
    lexnetDueDate:1,
    idTimeLexnet:1
  }
}

const namespaced: boolean = true

export const alertsConfigurationModule: Module<AlertsConfigurationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
