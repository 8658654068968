interface RelatedDataType {
  [index: string]: object
}

interface ShowRelatedFormType {
  [index: string]: boolean
}

interface FormIsValidType {
  [index: string]: boolean
}

interface CustomValidateField {
  [index: string]: string
}

interface NewRegister {
  [index: string]: object
}

export interface FormsState {
  formIsValid: FormIsValidType
  registerFormData: object
  showRelatedForm: ShowRelatedFormType
  showAditionalForm: ShowRelatedFormType
  validateFormFlag: boolean
  loadingFormData: boolean
  relatedData: RelatedDataType
  customValidateField: CustomValidateField
  newRegister: NewRegister
  selectedParentField: any
  parentFieldValues: object | null
  parentFormContext: string | null
}

export const formsMutationTypes = {
  CHANGE_RELATED_FORM_STATUS: 'CHANGE_RELATED_FORM_STATUS',
  CLEAR_FORM_IS_VALID: 'CLEAR_FORM_IS_VALID',
  PREPARE_REGISTER_FORM_DATA: 'PREPARE_REGISTER_FORM_DATA',
  RESET_FORM_IS_VALID: 'RESET_FORM_IS_VALID',
  RESET_REGISTER_FORM_DATA: 'RESET_REGISTER_FORM_DATA',
  RESET_RELATED_DATA: 'RESET_RELATED_DATA',
  RESET_CUSTOM_VALIDATE_FIELD: 'RESET_CUSTOM_VALIDATE_FIELD',
  LOAD_NEW_REGISTER: 'LOAD_NEW_REGISTER',
  SET_FORM_IS_VALID: 'SET_FORM_IS_VALID',
  SET_LOADING_FORM_DATA: 'SET_LOADING_FORM_DATA',
  SET_RELATED_DATA: 'SET_RELATED_DATA',
  SET_CUSTOM_VALIDATE_FIELD: 'SET_CUSTOM_VALIDATE_FIELD',
  VALIDATE_FORM: 'VALIDATE_FORM',
  SET_SELECTED_PARENT_FIELD_DATA: 'SET_SELECTED_PARENT_FIELD_DATA',
  SET_PARENT_FIELDS_DATA: 'SET_PARENT_FIELDS_DATA',
  SET_PARENT_FORM_CONTEXT: 'SET_PARENT_FORM_CONTEXT'
}

export const customValidatorTypes = {
  DNI: 'dni'
}
