import { ActionTree } from 'vuex'
import { SpinnerState, spinnerMutationTypes } from '@/store/modules/spinner/spinnerTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const actions: ActionTree<SpinnerState, RootState> = {
  showSpinner({ commit }, showMessage: boolean = false) {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    commit(spinnerMutationTypes.SHOW_SPINNER, showMessage)
  },
  hideSpinner({ commit }) {
    document.body.style.overflow = 'visible'
    document.body.style.position = 'initial'
    commit(spinnerMutationTypes.HIDE_SPINNER)
  },
  showSpinnerLayer({ commit }) {
    commit(spinnerMutationTypes.SHOW_SPINNER_LAYER)
  },
  hideSpinnerLayer({ commit }) {
    commit(spinnerMutationTypes.HIDE_SPINNER_LAYER)
  }
}
