import { ActionTree } from 'vuex'
import { ExpedientsState, expedientsMutationTypes } from '@/store/modules/expedients/expedientsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { listItemsMutationTypes } from '@/store/modules//listItems/listItemsTypes'
import { DialogTypes } from '../dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { ErrorCodes } from '@/store/modules/errors/errorsTypes'
import { UserType } from '@/store/modules/auth/authTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { router } from '@/router/router'
import { AxiosError } from 'axios'

const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<ExpedientsState, RootState> = {
  async fetchListExpedients({ commit }, filter = {}) {
    try {
      const url = 'expedients/listExpedients'
      const { data } = await new MainService().postData(url, filter)
      commit(expedientsMutationTypes.FETCH_EXPEDIENTS, data)
      commit(expedientsMutationTypes.SET_LAST_LISTEXPEDIENT_FILTER, filter)
    } catch (error) { }
  },
  async fetchExpedients({ commit }, { selectedRegisterId, listName, archived = null, userType = null }) {
    try {
      let filter = null

      if (userType === UserType.PORTAL) {
        filter = {
          idCustomer: selectedRegisterId,
          archived,
          public: 1,
          userType
        }
      }

      const url = `expedients/${listName}/${selectedRegisterId}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async saveExpedientStage({ commit }, saveData) {
    try {
      const url = 'expedients/stage/save'
      const { data } = await new MainService().postData(url, saveData)
      commit(expedientsMutationTypes.LAST_EXPEDIENT_SAVED, data['id'])
    } catch (error) { }
  },
  async setExpedientStageState({ commit }, data) {
    try {
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_STAGE_STATE, data)
    } catch (error) { }
  },
  async setExpedientStageStateForm({ commit }, data) {
    try {
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_STAGE_STATE_FORM, data)
    } catch (error) { }
  },
  async fetchExpedientsActions({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/actions/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientsAgenda({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = 'appointments/get'
      const { data } = await new MainService().postData(url, {
        ...filter,
        source: 'expedientAgenda',
        idExpedient: selectedRegisterId
      })
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientsActionsSummary({ commit }, { idExpedient, idStage, filter, search }) {
    try {
      const url = `expedient/${idExpedient}/actions/summary/get`
      const filters = { idStage, filter, search }
      const { data } = await new MainService().postData(url, filters)
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_ACTIONS_SUMMARY, data)
    } catch (error) {
      commit(expedientsMutationTypes.RESET_EXPEDIENT_ACTIONS_SUMMARY)
    }
  },
  async fetchExpedientSummary({ commit }, { idExpedient, idStage, filter, search }) {
    try {
      const url = `expedient/${idExpedient}/economic/summary/get`
      const filters = { idStage, filter, search }
      const { data } = await new MainService().postData(url, filters)
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_SUMMARY, data)
    } catch (error) {
      commit(expedientsMutationTypes.RESET_EXPEDIENT_SUMMARY)
    }
  },
  async fetchExpedientEconomicData({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/economic/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchSelectedExpedientEconomicData({ }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/economic/get`
      const { data } = await new MainService().postData(url, filter)
      return data[0]
    } catch (error) { }
  },
  async removeEconomicData({ dispatch }, { id, expedientNumber }) {
    try {
      const url = `expedients/economic/${id}/delete`
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_economic_data_removed', { expedientNumber })
        },
        { root: true }
      )
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
      }
    }
  },
  async saveEconomicData({ }, economicData) {
    try {
      const url = 'expedients/economic/save'
      await new MainService().postData(url, economicData)
    } catch (error) { }
  },
  async fetchExpedientsInvoices({ commit }, { selectedRegisterId, filter = null }) {
    try {
      const url = `expedient/${selectedRegisterId}/invoices/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientHistory({ commit }, { selectedRegisterId }) {
    try {
      const url = `expedient/${selectedRegisterId}/history/get`
      const { data } = await new MainService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientImportActionsList({ commit }, { idExpedient, filter = null }) {
    try {
      const url = `expedient/${idExpedient}/economic/import/actions/get`
      const { data } = await new MainService().postData(url, filter)
      commit(expedientsMutationTypes.SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS, data)
    } catch (error) { }
  },
  async fetchExpedientMaxCode({ }, { filter = null }) {
    try {
      const url = 'expedients/max-code/get'
      const { data } = await new MainService().postData(url, filter)
      return data.maxExpedient
    } catch (error) { }
  },
  async saveImportedActions({ }, { idExpedient, filter = null }) {
    try {
      const url = `expedient/${idExpedient}/economic/import/actions/save`
      await new MainService().postData(url, filter)
    } catch (error) { }
  },
  async fetchExpedientEconomicDataPendingList({ commit }, { idExpedient, filter = null }) {
    try {
      const url = `expedient/${idExpedient}/economic/pending/get`
      const { data } = await new MainService().postData(url, filter)
      commit(expedientsMutationTypes.SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS, data)
    } catch (error) { }
  },
  async fetchEconomicDataTaxesItems({ commit }, id) {
    try {
      const url = `/expedient/economic/${id}/taxes/get`
      const { data } = await new MainService().getData(url)
      commit(expedientsMutationTypes.SET_TAXES_ITEMS, data)
    } catch (error) { }
  },
  async fetchProviderTaxesItems({ commit }, id) {
    try {
      const url = `/invoices/provider/${id}/taxes/get`
      const { data } = await new MainService().getData(url)
      commit(expedientsMutationTypes.SET_TAXES_ITEMS, data)
    } catch (error) { }
  },
  async fetchExpedientInsurers({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/insurances/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async removeExpedientInsurer({ }, idExpedientInsurance) {
    try {
      const url = `expedients/insurance/${idExpedientInsurance}/delete`
      const { data } = await new MainService().deleteData(url)
      return {success: Boolean(data)}
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        return {success: false, message: errorMessage}
      }
    }
  },
  async fetchSelectedExpedientInsurer({ commit }, { idExpedientInsurance, filter }) {
    try {
      const url = `expedient/insurance/${idExpedientInsurance}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(expedientsMutationTypes.SET_SELECTED_EXPEDIENT_INSURER, data[0])
    } catch (error) { }
  },
  async saveExpedientInsurer({ }, filter) {
    try {
      const url = 'expedient/insurance/save'
      const { data } = await new MainService().postData(url, filter)
      return { success: Boolean(data.id), id: data.id }
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc }
    }
  },
  async fetchExpedientLastActionsWidgetData(_context, { selectedExpedientId, filter }) {
    try {
      const url = `expedient/${selectedExpedientId}/actions/get`
      const { data } = await new MainService().postData(url, filter)
      return data
    } catch (error) { }
  },
  async fetchExpedientSectionsToDuplicate({ commit }) {
    try {
      const url = '/expedient/sections/get'
      const { data } = await new MainService().getData(url)
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_SECTIONS_TO_DUPLICATE, data)
    } catch (error) { }
  },
  async duplicateExpedient({ dispatch, commit }, expedientData) {
    const actionsBeforeAcceptDialog = (idDuplicateExpedient: number) => {
      commit(expedientsMutationTypes.SET_DUPLICATE_EXPEDIENT_ID, idDuplicateExpedient)
      router.push(`/${URLS.EXPEDIENT}/${idDuplicateExpedient}`)
    }

    try {
      const url = '/expedient/duplicate/save'
      const { data } = await new MainService().postData(url, expedientData)
      const { id: idDuplicateExpedient } = data
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.duplicate_expedient_success'),
          action: () => actionsBeforeAcceptDialog(idDuplicateExpedient),
          onCloseCustomAction: () => actionsBeforeAcceptDialog(idDuplicateExpedient)
        },
        { root: true }
      )
    } catch (error) {
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: i18n.t('components.dialog.duplicate_expedient_error', { expedientName: expedientData.originalName })
        },
        { root: true }
      )
    }
  },
  async fetchExpedientProfitabilityDetail({ commit }, expedientId) {
    try {
      const url = `/profitability/detail/${expedientId}/get`
      const { data } = await new MainService().getData(url)
      commit(expedientsMutationTypes.SET_EXPEDIENT_PROFITABILITY_DETAIL, data)
    } catch (error) { }
  },
  async fetchExpedientsCourt({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/courts/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientCourt({ }, { selectedRegisterId, filter }) {
    try {
      const url = `/expedient/court/${selectedRegisterId}/get`
      const { data } = await new MainService().postData(url, filter)
      return data
    } catch (error) { }
  },
  async deleteExpedientCourt({ }, id) {
    try {
      const url = `expedients/court/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return data.result === 'OK'
    } catch (_error) { }
  },
  async saveExpedientCourt({ }, formData) {
    try {
      const url = `expedient/court/save`
      const { data } = await new MainService().postData(url, formData)
      return data
    } catch (error) {
      const {
        response: { data }
      } = error as any
      return data
    }
  },
  async fetchExpedientsIntervener({ commit }, { selectedRegisterId, filter }) {
    try {
      const url = `expedient/${selectedRegisterId}/interveners/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async fetchExpedientIntervener({ }, { selectedRegisterId, filter }) {
    try {
      const url = `/expedient/intervener/${selectedRegisterId}/get`
      const { data } = await new MainService().postData(url, filter)
      return data
    } catch (error) { }
  },
  async deleteExpedientIntervener({ }, id) {
    try {
      const url = `expedients/intervener/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return data.result === 'OK'
    } catch (_error) { }
  },
  async saveExpedientIntervener({ }, formData) {
    try {
      const url = `expedient/intervener/save`
      const { data } = await new MainService().postData(url, formData)
      return data
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return data
    }
  },
  async removeExpedient({ }, id) {
    try {
      const url = `expedients/${id}/delete`
      const { data } = await new MainService().deleteData(url)
      return { success: data.errorId === '0' }
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc }
    }
  },
  async saveExpedient({ }, formData) {
    try {
      const url = `expedients/save`
      const { data } = await new MainService().postData(url, formData)
      return { success: Boolean(data.id) }
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc }
    }
  },
  async fetchExpedientStages({commit}, { expedientId }) {
    try {
      const url = `expedient/${expedientId}/stages/get`
      const { data } = await new MainService().getData(url)
      commit(expedientsMutationTypes.FETCH_EXPEDIENT_STAGES, data)
    } catch (error) { }
  }
}
