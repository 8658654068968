<template lang="pug">

div(class="html-table-container")
  div(v-if="tableData.name" class="table-name-container")
    h2( class="table-name") {{ tableData.name }}

  table(:class="`table-${tableData.name}`")

    //- HEADERS
    thead
      tr(class="table-head-row")
        th(
          v-for="header in tableData.headers"
          :class="['head-item', {'button-col': header.type === ItemTypeEnum.BUTTON }]"
        ) {{ header.title }}

    //- BODY
    tbody
      tr(
        v-for="(item, itemIndex) in tableData.items"
        :key="`${itemIndex}-item`"
        :class="['body-row', {'disabled-item': item.disabled}]"
      )
        td(
          v-for="(header, headerIndex) in tableData.headers"
          :key="`${headerIndex}-header`"
          :class="['body-item', header.type]"
        )
            span(v-if="header.type === ItemTypeEnum.STRING || header.type === ItemTypeEnum.CURRENCY") {{ item.disabled && headerIndex !== 0 ? '' : item[header.key] }}
            span(v-if="header.type === ItemTypeEnum.BUTTON && item.buttons === true")
              button(@click.stop="buttonCallback(item)" :title="buttonText ? buttonText : undefined")
                span(:class="[ buttonIcon, 'icon' ]")

      //- SUMMARY
      tr(v-if="tableData.summary" class="summary-row")
        td(class="total-cell") {{ this.totalText }}
        td(
          v-for="(summary, summaryIndex) in parsedSummary"
          class="summary-item"
        )
          span {{ summary.value  }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItemTypeEnum, TableStructure, TableSummary } from './types/HTMLTableComponentTypes'

@Component({
  computed: {
    ItemTypeEnum() {
      return ItemTypeEnum
    }
  }
})
export default class HTMLTableComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  tableData!: TableStructure

  @Prop({
    type: Function
  })
  buttonCallback!: (item: any) => void

  @Prop({
    type: String
  })
  buttonIcon!: string

  @Prop({
    type: String
  })
  buttonText!: string

  totalText = this.$t('components.HTML_table.total')

  get parsedSummary() {
    const parsed: any[] = []

    this.tableData.headers.forEach((header, index) => {
      if (index !== 0) {
        const item: TableSummary = {
          key: header.key,
          value: '',
          type: header.type
        }
        this.tableData.summary.forEach((summary) => {
          if (header.key === summary.key) {
            item.value = summary.value
          }
        })
        parsed.push(item)
      }
    })
    return parsed
  }
}
</script>

<style lang="scss" scoped>
$height: 38px;

.html-table-container {
  $cellPadding: 14px;

  .table-name-container {
    display: flex;
    height: 50px;
    padding: 0px $spacing-xs;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    background: $neutral-grey-050;

    .table-name {
      @include milano-bold-16;
      overflow: hidden;
      color: $neutral-grey-800;
      text-overflow: ellipsis;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  table {
    width: 100%;
    font-family: $secondary-font;
    font-size: 12px;
    color: $neutral-grey-800;
    border-collapse: collapse;

    thead {
      display: table-caption;
    }

    tr {
      line-height: $height;
    }

    .table-head-row {
      display: flex;
      height: $height;
      align-items: center;
      align-self: stretch;
      border-bottom: 2px solid $main-1000;

      .head-item {
        @include milano-bold-14;
        width: 25%;
        color: $main-1000;
        text-overflow: ellipsis;
        text-align: left;
      }
      .head-item.button-col {
        width: 30px;
      }
    }

    .body-row {
      border-bottom: 1px solid $main-200;
      line-height: 16px;

      &.disabled-item .body-item {
        color: $gray-02;
      }

      &:last-of-type {
        border-bottom-color: $main-400;
      }
    }

    .body-item {
      display: inline-block;
      width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .body-item,
    .summary-item {
      &:first-of-type {
        color: $corporate-color;
      }
    }

    .summary-row {
      display: inline-flex;
      background-color: $gray-04;
      height: $height;
      width: 100%;

      > :first-child {
      padding-left: $spacing-xs;
      }

      > :last-child {
        padding-right: $spacing-xs;
      }

      .total-cell {
        color: $corporate-color;
        text-transform: uppercase;
        flex: 1;
      }
    }

    .currency-symbol {
      padding-left: 3px;
    }
  }

  ~ .html-table-container {
    margin-top: $spacing-xl;
  }
}
</style>
