<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :itemData="documentIntervener"
      :gridConfiguration="gridConfiguration"
      :toolbarOptions="toolbarItemsOptions"
      :toolbarClick="onToolbarClick"
      :commandClick="onCommandClick"
      @rowClick="onRowClick"
    ></Grid>

    <PromptDialog
      :title="modalTitle"
      :isOpened="openedPrompt"
      :context="formAlias"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :width="940"
      @execute-action="onExecuteAction"
      @changeFormField="onChangeFormFieldValue"
    ></PromptDialog>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import useFormSchema from '@/composables/useFormSchema'
import useGridConfiguration from '@/composables/useGridConfiguration'
import useDocumentIntervener from './composables/useDocumentIntervener'
import useDialog from '@/composables/useDialog'
import {
  ActionName,
  CommandClickEventArgs,
  CommandModel,
  commandButtonType
} from '@/components/grids/LfGrid/LfGridTypes'
import {
  ExpedientIntervenerFields,
  OTHER_INTERVENER_TYPE_ID
} from '@/components/expedients/ExpedientInterveners/types/ExpedientIntervenerFieldsTypes'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import { computed, onMounted, ref } from 'vue'
import { Icons } from '@/icons/icons'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { useI18n } from 'vue-i18n-composable'
import { RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'

const { t } = useI18n()

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    required: true
  },
  formAlias: {
    type: String,
    required: true
  },
  documentContext: {
    type: String,
    required: true
  }
})

const texts = {
  buttons: {
    delete: t('action_buttons.remove').toString(),
    add: t('action_buttons.addIntervener').toString()
  },
  modal: {
    title_new: t('components.dialog.interveners.new').toString(),
    title_edit: t('components.dialog.interveners.edit').toString()
  },
  dialog: {
    confirmDelete: ({ intervenerName, documentName }: { intervenerName: string; documentName: string }) =>
      t('components.dialog.action.intervener.confirmRemove', { intervenerName, documentName }).toString(),
    deleteSuccess: (intervenerName: string) =>
      t('components.dialog.action.intervener.deleteSuccess', { intervenerName }).toString()
  }
}

const commands: CommandModel[] = [
  {
    id: ActionName.DELETE,
    type: commandButtonType.NONE,
    title: texts.buttons.delete,
    buttonOption: {
      iconCss: Icons.REMOVE,
      cssClass: 'custombutton'
    }
  }
]

const openedPrompt = ref(false)
const formData = ref({})
const editMode = ref(false)

const toolbarItemsOptions = computed((): ToolbarItemModel[] => {
  return [
    {
      id: ActionName.SEARCH,
      text: 'Search',
      align: 'Left'
    },
    {
      id: ActionName.ADD,
      text: texts.buttons.add,
      align: 'Right',
      tooltipText: texts.buttons.add,
      cssClass: 'lf-btn-model4'
    }
  ]
})

// COMPOSABLES   ======================================================================================================
const { getDialogInfo, getDialogSuccess, getDialogError } = useDialog()

const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: `${props.context}-documentIntervener`,
  commandButtons: commands
})

const { formSchema, getFormSchema } = useFormSchema({
  formAlias: props.formAlias,
  context: `${props.context}-form-documentIntervener`
})

const { currentDocument, documentIntervener, read, save, remove } = useDocumentIntervener({
  parentContext: `${props.context}`,
  documentContext: `${props.documentContext}`
})

// COMPUTEDS ==========================================================================================================
const modalTitle = computed(() => {
  return editMode.value ? texts.modal.title_edit : texts.modal.title_new
})

// EVENTS =============================================================================================================
const onToolbarClick = ({ item }: ClickEventArgs) => {
  const { id } = item
  if (ActionName.ADD === id) {
    openedPrompt.value = true
    editMode.value = false
    formData.value = {}
  }
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    await save(formData.value)
    read()
    openedPrompt.value = false
  }
}

const onRowClick = ({ rowData }: RecordClickEventArgs) => {
  formData.value = rowData as {}
  editMode.value = true
  openedPrompt.value = true
}

const onCommandClick = ({ rowData }: CommandClickEventArgs) => {
  const { id, name } = rowData as any
  confirmDelete({ id, name })
}

const onChangeFormFieldValue = (schema: [], _formData: object, field: any, value: any) => {
  if (field.id === ExpedientIntervenerFields.INTERVENER_TYPE) {
    const otherIntervenerField: any = schema.find(({ id }) => id === ExpedientIntervenerFields.OTHER_INTERVENER)
    const hidden = !Boolean(value.id.toString() === OTHER_INTERVENER_TYPE_ID)
    otherIntervenerField!.hidden = hidden
  }
}

// METHODS ============================================================================================================
function confirmDelete({ id, name }: { id: string; name: string }) {
  const { Nombre } = currentDocument.value
  getDialogInfo({
    message: texts.dialog.confirmDelete({ intervenerName: name, documentName: Nombre }),
    action: async () => {
      await removing({ id, name })
      read()
    },
    mainButtonText: t('action_buttons.remove'),
    secondaryButtonText: t('action_buttons.cancel')
  })
}

async function removing({ id, name }: { id: string; name: string }) {
  const { success, message } = await remove(id)
  if (success) {
    getDialogSuccess({
      message: texts.dialog.deleteSuccess(name)
    })
    return
  }
  if (message) {
    getDialogError({
      message
    })
  }
}

// HOOKS =============================================================================================================
onMounted(async () => {
  await getFormSchema()
  read()
})
</script>
