<template>
  <div class="info-wrapper">
    <div>{{ texts.faq.q2 }}</div>
    <div class="info-message">
      <span :class="[Icons.INFO_FULL, 'icon']"></span>
      <span class="message">
        {{ texts.faq.q1 }}
        <br />
        {{ texts.faq.q3 }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Icons } from '@/icons/icons'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()

const texts = {
  faq: {
    q1: t('components.numeration.faq.q1'),
    q2: t('components.numeration.faq.q2'),
    q3: t('components.numeration.faq.q3')
  }
}

</script>
<style lang="scss">
.info-wrapper {
  @include milano-regular-14;
  .info-message {
    @include flex($justify-content: left-start, $flex-direction: row, $align-items: start);
    gap: $spacing-xs;
    background-color: $tertiary-byzantineBlue-050;
    padding: $spacing-xs $spacing-sm;
    border-radius: $cornerRadius-sm;
    margin: 10px 0;

    .icon {
      color: $tertiary-byzantineBlue-200;
      padding-top: 2px;
    }
  }
}
</style>
