import store from '@/store/store'
import { computed, ComputedRef } from 'vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Stage } from '../types/StageSelectorTypes'

interface ResponseUseStageSelector {
  stages: ComputedRef<Stage[]>
  setSelectedStage: (stageId: string) => void
  selectedStage: ComputedRef<string>
  resetStages: () => void
  fecthStages: () => void,
  expedientHasStages: ComputedRef<boolean>
  currentStageId: ComputedRef<string>
}
const context = ContextName.EXPEDIENTS
export default function useStageSelector(): ResponseUseStageSelector {

  const stages = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getStages`])

  const selectedRegisterId = computed(() => store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](context))

  const expedientHasStages = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getHasStages`])

  const selectedStage = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getExpedientStageState`])

  const currentStageId = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getCurrentStageId`])

  const setSelectedStage = (stageId: string) => {
    store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/setExpedientStageState`, stageId)
  }

  const fecthStages = async () => {
    if (selectedRegisterId.value) {
      await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/fetchExpedientStages`, {expedientId: selectedRegisterId.value})
    }
  }

  const resetStages = () => {
    store.commit(`${ModuleNamespaces.EXPEDIENTS}/RESET_EXPEDIENT_STAGES`)
  }

  return {
    expedientHasStages,
    fecthStages,
    resetStages,
    selectedStage,
    setSelectedStage,
    stages,
    currentStageId
  }
}
