<template lang="pug">

  div(class="custom-field")

    //- TEXTFIELD || NUMERIC || CURRENCY || DATE
    template(v-if="renderTextfield(idCustomFieldType)")
      label {{ item.label }}
      input(disabled)
      span(v-if="renderTextfieldIcon(idCustomFieldType)" :class="[renderedIcon(idCustomFieldType), 'show-icon']")

    //- TEXTAREA
    template(v-if="renderTextarea(idCustomFieldType)")
      label {{ label }}
      textarea(disabled)

    //- SELECT
    template(v-if="renderSelect(idCustomFieldType)")
      label {{ label }}
      v-select(
        class="select-field"
        :items="item.listOptions"
        :append-icon="icons.selectIcon"
        hide-details
        height="38"
        attach
        dense
        :menu-props="{ offsetY: true }"
        outlined
        item-text="value"
        clearable
        :multiple="Boolean(Number(item.multipleSelection))"
      )

    //- SWITCH
    template(v-if="renderSwitch(idCustomFieldType)")
      LexonSwitchComponent(
        class="switch-field"
        :id="String(id)"
        :label="label"
        :name="id"
        hide-details
        :value="true"
        disabled
      )

    //- FIELDSET
    template(v-if="renderFieldset(idCustomFieldType)")
      fieldset
        span(class="label") {{ label }}
        transition-group(name="reorder-transition" tag="div")
          div(
            v-for="(field, index) in fields"
            class="fieldset-container"
            :key="field.uuid"
          )

            //- FIELDSET TEXTFIELD || NUMERIC || CURRENCY || DATE
            div(v-if="renderTextfield(field.idCustomFieldType)" class="fieldset-field")
              label {{ field.label }}
              input(disabled)
              span(v-if="renderTextfieldIcon(field.idCustomFieldType)" :class="[renderedIcon(field.idCustomFieldType), 'show-icon']")

            //- FIELDSET TEXTAREA
            div(v-if="renderTextarea(field.idCustomFieldType)" class="fieldset-field")
              label {{ field.label }}
              textarea(disabled)

            //- FIELDSET SELECT
            div(v-if="renderSelect(field.idCustomFieldType)" class="fieldset-field")
              label {{ field.label }}
              v-select(
                class="select-field"
                :items="field.listOptions"
                :append-icon="icons.selectIcon"
                hide-details
                height="38"
                attach
                dense
                :menu-props="{ offsetY: true }"
                outlined
                item-text="value"
                clearable
                :multiple="Boolean(Number(field.multipleSelection))"
              )

            //- SWITCH
            template(v-if="renderSwitch(field.idCustomFieldType)")
              LexonSwitchComponent(
                class="switch-field"
                :id="String(field.id)"
                :label="field.label"
                :name="field.id"
                hide-details
                :value="true"
                disabled
              )

            CustomFieldActionButtonsComponent(
              :index="index"
              :item="field"
              :fieldsNumber="fields.length"
              @move-up="moveUp"
              @move-down="moveDown"
              @remove-field="removeField(field)"

              @edit-field="editField(field, index)"
            )

    //- NUMERATION
    template(v-if="renderNumeration(idCustomFieldType)")
      transition-group(name="reorder-transition" tag="div")
        div(
          v-for="(field, index) in fields"
          class="fieldset-container"
          :key="field.uuid"
        )
          //- FIELDSET NUMERIC
          div(v-if="renderNumeric(field.idCustomFieldType)" class="fieldset-field")
            LexonNumericComponent(
                :id="field.uuid"
                v-model="field.value"
                :placeholder="field.placeholder"
                :disabled="field.disabled"
              )

          //- FIELDSET TEXTFIELD || CURRENCY || DATE
          div(v-if="renderTextfieldOnly(field.idCustomFieldType)" class="fieldset-field")
            LexonTextFieldComponent(
                type="text"
                :id="field.uuid"
                v-model="field.value"
                :placeholder="field.placeholder"
                :disabled="field.disabled"
                :validationRules="field.validationRules"
              )

          //- FIELDSET SELECT
          div(v-if="renderSelect(field.idCustomFieldType)" class="fieldset-field")
            LexonAutocompleteComponent(
              v-model="field.value"
              :disabled="field.disabled"
              :prop-items="field.listOptions"
              :placeholder="field.placeholder"
              :clearable="false",
              item-name="name"
              item-value="id"
              key-id="id"
            )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import CustomFieldActionButtonsComponent from '@/components/customFields/customFieldsPlayground/CustomFieldActionButtons/CustomFieldActionButtonsComponent.vue'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import { Icons } from '@/icons/icons'
import { CustomFieldGroup, customFieldTypesEnum, CustomField } from '@/store/modules/customFields/customFieldsTypes'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import LexonNumericComponent from '@/components/forms/fields/LexonNumeric/LexonNumericComponent.vue'

@Component({
  components: {
    LexonTextFieldComponent,
    LexonNumericComponent,
    CustomFieldActionButtonsComponent,
    LexonSwitchComponent,
    LexonAutocompleteComponent: () =>
      import('@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue')
  }
})
export default class CustomFieldGeneratorComponent extends Vue {
  @Prop({
    type: Object
  })
  item!: CustomFieldGroup

  @Prop({
    type: Number
  })
  position!: number

  icons = {
    dateIcon: Icons.CALENDAR,
    currencyIcon: Icons.COIN,
    selectIcon: Icons.ANGLE_DOWN
  }

  @Emit()
  moveUp(index: number) {
    return { index, isFieldset: true, position: this.position }
  }

  @Emit()
  moveDown(index: number) {
    return { index, isFieldset: true, position: this.position }
  }

  @Emit()
  removeField(item: CustomField) {
    return item
  }

  @Emit()
  editField(item: CustomField, index: number) {
    return { item, index }
  }

  get idCustomFieldType() {
    return this.item.idCustomFieldType
  }

  get label() {
    return this.item.label
  }

  get id() {
    return this.item.id
  }

  get fields() {
    return this.item.fields
  }

  get isCurrencyType() {
    return this.idCustomFieldType === customFieldTypesEnum.CURRENCY
  }

  renderTextfield(idCustomFieldType: number) {
    return (
      idCustomFieldType === customFieldTypesEnum.TEXT ||
      idCustomFieldType === customFieldTypesEnum.NUMERIC ||
      idCustomFieldType === customFieldTypesEnum.CURRENCY ||
      idCustomFieldType === customFieldTypesEnum.DATE
    )
  }

  renderTextfieldOnly(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.TEXT
  }

  renderNumeric(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.NUMERIC
  }

  renderTextarea(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.TEXTAREA
  }

  renderSelect(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.LIST
  }

  renderSwitch(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.BOOLEAN
  }

  renderFieldset(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.GROUP
  }

  renderTextfieldIcon(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.CURRENCY || idCustomFieldType === customFieldTypesEnum.DATE
  }

  renderedIcon(idCustomFieldType: number) {
    switch (idCustomFieldType) {
      case customFieldTypesEnum.CURRENCY:
        return this.icons.currencyIcon
      case customFieldTypesEnum.DATE:
        return this.icons.dateIcon
    }
  }

  renderNumeration(idCustomFieldType: number) {
    return idCustomFieldType === customFieldTypesEnum.NUMERATION
  }
}
</script>

<style scoped lang="scss" scoped>
.custom-field {
  @include flex($flex-direction: column);
  margin-bottom: 12px;
  width: 100%;
  position: relative;

  label {
    @include milano-regular-16;
    color: $neutral-grey-800;
    align-self: flex-start;
  }

  input,
  textarea,
  fieldset,
  .fieldset-field {
    width: 100%;
  }

  input,
  textarea,
  .select-field {
    background-color: $neutral-white-000;
    border-radius: $cornerRadius-sm;
    border: 1px solid $neutral-grey-400;
  }

  .fieldset-field {
    position: relative;
  }

  .switch-field {
    width: 100%;
  }

  input,
  select,
  .select-field,
  ::v-deep .v-input__control {
    height: $input-size !important;
  }

  textarea {
    height: 120px;
    resize: none;
  }

  .select-field {
    width: 100%;
    border-radius: none;

    ::v-deep fieldset {
      display: none;
    }

    ::v-deep .v-icon::before {
      position: relative;
      font-size: 14px;
    }

    ::v-deep .v-list-item__action:first-child {
      &:first-child {
        margin-right: 7px;
      }

      .v-icon {
        font-size: 18px;
      }
    }
  }

  fieldset {
    display: flex;
    padding: $spacing-xxs $spacing-sm $spacing-sm $spacing-sm;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 0;
    border-radius: $cornerRadius-md;
    border: 1px solid $main-500;

    .label {
      @include milano-bold-16;
      color: $main-1000;
    }

    .fieldset-container {
      display: flex;
      width: 100%;
      margin-bottom: 12px;

      ::v-deep .actions-container {
        align-self: flex-end;
      }
    }
  }

  .show-icon {
    position: absolute;
    right: 15px;
    top: 32px;
    font-size: 20px;
    color: $main-1000;
  }

  @include reorder-transition;
}
</style>
