import { changeDateFormat } from "@/helpers/dateTime"
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'
import { ColumnModel } from "@/components/grids/LfGrid/LfGridTypes"

export interface SortColumn {
  column: string
  order: 'desc' | 'asc'
}

export interface RequestParams {
  page: number
  pageSize: number
  columnOrder?: SortColumn[]
  filter?: []
  searchSettings?: string
}

interface FilterColumn {
  field: string
  operator: string
  value: string
}
interface Filter {
  page: number
  pageSize: number
  columnOrder?: SortColumn[]
  filter?: FilterColumn[]
  search?: string
}

interface Predicate {
  field: string;
  operator: string;
  value: string;
  isComplex?: boolean;
  predicates?: Predicate[];
}

const dateColumnType = ['date', 'dateTime', 'datetime', 'dateEPOCH']

export default function useGridSaveUserConf() {

  const defaultPageSize: number = 10

  // TODO TYPE listconfig type
  const parseUserConfig = (listConfig: any,) => {
    const sortColumns: SortColumn[] = []
    const { pageSettings, sortSettings, searchSettings, filterSettings, archived } = listConfig

    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach(({ field, direction }: { field: string, direction: string }) => {
        const column: SortColumn = {
          column: field,
          order: direction === 'Ascending' ? 'asc' : 'desc'
        }
        sortColumns.push(column)
      })
    }
    const isFilterSetting = filterSettings && filterSettings.length > 0 && rememberUserConfig()
    const isSearchSetting = searchSettings !== undefined && searchSettings !== '' && rememberUserConfig()
    const isArchived = archived !== undefined && archived !== '' && rememberUserConfig()
    const config = {
      page: rememberUserConfig() ? pageSettings.currentPage : 0,
      pageSize: pageSettings.pageSize ? pageSettings.pageSize : defaultPageSize,
      columnOrder: sortColumns,
      ...(isFilterSetting && { filter: filterSettings }),
      ...(isSearchSetting && { search: searchSettings }),
      ...(isArchived && { archived })
    }
    return config
  }

  const getFilter = (state: any, parsedColumns: any): Filter => {
    const page = state.skip === 0 ? 0 : (state.skip + state.take) / state.take
    const pageSize = state.take

    const pFilter: Filter = {
      page,
      pageSize
    }

    if (state.sorted) {
      pFilter.columnOrder = getSort(state.sorted)
    }

    if (state.where) {
      pFilter.filter = getPredicates(state.where[0].predicates, parsedColumns)
    }

    const searchFilter = state.search
    if (searchFilter) {
      pFilter.search = searchFilter[0].key
    }
    return pFilter
  }

  const getPredicates = (predicates: Predicate[], columns: ColumnModel[]): Predicate[] => {
    return predicates.reduce((accum: Predicate[], predicate: Predicate) => {
      if (predicate.isComplex && predicate.predicates) {
        if (predicate.predicates[0].operator === 'greaterthan') {
          predicate.predicates[0].operator = 'equal'
        } else if (predicate.predicates[0].operator === 'lessthanorequal') {
          predicate.predicates[0].operator = 'notequal'
        }
        return accum.concat(getPredicates(predicate.predicates, columns));
      }
      const columnProps = columns.find(column => column.field === predicate.field) as any
      const itemValue = dateColumnType.includes(columnProps!.type)
        ? changeDateFormat(predicate.value, '', 'YYYY-MM-DD')
        : predicate.value;

      accum.push({
        field: predicate.field,
        operator: predicate.operator,
        value: itemValue
      });
      return accum;
    }, []);
  }

  const getSort = (sort: any) => {
    return sort.reverse().map((sortColumn: any) => {
      const order = sortColumn.direction.toLowerCase() === 'ascending' ? 'asc' : 'desc'
      const column = sortColumn.field || sortColumn.name
      return {
        order,
        column
      }
    })
  }

  const rememberUserConfig = () => {
    return store.getters[`${ModuleNamespaces.CONFIGURATION}/getRememberUserCustomConfigurationList`]
  }

  return {
    parseUserConfig,
    getFilter
  }
}
