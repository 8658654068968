<template>
  <div :class="[breakpointName, 'lexon-button-container']">
    <button @click.stop="lexonButtonEvent" :class="['lexon-button', { disabled: disabled }]">
      {{ getText }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, defineEmits } from 'vue'
import { useVuetify } from '@/composables/useVuetify'

const emit = defineEmits(['lexonButtonEvent'])

// DATA
const props = defineProps({
  text: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

// COMPOSABLES
const { breakpointName } = useVuetify()

// COMPUTED
const getText = computed(() => {
  return props.text ? props.text : 'components.lexon_button.default'
})

// EVENTS
function lexonButtonEvent() {
  emit('lexonButtonEvent')
}
</script>

<style scoped lang="scss">
.lexon-button-container.form-element {
  width: 100%;
  margin-bottom: 16px;

  &.lg .lexon-button,
  &.xl .lexon-button {
    position: relative;
    top: 9px;
  }

  .lexon-button {
    @include label-button-outlined($height: 32px);
    @include milano-bold-16;
    width: 100%;
    margin-left: 0;
  }
}

.fieldset .lexon-button-container.form-element {
  &.xl .lexon-button {
    top: 22px;
    height: 38px;
  }
}
</style>
