<template>
  <div class="required-fields-advice-container">
    <span v-if="props.showRequiredFieldsAdvice" class="required-fields-advice">{{ legend }}</span>
  </div>
</template>

<script lang="ts" setup>

import { useI18n } from 'vue-i18n-composable'
import { defineProps } from 'vue'

const props = defineProps({
  showRequiredFieldsAdvice: {
    type: Boolean,
    default: true,
    required: false
  }
})

const { t } = useI18n()

const legend = t('components.form_generator.required_fields')
</script>

<style lang="scss" scoped>
.required-fields-advice-container {
  display: flex;
  justify-content: flex-end !important;

  @include required-form-field;
}
</style>
