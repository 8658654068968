<template lang="pug">

div(
  v-if="!showSpinnerLayer"
  class="lexon-view-container audits-view"
  :key="componentKey"
)

  //- HEADER
  LfHeaderTitleComponent(
    :title="texts.title"
    class="header-title"
  )

  //- INFO MESSAGE
  StaticAlertComponent(:alert="alertData")

  //- TABS
  div(class="tabs-container")
    v-tabs(
      v-model="tab"
      class="tabs"
      background-color="transparent"
      hide-slider
    )
      v-tab(
        v-for="(tab, tabIndex) in tabs"
        @change="onTabChange(tabIndex)"
        :key="`tab-${tabIndex}`"
        active-class="active"
        :class="[{ 'change-tab-styles': $vuetify.breakpoint.mdAndUp }, 'tab']"
        :ripple="false"
      )
        span(class="tab-label") {{ tab }}

    v-tabs-items(v-model="tab")
      v-tab-item(
        v-for="(tab, contentIndex) in tabs"
        :key="`tab-${contentIndex}`"
        class="tab-content no-padding"
        :transition="false"
        :reverse-transition="false"
      )
        SpinnerLayerComponent(
          v-if="showTabSpinner"
          class="spinner-layer-container"
        )
        div(v-else)
          GridTable(
            v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0"
            :gridConfiguration="listConfiguration['Config']"
            :title="texts.title"
            :toolbarOptions="auditsToolbarOptions"
            :listName="listConfiguration['Alias']"
            :itemsData="serverSideData"
            :context="context"
            :itemsDataExport="serverSideExportData"
            :useServerPagination="useServerPagination"
            :showHeader="false"
            showColumnChooser
            @gridActionChange="gridActionChange"
            @gridExportData="gridExportData"
          )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import TabsComponent from '@/components/tabs/TabsComponent/TabsComponent.vue'
import { ListNames, ListConfiguration } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter } from 'vuex-class'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { Audit } from '@/store/modules/audits/auditsTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { Icons } from '@/icons/icons'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import { AlertsData, AlertsTypes } from '@/store/modules/alerts/alertsTypes'

const auditsModule: string = ModuleNamespaces.AUDITS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG

interface SortColumn {
  column: string
  order: 'desc' | 'asc'
}

@Component({
  components: {
    AlertComponent,
    LfHeaderTitleComponent,
    GridTable,
    SpinnerLayerComponent,
    TabsComponent,
    StaticAlertComponent
  }
})
export default class AuditsView extends mixins(ListViewMixin) {
  @Action('fetchAudits', { namespace: auditsModule })
  fetchAuditsAction: (paginationData: any) => Audit[]

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => void

  @Getter('getAudits', { namespace: auditsModule }) audits: Audit[]

  @Getter('getListConfig', { namespace: configurationModule })
  listConfig: ListConfiguration

  useServerPagination: boolean = true

  serverSideData: DataResult | object = {}
  serverSideExportData: DataResult | object = {}

  defaultPageSize: number = 10

  texts = {
    acceptButtonDialogText: this.$t('action_buttons.accept'),
    exportTooltip: this.$t('components.grid_table.tooltip.export'),
    heavyProcessDialogText: this.$t('components.dialog.heavy_process_info'),
    message: this.$t('components.alerts.audits'),
    printTooltip: this.$t('components.grid_table.tooltip.print'),
    title: this.$t('views.audits.title'),
    cleanFiltersButton: this.$t('components.grid_table.tooltip.clean_filter')
  }

  tab = null

  tabs = [this.$t('views.audits.tab_users'), this.$t('views.audits.tab_portal')]

  sourceFilter = ListNames.AUDIT

  showTabSpinner: boolean | null = null

  auditsToolbarOptions = [searchToolbarItem, kebakMenuToolbarItem]

  infoIcon = Icons.INFO

  componentKey = 1

  alertData: AlertsData = {
    type: AlertsTypes.INFO,
    message: this.texts.message
  }

  get context() {
    return ContextName.AUDITS
  }

  created() {
    this.initView()
    this.hideSpinnerLayerAction()
  }

  async initView() {
    this.refreshComponent()
    this.showTabSpinner = true
    await this.fetchCurrentListConfiguration(this.sourceFilter)
    await this.loadData()
  }

  refreshComponent() {
    this.componentKey++
  }

  async loadData() {
    const params = await this.parseUserConfig()
    await this.fetchAuditsAction(params)
    this.serverSideData = this.formatDataPaginated(this.audits)
    this.showTabSpinner = false
  }

  parseUserConfig() {
    const sortColumns: SortColumn[] = []
    const { pageSettings, sortSettings, searchSettings, filterSettings } = this.listConfig

    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach(({ field, direction }) => {
        const column: SortColumn = {
          column: field,
          order: direction === 'Ascending' ? 'asc' : 'desc'
        }
        sortColumns.push(column)
      })
    }
    const isFilterSetting = filterSettings && filterSettings.length > 0 && this.rememberUserConfig
    const isSearchSetting = searchSettings !== undefined && searchSettings !== '' && this.rememberUserConfig
    const config = {
      page: this.rememberUserConfig ? pageSettings.currentPage : 0,
      pageSize: pageSettings.pageSize ? pageSettings.pageSize : this.defaultPageSize,
      columnOrder: sortColumns,
      ...(isFilterSetting && { filter: filterSettings }),
      ...(isSearchSetting && { search: searchSettings }),
      source: this.sourceFilter
    }

    return config
  }

  formatDataPaginated(audits: any) {
    const items = formatFieldsTypeDateEPOCH(audits.data)
    const gridResult: DataResult = { result: items, count: parseInt(audits.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  addSourceFilterToParams(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    return {
      ...params,
      source: this.sourceFilter
    }
  }

  async gridActionChange(serverSideParams: string) {
    const paramsWithSource = this.addSourceFilterToParams(serverSideParams)
    await this.fetchAuditsAction(paramsWithSource)
    this.serverSideData = this.formatDataPaginated(this.audits)
  }

  async exportData(serverSideParams: string) {
    const paramsWithSource = this.addSourceFilterToParams(serverSideParams)
    await this.fetchAuditsAction(paramsWithSource)
    const { result } = this.formatDataPaginated(this.audits)
    this.serverSideExportData = result
  }

  gridExportData(serverSideParams: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.texts.heavyProcessDialogText,
      mainButtonText: this.texts.acceptButtonDialogText,
      hideSecondaryButton: true,
      action: () => this.exportData(serverSideParams),
      onCloseCustomAction: () => this.exportData(serverSideParams)
    })
  }

  onTabChange(tabIndex: number) {
    if (this.tab !== tabIndex) {
      if (tabIndex === 0) {
        this.sourceFilter = ListNames.AUDIT
      } else {
        this.sourceFilter = ListNames.AUDIT_PORTAL
      }
      this.initView()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/tabs/TabsComponent/tabsComponentStyles';

.audits-view {
  ::v-deep .e-row {
    pointer-events: none;
  }
}
</style>
