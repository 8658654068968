import { ActionTree } from 'vuex'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import {
  entitiesMutationTypes,
  EntityState,
  Entity,
  entity,
  entityKeyFields
} from '@/store/modules/entities/entitiesTypes'
import { getObjectByKey, getObjectByKeyAndValue, getParentObjectByKeyAndValue } from '@/helpers/object'

const endpointModule: string = ModuleNamespaces.ENDPOINT

export const createEntityByType = (entityType: number, entityId: number = 0): Entity => {
  const result: Entity = new Entity()

  const entityFound: any = getObjectByKeyAndValue(entity, 'type', entityType)

  if (entityFound) {
    result.type = entityFound.type
    result.alias = entityFound.alias
    result.icon = entityFound.icon
    result.route = entityFound.route
    result.id = entityId

    const parent: any = getParentObjectByKeyAndValue(entity, 'type', entityType)

    if (parent) {
      result.parent = {
        type: parent.type,
        alias: parent.alias,
        icon: parent.icon,
        route: parent.route
      }

      if (entityFound.route && entityFound.route.customKeyName) {
        result.keyName = entityFound.route.customKeyName
        result.keyId = entityFound.route.customKeyId
      } else {
        const keys: any = getObjectByKey(entityKeyFields, parent.alias)

        if (keys) {
          result.keyName = keys.name
          result.keyId = keys.id
        }
      }
    }
  }

  return result
}

export const actions: ActionTree<EntityState, RootState> = {
  async createEntity({ commit }, { entityType, idEntity, context }) {
    const entity = createEntityByType(entityType)

    if (entity && entity.alias) {
      commit(entitiesMutationTypes.SAVE_ENTITY, { entity, context })
    }
    commit(entitiesMutationTypes.SAVE_ID_ENTITY, { idEntity, context })
  },
  async saveSelectedEntity({ commit }, entity: Entity) {
    commit(entitiesMutationTypes.SAVE_ENTITY, entity)
  },
  async saveSelectedIdEntity({ commit, dispatch, getters }, { idEntity, context }) {
    commit(entitiesMutationTypes.SAVE_ID_ENTITY, { idEntity, context })
    const entity: Entity = getters.getEntity(context)
    if (entity && entity.alias) {
      dispatch(`${endpointModule}/saveEndpointsByEntity`, entity, { root: true })
    }
  },
  async saveSelectedIdEntityType({ commit }, idEntityType: number) {
    commit(entitiesMutationTypes.SAVE_ID_ENTITY_TYPE, idEntityType)
  },
  saveSelectedEntityName({ commit }, entityName) {
    commit(entitiesMutationTypes.SAVE_SELECTED_ENTITY_NAME, entityName)
  }
}
