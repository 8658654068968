<template>
  <div class="lexon-tabs-container" :class="getTabType">
    <div class="tabs">
      <div class="tabs-titles-container">
        <div
          v-for="tab in tabsData"
          :key="tab.name"
          class="tab-title"
          :class="{ active: activeTab === tab, disabled: props.isTabsDisabled && activeTab !== tab }"
          @click="selectTab(tab)"
        >
          {{ tab.label }}
        </div>
      </div>
      <TabSelectorComponent
        v-if="showTabSelector"
        :idEntity="1"
        @beforeSave="todo"
        @afterSave="todo"
        @onTabSelectorOpen="todo"
      />
    </div>
    <SpinnerLayerComponent v-show="spinnerIsShown" class="spinner-layer"></SpinnerLayerComponent>
    <div v-show="!spinnerIsShown" class="tabs-contents">
      <slot :activeTab="activeTab"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { TabsType } from '@/components/tabs/LfTabs/types/LfTabsTypes'
import useTabs from '@/components/tabs/LfTabs/composables/useTabs'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import TabSelectorComponent from '@/components/tabs/TabSelector/TabSelectorComponent.vue'

// PROPS =============================================================================================================
const props = defineProps({
  type: {
    type: String as PropType<TabsType>,
    default: TabsType.MAIN
  },
  // TODO ALE - ¿Tipamos las tabs?
  tabs: {
    type: Array as PropType<any[]>,
    required: true
  },
  selectedTab: {
    type: Number,
    default: 0
  },
  isTabsDisabled: {
    type: Boolean,
    default: false
  },
  showTabSelector: {
    type: Boolean,
    default: false
  }
})

// COMPOSABLES  =============================================================================================================
const { spinnerIsShown, showTabSpinner } = useTabs()

// DATA  =============================================================================================================
const tabsData = ref(props.tabs)
const activeTab = ref(tabsData.value[props.selectedTab])

// COMPUTED  =============================================================================================================
const getTabType = computed(() => {
  switch (props.type) {
    case TabsType.PRIMARY:
      return 'primary-styles'
    case TabsType.SECONDARY:
      return 'secondary-styles'
    default:
      return 'main-styles'
  }
})

// METHODS  =============================================================================================================
function selectTab(tab: any) {
  showTabSpinner()
  if (props.isTabsDisabled && activeTab.value !== tab) {
    return
  }
  activeTab.value = tab
}

// TODO ALE - Implementar lógica del TabsSelectorComponent
function todo() {
  console.log('todo')
}
</script>

<style scoped lang="scss">
$tab-content-height: 300px;

.lexon-tabs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: $spacing-xl;

  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    border-bottom: 1px solid;

    .tabs-titles-container {
      @include flex;

      .tab-title {
        @include flex;
        height: 36px;
        color: $neutral-grey-800;
        text-transform: none;
        cursor: pointer;
        border-radius: $cornerRadius-md $cornerRadius-md 0px 0px;
        padding: $spacing-xxs $spacing-xs;

        &.active {
          @include milano-bold-14;
          position: relative;

          &::after {
            content: '';
            width: 100%;
            position: absolute;
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .tabs-contents,
  .spinner-layer {
    @include borders($color: $neutral-grey-600);
    width: 100%;
    height: $tab-content-height;
    min-height: $tab-content-height;
    max-height: $tab-content-height;
    border-top: transparent;
  }

  .tabs-contents {
    @include scroll-styles;
    display: block;
    padding: $spacing-md;
  }

  &.main-styles {
    .tab-title {
      @include borders($color: $neutral-grey-400);
      @include milano-regular-14;

      &.active {
        @include milano-bold-14;
        color: $main-1000;
        position: relative;

        &::before {
          content: '';
          width: calc(100% - 16px);
          height: 1px;
          background-color: $main-1000;
          position: absolute;
          bottom: 0;
          z-index: 1;
        }

        &::after {
          content: '';
          width: 100%;
          height: 5px;
          background-color: $neutral-white-000;
          position: absolute;
          bottom: -2px;
        }
      }
    }
  }

  &.primary-styles {
    .tabs {
      height: 38px;
      border-color: $highlight-200;

      .tabs-titles-container {
        .tab-title {
          @include milano-regular-18;
          height: 37px;

          &:hover {
            background-color: $main-050;
          }

          &.active {
            @include milano-bold-18;

            &::after {
              height: 3px;
              background-color: $highlight-200;
              bottom: 0;
            }
          }
        }
      }
    }
  }

  &.secondary-styles {
    .tabs {
      height: 32px;
      border-color: $main-1000;

      .tabs-titles-container {
        .tab-title {
          @include milano-regular-16;
          height: 31px;

          &:hover {
            background-color: $main-050;
          }

          &.active {
            @include milano-bold-16;

            &::after {
              height: 3px;
              background-color: $main-1000;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
