<template>
  <div :class="['progress-bar-container', props.file.status]">
    <div class="info-container">
      <div class="file-name-container">
        <span class="file-icon e-fe-pdf" />
        <span class="file-name">{{ computedFileName }}</span>
      </div>
      <div class="status-container">
        <span class="status-info">{{ computedInfoText }}</span>
        <span :class="[computedInfoIcon, 'status-icon']" />
      </div>
    </div>

    <v-progress-linear
      :indeterminate="computedIndeterminate"
      rounded
      :color="vars.progress_bar_success"
      :background-color="computedBackgroundColor"
    />

    <span v-if="computedErrorText" class="error-text">{{ texts.errorText }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import { vars } from '@/styles/styleVars'
import { FileStatus, File } from './types/LfProgressBarTypes'

// PROPS
const props = defineProps({
  file: {
    type: Object as PropType<File>,
    required: true
  },
  keyName: {
    type: String,
    default: 'name'
  }
})

// TRANSLATIONS
const { t } = useI18n()

const texts = {
  errorText: t('components.progress_bar.error_text'),
  loadingText: t('components.progress_bar.loading_text'),
  successText: t('components.progress_bar.success_text')
}

// COMPUTED
const computedFileName = computed(() => props.file[props.keyName as keyof File])

const computedInfoText = computed(() => {
  switch (props.file.status) {
    case FileStatus.LOADED:
      return texts.successText
    default:
      return texts.loadingText
  }
})

const computedInfoIcon = computed(() => {
  switch (props.file.status) {
    case FileStatus.LOADED:
      return Icons.CHECK_ROUND_FULL
    case FileStatus.ERROR:
      return Icons.CLOSE_ROUND_FULL
    default:
      return Icons.SPINNER
  }
})

const computedIndeterminate = computed(() => props.file.status === FileStatus.LOADING)

const computedBackgroundColor = computed(() => {
  switch (props.file.status) {
    case FileStatus.LOADED:
      return vars.progress_bar_success
    case FileStatus.ERROR:
      return vars.progress_bar_error
    default:
      return vars.progress_bar_default_color
  }
})

const computedErrorText = computed(() => props.file.status === FileStatus.ERROR)
</script>

<style lang="scss" scoped>
@include file-icons($container-name: 'progress-bar-container');

.progress-bar-container {
  margin-top: $spacing-md;

  .info-container {
    display: flex;
    align-items: center;
    gap: $spacing-xxs;
    align-self: stretch;
    margin-bottom: $spacing-xs;

    .file-name-container {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;

      .file-icon {
        display: block;
        width: 22px;
        height: 22px;
      }

      .file-name {
        @include milano-medium-18;
        color: $neutral-grey-900;
        text-align: right;
        margin-left: $spacing-xs;
      }
    }

    .status-container {
      display: flex;
      align-items: center;
      gap: $spacing-xxs;

      .status-info {
        @include milano-regular-16;
        color: $neutral-grey-600;
      }

      .status-icon {
        margin-left: $spacing-xxs;
      }
    }
  }

  .error-text {
    @include milano-regular-14;
    color: $tertiary-persianRed-200;
    margin-top: $spacing-xxs;
  }

  &.Error {
    .info-container {
      .status-container {
        .status-info {
          color: $tertiary-persianRed-100;
        }

        .status-icon::before {
          color: $tertiary-persianRed-100;
        }
      }
    }
  }

  &.Loaded {
    .info-container {
      .status-container {
        .status-info {
          color: $tertiary-seaGreen-200;
        }

        .status-icon::before {
          color: $tertiary-seaGreen-200;
        }
      }
    }
  }
}
</style>
