export const isJson = (str: string): boolean => {
  return /^\{.*\"\:.*\}.*$/g.test(str)
}

export const sortObject = (obj: any): any => {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObject);
  }

  const sortedObj = {} as any;
  Object.keys(obj).sort().forEach((key) => {
    sortedObj[key] = sortObject(obj[key]);
  });

  return sortedObj;
}

export const stringifySorted = (obj: Object): string => {
  return JSON.stringify(
    sortObject(obj)
  );
}

export const jsonAreDifferent = (json1: Object, json2: Object): boolean => {
  return stringifySorted(json1) !== stringifySorted(json2);
}
