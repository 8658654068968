<template lang="pug">
  div(class="tandem-filters-container")
    //- BUTTONS
    div(class="buttons-row")
      span(class="tandem-filters-title") {{ texts.textSearch }}
      div(class="buttons-container")
        LfLabelButtonComponent(
          class="clear-button"
          :text="texts.cleanFilter"
          :action="() => clearFilters()"
          :variant="variant.text"
        )

    div(v-if="showDescriptors"
        class="tandem-aditional-filters")
      div(class="tandem-aditional-filter-tbox") {{ texts.aditionalFilters }}
      div(class="tandem-descriptors-container")
        div(class="tandem-descriptor"
            v-for="descriptor in getDescriptors")
            div(class="tandem-descriptor-text") {{ descriptor.value.value }}
            div(class="lf-icon-close-round tandem-descriptor-close"
            @click.stop="removeDescriptor(descriptor.key)")

    //- FILTERS
    div(class="tandem-filters")
      div(class="row-1")
        div(v-for="(filter, index) in tandemFilters" class="filter" v-if="index <= 2")
          LexonSelectComponent(
            :ref="filter.label"
            :label="filter.label"
            :propItems="filter.items"
            :isMultiselect="filter.multiSelect"
            :renderChips="filter.multiSelect"
            :keyValue="filter.keyValue?filter.keyValue:''"
            :returnObject="false"
            :paginated="false"
            showAllChips
            @input="setSelected($event, filter)"
          )
      div(class="row-2")
        div(v-for="(filter, index) in tandemFilters" class="filter" v-if="index > 2")
          div(v-if="filter.type===filterTypes.List")
            LexonSelectComponent(
              :ref="filter.label"
              :label="filter.label"
              :propItems="filter.items"
              :isMultiselect="filter.multiSelect"
              :renderChips="filter.multiSelect"
              :keyValue="filter.keyValue?filter.keyValue:''"
              :returnObject="false"
              :paginated="false"
              showAllChips
              @input="setSelected($event, filter)"
            )
          div(v-if="filter.type===filterTypes.DateInterval")
            LexonDateRangePickerComponent(
              :ref="filter.label"
              :tandemSearch="true",
              :label="filter.label"
              @input="setSelected($event, filter)"
            )
          div(v-if="filter.type===filterTypes.Switch")
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import { mixins } from 'vue-class-component'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { clone } from '@/helpers/object'
import { TandemFilter, TandemFilterType, TandemSelectedFiler } from '@/store/modules/tandemSearch/tandemSearchTypes'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const tandemSearchModule: string = ModuleNamespaces.TANDEM_SEARCH

@Component({
  components: {
    LfLabelButtonComponent,
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
    LexonDateRangePickerComponent: () =>
      import('@/components/forms/fields/LexonDateRangePicker/LexonDateRangePickerComponent.vue')
  }
})
export default class TandemFiltersComponent extends mixins(FieldBehaviorsMixin) {
  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule }) fetchCurrentViewConfiguration: ({}) => {}
  @Action('initFilters', { namespace: tandemSearchModule }) initFilters: (filters: TandemFilter[]) => {}

  @Mutation('SET_DESCRIPTORS', { namespace: tandemSearchModule }) setDescriptors: ({}) => void

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  filtersConfig: (context: string) => TandemFilter[]
  @Getter('getFilters', { namespace: tandemSearchModule }) filters: TandemFilter[]
  @Getter('getDescriptors', { namespace: tandemSearchModule }) getDescriptors: any
  @Getter('getSearchFacets', { namespace: tandemSearchModule }) facets: []

  context: string = ContextName.TANDEM_FILTERS

  filterTypes = {
    List: TandemFilterType.LIST,
    DateInterval: TandemFilterType.DATE_INTERVAL,
    Switch: TandemFilterType.SWITCH
  }

  selectedFilters = new Map()
  showDescriptors: boolean = false

  texts = {
    textSearch: this.$t('views.search.search_on_results'),
    cleanFilter: this.$t('components.grid_table.tooltip.clean_filter'),
    aditionalFilters: this.$t('views.search.tandem_aditional_filters')
  }

  lastFilterSelected: string = ''
  filterItemsCount = []
  reviewFilterFlag: boolean = false

  variant = {
    outlined: LabelButtonVariant.OUTLINED,
    solid: LabelButtonVariant.SOLID,
    ghost: LabelButtonVariant.GHOST,
    text: LabelButtonVariant.TEXT
  }

  get tandemFilters() {
    Object.entries(this.facets).forEach((facet: any) => {
      const facetFilter: any = {
        id: facet[0],
        name: facet[0],
        type: this.filterTypes.List,
        label: this.$t(`components.tandem.facet.${facet[0]}`).toString(),
        keyValue: 'id',
        metadataName: facet[0],
        multiSelect: true,
        items: []
      }
      if (facet[0] === 'entityType') {
        let items: any = []
        Object.values(entity).forEach((ent: any) => {
          let totalChildItems = 0
          const childItems: any = []
          Object.values(ent).forEach((e: any) => {
            if (e.type) {
              ;(this as any).facets['entityType'].forEach((f: any) => {
                if (f.value.toString() === e.type.toString()) {
                  const label = this.$t(`components.tandem.filter.entityType.${ent.alias}.${e.alias}`)
                  childItems.push({ id: e.type, name: `${label} (${f.numdocs})` })
                  totalChildItems += f.numdocs
                }
              })
            }
          })
          if (totalChildItems > 0) {
            const label = this.$t(`components.tandem.filter.entityType.${ent.alias}._`)
            items.push({ id: ent.type, name: `${label}` })
            items = items.concat(childItems)
          } else {
            ;(this as any).facets['entityType'].forEach((f: any) => {
              if (f.value.toString() === ent.type.toString()) {
                const label = this.$t(`components.tandem.filter.entityType.${ent.alias}`)
                items.push({ id: ent.type, name: `${label} (${f.numdocs})` })
              }
            })
          }
        })
        facetFilter.items = items
      } else {
        facet[1].forEach((item: any) => {
          const label =
            item.value !== 'none'
              ? item.value
              : this.$t('components.tandem.facet.without') + ' ' + facetFilter.label.toLowerCase()
          const i: any = {
            id: item.value,
            name: `${label} (${item.numdocs})`
          }
          facetFilter.items.push(i)
        })
      }
      const filter = this.filters.filter((item: any) => item.id === facetFilter.id)

      if (filter.length > 0) {
        let fieldFilter = false
        for (const key of this.selectedFilters.keys()) {
          if (key.includes(facetFilter.metadataName)) {
            fieldFilter = true
          }
        }
        if (facetFilter.id !== this.lastFilterSelected || !fieldFilter) {
          Vue.set(filter[0], 'items', facetFilter.items)
        }
      } else {
        this.filters.unshift(facetFilter)
      }
    })

    return this.filters
  }

  @Watch('getDescriptors', { immediate: true })
  changeDescriptors() {
    this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
      if (key.includes('principalDescriptors-') || key.includes('secondaryDescriptors-')) {
        this.selectedFilters.delete(key)
      }
    })
    this.getDescriptors.forEach((item: any) => {
      const key: string = item.key
      this.selectedFilters.set(key, {
        name: item.value.name,
        value: item.value.value,
        tag: item.value.value,
        filter: ''
      })
    })
    this.showDescriptors = this.getDescriptors.length > 0 ? true : false
    this.emitFilters()
  }

  async created() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.TANDEM_FILTERS,
      context: this.context
    })

    this.filtersConfig(this.context).forEach((item: any) => {
      if (item.endPointEntity) {
        item.endpoint = this.parseEndpointEntity(item.endPointEntity).url
      }
    })

    await this.initFilters(this.filtersConfig(this.context))
  }

  destroyed() {
    this.selectedFilters.clear()
    this.setDescriptors([])
  }

  setSelected(items: string[], filter: any) {
    const specialKeyFilters: string[] = [
      `entityType-${entity.actions.type}`,
      `entityType-${entity.contacts.type}`,
      `entityType-${entity.invoices.type}`
    ]
    this.lastFilterSelected = filter.metadataName
    this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
      if (key.includes(`${filter.metadataName}-`)) {
        this.selectedFilters.delete(key)
      }
    })

    if (!Array.isArray(items)) {
      if (null !== items) {
        items = [items]
      }
    }

    if (items) {
      items.forEach((item) => {
        const key: string = `${filter.metadataName}-${item}`
        this.selectedFilters.set(key, { name: filter.metadataName, value: item, tag: item, filter })
      })
    }

    if (this.reviewFilterFlag) {
      this.selectedFilters.forEach((_value: TandemSelectedFiler, key: string) => {
        const tandemKey = key.split('-')
        if (
          !(this as any).facets[tandemKey[0]]?.find((f: any) => f.value === tandemKey[1]) &&
          !specialKeyFilters.includes(key)
        ) {
          this.selectedFilters.delete(key)
        }
      })
    }

    if (items !== null) {
      if (
        (this as any).filterItemsCount[filter.metadataName] &&
        (this as any).filterItemsCount[filter.metadataName] > items.length
      ) {
        this.reviewFilterFlag = true
      } else {
        this.reviewFilterFlag = false
      }

      ;(this as any).filterItemsCount[filter.metadataName] = items.length
    }
    this.emitFilters()
  }

  async clearFilters() {
    Object.entries(this.$refs).forEach(([_key, item]) => {
      ;(item as any)[0].resetValue()
    })
    this.lastFilterSelected = ''
    this.selectedFilters.clear()
    this.setDescriptors([])
    this.emitFilters()
  }

  removeDescriptor(key: string) {
    const newDescriptors = clone(this.getDescriptors)
    const index = newDescriptors.findIndex((i: any) => i.key === key)
    if (index !== -1) {
      newDescriptors.splice(index, 1)
      this.setDescriptors(newDescriptors)
    }
  }

  emitFilters() {
    this.$emit('set-filter', this.selectedFilters)
  }
}
</script>

<style lang="scss" scoped>
.tandem-filters-container {
  @include flex;
  display: flex;
  flex-direction: column;

  .buttons-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: $spacing-sm;
    background-color: $neutral-grey-100;
    padding: $spacing-sm;

    .buttons-container {
      @include flex;
      gap: $spacing-sm;

      .clear-button {
        background-color: transparent;
      }
    }

    .tandem-filters-title {
      @include flex;
      @include milano-bold-16;
      color: $neutral-grey-600;
    }
  }

  .tandem-filters {
    width: 100%;
  }

  .tandem-aditional-filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    background-color: $neutral-grey-100;
    padding: $spacing-sm;
  }

  .tandem-descriptors-container {
    @include borders($color: $neutral-grey-400);
    display: flex;
    flex-wrap: wrap;
    padding: $spacing-md $spacing-sm;
    border-radius: 0 0 $cornerRadius-sm $cornerRadius-sm;
    background-color: $neutral-white-000;
  }

  .tandem-aditional-filter-tbox {
    color: $white-01;
    background-color: $main-500;
    font-family: $corporate-font;
    text-align: left;
    padding-left: 15px;
  }

  .tandem-descriptor {
    @include milano-regular-14;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: $cornerRadius-sm;
    background-color: $main-050;
    margin-right: $spacing-xs;
    line-height: 22px;

    .tandem-descriptor-text::first-letter {
      text-transform: capitalize;
    }
  }

  .tandem-descriptor-close {
    margin-left: 4px;
    cursor: pointer;
  }

  .tandem-filters {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background-color: $neutral-grey-100;
    padding: $spacing-sm;

    .row-1 {
      display: flex;
      justify-content: space-between;
      gap: $spacing-sm;

      .filter {
        width: 100%;
      }
    }

    .row-2 {
      display: flex;
      justify-content: flex-start;
      gap: $spacing-sm;

      .filter {
        width: 33%;
      }
    }

    ::v-deep .v-select.v-select--chips.v-input--dense .v-select__selections {
      min-height: min-content;
    }

    ::v-deep .lf-select-container.show-all-chips .v-select__slot {
      padding: 0 8px 0 10px;
      min-height: 38px !important;
    }
  }
}
</style>
