import { getCurrentInstance, computed } from 'vue'

export const useVuetify = () => {
  const instance = getCurrentInstance()
  const vuetifyInstance = instance?.proxy?.$vuetify

  const breakpointName = instance?.proxy?.$vuetify.breakpoint.name

  const isMobileBreakpoint = computed(() =>vuetifyInstance?.breakpoint.smAndDown)

  return {
    breakpointName,
    isMobileBreakpoint
  }
}
