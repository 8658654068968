<template>
  <section class="pending-actions-container">
    <SpinnerLayerComponent v-if="ListStatus.LOADING === status" class="spinner-layer" />
    <div class="pending-actions" v-else>
      <header>{{ texts.title }}</header>
      <div v-if="ListStatus.OK === status" class="items-row">
        <DashboardActionItemComponent v-for="(action, index) in widgetList" :key="`action-${index}`" :action="action" />
      </div>
      <section v-if="ListStatus.EMPTY === status" class="no-actions-container">
        <span :class="[noDataIcon, 'lf-icon__xl', 'no-data-icon']" />
        <div class="texts">
          <p class="no-data-text">{{ texts.noData }}</p>
          <i18n :path="path" tag="div" class="link-text">
            <template v-slot:link>
              <router-link :to="actionsListURL" class="actions-link">{{ texts.newActions }}</router-link>
            </template>
          </i18n>
        </div>
      </section>
      <section v-if="ListStatus.ERROR === status" class="no-actions-container">
        <span :class="[errorIcon, 'lf-icon__xl', 'no-data-icon']" />
        <div class="texts">
          <p class="no-data-text">{{ texts.loadError }}</p>
        </div>
      </section>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import DashboardActionItemComponent from '@/components/widgets/DashboardWidgets/actions/DashboardActionItem.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import useActionsWidget from '@/components/widgets/DashboardWidgets/actions/composable/useActionsWidget'
import { ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

// TRANSLATIONS
const { t } = useI18n()

const texts = {
  newActions: t('components.widgets.actions.new_actions'),
  noData: t('components.widgets.actions.no_data'),
  loadError: t('components.widgets.actions.load_error'),
  title: t('components.widgets.actions.title')
}

// DATA
const noDataIcon = Icons.ARCHIVE_EMPTY
const errorIcon = Icons.CLOSE_ROUND
const path = 'components.widgets.actions.link_text'
const actionsListURL = `${URLS.ACTIONS}/${URLS.ACTION_ALL}`

// COMPOSABLE
const { status, widgetList, loadWidgets } = useActionsWidget()

// HOOKS
onMounted(() => loadWidgets())
</script>

<style lang="scss" scoped>
.pending-actions-container {
  @include borders($color: $main-200);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-xs;
  width: 100%;
  margin-top: 24px;
  background-color: $neutral-white-000;
  padding: $spacing-sm $spacing-md $spacing-md $spacing-md;
  border-radius: $cornerRadius-md;
  min-height: 140px;

  .spinner-layer {
    background-color: $neutral-white-000;
    min-height: 140px;
  }

  header {
    @include milano-bold-18;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $neutral-grey-800;
    padding: $spacing-xs 0;
  }

  .items-row {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .pending-actions {
    width: 100%;

    .no-actions-container {
      display: flex;
      padding: $spacing-lg $spacing-xl;
      justify-content: flex-start;
      align-items: center;
      gap: $spacing-xs;

      .no-data-icon {
        color: $main-400;
      }

      .texts {
        .no-data-text {
          @include milano-medium-16;
          color: $neutral-grey-600;
          margin-bottom: 0;
        }

        .link-text {
          @include milano-regular-14;
          color: $neutral-grey-600;

          .actions-link {
            color: $main-800;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}
</style>
