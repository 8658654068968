<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter } from 'vuex-class'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { billEntityId } from '@/general/entityIds'

const authModule: string = ModuleNamespaces.AUTH
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class PermissionsMixin extends Vue {
  @Getter('getPermission', { namespace: authModule })
  checkEntityPermissionsGetter: (entityId: number) => PermissionVerbs

  @Getter('getSelectedIdEntityType', { namespace: entitiesModule })
  selectedIdEntityTypeGetter: number

  get viewPermission(): PermissionVerbs {
    return this.checkEntityPermissionsGetter(this.$route.meta?.entityType || this.selectedIdEntityTypeGetter)
  }

  get userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails(): boolean {
    const actionTypes = [
      entity.actions.calls.type,
      entity.actions.emails.type,
      entity.actions.meetings.type,
      entity.actions.others.type,
      entity.actions.procedures.type
    ]
    return actionTypes.some((actionType) => this.checkEntityPermissionsGetter(actionType).canSave)
  }

  get userHasPermissionToSaveAtLeastOneActionType(): boolean {
    const actionTypes = [
      entity.actions.calls.type,
      entity.actions.meetings.type,
      entity.actions.others.type,
      entity.actions.procedures.type
    ]
    return actionTypes.some((actionType) => this.checkEntityPermissionsGetter(actionType).canSave)
  }

  get userHasPermissionToInvoiceAtLeastOneInvoiceType(): boolean {
    const invoiceTypes = [
      entity.invoices.customers.type,
      entity.invoices.orderForms.type,
      entity.invoices.proforma.type,
      entity.invoices.providers.type
    ]
    return invoiceTypes.some((type) => this.checkEntityPermissionsGetter(type).canSave)
  }

  get userHasPermissionToViewAgenda(): boolean {
    const agendaPermissions = this.checkEntityPermissionsGetter(entity.agenda.type)
    return !!agendaPermissions.canView
  }

  get userHasPermissionToViewCustomerInvoices(): boolean {
    const customerIncvoicesPermissions = this.checkEntityPermissionsGetter(entity.invoices.customers.type)
    return !!customerIncvoicesPermissions.canView
  }

  get userHasPermissionToInvoice(): boolean {
    const invoicePermission = this.checkEntityPermissionsGetter(billEntityId)
    return !!invoicePermission.canView
  }

  checkFormPermissions(schema: any, entityId: number) {
    const { canSave } = this.checkEntityPermissionsGetter(entityId)
    if (!canSave) {
      schema.map((item: any) => {
        if (item.fields) {
          item.fields.map((i: any) => (i.disabled = true))
        }
        item.disabled = true
      })
    }
    return schema
  }
}
</script>
