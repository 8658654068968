import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { ComputedRef, computed } from "vue"


interface ResponseUseNumeration {
  numerationList: ComputedRef<any>
  read: () => Promise<void>
}

export default function useNumeration(): ResponseUseNumeration {
  const numerationList = computed(() => store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`])
  
  const read = async () => {
    await store.dispatch(`${ModuleNamespaces.NUMERATIONS}/fetchNumerations`)
  }

  return {
    numerationList,
    read
  }
}