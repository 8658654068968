<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :contextMenuItems="contextMenuItems"
      :itemData="actions"
      :commandClick="onCommandClick"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>
  </div>
</template>
<script lang="ts" setup>
import useGridConfiguration from '@/composables/useGridConfiguration'
import useContactActions from './composables/useContactActions'
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import { computed } from 'vue'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ActionName, CommandClickEventArgs } from '@/components/grids/LfGrid/LfGridTypes'
import { Icons } from '@/icons/icons'
import {
  CommandColumnModel,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()

// DEFINE PROPS =======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true,
    default: 'default'
  },
  context: {
    type: String,
    default: 'contactActions'
  },
  entityType: {
    type: String,
    default: '42'
  }
})

const texts = {
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    open_new_window: t('action_buttons.open_new_window').toString()
  }
}

const commandsBts: CommandColumnModel[] = [
  {
    title: t('action_buttons.open_new_window').toString(),
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]
// COMPOSABLES ========================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons: commandsBts
})

const { actions, read, open } = useContactActions({
  listName: 'listContactActions'
})
// STORE ACTIONS ======================================================================================================

// STORE GETTERS ======================================================================================================
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])

const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)

// COMPUTEDS ==========================================================================================================
const contextMenuItems = computed((): ContextMenuItemModel[] => {
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.OPEN_NEW_TAB,
      text: texts.contextMenuItems.open_new_window,
      iconCss: Icons.OPEN_WINDOW
    }
  ]
})
// EVENTS =============================================================================================================
const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  const { rowData } = rowInfo as any
  const { actionId, typeId } = rowData as any
  const { id } = item
  const params = { actionId: actionId.toString(), typeId }
  if (id === ActionName.EDIT) {
    open(params)
    return
  }
  if (id === ActionName.OPEN_NEW_TAB) {
    open(params, '_blank')
  }
}
const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { actionId, typeId } = rowData as any
  const params = { actionId: actionId.toString(), typeId }
  open(params, '_blank')
}

const onRowClick = (args: RecordClickEventArgs) => {
  const { rowData } = args
  const { actionId, typeId } = rowData as any
  const params = { actionId: actionId.toString(), typeId }
  open(params)
}

// METHODS ============================================================================================================

// HOOKS ==============================================================================================================
read(selectedRegisterId.value)
</script>
