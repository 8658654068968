import { GridComponent as EjsGrid, IFilter } from "@syncfusion/ej2-vue-grids"
import { DateRangePicker } from '@syncfusion/ej2-vue-calendars'
import { computed, Ref, ref } from "vue"
import { i18n } from "@/plugins/vue-i18n"
import { createElement } from '@syncfusion/ej2-base'
import { addTimeToDate } from "@/helpers/dateTime"

interface PropsUseCustomDateFilter {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
  setParsedFilterSettingsFlag: (value: boolean) => void
}

interface ResponseUseCustomDateFilter {
  customFilterDate: IFilter
  dateFilterValue: Ref<{ [key: string]: Date[] }>
  deleteDateFilterValue: (key: string) => void
  clearDateFilterValue: () => void
  getDateFilterValue: (column: string) => Date
  filterDateColumn: (args: any) => void
}

export default function useCustomDateFilter(props: PropsUseCustomDateFilter): ResponseUseCustomDateFilter {
  const { setParsedFilterSettingsFlag, gridRef } = props

  const dateRangeInstance = ref<DateRangePicker>()
  const showInstance = ref(false)
  const dateFilterValue = ref<{ [key: string]: Date[] }>({})

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  const customFilterDate: IFilter = {
    ui: {
      create: (args: any) => {
        if (!dateFilterValue.value[args.column.field]) {
          dateFilterValue.value[args.column.field] = gridInstance.value.filterSettings
            .columns!.filter((item: any) => item.field === args.column.field)
            .map((item: any) => (item.value ? item.value : false))
        }
        showInstance.value = false
        const parentHTMLElement = args.getOptrInstance.dropOptr.element.parentElement
        parentHTMLElement.parentElement.style.display = 'none'
        parentHTMLElement.parentElement.nextElementSibling.style.paddingTop = 0
        dateRangeInstance.value = new DateRangePicker({
          value: dateFilterValue.value[args.column.field] ? dateFilterValue.value[args.column.field] : undefined,
          format: 'dd/MM/yyyy',
          strictMode: true,
          showClearButton: false,
          serverTimezoneOffset: 2,
          openOnFocus: false,
          change: (e: any) => {
            if (e.value) {
              dateFilterValue.value[args.column.field] = e.value
              dateFilterValue.value[args.column.field] = [e.startDate, e.endDate]
                ; (gridRef as any).value?.filterByColumn(args.column.field, 'greaterthanorequal', dateFilterValue.value[args.column.field][0])
            }
          },
          cleared: (_e: any) => {
            gridInstance.value.clearFiltering([args.column.field])
            delete dateFilterValue.value[args.column.field]
            setParsedFilterSettingsFlag(true)
          }
        })
        const flValInput = createElement('input', { className: 'flm-input' })
        flValInput.setAttribute('placeholder', i18n.t('components.grid_table.date_search').toString())
        args.target.appendChild(flValInput)
        dateRangeInstance.value!.appendTo(flValInput)
      },
      write: (_args: any) => { },
      read: (_args: any) => { }
    }
  }

  const deleteDateFilterValue = (key: string) => {
    delete dateFilterValue.value[key]
  }

  const clearDateFilterValue = () => {
    dateFilterValue.value = {}
  }

  const getDateFilterValue = (column: string) => {
    return addTimeToDate(
      dateFilterValue.value[column][1],
      1440 + new Date().getTimezoneOffset(),
      'minutes'
    )
  }

  const filterDateColumn = (args: any) => {
    const { currentFilteringColumn } = args

    if (!currentFilteringColumn?.includes('EPOCH')) {
      return
    }

    const columnFilter = gridInstance.value.getColumns().find((item: any) => item.field === currentFilteringColumn)
    if (!columnFilter) {
      return
    }

    const checkIfFilterExist = gridInstance.value.filterSettings.columns.find(
      (item: any) => item.field === currentFilteringColumn && 'lessthanorequal' === item.operator
    )
    const value = addTimeToDate(
      dateFilterValue.value[currentFilteringColumn][1],
      1440 + new Date().getTimezoneOffset(),
      'minutes'
    )

    if (!checkIfFilterExist) {
      args.columns.push({
        predicate: 'and',
        field: currentFilteringColumn,
        operator: 'lessthanorequal',
        value
      })
    } else {
      checkIfFilterExist.value = value
    }
  }

  return {
    customFilterDate,
    dateFilterValue,
    deleteDateFilterValue,
    getDateFilterValue,
    clearDateFilterValue,
    filterDateColumn
  }
}
