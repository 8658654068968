import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes, DialogData } from '@/store/modules/dialog/dialogTypes'

interface ReturnUseDialog {
  getDialog: ({ type, message, action}: DialogData) => Promise<void>
  getDialogError: ({ message }: DialogData) => Promise<void>
  getDialogInfo: ({ message, action }: DialogData) => Promise<void>
  getDialogSuccess: ({ message, action }: DialogData) => Promise<void>
  getDialogWarning: ({ message }: DialogData) => Promise<void>
}

export default function useDialog(): ReturnUseDialog {
  const getDialog = async (data: DialogData) => {
    store.dispatch(`${ModuleNamespaces.DIALOG}/showDialog`, data)
  }

  const getDialogError = async ({ message }: DialogData) => {
    const type = DialogTypes.ERROR
    getDialog({ type,  message })
  }

  const getDialogSuccess = async ({ message, action }: DialogData) => {
    const type = DialogTypes.SUCCESS
    getDialog({ type,  message, action })
  }

  const getDialogInfo= async ({ message, mainButtonText, secondaryButtonText, action }: DialogData) => {
    const type = DialogTypes.INFO
    getDialog({ type,  message, mainButtonText, secondaryButtonText, action })
  }

  const getDialogWarning = async ({ message }: DialogData) => {
    const type = DialogTypes.WARNING
    getDialog({ type,  message })
  }

  return {
    getDialog,
    getDialogError,
    getDialogSuccess,
    getDialogInfo,
    getDialogWarning
  }
}
