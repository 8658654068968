import { ActionTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { documentOnlineMutationTypes, DocumentOnlineState } from '@/store/modules/documentOnline/documentOnlineTypes'
import { DocumentOnlineService } from '@/services/DocumentOnlineService'

export const actions: ActionTree<DocumentOnlineState, RootState> = {
  saveFileName({ commit }, fileName) {
    commit(documentOnlineMutationTypes.SAVE_FILE_NAME, fileName)
  },
  saveFileUrl({ commit }, fileUrl) {
    commit(documentOnlineMutationTypes.SAVE_FILE_URL, fileUrl)
  },
  saveFileVersion({ commit }, fileVersion) {
    commit(documentOnlineMutationTypes.SAVE_FILE_VERSION, fileVersion)
  },
  saveFileSize({ commit }, fileSize) {
    commit(documentOnlineMutationTypes.SAVE_FILE_SIZE, fileSize)
  },
  saveFileAction({ commit }, fileAction) {
    commit(documentOnlineMutationTypes.SAVE_ACTION, fileAction)
  },
  async fetchRequestToken({ commit }, formData) {
    try {
      const url = '?operation=getToken'
      const { headers } = await new DocumentOnlineService().getRequestToken(url, formData)
      commit(documentOnlineMutationTypes.SAVE_ACCESS_TOKEN, headers['wopi-access-token'])
      commit(documentOnlineMutationTypes.SAVE_ACCESS_TOKEN_TTL, headers['wopi-access-token-ttl'])
      commit(documentOnlineMutationTypes.SAVE_FILE_VERSION, headers['wopi-file-version'])
    } catch (error) {}
  },
  async fetchAppType({ commit }, formData) {
    try {
      const url = '?operation=getApp'
      const { headers } = await new DocumentOnlineService().getRequestToken(url, formData)
      commit(documentOnlineMutationTypes.SAVE_APP_TYPE, headers['wopi-application'])
      commit(documentOnlineMutationTypes.SAVE_NEEDS_CONVERSION, false)
      commit(documentOnlineMutationTypes.SAVE_READ_ONLY, false)
      if (headers['wopi-es-conversion']) {
        const needsConversion = 'True' === headers['wopi-es-visible']
        commit(documentOnlineMutationTypes.SAVE_NEEDS_CONVERSION, needsConversion)
      }
      if (headers['Wopi-Es-Conversion']) {
        const isVisible = headers['Wopi-Es-Visible']
        const readOnly = !(isVisible === 'True')
        commit(documentOnlineMutationTypes.SAVE_READ_ONLY, readOnly)
      }
    } catch (error) {}
  },
  async fetchFileToken({ commit }, idFile) {
    try {
      const url = `repository/file/token/${idFile}/get`
      const { data } = await new DocumentOnlineService().getFileToken(url)
      commit(documentOnlineMutationTypes.SAVE_FILE_TOKEN, data)
    } catch (error) {}
  },
  resetFileUrl({ commit }) {
    commit(documentOnlineMutationTypes.RESET_FILE_URL)
  }
}
