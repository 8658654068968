<template lang="pug">

div(class="step-wizard-container")

  h2(v-if="stepNumber && title" class="step-title") {{ stepText }} {{ stepNumber }} | {{ title }}

  div(v-if="explanation" class="step-instructions") {{ explanation }}

  slot

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PrintOrSaveInvoicesStepComponent extends Vue {
  @Prop({
    type: Number
  })
  stepNumber!: number

  @Prop({
    type: String
  })
  title!: string

  @Prop({
    type: String
  })
  explanation!: string

  stepText = this.$t('components.print_or_save_invoices_step.step')
}
</script>

<style lang="scss" scoped>
.step-wizard-container {
  width: 100%;

  .step-title {
    @include milano-bold-18;
    color: $main-1000;
  }

  .step-instructions {
    @include milano-regular-14;
    color: $neutral-grey-800;
    margin-top: $spacing-xs;
  }

  .static-alert {
    margin-top: $spacing-md;
  }
}
</style>
