import ContactsView from '@/views/contacts/Contacts/ContactsView.vue'
import ContactDetailView from '@/views/contacts/Contacts/ContactDetailView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ContactEntityId } from '@/general/entityIds'

export const contactsRoutes = [
  {
    path: `/${URLS.CONTACTS}`,
    name: URLS.CONTACTS,
    component: ContactsView,
    meta: {
      requiresAuth: true,
      title: URLS.CONTACTS,
      entityType: ContactEntityId.CONTACTS
    },
  },
  {
    path: `/${URLS.CONTACT}/:selectedRegisterId`,
    name: `${URLS.CONTACTS}-${URLS.CONTACT}`,
    component: ContactDetailView,
    props: (route: { params: { selectedRegisterId: any } }) => ({
      menuName: URLS.CONTACTS,
      context: ContextName.CONTACT,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.CONTACT
      },
      entityType: entity.contacts.type,
      entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
        ? parseInt(route.params.selectedRegisterId, 10)
        : 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONTACT,
      title: URLS.CONTACT,
      entityType: ContactEntityId.CONTACTS
    }
  }
]

export const contactsUrl = [
  `${URLS.CONTACTS}`,
  `${URLS.CONTACT}`
]
