export interface Endpoint {
  get: string
  save: string
  delete: string
  list: string
}

export interface EndpointEntity {
  url: string
  method: string
  params: ParamsEntity[]
}

export interface ParamsEntity {
  name: string
  value: string
}

interface EndpointType {
  [entityType: number]: Endpoint
}

export interface EndpointState {
  endpoints: EndpointType
}

export const endpointsMutationTypes = {
  SAVE_ENDPOINTS: 'SAVE_ENDPOINTS'
}

export const endpoints = {
  contacts: {
    get: '/contacts/{entity.alias}/{entity.id}/get',
    save: '/contacts/{entity.alias}/save',
    delete: '/contacts/{entity.alias}/{entity.id}/delete',
    list: '/contacts/{entity.alias}/{entity.id}'
  },
  contactsGeneric: {
    get: '/contacts/{0}/{1}/get',
    save: '/contacts/{0}/save',
    delete: '/contacts/{0}/{1}/delete',
    list: '/contacts/{0}/{1}'
  },
  actions: {
    get: 'actions/action/{entity.id}/get',
    save: 'actions/save',
    delete: 'actions/action/{entity.id}/delete',
    list: 'actions/{entity.id}/get'
  },
  contactContacts: {
    delete: '/contact/contacts/{0}/{1}/delete',
    get: '/contact/contacts/{0}/{1}/{2}/get',
    list: '/contact/contacts/{0}/{1}/get',
    save: '/contact/contacts/{0}/save'
  },
  actionsRelations: {
    delete: '/action/relations/{0}/delete',
    get: 'action/relation/{0}/get',
    list: 'action/relations/{0}/get',
    save: '/action/relations/save'
  },
  subActions: {
    delete: '',
    get: '',
    list: 'v2/action/{0}/relationships/sub-action',
    save: ''
  },
  documents: {
    get: 'repository/documents/{entity.id}/get',
    save: 'repository/documents/save',
    delete: 'repository/documents/{entity.id}/delete',
    list: 'repository/documents/get'
  },
  expedients: {
    get: 'expedients/{entity.id}/get',
    save: 'expedients/save',
    delete: 'expedients/{entity.id}/delete',
    list: 'expedients/get'
  },
  expedientsIntervener: {
    get: 'expedient/intervener/{0}/get',
    save: 'expedient/intervener/save',
    delete: 'expedients/intervener/{0}/delete',
    list: 'expedient/{0}/interveners/get'
  },
  expedientsCourt: {
    get: 'expedient/court/{0}/get',
    save: 'expedient/court/save',
    delete: 'expedients/court/{0}/delete',
    list: 'expedient/{0}/courts/get'
  },
  maintenance: {
    get: 'maintenance/{source}/get',
    save: 'maintenance/{source}/save/post',
    delete: 'maintenance/{source}/{id}',
    list: 'maintenance/{source}'
  },
  configurationPanel: {
    get: 'maintenance/{entity.id}/get',
    save: 'maintenance/{entity.id}/save/post',
    delete: 'maintenance/{entity.id}/{id}',
    list: 'maintenance/{entity.id}'
  },
  invoices: {
    get: '/invoices/{entity.alias}/{entity.id}/get',
    save: '/invoices/{entity.alias}/save',
    delete: '/invoices/{entity.alias}/{entity.id}/delete',
    list: '/invoices/{entity.alias}/list'
  },
  customerBankdrafts: {
    get: '/invoices/customers/bankdraft/{entity.id}/get',
    save: '/invoices/customers/bankdraft/save',
    delete: '/invoices/customers/bankdraft/{entity.id}/delete',
    list: '/invoices/customers/bankdraft/list'
  },
  providerBankdrafts: {
    get: '/invoices/providers/bankdraft/{entity.id}/get',
    save: '/invoices/providers/bankdraft/save',
    delete: '/invoices/providers/bankdraft/{entity.id}/delete',
    list: '/invoices/providers/bankdraft/list'
  },
  provisions: {
    get: '/expedient/economic/{entity.id}/get',
    save: '/expedients/economic/save',
    delete: '/expedients/economic/{entity.id}/delete',
    list: '/invoices/providing/'
  },
  invoiceDetails: {
    get: '/invoices/{0}/detail/{1}/get',
    save: '/invoices/{0}/detail/save',
    delete: '/invoices/{0}/detail/{1}/delete',
    list: '/invoices/{0}/{1}/detail/list'
  },
  invoiceTotals: {
    get: '/invoices/{0}/{1}/totals/get'
  },
  invoiceBankdrafts: {
    get: '/invoices/{0}/bankdraft/{1}/get',
    save: '/invoices/{0}/bankdraft/save',
    delete: '/invoices/{0}/bankdraft/{1}/delete',
    list: '/invoices/{0}/{1}/bankdraft/list'
  },
  invoiceBankdraftTotals: {
    get: '/invoices/{0}/{1}/bankdraft/totals/get'
  },
  users: {
    get: 'user/{entity.id}',
    save: 'user',
    delete: 'user/{entity.id}/delete',
    list: 'user/get'
  },
  professionals: {
    get: 'user/professionals/{entity.id}',
    save: 'maintenance/manProfessionals/save/post',
    delete: 'user/professionals/{entity.id}',
    list: 'user/get'
  },
  template: {
    get: 'template/{entity.id}/get',
    save: 'template/save',
    delete: 'template/{entity.id}/delete',
    list: 'template/list'
  },
  orderForms: {
    get: 'order-form/{entity.id}/get',
    save: 'order-form/save',
    delete: 'order-form/{entity.id}/delete',
    list: 'order-form/get'
  }
}
