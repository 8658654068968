import { GetterTree } from 'vuex'
import { AlertMode, AlertsData, AlertsState } from '@/store/modules/alerts/alertsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<AlertsState, RootState> = {
  showAlert(state: AlertsState): boolean {
    return state.showAlert
  },
  getAlertMode(state: AlertsState): AlertMode | null {
    return state.alertMode
  },
  getAlertData(state: AlertsState): AlertsData | null {
    return state.alertData
  }
}
