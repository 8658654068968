import { ActionTree } from 'vuex'
import { router } from '@/router/router'
import {
  SelectedRegisterState,
  selectedRegisterMutationTypes
} from '@/store/modules/selectedRegister/selectedRegisterTypes'
import { ContextName, ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { SelectedRegisterService } from '@/services/SelectedRegisterService'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { MainService } from '@/services/MainService'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'

const billingModule: string = ModuleNamespaces.BILLING
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const contactsModule: string = ModuleNamespaces.CONTACTS
const dialogModule: string = ModuleNamespaces.DIALOG
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const reportsModule = ModuleNamespaces.REPORTS

const getBillingDeleteSourceParameter = (listName: string) => {
  switch (listName) {
    case ListNames.CUSTOMER_BANKDRAFTS:
      return 'invoices/customers/bankdraft'
    case ListNames.CUSTOMER_INVOICES:
      return 'invoices/customers'
    case ListNames.ORDER_FORMS:
      return 'order-form'
    case ListNames.PROFORMA_INVOICES:
      return 'invoices/proforma'
    case ListNames.PROVIDER_BANKDRAFTS:
      return 'invoices/providers/bankdraft'
    case ListNames.PROVIDER_INVOICES:
      return 'invoices/providers'
    case ListNames.PROVISIONS:
      return 'expedients/economic'
  }
}

export const actions: ActionTree<SelectedRegisterState, RootState> = {
  async saveSelectedRegisterId({ commit, dispatch }, { id, context }) {
    commit(selectedRegisterMutationTypes.SAVE_SELECTED_REGISTER_ID, { id, context })
    dispatch(`${entitiesModule}/saveSelectedIdEntity`, { idEntity: id, context }, { root: true })
  },
  async saveParentdRegisterId({ commit }, id) {
    commit(selectedRegisterMutationTypes.SAVE_PARENT_REGISTER_ID, id)
  },
  async saveSelectedRegisterData({ commit }, { selectedRegisterData, context }) {
    commit(selectedRegisterMutationTypes.FETCH_SELECTED_REGISTER_DATA, { selectedRegisterData, context })
  },
  async fetchSelectedRegisterData({ commit }, { endpoint, context, post }) {
    try {
      let result
      if (post) {
        const { data } = await new MainService().postData(endpoint, post)
        result = data
      } else {
        const { data } = await new SelectedRegisterService().getData(endpoint)
        result = data
      }
      let reg = result
      if (result.length && result[0]) {
        reg = result[0]
      }
      commit(selectedRegisterMutationTypes.FETCH_SELECTED_REGISTER_DATA, {
        selectedRegisterData: reg,
        context
      })
    } catch (error: any) {
      commit(selectedRegisterMutationTypes.RESET_SELECTED_RELATED_DATA)
      if (ContextName.EXPEDIENTS === context && error.response && 404 === error.response.status) {
        router.push(`/${URLS.EXPEDIENTS}`)
      }
    }
  },
  async deleteRegister({ dispatch }, { deleteEndpoint, listEndPoint = null, goToURL, name, dialogText }) {
    try {
      await new MainService().deleteData(deleteEndpoint)

      if (null !== goToURL) {
        goToURL !== -1 ? router.push({ name: goToURL }) : router.go(-1)
      }

      if (listEndPoint) {
        await new MainService().getData(listEndPoint)
      }
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_removed_register', { text: dialogText, register: name })
        },
        { root: true }
      )
    } catch (error: any) {
      // TODO: Hay que estandarizar la salida del delete  (no se que es error.response.data.exceptionCode)
      const errorMessage = error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async removeRegister(
    { dispatch },
    { parentCategory, selectedEntityName, idSelectedRegister, goToURL, name, dialogText }
  ) {
    try {
      let url: string
      switch (parentCategory) {
        case ParentCategory.EXPEDIENTS:
          url = `expedients/${idSelectedRegister}/delete`
          break
        case ParentCategory.BILLING:
          url = `${getBillingDeleteSourceParameter(selectedEntityName)}/${idSelectedRegister}/delete`
          break
        case ParentCategory.REPORTS:
          url = `/report/${idSelectedRegister}`
          break
        case ParentCategory.MAINTENANCE:
          url = `/${parentCategory}/${selectedEntityName}/${idSelectedRegister}`
          break
        case ParentCategory.PROFESSIONALS:
          url = `/maintenance/manProfessionals/${idSelectedRegister}`
          break
        default:
          url = `${parentCategory}/${selectedEntityName}/${idSelectedRegister}/delete`
          break
      }
      await new MainService().deleteData(url)
      router.push({ name: goToURL })
      switch (parentCategory) {
        case ParentCategory.EXPEDIENTS:
          await dispatch(
            `${expedientsModule}/fetchListExpedients`,
            store.getters[`${ModuleNamespaces.EXPEDIENTS}/getLastListExpedientFilter`],
            { root: true }
          )
          break
        case ParentCategory.BILLING:
          await dispatch(
            `${billingModule}/fetchBillingData`,
            {
              billingType: selectedEntityName,
              filter: store.getters[`${ModuleNamespaces.BILLING}/getLastListBillingFilter`]
            },
            { root: true }
          )
          break
        case ParentCategory.REPORTS:
          await dispatch(`${reportsModule}/fetchListReports`, selectedEntityName, { root: true })
          break
        case ParentCategory.CONTACTS:
          await dispatch(`${contactsModule}/fetchContacts`, selectedEntityName, { root: true })
          break
        case ParentCategory.MAINTENANCE:
          await dispatch(`${configurationModule}/fetchCurrentListConfiguration`, selectedEntityName, { root: true })
          break
      }
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_removed_register', { text: dialogText, register: name })
        },
        { root: true }
      )
    } catch (error: any) {
      const errorMessage = error.response.data.detail ? error.response.data.detail : error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async fetchRelatedSelectedRegister({ commit }, { url, filterData }) {
    try {
      if (filterData) {
        const { data } = await new MainService().postData(url, filterData)
        commit(selectedRegisterMutationTypes.FETCH_SELECTED_RELATED_DATA, data[0] || data)
      } else {
        const { data } = await new SelectedRegisterService().getData(url)
        commit(selectedRegisterMutationTypes.FETCH_SELECTED_RELATED_DATA, data[0] || data)
      }
    } catch (error) {}
  }
}
