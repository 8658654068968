import { GetterTree } from 'vuex'
import { ContactsState } from '@/store/modules/contacts/contactsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

const entitiesModule = ModuleNamespaces.ENTITIES

export const getters: GetterTree<ContactsState, RootState> = {
  getAllContacts(state, _getters, rootState) {
    const selectedEntity = rootState[entitiesModule].selectedEntityName
    switch (selectedEntity) {
      case entity.contacts.alias:
        return formatFieldsTypeDateEPOCH(state.contacts)
      default:
        return state.contacts
    }
  },
  getPaginateContacts(state) {
    return state.contacts
  },
  getContactTypeSelected(state) {
    return state.contactTypeSelected
  }
}
