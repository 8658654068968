<template>
  <v-menu bottom left nudge-bottom="48" nudge-right="18" content-class="navigation-context-menu">
    <template v-slot:activator="{ on, attrs }">
      <span :class="[iconConfig, 'main-icon']" v-bind="attrs" v-on="on" />
    </template>

    <v-list class="list">
      <v-list-item
        v-for="(item, index) in items"
        v-if="item.show"
        :key="index"
        class="list-item"
        @click="redirect(item)"
      >
        <span :class="[item.icon, 'lf-icon__lg']" />
        <span>{{ item.name }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import useRouter from '@/composables/useRouter'
import { Icons } from '@/icons/icons'
import { ContextMenuItem } from '@/components/Navigation/AppBarToolbarItems/contextMenus/types'
import { URLS } from '@/router/routes/urlRoutes'
import usePermissions from '@/composables/usePermissions'
import { MaintenanceEntityId } from '@/general/entityIds'

// PROPS
const props = defineProps({
  showItem: {
    type: Boolean,
    required: false,
    default: true
  },
  configurationUrl: {
    type: String,
    required: true
  }
})

// ROUTER
const { goToRoute } = useRouter()
const { checkEntityPermissionsGetter } = usePermissions()

// TRANSLATIONS
const { t } = useI18n()
const text = {
  activity: t('navigation.activity_log.activity'),
  configurationPanel: t('navigation.activity_log.configuration_panel')
}

// DATA
const iconConfig = ref(Icons.CONFIGURATION)

const items = computed((): ContextMenuItem[] => {
  const { canView } = checkEntityPermissionsGetter(MaintenanceEntityId.MAINTENANCE)
  return [
    {
      icon: Icons.SETTINGS,
      name: text.configurationPanel,
      url: props.configurationUrl,
      show: Boolean(canView)
    },
    {
      icon: Icons.TRACK_RIGHT,
      name: text.activity,
      url: `${URLS.AUDITS}`,
      show: props.showItem
    }
  ]
})

// METHODS
function redirect(item: ContextMenuItem) {
  goToRoute({
    name: item.url
  })
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/AppBarToolbarItems/contextMenus/styles/contextmenu.scss';

.navigation-context-menu {
  --menu-with: 260px;
}
</style>
