<template lang="pug">
div
  LfHeaderTitleComponent(:title="$t('views.billing_customer_bankdrafts.title')" class="header-title")
  LfTotalsComponent(
    v-if="totals && totals.length"
    :items="totals"
  )

  GridTable(
    v-if="renderGrid && !showSpinnerLayer"
    ref="grid"
    :itemsData="serverSideData"
    :gridConfiguration="gridConfiguration"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="contextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    @toolbarClicked="onToolbarClicked"
    :archivedFilter="archivedSelectedOption"
    :commandClick="onCommandClick"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    excelExportedFileName="Cobros a Clientes"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    :useServerPagination="useServerPagination"
    :itemsDataExport="serverSideExportData"
  )

  ejs-contextmenu(
    :id="idContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="invoicesArchivedContextMenuItemsParsed"
    :select="onInvoicesArchivedClick"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import { Icons } from '@/icons/icons'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ContextMenuClickEventArgs, DataResult } from '@syncfusion/ej2-vue-grids'
import useGridSaveUserConf from '@/composables/useGridSaveUserConf'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

const billingModule: string = ModuleNamespaces.BILLING
const dialogModule: string = ModuleNamespaces.DIALOG
const configurationModule: string = ModuleNamespaces.CONFIGURATION

const { parseUserConfig } = useGridSaveUserConf()

@Component({
  components: {
    LfHeaderTitleComponent,
    LfTotalsComponent,
    GridTable
  }
})
export default class CustomerBankdraftsView extends mixins(ListViewMixin, ArchivedFilterMixin) {
  @Action('fetchCustomerBankdraftList', { namespace: billingModule })
  fetchCustomerBankdraftList: (filter: {}) => Promise<void>

  @Action('fetchCustomerBankdraftTotals', { namespace: billingModule })
  fetchCustomerBankdraftTotals: (filter: {}) => Promise<void>

  @Action('billingRemove', { namespace: billingModule })
  removeCustomerBankdraft: (id: string) => { success: boolean; message: string }

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getBillingData', { namespace: billingModule })
  invoiceData: DataResult

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'
  gridFilters: any = { page: 0, pageSize: 10, columnOrder: [] }

  useServerPagination: boolean = true

  serverSideData: DataResult = {} as DataResult
  serverSideExportData = []

  get context() {
    return ContextName.BILLING
  }

  get toolbarOptionsCustom() {
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectArchivedOption
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get gridConfiguration() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_KEBAK_MENU,
        type: 'None',
        title: 'Open',
        buttonOption: {
          iconCss: Icons.KEBAB,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  get filters() {
    return {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }
  }

  async created() {
    await this.rememberUserCustomConfigurationList(true)
    await this.fetchCurrentListConfiguration(ListNames.CUSTOMER_BANKDRAFTS)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.getFilterParams()
    this.fetchGridItems()
    this.fetchInvoiceTotals()
    this.saveSelectedEntityName(BillingTypes.CUSTOMER_BANKDRAFTS)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async getFilterParams() {
    const listConfig = JSON.parse((this as any).listConfiguration['Config'])
    this.archivedSelectedOption = listConfig.archived || 0
    const params = await parseUserConfig(listConfig)
    this.gridFilters = params
  }

  async fetchGridItems() {
    await this.fetchCustomerBankdraftList({
      archived: this.archivedSelectedOption,
      ...this.filters
    })
    this.serverSideData = this.formatDataPaginated(this.invoiceData as any)
  }

  fetchInvoiceTotals() {
    this.fetchCustomerBankdraftTotals(this.filters)
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
    this.fetchCustomerBankdraftTotals(this.filters)
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { id, description } = rowData as any
    switch (action) {
      case ActionName.REMOVE:
        this.confirmDelete(id, description)
        break
      default:
        this.open(id, '_blank')
        break
    }
  }

  open(id: string, target = '_self') {
    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.CUSTOMER_BAKDRAFTS}`,
      params: { selectedRegisterId: id }
    })

    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  confirmDelete(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.provisions_literal')
      }),
      action: async () => {
        await this.remove(id, name)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async remove(id: string, name: string) {
    const url = `/invoices/customers/bankdraft/${id}/delete`
    const { success } = await this.removeCustomerBankdraft(url)
    if (success) {
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.provisions_literal'),
          register: name
        })
      })
    } else {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.error_remove_order_form')
      })
    }
  }

  onCustomToolbarContextMenuClick(args: ContextMenuClickEventArgs) {
    const { item } = args
    const { id } = item

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onInvoicesArchivedClick(args)
      return
    }
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  async gridActionChange(serverSideParams: string) {
    const { page, pageSize, columnOrder, search, filter } = JSON.parse(serverSideParams)
    this.gridFilters = {
      ...this.filters,
      page,
      pageSize,
      columnOrder,
      filter,
      search
    }
    await this.fetchGridItems()
    this.fetchInvoiceTotals()
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchCustomerBankdraftList(ssp)
    this.serverSideExportData = this.formatData(this.billingData)
  }
}
</script>
