<template>
  <div :class="['lf-counter-wrapper', { 'execute-action': props.info.action }]" @click="executeAction">
    <div class="lf-counter">
      <div class="text-container">
        <span class="text">{{ props.info.title }}</span>
        <LexonTooltipComponent
          v-if="props.info.tooltipText"
          :icon="tooltipIcon"
          :message="props.info.tooltipText"
          position="top"
        />
      </div>
      <SpinnerLayerComponent v-if="props.showSpinner" class="spinner-layer" />
      <span v-else class="number">{{ formattedNumber }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue'
import { Icons } from '@/icons/icons'
import { CounterInfo } from '@/components/counters/types/CounterTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'

const props = defineProps({
  info: {
    type: Object as PropType<CounterInfo>,
    required: true
  },
  showSpinner: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})

const tooltipIcon = Icons.INFO_FULL

const formattedNumber = computed(() => {
  return props.info.qty.toString().length > 1 ? props.info.qty : ('00' + props.info.qty).slice(-2)
})

// METHODS
function executeAction() {
  if (props.info.action) props.info.action()
}
</script>

<style lang="scss" scoped>
.lf-counter-wrapper {
  @include flex;
  @include borders;
  flex: var(--lf-counter-flex, 1 0 0);
  height: var(--lf-counter-height, 124px);
  padding: var(--lf-counter-padding-top, $spacing-md) 0;
  border-radius: $cornerRadius-md;
  border-color: $main-400;
  background-color: $neutral-white-000;

  &:hover {
    border-color: $main-800;
    box-shadow: $shadow-md;
  }

  &.execute-action {
    cursor: pointer;
  }

  .lf-counter {
    @include flex($flex-direction: column);
    width: 100%;
    padding: $spacing-xs $spacing-nule;
    gap: $spacing-sm;

    .text-container {
      display: flex;
      margin: 0 8px;

      .text {
        @include flex;
        @include milano-bold-16;
        width: 100%;
        color: $neutral-grey-800;
        text-align: center;
      }
    }

    .number {
      @include flex;
      @include milano-bold-28;
      height: 30px;
      color: $main-1000;
      gap: 10px;
      text-align: center;
    }

    .spinner-layer {
      ::v-deep .spinner-lefebvre {
        width: $spinner-md;
        height: $spinner-md;
      }
    }
  }
}
</style>
