import { ColumnModel } from "../LfGridTypes"
import ColorsColumnTemplateComponent from "../../ColumnTemplates/ColorsColumnTemplateComponent.vue"
import StatusTemplateComponent from "../../ColumnTemplates/StatusTemplateComponent.vue"
import DataToHourTemplateComponent from "../../ColumnTemplates/DataToHourTemplateComponent.vue"
import IconColumnTemplateComponent from "../../ColumnTemplates/IconColumnTemplateComponent.vue"
import FileIconColumnTemplateComponent from "../../ColumnTemplates/FileIconColumnTemplateComponent.vue"
import BoolToYesNoTemplateComponent from "../../ColumnTemplates/BoolToYesNoTemplateComponent.vue"
import MonitoringDialogAuditColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogAuditColumnTemplateComponent.vue'
import MonitoringDialogRecipientColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogRecipientColumnTemplateComponent.vue'
import MonitoringDialogRequestColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogRequestColumnTemplateComponent.vue'
import MonitoringDialogStatusColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogStatusColumnTemplateComponent.vue'

interface ResponseUseGridTemplates {
  getTemplate: any
}

export default function useGridTemplates(): ResponseUseGridTemplates {

  // TEMPLATES
  const getTemplate = (column: ColumnModel) => {
    const { templateName } = column as any
    const templateFunction = templates[templateName]
    return templateFunction || null
  }

  // TEMPLATES
  const templates: { [key: string]: () => { template: any } } = {
    colorsColumnTemplate: () => {
      return { template: ColorsColumnTemplateComponent }
    },
    columnStatusTemplate: () => {
      return { template: StatusTemplateComponent }
    },
    dataToHourTemplate() {
      return { template: DataToHourTemplateComponent }
    },
    iconColumnTemplate() {
      return { template: IconColumnTemplateComponent }
    },
    yesNoColumnTemplate() {
      return { template: BoolToYesNoTemplateComponent }
    },
    monitoringDialogStatusColumnTemplate: () => {
      return { template: MonitoringDialogStatusColumnTemplateComponent }
    },
    monitoringDialogRequestColumnTemplate: () => {
      return { template: MonitoringDialogRequestColumnTemplateComponent }
    },
    monitoringDialogRecipientColumnTemplate: () => {
      return { template: MonitoringDialogRecipientColumnTemplateComponent}
    },
    monitoringDialogAuditColumnTemplate: () => {
      return { template: MonitoringDialogAuditColumnTemplateComponent }
    },
    documentType() {
      return { template: FileIconColumnTemplateComponent }
    },
  }

  return {
    getTemplate
  }
}
