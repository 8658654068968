<template lang="pug">

div(class="column-template-container")
  div(class="column-text") {{ data.headerText }}
  div(class="border" :style="renderBorderColor(data.keyField)")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class LexonKanbanColumnTemplate extends Vue {
  renderBorderColor(columnName: string) {
    switch (columnName) {
      case this.$t('components.lexon_kanban.columns.planned'):
        return { background: '#D1DAEB' }
      case this.$t('components.lexon_kanban.columns.initiated'):
        return { background: '#F5D359' }
      case this.$t('components.lexon_kanban.columns.done'):
        return { background: '#FFB759' }
      case this.$t('components.lexon_kanban.columns.out_of_time'):
        return { background: '#59AC85' }
      case this.$t('components.lexon_kanban.columns.cancelled'):
        return { background: '#D8B982' }
      case this.$t('components.lexon_kanban.columns.pending_resolution'):
        return { background: '#C9CAC9' }
    }
  }
}
</script>

<style lang="scss" scoped>
.column-template-container {
  @include ellipsis;
  display: flex;
  flex-direction: column;
  width: 100%;

  .column-text {
    @include milano-bold-16;
    @include ellipsis;
    color: $neutral-grey-800;
  }

  .border {
    width: 100%;
    height: 8px;
    border-radius: 3px;
    margin-top: 6px;
  }
}
</style>
