<template>
  <section :class="['header-component', { 'is-mobile': isMobileBreakpoint }]">
    <header class="header-login">
      <div class="logo-container">
        <div class="logo-desktop">
          <img :src="logo" alt="Lefebvre logo" />
        </div>
      </div>
    </header>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useVuetify } from '@/composables/useVuetify'

// COMPOSABLES
const { isMobileBreakpoint } = useVuetify()

// COMPUTED
const logo = computed(() => {
  if (isMobileBreakpoint.value) {
    return require('@/assets/images/svg/Union.svg')
  } else {
    return require('@/assets/images/logos/lefebvre-202x40.png')
  }
})
</script>

<style lang="scss" scoped>
.header-component {
  width: 100%;
  display: flex;
  padding: $spacing-sm $spacing-xxl;
  justify-content: space-between;
  align-items: center;
  background: $neutral-white-000;
  box-shadow: $shadow-md;
  margin-bottom: 4%;

  .header-login {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    background: $neutral-white-000;

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      flex: 1 0 0;

      .logo-desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }

    img {
      width: 191.9px;
      height: 38px;
    }
  }

  &.is-mobile {
    padding: 10px $spacing-md;
    margin-bottom: $spacing-xxl;

    img {
      width: 100%;
    }
  }
}
</style>
