import { getUrlByIdEntity, redirectTo } from '@/helpers/routes'
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { Ref, ref } from "vue"

interface ResponseUseContactBankdrafts {
  bankdrafts: Ref<any[]>,
  read: (selectedRegisterId: string) => Promise<void>
  open: (idEntity: number, sid: string, target?: '_self' | '_blank') => void
}

interface PropsUseContactBankdrafts {
  listName: string
}

export default function useContactBankdrafts(props: PropsUseContactBankdrafts): ResponseUseContactBankdrafts {
  const bankdrafts = ref([])

  const read = async (selectedRegisterId: string) => {
    await store.dispatch(`${ModuleNamespaces.INVOICES}/fetchContactBankDraft`, {
      selectedRegisterId,
      listName: props.listName
    })
    bankdrafts.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const open = (idEntity: number, selectedRegisterId: string, target = '_self') => {
    const urlInvoice = getUrlByIdEntity(idEntity, selectedRegisterId)
    redirectTo(urlInvoice, target)
  }

  return {
    bankdrafts,
    read,
    open
  }
}
