<template>
  <div class="pagination-server-container">
    <div class="pagination">
      <span :class="[icon.left, 'icon', 'lf-icon__md']" @click="changePage(-1)" />
      <div class="number">{{ props.currentPage }}</div>
      <span :class="[icon.right, 'icon', 'lf-icon__md']" @click="changePage(1)" />
    </div>
    <div class="pages-info">
      <div class="current">{{ props.currentPage }}</div>
      <div>{{ texts.of  }}</div>
      <div>{{ totalPages }} {{ texts.pages }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Icons } from '@/icons/icons'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()

// PROPS
const props = defineProps({
  currentPage: {
    type: Number,
    required: true
  },
  totalRegisters: {
    type: Number,
    required: true
  },
  registersPerPage: {
    type: Number,
    default: 5
  }
})

// DATA
const icon = {
  left: Icons.ANGLE_LEFT,
  right: Icons.ANGLE_RIGHT
}

const texts = {
  of: t('components.pagination.of'),
  pages: t('components.pagination.pages'),
}

// COMPUTED
const totalPages = computed(() => Math.ceil(props.totalRegisters / props.registersPerPage))

// EVENTS
const emit = defineEmits(['change-page'])

function changePage(step: 1 | -1) {
  if (props.currentPage === 1 && step === -1) {
    return
  }
  if (props.currentPage === totalPages.value && step === 1) {
    return
  }
  emit('change-page', step)
}
</script>

<style lang="scss" scoped>
.pagination-server-container {
  @include flex;
  width: 100%;
  height: 42px;
  gap: 1px;

  .pagination {
    @include flex;
    gap: 10px;
    flex: 1;

    .icon {
      color: $main-1000;
      cursor: pointer;
    }

    .number {
      @include milano-regular-14;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: $cornerRadius-sm;
      background: $main-100;
      color: $neutral-grey-800;
    }
  }

  .pages-info {
    @include milano-regular-14;
    @include flex;
    height: 28px;
    gap: 6px;
    color: $neutral-grey-800;

    .current {
      @include milano-bold-14;
    }
  }
}
</style>
