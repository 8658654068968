export enum TrackerEvents {
  VIEW_PAGE = 'Ver Pagina',
  SEARCH = 'Buscar',

  // Expedients =================
  CREATE_EXPEDIENT = 'Crear Expediente',
  ADD_EXPEDIENT_INTERVENER = 'Añadir Interviniente',
  EDIT_EXPEDIENT = 'Editar Expediente',

  // Actions =================
  CREATE_ACTION = 'Crear actuacion',
  REMOVE_ACTION = 'Eliminar actuacion',
  EDIT_ACTION = 'Editar actuacion',

  // Economic Data =================
  CREATE_ECONOMIC_DATA = 'Nuevo dato economico',
  REMOVE_ECONOMIC_DATA = 'Eliminar dato economico',
  IMPORT_ACTIONS_IN_ECONOMIC_DATA = 'Importar actuaciones a datos economicos',
  INVOICE_ECONOMIC_DATA = 'Facturar datos economicos',

  // INVOICE =====================
  CREATE_INVOICE = 'Crear factura',
  EDIT_INVOICE = 'Editar factura',

  // Contact =================
  CREATE_CONTACT = 'Crear contacto',
  EDIT_CONTACT = 'Editar contacto',

  // Documentos =================
  NEW_DOCUMENT_TEMPLATE = 'Nuevo documento plantilla',

  // ===============
  OPEN_LEFEBVRE_MAIL = 'Abrir en Lefebvre Mail',
  CREATE_MAIL_ACTION = 'Crear actuación correo',
  ATTACH_TO_MAIL = 'Adjuntar a correo',

  TAB_CHANGE = 'Tab Change',

  OPEN_EXPEDIENT = 'Open Expedient',
  REMOVE_EXPEDIENT = 'Eliminar Expediente',
  DUPLICATE_EXPEDIENT = 'Duplicar Expediente',

  ACTIONS_BUTTONS = 'Actions Buttons',
  GRID_TABLE_TOOLBAR = 'Grid Table Toolbar',
  GRID_TABLE_CONTEXT_MENU = 'Grid Table Context Menu',
  SIMPLE_GRID_TOOLBAR = 'Simple Grid Toolbar',
  ERROR_GLOBAL = 'ERROR GLOBAL:',
  ERROR = 'ERROR:',

  DOCS_IA = 'Documentos analizados',
  // NAVIGATION DRAWER
  OPEN_NAVIGATION_DRAWER = 'Ampliar menú',
  CLOSE_NAVIGATION_DRAWER = 'Minimizar menú',


  // VIEW_ALL_EXPEDIENTS='Ver Todos Expedientes',

  // VIEW_PAGE='Crear Expediente',
  // VIEW_PAGE='Añadir Interviniente',
  // VIEW_PAGE='Buscar',
  // VIEW_PAGE='Cerrar Sesion',
  // VIEW_PAGE='Enviar notificación interna',
  // VIEW_PAGE='Enviar notificación a clientes',

  // VIEW_PAGE='Imprimir listado',
  // VIEW_PAGE='Exportar listado',

  // VIEW_PAGE='Crear actuación',
  // VIEW_PAGE='Eliminar actuación',
  // VIEW_PAGE='Editar actuación',
  // VIEW_PAGE='Nuevo documento',
  // VIEW_PAGE='Nuevo documento plantilla',
  // VIEW_PAGE='Previsualización',
  // VIEW_PAGE='Renombrar archivo',
  // VIEW_PAGE='Eliminar archivo',
  // VIEW_PAGE='Copiar archivo',
  // VIEW_PAGE='Descargar archivo',
  // VIEW_PAGE='Convertir a pdf',
  // VIEW_PAGE='Actuaciones facturables',
  // VIEW_PAGE='Actuaciones color',
  // VIEW_PAGE='Actuaciones Portal Cliente',
  // VIEW_PAGE='Archivos Portal Cliente',
  // VIEW_PAGE='Nuevo dato econonico',
  // VIEW_PAGE='Eliminar dato economico',
  // VIEW_PAGE='Importar actuaciones a datos economicos',
  // VIEW_PAGE='Facturar datos economicos',
  // VIEW_PAGE='Configuracion Auditoria',
  // VIEW_PAGE='Menu Bases de Datos',
  // VIEW_PAGE='Menu Pagos Certificados',
  // VIEW_PAGE='Menu Registro Impagados',
  // VIEW_PAGE='Abrir informe',
}

export interface Tracker {
  init: (token: string, config?: object) => void
  trackEvent: (event: string, properties?: object) => void
  // trackError: (event: string, message: string | unknown, information?: string | object | unknown) => void
  register: () => void
  reset: () => void
  pageView: () => void
  checkMixPanel: () => boolean
}
