<template lang="pug">

  div(class="audit-container")

    //- Render only one status
    div(v-if="onlyRenderOneAudit")
      div(v-if="onlyOneAuditId" class="audit-grid-item"
          @click="downloadAudit(onlyOneAuditId)")
        span(
          :class="[downloadIcon, 'download-icon']"
        )
        span {{ texts.download }}
      span(v-else class="not-available") {{ texts.notAvailable }}

    //- Render multiple status
    ul(v-else class="audits")
      li(v-for="recipient in recipients")
        div(v-if="recipient.idDocumentAudit" class="audit-grid-item"
            @click="downloadAudit(recipient.idDocumentAudit)")
          span(
            :class="[downloadIcon, 'download-icon']"
          )
          span {{ texts.download }}
        span(v-else class="not-available") {{ texts.notAvailable }}

    OpenFileComponent(
      ref="openFileComponent"
    )

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { FirmaRequestTypes, ParsedFirmaAudit, Recipient } from '@/store/modules/firma/firmaTypes'
import OpenFileComponent from '@/components/FileManager/OpenFileComponent.vue'

@Component({
  name: 'monitoringDialogAuditColumnTemplate',
  components: {
    OpenFileComponent
  }
})
export default class MonitoringDialogAuditColumnTemplateComponent extends Vue {
  data: ParsedFirmaAudit

  downloadIcon = Icons.DOWNLOAD

  texts = {
    download: this.$t('action_buttons.download'),
    notAvailable: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.not_available')
  }

  $refs!: {
    openFileComponent: InstanceType<typeof OpenFileComponent>
  }

  get recipients() {
    return this.data.recipients
  }

  get onlyRenderOneAudit() {
    /* El boton solo se pinta si tiene id de AUDITORIA. Si estamos en
    (FirmaRequestTypes.FIRMA_REQUEST || idType === FirmaRequestTypes.CERTIFIED_DOCUMENT
     solo hay un botón de descarga
     Si estas en SMS y EMAIL hay un botón de descarga por cada email.
    (Aquí puede pasar que haa botones de descarga y no disponiles en función del id de auditoría) */
    const { idType } = this.data
    return idType === FirmaRequestTypes.FIRMA_REQUEST || idType === FirmaRequestTypes.CERTIFIED_DOCUMENT
  }

  get onlyOneAuditId() {
    const itemWithAudit: Recipient | undefined = this.recipients.find((recipient) => recipient.idDocumentAudit !== null)
    return itemWithAudit ? itemWithAudit.idDocumentAudit : null
  }

  downloadAudit(id: number) {
    const openFileComponent = this.$refs.openFileComponent
    const file = {
      id,
      originalFileName: 'audit_signaturit.pdf'
    }
    if (openFileComponent) {
      openFileComponent.downloadFile(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.audit-container {
  @include flex($justify-content: flex-start);

  .audits {
    @include list;

    li {
      margin-bottom: 6px;
    }
  }

  .audit-grid-item {
  @include flex($justify-content: flex-start);
  cursor: pointer;
  color: $tertiary-byzantineBlue-200;

    .download-icon {
      font-size: 18px;
      margin-right: 6px;
    }
  }

  div.audit-grid-item {
    margin-top: 3px;
  }
}
</style>
