<template lang="pug">

div(v-if="showGrid" :class="['grid-container', 'simple-grid', { 'empty-grid': itemsData.length === 0, 'is-portal': isPortalUser, 'is-in-tab': isInTab }]")
  AlertComponent(
    :whereIsRendered="componentWhereIsRenderedAlertComponent"
  )
  header(v-if="showHeaderTitle" class="simple-grid-header")
    span(:class="[headerIcon, 'icon']")
    slot(name="header-title")
    v-spacer
    div(v-if="showHeaderButtons" class="buttons-container")
      div(class="add-new")
        button(@click.stop="exportToPDF" :class="[icons.print, 'add-new-button']")
        button(@click.stop="excelExport" :class="[icons.export, 'add-new-button']")
        template(v-if="canSave")
          button(v-if="clickOnlyOnceProp" @click.once="executeAction" :class="[icons.addNew, 'add-new-button', { 'disabled': disabledNewButton }]")
          button(v-else @click.stop="executeAction" :class="[icons.addNew, 'add-new-button', { 'disabled': disabledNewButton }]")
  div(v-if="showButtons" class="buttons-container")
    div(class="add-new")
      button(@click.stop="exportToPDF" :class="[icons.print, 'add-new-button']")
      button(@click.stop="excelExport" :class="[icons.export, 'add-new-button']")
      button(@click.stop="executeAction" :class="[icons.addNew, 'add-new-button']" v-if="canSave")
  div(v-else)
    slot(name="buttons")

  ejs-grid(
    ref="grid"
    :class="['grid-table', !allowSelection ? 'grid-not-selection' : ''] "
    v-bind="gridProps"
    :detailTemplate="showPartialbankdraftDetail ? partialbankdraftTemplate : null"
  )
    e-columns
      e-column(
        v-for="(column, index) in parsedColumns"
        :allowFiltering="!column.hideFilter"
        :customAttributes="getCustomAttributes(column.customAttributes)"
        :field="column.field"
        :filter="parsedFilter(column)"
        :format="column.format"
        :headerTemplate="column.headerTemplate"
        :headerText="column.headerText"
        :headerTextAlign="column.headerTextAlign"
        :isPrimaryKey="column.isPrimaryKey ? true : false"
        :key="`column-${index}`"
        :minWidth="column.minWidth"
        :template="getTemplate(column.templateName)"
        :textAlign="column.textAlign"
        :type="column.type"
        :visible="column.visible"
        :width="column.width"
      )

  ejs-contextmenu(
    id="contextMenuComponent"
    ref="contextMenuComponent"
    class="context-menu-component"
    :items="renderedContextMenuItems"
    :select="contextMenuClickedItem"
    :beforeOpen="onContextMenuBeforeOpen"
  )

  OpenFileComponent(
    ref="openFileComponent"
  )

</template>

<script lang="ts">
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator'
import GridTablesMixin from '@/mixins/GridTablesMixin.vue'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import OpenFileComponent from '@/components/FileManager/OpenFileComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { Icons } from '@/icons/icons'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'
import { InvoiceEntityId } from '@/general/entityIds'
import { URLS } from '@/router/routes/urlRoutes'
import { goToDocumentRoute } from '@/helpers/file'
import { actionClassificationTypes, ActionRelationTypes } from '@/store/modules/actions/actionsTypes'
import { PageSettingsModel } from '@syncfusion/ej2-vue-grids'
import useContacts from '@/views/contacts/composables/useContacts'
import { OpenMSOfficeFileData } from '@/components/FileManager/types/FileManagerTypes'

const authModule: string = ModuleNamespaces.AUTH
const contextMenuModule: string = ModuleNamespaces.CONTEXT_MENU
const searchModule: string = ModuleNamespaces.SEARCH
const { openContact } = useContacts()

@Component({
  components: {
    AlertComponent,
    OpenFileComponent
  }
})
export default class SimpleGridTableComponent extends Mixins(GridTablesMixin) {
  @Prop({
    type: Boolean,
    default: false
  })
  showPartialbankdraftDetail!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showButtons!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showHeaderButtons!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  disabledNewButton!: boolean

  @Prop({
    type: String,
    required: true
  })
  columns!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showHeaderTitle!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  allowReorder!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  redirectOnEdit!: boolean

  @Prop({
    type: String
  })
  noResultsCustomMessage!: string

  @Prop({
    type: String,
    default: Icons.CONTACTS
  })
  headerIcon!: string

  @Prop({
    type: String
  })
  gridIcon!: string

  @Prop({
    type: String
  })
  gridMessage!: string

  @Prop({
    type: Boolean,
    default: true
  })
  persistSelection!: boolean

  @Prop({
    type: Number
  })
  permissionsEntity!: number

  @Prop({
    type: Boolean,
    default: false
  })
  clickOnlyOnceProp!: boolean

  @Prop({
    type: Number
  })
  paginationPageSize!: number

  @Prop({
    type: Boolean,
    default: false
  })
  cancelOpenContextMenu!: boolean

  @Getter('checkIfItsSearchRoute', { namespace: searchModule }) isSearchView: boolean

  @Getter('getCompanyMailVersion', { namespace: authModule }) companyMailVersion: number

  @Action('setItemsDependingOnWhichGridTableWeWantToShow', { namespace: contextMenuModule })
  setContextMenuItems: ([]) => void

  icons = {
    addNew: Icons.ADD,
    print: Icons.PRINT,
    export: Icons.DOWNLOAD
  }

  gridInstance: any = null
  gridElement: any = null
  contextMenuComponent: any = null

  // TODO - Eliminar y tipar en grid table mixin
  // $refs!: {
  //   grid: any,
  //   contextMenuComponent: any,
  //   customToolbarContextMenuComponent: any,
  //   openFileComponent: any
  // }

  pageSettings: PageSettingsModel = {
    pageSizes: [5, 10, 25, 50],
    pageSize: this.paginationPageSize || 10,
    pageCount: 5
  }

  relatedForm = this.showButtons

  selectionOptions = { persistSelection: this.persistSelection, enableToggle: true }

  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.SIMPLE_GRID_TABLE

  @Emit()
  executeAction() {
    this.$emit('simpleGridActionNew')
  }

  get parsedColumns() {
    if (this.columns) {
      return JSON.parse(this.columns)
    }
    return null
  }

  get gridProps(): object {
    return {
      ...this.bothGridsSharedProps,
      actionComplete: this.gridActionComplete,
      allowFiltering: this.isSearchView || this.showFilters ? true : false,
      allowReordering: this.isSearchView || this.allowReorder ? true : false,
      contextMenuClick: this.contextMenuClickedItem,
      contextMenuOpen: this.onContextMenuBeforeOpen,
      pageSettings: this.pageSettings,
      recordClick: this.contextMenuItems ? this.onCellClicked : null,
      resizeStop: this.resizeStop,
      toolbar: this.showToolbar ? this.toolbarOptions : null,
      toolbarClick: this.showToolbar ? this.clickHandler : null,
      filterSettings: this.filterSettings,
      selectionSettings: this.selectionOptions
    }
  }

  get showToolbar() {
    return this.isSearchView ? true : false
  }

  get canSave() {
    if (this.permissionsEntity) {
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      return canSave
    }
    return 1
  }

  mounted() {
    this.gridInstance = (this as any).$refs.grid.ej2Instances
    this.gridElement = this.$refs.grid
    this.contextMenuComponent = this.$refs.contextMenuComponent
    this.setContextMenuItems((this as any).contextMenuItems)

    this.checkIfNoResults()
    this.createFilterTooltips()
  }

  updated() {
    if (this.showGrid) {
      this.checkIfNoResults()
    }
  }

  checkIfNoResults() {
    // if (!this.itemsData.length) {
    //   this.renderNoResultsTable()
    // } else {
    //   this.renderResultsTable()
    // }
  }

  parsedFilter(columnConf: any) {
    if (columnConf.isCheckboxType) {
      return this.checkboxFilter
    } else {
      return ''
    }
  }

  gridActionComplete() {
    this.getTextColorInYesOrNoColumns()
    this.getTextColorInDateColumns()
    this.createFilterTooltips()
  }

  renderNoResultsTable() {
    const frozenContent = this.$el.querySelector('.e-frozencontent') as HTMLElement
    const movableContent = this.$el.querySelector('.e-movablecontent') as HTMLElement
    const noResultsTable = this.$el.querySelector('.no-registers-container') as HTMLElement
    if (!noResultsTable) {
      const noRegistersContainer = document.createElement('div')
      const tableContent = this.$el.querySelector('.e-content')
      noRegistersContainer.classList.add('no-registers-container')
      noRegistersContainer.innerHTML = `
        <span class="${this.gridIcon || this.headerIcon} no-registers-icon"></span>
        <div class="text">${
          this.noResultsCustomMessage ? this.noResultsCustomMessage : this.$t('components.no_results.no_data_text')
        }</div>
      `
      tableContent!.prepend(noRegistersContainer)
      if (frozenContent) {
        frozenContent.style.display = 'none'
      }
      if (movableContent) {
        movableContent.style.display = 'none'
      }
    }
  }

  renderResultsTable() {
    const frozenContent = this.$el.querySelector('.e-frozencontent') as HTMLElement
    const movableContent = this.$el.querySelector('.e-movablecontent') as HTMLElement
    const noRegistersContainer = this.$el.querySelector('.no-registers-container') as HTMLElement
    if (noRegistersContainer) {
      noRegistersContainer.remove()
      if (frozenContent) {
        frozenContent.style.display = 'block'
      }
      if (movableContent) {
        movableContent.style.display = 'block'
      }
    }
  }

  onCellClicked(args: any) {
    if (this.activeOnClickCell) {
      this.selectedRegister = args.rowData
      if (0 === args.cellIndex) {
        if (this.isFrozenColsMode || args.target.classList.contains('context-menu-launcher')) {
          this.openContextMenuComponent(args)
        }
      } else {
        this.redirectTemporaryBehaviour(args)
      }
    }
  }

  openContextMenuComponent(args: any) {
    const rect = args.target.getBoundingClientRect()
    const top = rect.top + window.scrollY
    const left = rect.left + 15
    this.$refs.contextMenuComponent.open(top, left)
  }

  onContextMenuBeforeOpen(args: any) {
    if (this.cancelOpenContextMenu) {
      args.cancel = true
      return
    }
    if (this.isPortalUser) {
      args.cancel = true
    }
    const contextMenuGridObject = this.gridInstance.contextMenuModule.contextMenu
    const { idEntity } = this.selectedRegister
    const { items } = args
    const contextMenuItems = this.parsedItemsByPermissions(idEntity || this.permissionsEntity, items)
    contextMenuGridObject.enableItems(contextMenuItems, false)
    this.contextMenuComponent.enableItems(contextMenuItems, false)

    this.$emit(
      'simpleGridOnContextMenuBeforeOpen',
      this.contextMenuComponent,
      contextMenuGridObject,
      this.selectedRegister
    )
  }

  getTemplate(templateName: string) {
    if (templateName && 'undefined' !== typeof (this as any)[templateName]) {
      return (this as any)[templateName]
    }

    return false
  }

  redirectTemporaryBehaviour(args: any, target: string = '_self') {
    if (this.redirectOnEdit && this.title === 'Expedientes' && !this.relatedForm) {
      const subdirectory1 = this.$t('routes.expedients').toString()
      const routeData = this.$router.resolve({
        name: `${subdirectory1}-${subdirectory1}`,
        params: { selectedRegisterId: this.selectedRegister.idExpedient }
      })
      if (target === '_self') {
        // TODO TIPAR BIEN
        ;(this as any).$router.push(routeData.resolved)
      } else {
        window.open(routeData.href, target)
      }
    } else if (this.redirectOnEdit && this.title === 'Intervinientes') {
      if (target === '_self') {
        this.$emit('simpleGridActionEdit', this.selectedRegister, { principal: true }, args)
      } else {
        openContact(this.selectedRegister.id, '_blank')
      }
    } else if (this.redirectOnEdit && this.title === 'Clasificación') {
      if (target === '_self') {
        this.$emit('simpleGridActionEdit', this.selectedRegister, { principal: true }, args)
      } else {
        const intervenerType = (actionClassificationTypes as any)[this.selectedRegister.alias]
        let routeData
        if (this.selectedRegister.alias === ActionRelationTypes.EXPEDIENT) {
          const expedients = this.$t('routes.expedients').toString()
          routeData = this.$router.resolve({
            name: `${expedients}-${expedients}`,
            params: { selectedRegisterId: this.selectedRegister.idRelation }
          })
        } else {
          const subdirectory0 = this.$t('routes.contacts').toString()
          const subdirectory1 = this.$t('routes.' + intervenerType).toString()
          routeData = this.$router.resolve({
            name: `${subdirectory0}-${subdirectory1}`,
            params: { selectedRegisterId: this.selectedRegister.idRelation }
          })
        }
        window.open(routeData.href, target)
      }
    } else if (this.redirectOnEdit && this.title === 'Archivos') {
      this.goToDocument()
    } else if (this.redirectOnEdit && this.title === 'Facturación' && !this.relatedForm) {
      const subdirectory1 = URLS.BILLING
      let subdirectory2 = ''
      let idInvoice = this.selectedRegister.idInvoice

      switch (this.selectedRegister.type) {
        case 'Fras. Cliente':
        case 'Factura cliente':
          subdirectory2 = URLS.CUSTOMER_INVOICES
          idInvoice = this.selectedRegister.idInvoice || this.selectedRegister.idCustomerInvoice
          break
        case 'Fras. Proveedor':
        case 'Factura proveedor':
          subdirectory2 = URLS.PROVIDER_INVOICES
          idInvoice = this.selectedRegister.idInvoice || this.selectedRegister.idProviderInvoice
          break
        case 'Fras. Proforma':
        case 'Factura proforma':
          subdirectory2 = URLS.PROFORMA_INVOICES
          idInvoice = this.selectedRegister.idInvoice || this.selectedRegister.idProformaInvoice
          break
        case 'Provisiones':
        case 'Provisión':
          subdirectory2 = URLS.PROVISIONS
          idInvoice = this.selectedRegister.idInvoice || this.selectedRegister.id
          break
      }

      const routeData = this.$router.resolve({
        name: `${subdirectory1}-${subdirectory2}`,
        params: { selectedRegisterId: idInvoice }
      })
      if (target === '_self') {
        // TODO TIPAR BIEN
        ;(this as any).$router.push(routeData.resolved)
      } else {
        window.open(routeData.href, target)
      }
    } else if (this.redirectOnEdit && this.title === 'Contactos' && !this.relatedForm) {
      const subdirectory1 = this.$t('routes.contacts').toString()
      let subdirectory2 = ''
      Object.entries(entity.contacts).forEach((item: any) => {
        if (item[1].type === this.selectedRegister.idEntity) {
          subdirectory2 = this.$t('routes.' + item[1].alias).toString()
        }
      })
      const routeData = this.$router.resolve({
        name: `${subdirectory1}-${subdirectory2}`,
        params: { selectedRegisterId: this.selectedRegister.id }
      })
      if (target === '_self') {
        // TODO TIPAR BIEN
        ;(this as any).$router.push(routeData.resolved)
      } else {
        window.open(routeData.href, target)
      }
    } else if (this.redirectOnEdit && this.title === 'Actuaciones' && !this.relatedForm) {
      switch (this.selectedRegister.type) {
        case 'Correo Electrónico':
          let url = ''
          if (this.companyMailVersion === 1) {
            url = `emails?id=${this.selectedRegister.actionId}&returnURL=${window.location.origin}${this.$route.path}`
            window.open(`${process.env.VUE_APP_URL}/${url}`, target)
          } else {
            if (target === '_self') {
              const routeData = this.$router.resolve({
                name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
                params: { selectedRegisterId: this.selectedRegister.actionId }
              })
              // TODO TIPAR BIEN
              ;(this as any).$router.push(routeData.resolved)
            } else {
              url = `/${URLS.EMAIL}/edit/${this.selectedRegister.actionId}`
              window.open(`${url}`, target)
            }
          }
          break
        default:
          const subdirectory1 = this.$t('routes.actions').toString()
          let subdirectory2 = ''
          Object.entries(entity.actions).forEach((item: any) => {
            if (item[1].type === this.selectedRegister.idEntity) {
              subdirectory2 = this.$t('routes.' + item[1].alias).toString()
            }
          })
          const routeData = this.$router.resolve({
            name: `${subdirectory1}-${subdirectory2}`,
            params: { selectedRegisterId: this.selectedRegister.actionId }
          })

          if (target === '_self') {
            // TODO TIPAR BIEN
            ;(this as any).$router.push(routeData.resolved)
          } else {
            window.open(routeData.href, target)
          }
          break
      }
    } else if (
      this.title === 'Fras. Cliente' ||
      this.title === 'Factura cliente' ||
      this.title === 'Fras. Proforma' ||
      this.title === 'Factura proforma' ||
      this.title === 'Provisiones' ||
      this.title === 'Fras. Proveedor' ||
      this.title === 'Factura proveedor' ||
      this.title === 'Cobros' ||
      this.title === 'Pagos'
    ) {
      let entityType = ''
      switch (this.title) {
        case 'Fras. Cliente':
        case 'Factura cliente':
          entityType = URLS.CUSTOMER_INVOICES
          break
        case 'Fras. Proforma':
        case 'Factura proforma':
          entityType = URLS.PROFORMA_INVOICES
          break
        case 'Fras. Proveedor':
        case 'Factura proveedor':
          entityType = URLS.PROVIDER_INVOICES
          break
        case 'Provisiones':
          entityType = URLS.PROVISIONS
          break
        case 'Cobros':
          entityType = URLS.CUSTOMER_BAKDRAFTS
          break
        case 'Pagos':
          entityType = URLS.PROVIDER_BANKDRAFTS
          break
      }

      const routeData = this.$router.resolve({
        name: `${URLS.BILLING}-${entityType}`,
        params: { selectedRegisterId: this.selectedRegister.id }
      })

      if (target === '_self') {
        // TODO TIPAR BIEN
        ;(this as any).$router.push(routeData.resolved)
      } else {
        window.open(routeData.href, target)
      }
    } else if (this.title === 'Facturas' || this.title === 'Cobros/Pagos') {
      let entityType = ''
      switch (this.selectedRegister.idEntity) {
        case InvoiceEntityId.CUSTOMER_INVOICES:
          entityType = URLS.CUSTOMER_INVOICES
          break
        case InvoiceEntityId.PROVIDER_INVOICES:
          entityType = URLS.PROVIDER_INVOICES
          break
        case InvoiceEntityId.PROFORMA_INVOICES:
          entityType = URLS.PROFORMA_INVOICES
          break
        case InvoiceEntityId.CUSTOMER_BAKDRAFTS:
          entityType = URLS.CUSTOMER_BAKDRAFTS
          break
        case InvoiceEntityId.PROVIDER_BANKDRAFTS:
          entityType = URLS.PROVIDER_BANKDRAFTS
          break
      }
      const routeData = this.$router.resolve({
        name: `${URLS.BILLING}-${entityType}`,
        params: { selectedRegisterId: this.selectedRegister.idInvoice }
      })

      if (target === '_self') {
        ;(this as any).$router.push(routeData.resolved)
      } else {
        window.open(routeData.href, target)
      }
    } else {
      this.$emit('simpleGridActionEdit', this.selectedRegister, { principal: true }, args)
    }
  }

  async resizeStop() {
    this.$nextTick(() => {
      this.hideScroll()
    })
  }

  contextMenuClickedItem(args: any) {
    if (args.item) {
      switch (args.item.text) {
        case this.$t('components.context_menu.edit'):
        case this.$t('components.context_menu.look'):
          this.redirectTemporaryBehaviour(args)
          break
        case this.$t('components.context_menu.remove'):
          this.$emit('simpleGridActionRemove', this.selectedRegister)
          break
        case this.$t('components.context_menu.open_window'):
          this.redirectTemporaryBehaviour(args, '_blank')
          break
        case this.$t('components.context_menu.edit_intervener'):
          openContact(this.selectedRegister.id)
          break
        case this.$t('components.context_menu.download'):
          this.downloadFile(this.selectedRegister)
          break
        case this.$t('components.context_menu.see'):
          this.openFile(this.selectedRegister)
          break
        case this.$t('components.context_menu.go_to'):
          this.goToDocument()
          break
        case this.$t('components.context_menu.add_partialbankdraft'):
          this.$emit('simpleGridActionPartialbankdraftAdd')
          break
      }
    }
    this.$emit('simpleGridContextMenuClicked', args)
  }

  async goToDocument() {
    const pathInfo: DocumentPathInfo = await this.fetchDocumentPathInfoAction(this.selectedRegister.id)
    const route = goToDocumentRoute(this.selectedRegister, pathInfo)
    window.open(route, '_blank')
  }

  excelExport(): void {
    const grid = this.gridElement
    const excelExportProperties = {
      fileName: `${this.title}.xlsx`
    }
    this.showActionsColumn(this.showFirstColumn)
    grid.excelExport(excelExportProperties)
  }

  clickHandler(args: any) {
    const target = args.originalEvent.target.closest('button')
    // TODO mirar el contexto
    switch (target && target.id) {
      case 'export':
        return this.excelExport()
      case 'print':
        return this.exportToPDF()
    }
  }

  openFile(document: any) {
    this.processDocument(document)
  }

  downloadFile(document: any) {
    this.processDocument(document, true)
  }

  processDocument(document: any, download: boolean = false) {
    const file: OpenMSOfficeFileData = {
      id: document.id,
      name: document.name,
      originalFileName: document.initialFile
    }

    const openFileComponent: any = this.$refs.openFileComponent
    if (openFileComponent) {
      if (download) {
        openFileComponent.downloadFile(file)
      } else {
        openFileComponent.openFileWithOffice(file)
      }
    }
  }
}
</script>
