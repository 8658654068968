<template lang="pug">

section(class="custom-permissions-container")

  div(
    v-if="componentIsRendered"
    class="custom-permissions"
  )

    fieldset(
      class="fieldset"
    )
      legend(class="fieldset-legend") {{ texts.legend }}

      div(class="permissions-container")

        //- FIRST ROW
        div(class="selected-permission-info-row")

          LexonSwitchComponent(
            v-model="componentData.allSelected"
            id="all-selected"
            :label="texts.allPermissionsSelected"
            :returnBoolean="true"
            class="lexon-switch"
            @emit-click-event="toggleSelectAllPermissions"
          )

          //- PERMISSION TYPE HEADERS
          div(class="permission-types-container")
            div(v-for="(item, index) in permissionTypeHeaders" class="info-text" :key="index")
              span(:class="[item.icon, 'type-icon']")
              span {{ item.text }}

        //- PERMISSIONS
        v-expansion-panels(multiple)
          v-expansion-panel(
              v-for="permission in componentData.permissions"
              :key="permission.idEntity"
            )
            v-expansion-panel-header
              div(class="permission-row")
                div(class="switch-container")
                  LexonSwitchComponent(
                    v-model="permission.activated"
                    id="all-selected"
                    :label="permission.label"
                    :returnBoolean="true"
                    class="lexon-switch"
                    @click.native.stop="renderCheckboxesStatus(permission.activated, permission.idEntity)"
                    @emit-click-event="checkIfAllPermissionsAreActivated"
                  )

                  //- SEE MORE
                  div(
                      v-if="permission.subEntities"
                      @click="toggleOpenAccordion(permission.idEntity)"
                      class="see-more-container"
                    )
                    div(class="see-more-text") {{ renderSeeMoreText(permission.subEntities.panelIsOpened) }}
                    span(:class="[renderSeeMoreIcon(permission.subEntities.panelIsOpened), 'see-more-icon']")

                //- CHECKBOXES
                div(:key="componentKey" class="checkboxes-container")
                  CheckboxComponent(
                    v-for="(checkboxValue, type) in permission.type"
                    :key="`${permission.label}-${type}`"
                    class="checkbox-component"
                    :id="`${permission.label}-${type}`"
                    :value="checkboxValue"
                    :disabled="checkIfDisableCheckbox(permission, type)"
                    @click.native.stop="checkboxesLogic(permission.idEntity, type, checkboxValue)"
                  )

            v-expansion-panel-content(v-if="permission.subEntities")
              LexonAutocompleteComponent(
                :label="permission.subEntities.autocompleteLabel"
                :propItems="permission.subEntities.items"
                multiple
                chips
                :addNew="false"
                :showSelectAll="true"
                @input="selectAutocompleteItems($event, permission.idEntity)"
                :value="permission.subEntities.selectedItems"
                itemName="name"
                itemValue="idEntity"
              )

  SpinnerLayerComponent(v-else class="spinner-layer")

</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import {
  entitiesWithDisableCheckbox,
  UsersMaintenanceCustomPermissionsComponentCheckboxType as CheckboxType,
  UsersMaintenanceCustomPermissionsComponentData as ComponentData,
  UsersMaintenanceCustomPermissionsComponentPermission as Permission,
  UsersMaintenanceCustomPermissionsComponentSubEntity as SubEntity
} from '../types/UsersMaintenanceComponentTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import CheckboxComponent from '@/components/Checkbox/CheckboxComponent.vue'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'
import { arrayIsEquals } from '@/helpers/array'
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const usersModule: string = ModuleNamespaces.USERS

@Component({
  components: {
    CheckboxComponent,
    LexonSwitchComponent,
    LexonAutocompleteComponent,
    SpinnerLayerComponent
  }
})
export default class UsersMaintenanceCustomPermissionsComponent extends Vue {
  @Action('fetchPermissions', { namespace: usersModule })
  fetchPermissions: (id: any) => any

  @Getter('getPermissions', { namespace: usersModule })
  getPermissions: ComponentData

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}

  texts = {
    allPermissionsSelected: this.$t('components.users_maintenance_custom_permissions.all_permissions_selected'),
    edit: this.$t('components.users_maintenance_custom_permissions.edit'),
    legend: this.$t('components.users_maintenance_custom_permissions.legend'),
    remove: this.$t('components.users_maintenance_custom_permissions.remove'),
    see: this.$t('components.users_maintenance_custom_permissions.see'),
    seeLess: this.$t('components.users_maintenance_custom_permissions.see_less'),
    seeMore: this.$t('components.users_maintenance_custom_permissions.see_more')
  }

  componentKey = 0

  permissionTypeHeaders: Array<{ icon: string; text: TranslateResult }> = [
    {
      icon: Icons.PENCIL,
      text: this.texts.edit
    },
    {
      icon: Icons.REMOVE,
      text: this.texts.remove
    },
    {
      icon: Icons.VISIBLE,
      text: this.texts.see
    }
  ]

  get componentData(): ComponentData {
    return this.getPermissions
  }

  get selectedGridUserId(): string {
    return (this as any).selectedRegisterData(ContextName.MAINTENANCE_USERS_CONFIG).id
  }

  get componentIsRendered() {
    return this.componentData && Object.keys(this.componentData).length
  }

  created() {
    if (!this.componentIsRendered) {
      this.fetch()
    }
  }

  async fetch() {
    await this.fetchPermissions(this.selectedGridUserId)
    this.checkIfAllCheckboxesAreSelectedAndAllPermissionsAreActivated()
  }

  findSelectedItem(idEntity: number) {
    return this.componentData.permissions.find((item: Permission) => item.idEntity === idEntity)
  }

  toggleSelectAllPermissions(value: boolean) {
    this.componentData.permissions.forEach((permission: Permission) => {
      permission.activated = value

      const checkboxes = permission.type as unknown as { [key: string]: boolean }

      Object.keys(checkboxes).forEach((key) => {
        checkboxes[key] = value
      })
    })
  }

  checkIfAllPermissionsAreActivated(): void {
    const allActivated = this.componentData.permissions.every((item) => item.activated === true)
    this.componentData.allSelected = allActivated
  }

  checkIfAllCheckboxesAreSelected(): void {
    const allCheckboxesAreSelected = this.componentData.permissions.every(
      (item) => item.type.edit === true && item.type.remove === true && item.type.see === true
    )
    this.componentData.allSelected = allCheckboxesAreSelected
  }

  checkIfAllCheckboxesAreSelectedAndAllPermissionsAreActivated(): void {
    const allActivated = this.componentData.permissions.every((item) => item.activated === true)
    const allCheckboxesAreSelected = this.componentData.permissions.every(
      (item) => item.type.edit === true && item.type.remove === true && item.type.see === true
    )
    const firstSwitchStatus = allActivated && allCheckboxesAreSelected
    this.componentData.allSelected = firstSwitchStatus
  }

  toggleOpenAccordion(idEntity: number) {
    const selectedItem = this.findSelectedItem(idEntity)
    if (selectedItem && selectedItem.subEntities) {
      selectedItem.subEntities.panelIsOpened = !selectedItem.subEntities.panelIsOpened
    }
  }

  renderCheckboxesStatus(checkboxStatus: boolean, idEntity: number) {
    const selectedItem = this.findSelectedItem(idEntity)

    if (selectedItem) {
      const checkboxes = selectedItem.type as unknown as { [key: string]: boolean }

      Object.keys(checkboxes).forEach((key) => {
        checkboxes[key] = this.isChecked(checkboxStatus)
      })
    }
  }

  checkboxesLogic(idEntity: number, type: string, checkboxValue: boolean) {
    const selectedItem = this.findSelectedItem(idEntity)

    if (selectedItem) {
      const checkboxes = selectedItem.type as unknown as { [key: string]: boolean }
      checkboxes[type] = !checkboxValue

      // Deselecciona el switch correspondiente si todos los checkboxes estás desmarcados
      const allCheckboxesUnselected = Object.values(checkboxes).every((value) => value === false)
      if (allCheckboxesUnselected) {
        selectedItem.activated = false
      }

      // Si se selecciona el checkbox de editar o de eliminar, se selecciona el de ver tambiém
      if ((type === CheckboxType.EDIT || type === CheckboxType.REMOVE) && !this.isChecked(checkboxValue)) {
        checkboxes[CheckboxType.SEE] = true
      }

      // Comprobamos si hay que marcar el switch principal de "TODOS LOS PERMISOS"
      this.checkIfAllCheckboxesAreSelected()
    }

    this.componentKey += 1
  }

  checkIfDisableCheckbox(permission: Permission, type: string): boolean {
    const { activated, type: checkboxes, idEntity } = permission

    if (entitiesWithDisableCheckbox.includes(idEntity)) {
      return true
    }

    if (!this.isChecked(activated)) {
      return true
    }

    return type === CheckboxType.SEE && (checkboxes.edit || checkboxes.remove)
  }

  selectAutocompleteItems(selectedSubEntities: SubEntity[] | number [], idEntity: number) {
    const idSubEntities: number[] = [];
    selectedSubEntities.forEach((item:any) => {
      if (typeof item === 'number') {
        idSubEntities.push(item);
      } else {
        idSubEntities.push(item.idEntity);
      }
    });
    const selectedItem = this.findSelectedItem(idEntity)
    if (selectedItem && selectedItem.subEntities && !arrayIsEquals(selectedItem.subEntities.selectedItems, idSubEntities)) {
      selectedItem.subEntities.selectedItems = idSubEntities
    }
  }

  renderSeeMoreText(isOpened: boolean) {
    const { seeLess, seeMore } = this.texts
    return isOpened ? seeLess : seeMore
  }

  renderSeeMoreIcon(isOpened: boolean) {
    return isOpened ? Icons.ANGLE_UP : Icons.ANGLE_DOWN
  }

  isChecked(checkboxValue: any): boolean {
    return !!Number(checkboxValue)
  }
}
</script>

<style lang="scss" scoped>
.custom-permissions-container {
  @include flex;

  .spinner-layer {
    height: 180px;
  }
}

.custom-permissions {
  width: auto;
  max-width: 912px;
  margin: 10px;

  .v-expansion-panel {
    &::before {
      display: none;
    }

    &::after {
      border-color: $corporate-color !important;
      border-top-style: dashed;
    }

    .v-expansion-panel-header {
      pointer-events: none;
      padding: 0;

      &::before {
        display: none;
      }

      .see-more-container,
      .lexon-switch,
      .checkbox-component:not(.disabled) {
        pointer-events: all;
      }

      ::v-deep .v-expansion-panel-header__icon {
        display: none;
      }
    }

    ::v-deep .v-expansion-panel-content__wrap {
      padding: 0 24px 16px 0;
    }

    &.v-expansion-panel--active:not(:first-child)::after,
    &.v-expansion-panel--active + .v-expansion-panel::after {
      opacity: 1;
    }
  }

  .fieldset {
    @include borders($color: $blue-04);
    padding: 0 10px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;

    .fieldset-legend {
      font-family: $corporate-font-medium;
      line-height: 16px;
      font-size: 12px;
      color: $blue-04;
      text-transform: uppercase;
      padding: 2.5px 4px 0;
      margin-left: 4px;
    }

    .lexon-switch {
      ::v-deep label {
        text-transform: uppercase;
      }
    }

    .permissions-container {
      width: 100%;
      padding: 0 18px;

      .selected-permission-info-row {
        @include flex($justify-content: space-between);
        @include border($width: 2px);
        width: 100%;

        .permission-types-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          width: 40%;

          .info-text {
            @include flex;
            color: $corporate-color;
            font-size: 14px;
            font-family: $corporate-font-bold;
            text-transform: uppercase;

            .type-icon {
              display: none;
              font-size: 20px;
              margin-right: 5px;
            }
          }
        }
      }

      .permission-row {
        @include flex;
        width: 100%;

        .switch-container {
          @include flex($justify-content: space-between);
          width: 60%;

          .see-more-container {
            @include flex;
            color: $corporate-color;
            margin-right: 7%;
            cursor: pointer;

            .see-more-text {
              font-family: $corporate-font-bold;
              font-size: 14px;
              margin-right: 8px;
            }

            .see-more-icon {
              font-size: 20px;
            }
          }
        }

        .checkboxes-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          width: 40%;
          text-align: center;

          ::v-deep .checkbox:checked + label::after {
            font-size: 18px;
          }

          ::v-deep label {
            font-size: 18px;

            &::before {
              margin-right: 0;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@include desktopAndMore {
  .custom-permissions {
    margin: 50px;

    .type-icon {
      display: block !important;
    }
  }
}

::v-deep .lf-autocomplete-container {
  div.v-list-item__action {
    & > .v-simple-checkbox {
      padding-bottom: 0px !important;
    }
  }
}
</style>
