<template lang="pug">

ValidationProvider(
  ref="currencyValidator"
  :rules="validationRules"
  :name="validatorName"
  :vid="validatorName"
  immediate
  v-slot="{ errors }"
  tag="div"
)
  div(
    :class="[{ 'error-styles': errors[0] }, { 'disabled-field': disabled }, 'lf-currency-container']"
  )
    div(class="label-container")
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ label }}
      span(
        v-if="showInfo"
        :class="[icons.info, 'label-icon']"
      )
    ejs-numerictextbox(
      ref="numericTextBox"
      :id="id"
      :name="name"
      v-model="innerValue"
      :showSpinButton="false"
      :showClearButton="true"
      :placeholder="placeholder"
      :decimals="decimals"
      :min="minValue"
      :max="maxValue"
      :format="numberOfDecimalPlaces"
      :validateDecimalOnType="true"
      :class="[{ 'error-styles': errors[0] }, 'lf-currency-input']"
      :change="onChange"
      :created="onTextBoxCreated"
      :destroyed="onTextBoxDestroyed"
    )

    div(class="messages-container")
      span(v-if="errors.length" class="alert-message") {{ errors[0] }}
      span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs'
import { ValidationProvider } from 'vee-validate'
import { Icons } from '@/icons/icons'
import { calculateNumberOfDecimalPlaces } from '@/helpers/numeric'
import NumericTextBoxClearButtonMixin from '@/mixins/NumericTextBoxClearButtonMixin.vue'

Vue.use(NumericTextBoxPlugin)

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonCurrencyComponent extends mixins(NumericTextBoxClearButtonMixin) {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showInfo!: boolean

  @Prop({
    type: [Number, String]
  })
  value!: number

  @Prop({
    type: String
  })
  id!: string

  @Prop({
    type: String
  })
  name!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: String
  })
  validatorName!: string

  @Prop({
    type: Number,
    default: null
  })
  min!: number

  @Prop({
    type: Number,
    default: null
  })
  max!: number

  @Prop({
    type: Number,
    default: 6
  })
  decimals!: number

  $refs!: {
    currencyValidator: InstanceType<typeof ValidationProvider>
  }

  icons = {
    info: Icons.INFO_FULL
  }

  innerValue: any = null

  decimalPlaces: number | null = null

  @Watch('innerValue')
  changedInnerValue(newVal: string) {
    this.validateField()
    this.emitValue(newVal)
  }

  @Watch('value')
  changedValue(value: string) {
    this.innerValue = value
  }

  @Emit()
  emitValue(value: any) {
    return this.$emit('input', value)
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }

  get numberOfDecimalPlaces() {
    return `n${this.decimalPlaces}`
  }

  get minValue() {
    return this.min ? this.min : -999999999
  }

  get maxValue() {
    return this.max ? this.max : 999999999
  }

  created() {
    if (this.value) {
      this.decimalPlaces = calculateNumberOfDecimalPlaces(this.value)
      this.innerValue = this.value
    }
  }

  validateField() {
    if (this.$refs.currencyValidator) {
      return this.$refs.currencyValidator.validate()
    }
  }

  onChange() {
    this.decimalPlaces = calculateNumberOfDecimalPlaces(this.innerValue)
  }
}
</script>

<style lang="scss" scoped>
.lf-currency-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  width: 100%;

  .label-container {
    @include flex;
    height: 22px;
    color: $corporate-color;

    .label-text {
      @include label-text;
      position: relative;
    }

    .label-icon {
      font-size: 15px;
    }
  }

  ::v-deep .e-control-wrapper.e-numeric.e-input-group {
    @include textfield-styles;
    position: relative;
    margin-bottom: 0;
    color: $neutral-grey-800 !important;
    border-color: $neutral-grey-400 !important;
    padding-left: 42px;

    &::before {
      font-family: $lf-icons-font;
      font-size: $icon-size;
      content: '\e939';
      position: absolute;
      left: 8px;
      top: 9px;
      color: $main-1000;
    }

    &::after {
      display: block;
      width: 1px;
      height: 20px;
      content: ' ';
      position: absolute;
      left: 32px;
      top: 8px;
      background: $neutral-grey-400;
    }

    &:hover {
      background-color: $main-050 !important;
    }

    .lf-currency-input {
      width: 100%;

      &::selection {
        background-color: $selection-color !important;
        color: $gray-01 !important;
      }

      &::placeholder {
        color: $neutral-grey-600;
      }
    }

    &.e-input-focus {
      border: 1px solid $main-1000 !important;

      &::before {
        width: 1px;
        background-color: $neutral-white-000;
      }

      &::after {
        width: 1px;
      }

      &:hover {
        background-color: $neutral-white-000 !important;
      }
    }

    .e-clear-icon {
      position: relative;
      top: 9px;
      right: 5px;
      width: $icon-size;
      height: $icon-size;
    }

    .e-clear-icon::before {
      font-family: $lf-icons-font;
      font-size: 14px;
      color: $corporate-color;
      content: '\e938';
      margin-right: 22px;
    }
  }

  &.disabled-field {
    pointer-events: none;

    .label-container .label-text {
      color: $disabled-color;

      &::after {
        color: $disabled-color;
      }
    }

    ::v-deep .e-input-group.e-control-wrapper.e-numeric {
      border-color: $gray-03 !important;
      background-color: $gray-04;

      .lf-currency-input {
        color: $gray-01;
      }

      .lf-currency-input::placeholder {
        color: $disabled-color;
      }

      &::after {
        color: $disabled-color;
      }

      &:hover {
        border-color: $error-color !important;
        background-color: $red-07;
      }
    }

    .help-message {
      color: $disabled-color;
    }
  }

  &.error-styles {
    ::v-deep .e-input-group.e-control-wrapper.e-numeric {
      border-color: $error-color !important;

      .e-clear-icon::before {
        color: $error-color;
      }

      .lf-currency-input {
        color: $error-color;
      }

      .lf-currency-input::placeholder {
        font-size: 12px;
        color: $error-color;
      }

      &::after {
        color: $error-color;
      }

      &:hover {
        border-color: $error-color !important;
        background-color: $red-07;
      }
    }
  }
}
</style>
