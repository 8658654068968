<template lang="pug">

v-app-bar(
  clipped-left
  app
  height="calculateHeight"
  flat
  fixed
  class="app-bar"
)

  div(class="tablet-header" v-if="checkIfIsTablet")
    router-link(class="logo-container" :to="{ name: logoRouteName }")
      img(:src="renderedLogo" alt="Logo LexON" class="logo")

    AppBarToolbarItemsComponent

  div(class="app-bar-content")

    router-link(v-if="!checkIfIsTablet" class="logo-container" :to="{ name: logoRouteName }")
      img(:src="renderedLogo" alt="Logo LexON" class="logo")

    //- div(class="flex-grow-1")
    v-spacer

    div(v-security.view="securityCapacities.mainSearch" class="search-section")
      LexonSearchFieldComponent(
        @searchTerm="onSearchTerm"
        v-security.view="securityCapacities.mainSearch"
      )
      //- LexonChronometerComponent(
      //-   v-if="userHasPermissionToSaveAtLeastOneActionType"
      //-   v-security.view="securityCapacities.chronometer"
      //- )

    AppBarToolbarItemsComponent

</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { URLS } from '@/router/routes/urlRoutes'
import AppBarToolbarItemsComponent from '@/components/Navigation/AppBarToolbarItems/AppBarToolbarItemsComponent.vue'
import LexonChronometerComponent from '@/components/LexonChronometer/LexonChronometerComponent.vue'
import LexonSearchFieldComponent from '@/components/LexonSearchField/LexonSearchFieldComponent.vue'
import { Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SearchTab } from '@/store/modules/tabs/tabsTypes'
import { SecurityCapacities } from '@/store/modules/security/securityTypes'
import { UserType } from '@/store/modules/auth/authTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
// import { MENU_MINI_STATUS_MIN_WIDTH, MENU_MINI_STATUS_MAX_WIDTH } from '@/components/Navigation/NavigationConsts'

const tabsModule = ModuleNamespaces.TABS
const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    AppBarToolbarItemsComponent,
    LexonChronometerComponent,
    LexonSearchFieldComponent
  }
})
export default class AppBarComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: Number
  })
  windowWidth!: number

  @Mutation('SET_CURRENT_SEARCH_TAB_INFO', { namespace: tabsModule }) setCurrentTabInfo: ({}) => void

  @Getter('getCurrentSearchTabInfo', { namespace: tabsModule }) searchViewTabInfo: SearchTab
  @Getter('getUserType', { namespace: authModule }) userType: UserType
  @Getter('getIsLexonDmsUser', { namespace: authModule }) getIsLexonDmsUserGetter: boolean
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean

  securityCapacities = {
    mainSearch: SecurityCapacities.MAIN_SEARCH,
    chronometer: SecurityCapacities.CHRONOMETER
  }

  get logoRouteName(): string {
    return this.isPortalUser ? URLS.EXPEDIENTS : URLS.DASHBOARD
  }

  get checkIfIsTablet(): boolean {
    return this.windowWidth <= 768
  }

  get calculateHeight(): number {
    return this.checkIfIsTablet ? 132 : 76
  }

  get renderedLogo() {
    const renderedImage = this.getIsLexonDmsUserGetter ? 'lefebvre-tandem-156x50.png' : 'lefebvre-lexon-145x50.png'
    return require(`@/assets/images/logos/${renderedImage}`)
  }

  onSearchTerm(searchTerm: string) {
    trackEvent(TrackerEvents.SEARCH)
    this.setCurrentTabInfo({ name: '', index: 0 })
    this.$router.push({
      name: URLS.SEARCH,
      params: {
        searchTerm
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.app-bar {
  // display: flex;
  // width: 100%;
  // height: $app-bar-height;
  background-color: $neutral-white-000 !important;
  // justify-content: space-between;
  // align-items: center;
  // flex-shrink: 0;
  // padding: $spacing-sm $spacing-xl;

  &.v-app-bar.v-app-bar--fixed {
    z-index: 9;
  }

  ::v-deep .v-toolbar__content {
    // @include border($direction: bottom);
    width: 100%;
    box-shadow: $shadow-md;
    padding: 0;
  }

  .tablet-header {
    display: flex;
    justify-content: space-between;
    height: calc(#{$app-bar-height} - 20px);
    margin-left: 4px;
  }

  .app-bar-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: $app-bar-height;
    background-color: $neutral-white-000;
    padding: $spacing-sm $spacing-xl;
  }

  // .icon-style {
  //   @include icon-style;
  // }

  .logo-container {
    // height: $app-bar-height;
    height: 38px;

    .logo {
      height: 38px;
    }
  }

  .search-section {
    @include flex($justify-content: flex-end);
    // @include borders($color: $corporate-color);
    // width: 100%;
    max-width: 660px;
    // height: $app-bar-height;
    color: $corporate-color;
    // padding: 0 25px;
    // border-top: none;
    // border-bottom: none;
  }
}

@include untilTablet {
  .app-bar {
    ::v-deep .v-toolbar__content {
      display: block;
      border: none;
    }

    // .search-section {
    //   padding: 0;
    //   border: none;
    // }
  }
}

@include tabletAndMore {
  .app-bar {
    .drawer-trigger {
      padding-left: 17px;
    }

    // .logo-container {
    //   margin-right: 20px;
    // }

    // .search-section {
    //   width: auto;
    // }
  }
}

@include desktopLarge {
  .app-bar {
    .other-links {
      margin: 0 17px 0 40px;
    }
  }
}

@include desktopExtraLarge {
  .app-bar {
    // .search-section {
    //   margin-right: 5%;
    // }
  }
}
</style>
