export const findAndAssignField = (schema: any, fieldId: any, value: any) => {
  const field = schema.find((item: any) => item.id === fieldId)
  if (field) {
    field.filteredValue = value
    field.disabled = value == null // == para comprobar tanto undefined como null
  }
  return field
}

export const updateDisabledFields = (formFields:any, formData:any, fieldsToValidate: any, forceDisable:boolean = false) => {
  formFields.forEach((field: any) => {
    if (fieldsToValidate.includes(field.id)) {
      if (field.disabled && formData[field.id] != null) {
        field.disabled = false;
      }
      if (forceDisable) {
        field.disabled = true;
      }
    }
  });
  return formFields
}

