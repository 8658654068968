<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { Action as ActionInterface } from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const dialogModule: string = ModuleNamespaces.DIALOG

@Component
export default class RemoveActionMixin extends Vue {
  @Action('removeAction', { namespace: actionsModule })
  removeAction: (action: ActionInterface) => Promise<{ success: boolean; message: string }>

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  getRemoveSuccessMessageFromAction(action: ActionInterface) {
    if (action.appointmentId && action.childCount) {
      return this.$t('components.dialog.action_remove_success.with_event_and_sub_actions', { subject: action.subject })
    } else if (action.appointmentId) {
      return this.$t('components.dialog.action_remove_success.with_event', { subject: action.subject })
    } else if (action.childCount) {
      return this.$t('components.dialog.action_remove_success.with_sub_actions', { subject: action.subject })
    } else {
      return this.$t('components.dialog.action_remove_success.without_event_and_sub_actions', {
        subject: action.subject
      })
    }
  }

  getRemoveConfirmMessageFromAction(action: ActionInterface) {
    if (action.appointmentId && action.childCount) {
      return this.$t('components.dialog.action_remove_confirm.with_event_and_sub_actions', { subject: action.subject })
    } else if (action.appointmentId) {
      return this.$t('components.dialog.action_remove_confirm.with_event', { subject: action.subject })
    } else if (action.childCount) {
      return this.$t('components.dialog.action_remove_confirm.with_sub_actions', { subject: action.subject })
    } else {
      return this.$t('components.dialog.action_remove_confirm.without_event_and_sub_actions', {
        subject: action.subject
      })
    }
  }

  async removingAction(action: ActionInterface) {
    const { success, message } = await this.removeAction(action)
    if (success) {
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message: this.getRemoveSuccessMessageFromAction(action)
      })
      return
    }
    this.showDialog({
      type: DialogTypes.ERROR,
      message
    })
  }
}
</script>
