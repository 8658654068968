<template lang="pug">

div(class="email-template-customer-portal-tab-container" v-if="!showEditTemplate")
  LfHeaderTitle(:title="headerTitle" class="header-title")

  GridTable(
    v-if="Object.keys(listConfiguration).length"
    :itemsData="listItems"
    :gridConfiguration="listConfiguration['Config']"
    :title="$t('navigation.drawer-menu-items.email_templates')"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="contextMenu"
    :listName="listConfiguration['Alias']"
    :context="context"
    :showHeader="false"
    isInTab
    :showColumnChooser="false"
    @contextMenuClicked="onContextMenuClicked"
    @cellClicked="onCellClicked"
  )

  SpinnerLayerComponent(v-else class="spinner-layer")

EditTemplateComponent(
  v-else
  :template="selectedTemplate"
  @on-close-form="showGrid"
)
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ConfigurationAlias, ListNames } from '@/store/modules/configuration/configurationTypes'
import { Icons } from '@/icons/icons'
import EditTemplateComponent from './EditTemplateComponent.vue'
import { Action } from 'vuex-class'
import { EmailTemplate } from '@/components/maintenance/customerPortal/types/maintenanceCustomerPortalTypes'
import {
  kebakMenuToolbarItem,
  searchToolbarItem,
  filterToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import LfHeaderTitle from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'

const maintenanceModule = ModuleNamespaces.MAINTENANCE

@Component({
  components: {
    GridTable,
    SpinnerLayerComponent,
    EditTemplateComponent,
    LfHeaderTitle
  }
})
export default class EmailTemplatesCustomerPortalTabComponent extends mixins(ListViewMixin) {
  @Prop({
    type: String
  })
  headerTitle!: string

  @Action('fetchMaintenanceType', { namespace: maintenanceModule })
  fetchTemplatesAction: ({}) => Promise<EmailTemplate[]>

  showEditTemplate = false

  selectedTemplate: EmailTemplate = { id: '', name: '', subject: '' }

  get toolbarOptionsCustom() {
    return [searchToolbarItem, filterToolbarItem, kebakMenuToolbarItem]
  }

  get contextMenu() {
    const { editItem } = this.contextMenuOptionsTexts
    return [
      {
        text: editItem,
        iconCss: Icons.EDIT
      }
    ]
  }

  get context() {
    return ContextName.MAINTENANCE
  }

  created() {
    this.init()
  }

  async init() {
    await this.fetchTemplatesAction({ maintenanceType: ConfigurationAlias.MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES })
    await this.fetchCurrentListConfiguration(ListNames.CUSTOMER_PORTAL_TEMPLATES_MAINTENANCE)
  }

  openEmailTemplate(selectedRegister: EmailTemplate) {
    this.selectedTemplate = selectedRegister
    this.showEditTemplate = true
  }

  onContextMenuClicked(_args: any, selectedRegister: EmailTemplate) {
    this.openEmailTemplate(selectedRegister)
  }

  async showGrid() {
    await this.fetchTemplatesAction({ maintenanceType: ConfigurationAlias.MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES })
    this.showEditTemplate = false
  }

  async onCellClicked(selectedRegister: EmailTemplate) {
    this.openEmailTemplate(selectedRegister)
  }
}
</script>

<style lang="scss" scoped>
.email-template-customer-portal-tab-container {
  .spinner-layer {
    height: 200px;
  }

  ::v-deep .e-toolbar-right .e-toolbar-item:last-child {
    border-radius: 0 50% 50% 0 !important;
    padding: 0;
  }
}
</style>
