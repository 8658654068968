import { getCurrentInstance } from 'vue'

export const useAttrs = () => {
  const instance = getCurrentInstance()

  const attrsValue = instance?.proxy?.$attrs.value

  return {
    attrsValue
  }
}
