import { ActionTree } from 'vuex'
import {
  Action as ActionInterface,
  actionsMutationTypes,
  ActionsState, AssociateEconomicDataToActionRequest,
  MoveExpedientActionRequest,
  RelatedActionResponse
} from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ActionsService } from '@/services/ActionsService'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'
import { AlertsTypes, ComponentWhereIsRendered } from '../alerts/alertsTypes'
import { i18n } from '@/plugins/vue-i18n'
import { AxiosError } from 'axios'

const alertsModule: string = ModuleNamespaces.ALERTS

export const actions: ActionTree<ActionsState, RootState> = {
  async fetchActions({ commit }, serverSideParams) {
    try {
      const url = 'actions/get'
      const { data } = await new ActionsService().postData(url, serverSideParams['json'])
      commit(actionsMutationTypes.FETCH_ACTIONS, data)
    } catch (error) {
      commit(actionsMutationTypes.RESET_ACTIONS)
    }
  },
  async fetchAction({ commit }, idAction) {
    try {
      const url = `actions/action/${idAction}/get`
      const { data } = await new ActionsService().getData(url)
      commit(actionsMutationTypes.FETCH_ACTION, data)
    } catch (error) {}
  },
  async fetchParentAction({ commit }, subAction: ActionInterface) {
    if (!subAction.parentId) {
      // tslint:disable-next-line: no-console
      console.log('Error: SubAction does not have a parentId')
      commit(actionsMutationTypes.RESET_PARENT_ACTION)
      return
    }
    try {
      const { data } = await new ActionsService().getData(
        `actions/action/${subAction.parentId}/get`
      )
      if (data.parentId) {
        // tslint:disable-next-line: no-console
        console.log('Response Error: The parent action is not a valid parent, as it have a parentId')
        commit(actionsMutationTypes.RESET_PARENT_ACTION)
        return
      }
      commit(actionsMutationTypes.FETCH_PARENT_ACTION, data)
    }catch (error){
      // tslint:disable-next-line: no-console
      console.log(error)
      commit(actionsMutationTypes.RESET_PARENT_ACTION)
    }
  },
  async fetchContactActions({ commit }, { listName, selectedRegisterId, contactType }) {
    try {
      const url = `actions/${listName}/${contactType}/${selectedRegisterId}/get`
      const { data } = await new ActionsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async saveSelectedActionType({ commit }, actionType) {
    commit(actionsMutationTypes.SAVE_SELECTED_ACTION_TYPE, actionType)
  },
  async fetchActionRelations({ commit }, { selectedRegisterId }) {
    try {
      const url = `action/relations/${selectedRegisterId}/get`
      const { data } = await new ActionsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchPanelActions() {
    try {
      const url = '/actions/get'
      const jsonData = '{"source": "panel"}'
      const { data } = await new ActionsService().postData(url, jsonData)
      return data
    } catch (error) {}
  },
  async editPanelAction(_context, data) {
    try {
      const endpoint = 'actions/save'
      await new MainService().postData(endpoint, data)
    } catch (error) {}
  },
  async isUniqueCode(_context, params) {
    try {
      const endpoint = 'maintenance/manActionsCodificationsCheck/post'
      const { data } = await new MainService().postData(endpoint, params)
      return data
    } catch (error) {}
  },
  async getActionByCode(_context, params) {
    try {
      const endpoint = 'maintenance/manActionsCodifications/post'
      const { data } = await new MainService().postData(endpoint, params)
      return data
    } catch (error) {}
  },
  async associateEconomicDataToAction({ dispatch }, request: AssociateEconomicDataToActionRequest ) {
    try {
      const endpoint: string = `v2/economic-data/${request.economicDataId}/relationships/actions`
      const formData: FormData = new FormData()
      request.actionIdLot.forEach((actionId:number, index: number): void => {
        formData.append(`actionId[${index}]`, actionId.toString());
      })
      await new MainService().postFormData(endpoint, formData)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.associated_economic_data_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error: any ) {
      const { data } = error.response
      const exceptionDescParse = JSON.parse(data.exceptionDesc)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: exceptionDescParse.exceptionDesc,
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    }
  },
  async disassociateEconomicDataToAction( {}, relationData ) {
    try {
      const { actionId, economicDataId } = relationData
      const endpoint = `v2/economic-data/${economicDataId}/relationships/action/${actionId}`
      await new MainService().deleteData(endpoint)
    } catch (error: any ) {
    }
  },
  async moveActionToAnotherExpedientAction({ dispatch }, request: MoveExpedientActionRequest ):Promise <void> {
    try {
      const endpoint:string = `v2/expedient/${request.toExpedientId}/relationships/expedient/${request.fromExpedientId}/action`
      const formData:FormData = new FormData()
      request.actionIdLot.forEach((actionId:number, index: number): void => {
        formData.append(`actionId[${index}]`, actionId.toString());
      })
      await new MainService().putFormData(endpoint, formData)
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.move_action_to_another_expedient_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    } catch (error: any ) {
      const { data } = error.response
      dispatch(
        `${alertsModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: data.exceptionDesc,
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
        },
        { root: true }
      )
    }
  },
  async viewActionsDetail(_context, economicDataId ) {
    try {
      const { data } = await new MainService()
        .getData(`/v2/economic-data/${ economicDataId }/relationships/actions`)
      return data as RelatedActionResponse
    } catch (error) {}
  },
  async viewInvoiceActionsDetailAction(_context, invoiceId ) {
    try {
      const { data } = await new MainService()
        .getData(`/v2/invoice/${ invoiceId }/relationships/actions`)
      return data as RelatedActionResponse
    } catch (error) {}
  },
  async removeAction({}, action: ActionInterface) {
    if(!action?.actionId) {
      return { success: false, message: i18n.t('components.dialog.action_remove_error')}
    }
    try {
      await new MainService().deleteData(`actions/action/${action.actionId}/delete`)
      return {success: true}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.detail || data?.exceptionDesc}
    }
  },
  async removeActionClassification({}, id) {
    try {
      const url = `action/relations/${id}/delete`
      await new MainService().deleteData(url)
      return {success: true}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.detail || data?.exceptionDesc}
    }
  }
}
