import { RepositoryService } from '@/services/RepositoryService'
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { AxiosError } from "axios"
import { ComputedRef, computed } from "vue"

interface PropsUseDocumentIntervener {
  parentContext: string,
  documentContext: string
}

interface ResponseUseDocumentIntervener {
  documentIntervener: ComputedRef<any[]>
  currentDocument: ComputedRef<any>
  read: () => void
  save: (formData: any) => void
  remove: (id: string) => Promise<{success: boolean, message?: string}>
}

export default function useDocumentIntervener(props: PropsUseDocumentIntervener): ResponseUseDocumentIntervener {

  const { documentContext } = props

  const currentDocument = computed(() => store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`](documentContext))

  const read = async () => {
    await store.dispatch(`${ModuleNamespaces.FILE_MANAGER}/fetchInterveners`, currentDocument.value.IdDocumento ?? 0)
  }

  const save = async (formData: any) => {
    try {
      const data = {
        IdDocument: currentDocument.value.IdDocumento,
        IdIntervener: formData.intervener.id,
        IdIntervenerType: formData.intervenerType.id,
        IdOtherCategory: formData.idOtherCategory,
        ...(formData.id !== undefined && { id: formData.id })
      }
      const response = await new RepositoryService().insertDocumentIntervener(data)
      return {success: Boolean(response.data.result)}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.detail || data?.exceptionDesc}
    }
  }

  const remove = async (id: string) => {
    const response = await new RepositoryService().deleteDocumentIntervener(id)
    return {
      success: response.data && 'result' in response.data ? response.data.result : false,
      message: response.data && 'message' in response.data ? response.message : response.statusText
    }
  }

  const documentIntervener = computed(() => store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`])

  return {
    documentIntervener,
    currentDocument,
    read,
    save,
    remove
  }
}
