<template>
  <div>
    <GridChipsFiltersComponent
      v-if="chipsFilter"
      :filters="filters"
      @removeFilter="onRemoveFilter"
    ></GridChipsFiltersComponent>
    <ejs-grid
      v-if="columns"
      ref="grid"
      width="100%"
      class="grid-table"
      :allowPaging="allowPaging"
      :allowSorting="allowSorting"
      :allowFiltering="allowFiltering"
      :allowExcelExport="true"
      :allowReordering="true"
      :allowPdfExport="true"
      :allowResizing="true"
      :contextMenuItems="contextMenuItems"
      :contextMenuModule="true"
      :dataSource="itemData"
      :enablePersistance="true"
      :selectionSettings="selectionOptions"
      :showColumnChooser="showColumnChooser"
      :rowHeight="40"
      :childGrid="childGrid"
      :toolbar="toolbarOptionsParsed"
      :pageSettings="pageSettings"
      :filterSettings="filterSettings"
      :searchSettings="searchSettings"
      :sortSettings="sortSettings"
      :sortModule="true"
      :columnChooserSettings="columnChooserSettings"
      @contextMenuClick="onContextMenuClicked"
      @contextMenuOpen="onContextMenuBeforeOpen"
      @commandClick="onCommandClicked"
      @rowSelected="onRowSelected"
      @rowDeselected="onRowSelected"
      @recordClick="onRowClick"
      @rowDataBound="rowDataBound"
      @pdfExportComplete="onPdfExportComplete"
      @pdfQueryCellInfo="onPdfQueryCellInfo"
      @toolbarClick="onToolbarClicked"
      @actionBegin="onActionBegin"
    >
      <e-columns>
        <e-column
          v-for="(column, index) in columns"
          :filter="getCustomFilter(column)"
          :clipMode="getClipMode(column)"
          :allowFiltering="column.allowFiltering"
          :allowReordering="column.allowReordering"
          :allowResizing="column.allowFiltering"
          :allowSorting="column.allowSorting"
          :commands="column.commands"
          :field="column.field"
          :filterModule="column.filterModule"
          :format="column.format"
          :headerTemplate="column.headerTemplate"
          :headerText="column.headerText"
          :headerTextAlign="column.headerTextAlign"
          :isPrimaryKey="column.isPrimaryKey ? true : false"
          :key="`column-${index}`"
          :minWidth="column.minWidth"
          :maxWidth="column.maxWidth"
          :textAlign="column.textAlign"
          :type="column.type"
          :visible="column.visible"
          :width="column.width"
          :showInColumnChooser="column.showInColumnChooser"
          :template="getTemplate(column)"
          :freeze="childGrid ? undefined : column.freeze"
        >
        </e-column>
      </e-columns>
    </ejs-grid>
    <ejs-contextmenu
      id="customToolbarContextMenu"
      ref="customToolbarContextMenu"
      :items="customToolbarContextMenuItems"
      @select="customToolbarContextMenuClick"
    ></ejs-contextmenu>
  </div>
</template>
<script lang="ts">
import {
  ColumnChooser,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  Toolbar,
  Page,
  PdfExport,
  Reorder,
  Resize,
  Search,
  Sort
} from '@syncfusion/ej2-vue-grids'
export default {
  provide: {
    grid: [
      ColumnChooser,
      CommandColumn,
      ContextMenu,
      DetailRow,
      ExcelExport,
      Filter,
      Freeze,
      Toolbar,
      Page,
      PdfExport,
      Reorder,
      Resize,
      Search,
      Sort
    ]
  }
}
</script>
<script lang="ts" setup>
import { PropType, Ref, computed, ref } from 'vue'
import {
  GridComponent as EjsGrid,
  ColumnsDirective as EColumns,
  ColumnDirective as EColumn,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs,
  GridComponent,
  RowInfo,
  RowSelectEventArgs,
  RowDeselectEventArgs,
  ClipMode,
  ContextMenuOpenEventArgs
} from '@syncfusion/ej2-vue-grids'
import {
  ActionName,
  ClickEventArgs,
  ColumnModel,
  CommandClickEventArgs,
  GridConfiguration,
  ItemModel,
  pageSettingsDefault
} from './LfGridTypes'
import GridChipsFiltersComponent from '@/components/grids/GridChipsFiltersComponent.vue'
import { defineEmits } from 'vue'
import { gridProps } from './LfGridProps'
import { gridEmits } from './LfGridEmits'

import useGridMethods from './composables/useGridMethods'
import useGridTemplates from './composables/useGridTemplates'
import useGridFilters from './composables/useGridFilters'
import useGridContextMenu from './composables/useGridContextMenu'
import useGridPdf from './composables/useGridPdf'
import useGridSearch from './composables/useGridSearch'
import useGridFilterChips from './composables/useGridFilterChips'

const emit = defineEmits(gridEmits)

const grid: Ref<InstanceType<typeof GridComponent> | null> = ref(null)
const customToolbarContextMenu: Ref<InstanceType<typeof ContextMenu> | null> = ref(null)
const selectedItem: Ref<any> = ref(null)

const props = defineProps({
  ...gridProps,
  gridConfiguration: {
    type: Object as PropType<GridConfiguration>,
    required: true
  }
})

const customToolbarContextMenuItems = ref<ContextMenuItemModel[]>([])

// COMPOSABLES
const { excelExport, getSelectedRecords } = useGridMethods({ gridRef: grid })
const { search } = useGridSearch({ gridRef: grid })
const { getTemplate } = useGridTemplates()

const { exportToPDF, onPdfQueryCellInfo, onPdfExportComplete } = useGridPdf({
  gridRef: grid,
  exportName: props.exportName
})

const { removeFilterByField, filters } = useGridFilterChips({ gridRef: grid })

const { hasFilters, clearFilter, deleteDateFilterValue, getCustomFilter, filterDateColumn } = useGridFilters({
  gridRef: grid,
  defaultFilterSettings: props.filterSettings
})

const { contextMenuBeforeOpen, getItemPositionByTarget } = useGridContextMenu({ gridRef: grid })

// COMPUTED
const columns = computed((): ColumnModel[] => props.gridConfiguration.columns)
const pageSettings = computed(() => props.gridConfiguration.pageSettings || pageSettingsDefault)
const sortSettings = computed(() =>
  props.gridConfiguration.sortSettings?.length > 0 ? { columns: props.gridConfiguration.sortSettings } : {}
)
const searchSettings = computed(() => ({
  ...props.searchSettings,
  ...(props.gridConfiguration.searchSettings ? { key: props.gridConfiguration.searchSettings } : {})
}))
const filterSettings = computed(() => ({
  ...props.filterSettings,
  ...(props.gridConfiguration.filterSettings ? { columns: props.gridConfiguration.filterSettings } : {})
}))

const toolbarOptionsParsed = computed(() => {
  if (!props.toolbarOptions) {
    return
  }
  return props.toolbarOptions.map((item) => {
    if (item.id === ActionName.FILTER) {
      return { ...item, visible: hasFilters.value }
    }
    return item
  })
})

// EVENTS
function onActionBegin(args: any) {
  const { action, currentFilterColumn, requestType } = args
  if ('clearFilter' === action && 'customFilterDate' === currentFilterColumn.filterModule) {
    deleteDateFilterValue(currentFilterColumn.field)
    return
  }

  if (requestType === 'filtering' && action === 'filter') {
    filterDateColumn(args)
  }
}

function onCommandClicked(args: CommandClickEventArgs) {
  const { commandColumn, target, rowData } = args
  if (commandColumn.id === ActionName.OPEN_KEBAK_MENU) {
    const { x, y } = target?.getClientRects()[0] as DOMRect
    selectedItem.value = rowData
    openCustomToolbarContextMenu(props.contextMenuItems, y, x)
    return
  }
  props.commandClick(args)
}

function onRowClick(args: RecordClickEventArgs) {
  const { rowData, rowIndex, column } = args
  if (column!.commands !== undefined) {
    return
  }
  emit('rowClick', { rowData, rowIndex })
}

function onContextMenuBeforeOpen(args: ContextMenuOpenEventArgs) {
  const argsContext = contextMenuBeforeOpen(args)
  emit('contextMenuBeforeOpen', argsContext)
}

function onContextMenuClicked(args: ContextMenuClickEventArgs) {
  const { event, item, rowInfo } = args
  emit('contextMenuClicked', { event, item, rowInfo })
}

function onToolbarClicked(args: ClickEventArgs) {
  const { originalEvent, item } = args
  const { id, contextMenuItems } = item
  const { target } = originalEvent as PointerEvent
  const { top, left } = getItemPositionByTarget(target)

  switch (id) {
    case ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU:
      if (contextMenuItems) {
        openCustomToolbarContextMenu(contextMenuItems, top, left)
      }
      break
    case ActionName.EXPORT:
      excelExport()
      break
    case ActionName.PRINT:
      exportToPDF()
      break
    case ActionName.FILTER:
      clearFilter()
      // saveGridConfig('clear filter') emit event
      break
    case ActionName.SEARCH:
      search({ target })
      break
    case ActionName.ADD:
      disabledClickButton(item)
      props.toolbarClick(args)
      emit('toolbarClicked', args)
      break
    default:
      props.toolbarClick(args)
      emit('toolbarClicked', args)
      break
  }
}

function disabledClickButton(item: ItemModel) {
  item.disabled = true

  setTimeout(() => {
    item.disabled = false
  }, 2000)
}

function onRowSelected(_args: RowSelectEventArgs | RowDeselectEventArgs) {
  const selectedRecords = getSelectedRecords()
  emit('rowsSelected', selectedRecords)
}

const customToolbarContextMenuClick = (args: ContextMenuClickEventArgs) => {
  const { id } = args.item

  args.rowInfo = {
    rowData: selectedItem.value
  } as RowInfo

  switch (id) {
    case ActionName.EXPORT:
      excelExport()
      break
    case ActionName.PRINT:
      exportToPDF()
      break
    default:
      emit('contextMenuClicked', args)
      return
  }
}

function onRemoveFilter({ filter }: any) {
  const { field } = filter
  removeFilterByField(field)
}
// METHODS
function openCustomToolbarContextMenu(contextMenuItems: ContextMenuItemModel[], top: number, left: number) {
  if (customToolbarContextMenu.value) {
    customToolbarContextMenuItems.value = contextMenuItems
    ;(customToolbarContextMenu.value as any).open(top, left)
  }
}

const getClipMode = (column: ColumnModel): ClipMode | undefined => {
  if (!column.headerText) {
    return
  }
  return 'EllipsisWithTooltip'
}

defineExpose({
  gridRef: grid
})

</script>
