<template>
  <section :class="['selected-register-container', 'lexon-view-container', 'lf-tabs-containerr']">
    <LfTabsComponent :tabs="tabs" :selectedTab="0" :isTabsDisabled="isTabsDisabled" :type="TabsType.MAIN">
      <template v-slot="{ activeTab }">
        <div v-for="component in activeTab.items" :key="component.idSubTypeObject" class="component-wrapper">
          <DynamicTabComponent v-if="activeTab" :component="getComponent(component.idSubTypeObject)" />
        </div>
      </template>
    </LfTabsComponent>
  </section>
</template>

<!-- <script lang="ts">
// TODO ALE - Esto aparentemente se necesita en la ExpedientDetailView pararenderizar el TabsSelector
export default {
  provide: {
    showTabSelectorComponent: true
  }
}
</script> -->

<script lang="ts" setup>
import { ConfigurationElements } from '@/store/modules/configuration/configurationTypes'
import { VueComponentType } from '@/types/SharedVueTypes'
import { TabsType } from '@/components/tabs/LfTabs/types/LfTabsTypes'
import mock from '@/views/LfTabsExample/mock.json'
import useTabs from '@/components/tabs/LfTabs/composables/useTabs'
import LfTabsComponent from '@/components/tabs/LfTabs/LfTabsComponent.vue'
import ToggleTabsComponent from '@/components/tabs/LfTabs/components/ToggleTabsComponent.vue'
import DefaultComponent from '@/components/tabs/LfTabs/components/DefaultComponent.vue'
import DynamicTabComponent from '@/components/tabs/LfTabs/components/DynamicTabComponent.vue'
// TODO ALE - Hay que importar los componentes pertenecientes a cada vista
// import ExpedientGlobalVisionComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientGlobalVisionComponent.vue'
// import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'

const getComponent = (id: string): VueComponentType => {
  const mappings: { [key: string]: VueComponentType } = {
    [ConfigurationElements.EXPEDIENT_GLOBAL_VISION]: ToggleTabsComponent,
    [ConfigurationElements.FORM]: ToggleTabsComponent
  }

  return mappings[id] || DefaultComponent
}

// COMPOSABLES  =============================================================================================================
const { isTabsDisabled } = useTabs()

// DATA =============================================================================================================
const { tabs } = mock
</script>

<style lang="scss" scoped>
.lf-tabs-containerr {
  height: 100vh;
  width: 100%;

  .component-wrapper {
    @include flex;
    width: 100%;
    height: 100%;
  }
}
</style>
