<template lang="pug">
  div( :class="['file-icon-column']" )
    span( :class="['e-fe-icon', getIconClass]" )
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { getFileExtension } from '@/helpers/file'

@Component({
  name: 'fileIconColumnTemplate'
})
export default class IconColumnTemplateComponent extends Vue {
  data: any

  get getIconClass() {
    const fileExtension = getFileExtension(this.data.name)
    return fileExtension ? `e-fe-${fileExtension}` : 'e-fe-unknown'
  }
}
</script>

<style lang="scss" scoped>
  @include file-icons($container-name: 'file-icon-column');

  .file-icon-column {
    [class^="e-fe-"]::before {
      content: '\e94b';
    }

    .e-fe-icon {
      display: flex;

      &::before {
        font-family: $lf-icons-font !important;
        color: $main-1000;
        margin-right: 5px;
        font-size: 14px;
        width: 15px;
      }
    }
  }
</style>
