import { ref, Ref } from 'vue'

interface ReturnUseTab {
  disableTabs: () => void
  enableTabs: () => void
  hideTabSpinner: () => void
  isTabsDisabled: Ref<boolean>
  showTabSpinner: () => void
  spinnerIsShown: Ref<boolean>
  toggleDisabledTabs: (status: boolean) => void
}

// SHARED STATES
const spinnerIsShown = ref(false)
const isTabsDisabled = ref(false)


export default function useTabs(): ReturnUseTab {

  const showTabSpinner = () => {
    spinnerIsShown.value = true
  }

  const hideTabSpinner = () => {
    spinnerIsShown.value = false
  }

  const disableTabs = () => {
    isTabsDisabled.value = true
  }

  const enableTabs = () => {
    isTabsDisabled.value = false
  }

  const toggleDisabledTabs = (status: boolean) => {
    isTabsDisabled.value = status
  }

  return {
    toggleDisabledTabs,
    spinnerIsShown,
    showTabSpinner,
    isTabsDisabled,
    hideTabSpinner,
    enableTabs,
    disableTabs
  }
}
