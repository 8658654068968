import store from '@/store/store'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ComputedRef } from 'vue'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import useSelectedRegister from '@/composables/useSelectedRegister'
import { InvoiceTypes, Partialbankdraft } from '@/store/modules/invoices/invoicesTypes'
import useFormData from '@/composables/useFormData'

export default function useCustomerInvoiceBankdraft() {
  const {
    getSelectedRegisterId,
    getSelectedRegisterByEntity,
    loadSelectedRegister
  } = useSelectedRegister({ entityName: ContextName.BILLING_CUSTOMER_INVOICES })
  const { saveQuietRegisterFormData } = useFormData()

  const customerInvoiceId = getSelectedRegisterId(ContextName.BILLING_CUSTOMER_INVOICES)

  const customerInvoice = getSelectedRegisterByEntity(ContextName.BILLING_CUSTOMER_INVOICES)

  const invoiceCode = getSelectedRegisterByEntity(ContextName.BILLING_CUSTOMER_INVOICES).code

  const totals = computed(() => store.getters[
    `${ ModuleNamespaces.INVOICES }/getCustomeInvoiceTotals`
    ]
  )

  const bankdraftList = computed(() => store.getters[
    `${ ModuleNamespaces.LIST_ITEMS }/getListItemsWithFormattedDates`
    ]
  )

  const loadTotals = (customerInvoiceId: number) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/fetchCustomerInvoiceTotals`, customerInvoiceId
  )

  const loadBankdraftList = (customerInvoiceId: number) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/fetchInvoiceDetails`, {
      endpoint: `/invoices/customers/${ customerInvoiceId }/bankdraft/list`,
      filter: { source: ListNames.CUSTOMER_BANKDRAFTS_DETAILS }
    }
  )

  const loadCustomerInvoice = (customerInvoiceId: number) => loadSelectedRegister({
    endpoint: `/invoices/customers/${ customerInvoiceId }/get`,
    context: ContextName.BILLING_CUSTOMER_INVOICES
  })

  const saveCustomerInvoice = (customerInvoiceId: number) => saveQuietRegisterFormData({
    endpoint: '/invoices/customers/save',
    idSelectedRegister: customerInvoiceId.toString(),
    context: ContextName.BILLING_CUSTOMER_INVOICES
  })

  const automaticInsert = (customerInvoiceId: number) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/fetchInvoiceBankdraftAutomaticInsert`, {
      invoiceType: InvoiceTypes.CUSTOMER,
      idInvoice: String(customerInvoiceId)
    }
  )

  const existsEndpointError: ComputedRef<boolean> = computed(() => store.getters[
    `${ ModuleNamespaces.ERROR }/existsEndpointErrorStatus`
    ]
  )

  const saveNewPartialbankdraft = (formData: any, bankdraftId: any) => {
    store.dispatch(
      `${ ModuleNamespaces.INVOICES }/createPartialbankdraftAction`, {
        formData,
        bankdraftId
      }
    )
  }

  const saveExistPartialbankdraft = (formData: any) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/editPartialbankdraftAction`, {
      formData,
      partialbankdraftId: formData.id
    }
  )

  const removeCustomerInvoiceBankdraft = (bankdraftId: number, bankdraftDescription: string) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/removeCustomerInvoiceBankdraft`, {
      bankdraftId,
      bankdraftDescription
    }
  )

  const removePartialbankdraft = (partialbankdraft: Partialbankdraft) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/removePartialbankdraftAction`, partialbankdraft
  )

  const getBankdraft = async (bankdraftId: number) => {
    return await store.dispatch(
      `${ ModuleNamespaces.INVOICES }/fetchInvoiceDetails`,
      { endpoint: `/invoices/customers/bankdraft/${ bankdraftId }/get` }
    )
  }

  return {
    customerInvoiceId,
    invoiceCode,
    totals,
    bankdraftList,
    existsEndpointError,
    customerInvoice,
    getBankdraft,
    removeCustomerInvoiceBankdraft,
    removePartialbankdraft,
    saveNewPartialbankdraft,
    saveExistPartialbankdraft,
    automaticInsert,
    loadCustomerInvoice,
    saveCustomerInvoice,
    loadTotals,
    loadBankdraftList
  }
}
