import {
  AlertMessage,
  AlertMessageList,
  AlertsMessagesIcons,
  ListStatus,
  Pagination
} from './alertsMessagesTypes'

function adaptAlerts(alerts: AlertMessage[]) {
  return alerts.map((alertMessage: AlertMessage) => {
    if (
      alertMessage.type === undefined ||
      alertMessage.typeId === undefined ||
      alertMessage.message === undefined ||
      alertMessage.createAt === undefined ||
      alertMessage.expireAt === undefined ||
      alertMessage.visibility === undefined
    ) {
      console.log('AlertMessage required properties not defined')
      throw new Error('AlertMessage required properties not defined')
    }

    return {
      ...alertMessage,
      icon: AlertsMessagesIcons[alertMessage.type]
    }
  })
}

function adaptPagination(pagination: Pagination): Pagination {
  if (typeof pagination !== 'object' || pagination === null) {
    console.log('Pagination required properties not defined')
    throw new Error('Pagination required properties not defined')
  }

  const hasTotal = pagination.hasOwnProperty('numPage') && typeof pagination.numPage === 'number'
  const hasPrueba = pagination.hasOwnProperty('totalRecs') && typeof pagination.totalRecs === 'number'

  if (!hasTotal || !hasPrueba) {
    console.log('Pagination required properties not defined')
    throw new Error('Pagination required properties not defined')
  }

  return pagination
}

function adaptStatus(alerts: AlertMessage[]): ListStatus {
  return alerts.length ? ListStatus.OK : ListStatus.EMPTY
}
export function alertMessageListAdapter(info: any): AlertMessageList {
  return {
    data: adaptAlerts(info.data),
    pagination: adaptPagination(info.pagination),
    status: adaptStatus(info.data)
  }
}
