import { Ref, ref } from 'vue'
import { Toast } from '@/components/Toasts/types/toastsTypes'
import { uuid } from '@/helpers/helpers'

interface ToastsComposable {
  toasts: Ref<Toast[]>
  addToast: (toast: Toast) => void
  removeToast: (toast: Toast) => void
  storageToast: (toast: Toast) => void
}

const toasts = ref<Toast[]>([])

export default function useToasts(): ToastsComposable {

  function addToast(toast: Toast) {
    // Si no tiene un id hardcoded añadimos uno dinámico
    if (!toast.id) {
      toast.id = uuid()
      console.log('entrooo', toast.id, toast, uuid())
    }
    toasts.value.push(toast)
  }

  function removeToast(selectedToast: Toast) {
    toasts.value = toasts.value.filter((toast) => toast.id !== selectedToast.id)
  }

  function storageToast(selectedToast: Toast) {
    const storageName = 'toasts'
    let storedToasts = localStorage.getItem(storageName)
    let toastsToStore = storedToasts ? JSON.parse(storedToasts) : []
    toastsToStore.push(selectedToast)
    storedToasts = JSON.stringify(toastsToStore)
    localStorage.setItem(storageName, storedToasts)
  }

  return { toasts, addToast, removeToast, storageToast }
}
