export enum ContactTypes {
  CONTACTS_ALL = 'all'
}

export enum ContactTypeId {
  CONTACTS_ALL = 42
}

export enum ContactPersonTypeId {
  NATURAL = '0',
  LEGAL = '1'
}

export interface ContactsState {
  contacts: any[] | null
  contactTypeSelected: ContactTypes | null
}

export const contactsMutationTypes = {
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  RESET_CONTACTS: 'RESET_CONTACTS',
  CONTACT_TYPE_SELECTED: 'CONTACT_TYPE_SELECTED',
  SAVE_SELECTED_CONTACT_PERSON_TYPE: 'SAVE_SELECTED_CONTACT_PERSON_TYPE'
}

export interface ContactItem {
  id: string
  name: string
  personTypeId: ContactPersonTypeId
}
