<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :itemData="actionClassification"
      :gridConfiguration="gridConfiguration"
      :toolbarOptions="toolbarItemsOptions"
      :toolbarClick="onToolbarClick"
      :commandClick="onCommandClick"
      :contextMenuItems="contextmenuitems"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
      @contextMenuBeforeOpen="onContextMenuBeforeOpen"
      :exportName="texts.exportName"
      :rowDataBound="onRowDataBound"
    ></Grid>

    <PromptDialog
      :title="modalTitle"
      :isOpened="openedPrompt"
      :context="formAlias"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :width="940"
      @execute-action="onExecuteAction"
      @changeFormField="onChangeFormFieldValue"
    ></PromptDialog>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import {
  ActionName,
  CommandClickEventArgs,
  CommandModel,
  commandButtonType
} from '@/components/grids/LfGrid/LfGridTypes'
import useFormSchema from '@/composables/useFormSchema'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { Icons } from '@/icons/icons'
import { computed, onMounted, ref } from 'vue'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import useActionClassification from './composables/useActionClassification'
import { ContextMenuClickEventArgs, ContextMenuItemModel, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import useDialog from '@/composables/useDialog'
import { useI18n } from 'vue-i18n-composable'
import { kebakMenuToolbarItem } from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import {
  ActionClassificationFields,
  ActionRelationTypes,
  OTHER_INTERVENER_TYPE
} from '@/store/modules/actions/actionsTypes'
import usePermissions from '@/composables/usePermissions'
import useRouter from '@/composables/useRouter'
import { RowDataBoundEventArgs } from '@syncfusion/ej2-vue-grids'

const { t } = useI18n()

const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    required: true
  },
  formAlias: {
    type: String,
    required: true
  }
})

const texts = {
  buttons: {
    delete: 'Eliminar',
    add: 'Agregar',
    print: 'Imprimir',
    export: 'Exportar',
    openWindow: t('components.context_menu.open_window').toString()
  },
  contextMenuItems: {
    edit: t('components.context_menu.edit').toString(),
    openWindow: t('components.context_menu.open_window').toString(),
    remove: t('components.context_menu.remove').toString()
  },
  modal: {
    title_new: 'Nueva clasificación',
    title_edit: 'Editar clasificación'
  },
  dialog: {
    confirmDelete: ({ classificationName, subjectAction }: { classificationName: string; subjectAction: string }) =>
      t('components.dialog.action.classification.confirmRemove', { classificationName, subjectAction }).toString(),
    deleteSuccess: (classificationName: string) =>
      t('components.dialog.action.classification.deleteSuccess', { classificationName }).toString()
  },
  exportName: t('entity.action_classification').toString()
}

const openedPrompt = ref(false)
const formData = ref({})
const editMode = ref(false)

const { isSubAction, outerRouteNames, currentAction, actionClassification, read, save, remove } =
  useActionClassification({ parentContext: `${props.context}` })

const commands = computed((): CommandModel[] => [
  {
    id: ActionName.DELETE,
    type: commandButtonType.NONE,
    title: texts.buttons.delete,
    buttonOption: {
      iconCss: Icons.REMOVE,
      cssClass: 'custombutton delete-classification'
    }
  },
  {
    id: ActionName.OPEN_WINDOW,
    type: commandButtonType.NONE,
    title: texts.buttons.openWindow,
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
])

const toolbarItemsOptions = computed((): ToolbarItemModel[] => [
  {
    id: ActionName.ADD,
    text: texts.buttons.add,
    align: 'Right',
    tooltipText: texts.buttons.add,
    cssClass: 'lf-btn-model4'
  },
  kebakMenuToolbarItem
])

const contextmenuitems = computed((): ContextMenuItemModel[] => {
  const { canDelete } = viewPermission.value
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.OPEN_WINDOW,
      text: texts.contextMenuItems.openWindow,
      iconCss: Icons.OPEN_WINDOW
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

const { openRouteInNewWindow } = useRouter()

const { getDialogInfo, getDialogSuccess, getDialogError } = useDialog()

const { viewPermission } = usePermissions()

const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: `$actionClassification`,
  commandButtons: commands
})

const { formSchema, getFormSchema } = useFormSchema({
  formAlias: props.formAlias,
  context: `actionClassification`
})

const modalTitle = computed(() => {
  return editMode.value ? texts.modal.title_edit : texts.modal.title_new
})

const onToolbarClick = ({ item }: ClickEventArgs) => {
  const { id } = item
  if (ActionName.ADD === id) {
    openedPrompt.value = true
    editMode.value = false
    formData.value = {}
  }
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    const { success, message }: any = await save(formData.value)
    if (!success) {
      await getDialogError({
        message
      })
    }
    read()
    openedPrompt.value = false
  }
}

const onRowClick = ({ rowData }: RecordClickEventArgs) => {
  formData.value = rowData as {}
  editMode.value = true
  openedPrompt.value = true
}

const onCommandClick = ({ commandColumn, rowData }: CommandClickEventArgs) => {
  const { id, name, alias } = rowData as any
  const buttonId = commandColumn?.id
  switch (buttonId) {
    case ActionName.OPEN_WINDOW:
      openClasificationInNewWindow(rowData)
      break
    case ActionName.DELETE:
      confirmDelete({ id, name, alias })
      break
  }
}

const onChangeFormFieldValue = (schema: [], _formData: object, field: any, value: any) => {
  if (field.id === ActionClassificationFields.ACTION_ID_TYPE) {
    const otherIntervenerField: any = schema.find(({ id }) => id === ActionClassificationFields.OTHER_INTERVENER)
    otherIntervenerField!.hidden = !Boolean(value.id === OTHER_INTERVENER_TYPE)
  }
}

function onContextMenuClicked({ item, rowInfo }: ContextMenuClickEventArgs) {
  const { id, name, alias } = rowInfo?.rowData as any
  switch (item.id) {
    case ActionName.EDIT:
      onRowClick(rowInfo as any)
      break
    case ActionName.OPEN_WINDOW:
      openClasificationInNewWindow(rowInfo?.rowData as any)
      break
    case ActionName.REMOVE:
      confirmDelete({ id, name, alias })
      break
  }
}

function onContextMenuBeforeOpen(args: any) {
  const {
    rowInfo: { rowData },
    hideItems,
    showAllItems
  } = args
  const { idActionType } = rowData

  if (idActionType.id === 'EXP') {
    hideItems([texts.contextMenuItems.edit, texts.contextMenuItems.remove])
    return
  } else {
    showAllItems()
  }
}

function openClasificationInNewWindow(rowData: any): void {
  if (ActionRelationTypes.EXPEDIENT === rowData.idActionType.id) {
    openRouteInNewWindow({
      name: outerRouteNames[rowData.idActionType.id],
      params: { selectedRegisterId: rowData.idRelation.id }
    })
  } else {
    openRouteInNewWindow({
      name: outerRouteNames.contact,
      params: { selectedRegisterId: rowData.idRelation.id }
    })
  }
}

function confirmDelete({ id, name, alias }: { id: string; name: string; alias: string }) {
  if (alias === ActionRelationTypes.EXPEDIENT) {
    return
  }

  const { subject } = currentAction.value
  getDialogInfo({
    message: texts.dialog.confirmDelete({ classificationName: name, subjectAction: subject }),
    action: async () => {
      await removing({ id, name })
      read()
    },
    mainButtonText: t('action_buttons.remove'),
    secondaryButtonText: t('action_buttons.cancel')
  })
}

async function removing({ id, name }: { id: string; name: string }) {
  const { success, message } = await remove(id)
  if (success) {
    await getDialogSuccess({
      message: texts.dialog.deleteSuccess(name)
    })
    return
  }
  if (message) {
    await getDialogError({
      message
    })
  }
}

function onRowDataBound({ row, data }: RowDataBoundEventArgs) {
  const { alias } = data as any
  if (alias === ActionRelationTypes.EXPEDIENT) {
    const rowElement = row
    const deleteClassificationElement = rowElement!.querySelector('.delete-classification')
    deleteClassificationElement!.classList.add('disabled')
  }
}

onMounted(async () => {
  await getFormSchema()
  read()
})
</script>
<style lang="scss" scoped>
  ::v-deep .delete-classification.disabled {
    cursor: default !important;

    span {
      display: none;
    }
  }
</style>
