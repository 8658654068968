<template>
  <v-menu bottom left nudge-bottom="48" nudge-right="18" content-class="navigation-context-menu">
    <template v-slot:activator="{ on, attrs }">
      <span :class="[iconProduct, 'main-icon']" v-bind="attrs" v-on="on" />
    </template>

    <v-list class="list">
      <v-list-item class="list-title"> {{ text.ecosystem }} </v-list-item>
      <v-list-item v-for="tool in toolList" :key="tool.name" class="list-item" @click="redirect(tool.url)">
        <span :class="[tool.icon, 'lf-icon__lg']" />
        <span>{{ tool.name }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { Icons } from '@/icons/icons'
import useUserTool from '@/components/Navigation/AppBarToolbarItems/contextMenus/composables/useUserTool'
import { useI18n } from 'vue-i18n-composable'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  ecosystem: t('navigation.hub.ecosystem')
}

// DATA
const iconProduct = ref(Icons.PRODUCTS)

// COMPOSABLES
const { loadTools, toolList } = useUserTool()

// METHODS
function redirect(url: string) {
  window.open(url, '_blank')
}

onMounted(() => loadTools())
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/AppBarToolbarItems/contextMenus/styles/contextmenu.scss';
</style>
