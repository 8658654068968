import { Ref, ref } from 'vue'

interface TooltipComposable {
  tooltipMessage: Ref<string>
  setTooltipMessage: (message: string) => void
}

const tooltipMessage = ref('Análisis documental disponible')

export default function useTooltip(): TooltipComposable {
  function setTooltipMessage(message: string) {
    tooltipMessage.value = message
  }


  return { tooltipMessage, setTooltipMessage  }
}
