<template lang="pug">
  extends ../../views/Tabs/tabsView.pug
  block more-actions
    StageSelectorComponent(
      v-if="isExpedientsDetailView && formLoaded && !isPortalUser"
      :context="context"
    )
  block tabHeader
</template>
<script lang="ts">
import TabsView from '@/views/Tabs/TabsView.vue'
import store from '@/store/store'
import { Component, Provide, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { Action, Getter, Mutation } from 'vuex-class'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import {
  ConfigurationAlias,
  ConfigurationElements,
  ConfigurationTypes
} from '@/store/modules/configuration/configurationTypes'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ContextName, ModuleNamespaces, ValidationRules } from '@/store/types/storeGlobalTypes'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import TaxesDynamicFormFieldsMixin from '@/mixins/TaxesDynamicFormFields/TaxesDynamicFormFieldsMixin.vue'
import { Tab, TabNames } from '@/store/modules/tabs/tabsTypes'
import { endpoints as endpointsTypes } from '@/store/modules/endpoint/endpointTypes'
import { CustomDialogComponentName } from '@/store/modules/dialog/dialogTypes'
import StageSelectorComponent from '@/components/StageSelector/StageSelectorComponent.vue'
import useDialog from '@/composables/useDialog'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
import { InvoiceEntityId } from '@/general/entityIds'

const { getDialogError } = useDialog()

const authModule = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const formsModule: string = ModuleNamespaces.FORMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    StageSelectorComponent
  }
})
export default class ExpedientDetailView extends mixins(TabsView, TaxesDynamicFormFieldsMixin) {
  @Provide()
  showTabSelectorComponent = true

  @Provide()
  saveOnBeforeOpenTabSelector = true

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void
  @Action('fetchExpedientMaxCode', { namespace: expedientsModule })
  expedientMaxCode: ({}) => Promise<void>
  @Action('fetchRelatedSelectedRegister', { namespace: selectedRegisterModule })
  fetchCustomerData: ({}) => {}
  @Action('fetchExpedientStages', { namespace: expedientsModule })
  fetchExpedientStagesAction: ({}: { expedientId: number }) => Promise<void>
  @Action('setExpedientStageState', { namespace: expedientsModule })
  setExpedientStageStateAction: (idStage: string) => Promise<void>

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => Promise<void>
  @Mutation('SHOW_OR_HIDE_ONE_TAB', { namespace: configurationModule })
  showOrHidePeriodicBillingTab: ({
    context,
    tabName,
    status
  }: {
    context: string
    tabName: string
    status: boolean
  }) => void
  @Mutation('ENABLE_OR_DISABLE_ONE_TAB', { namespace: configurationModule })
  enableOrDisablePeriodicBillingTab: ({
    context,
    tabName,
    status
  }: {
    context: string
    tabName: string
    status: boolean
  }) => void
  @Mutation('SET_EDITING_PERIODIC_BILLING', { namespace: configurationModule })
  setEditingPeriodicBillingFlag: () => void
  @Mutation('RESET_EDITING_PERIODIC_BILLING', { namespace: configurationModule })
  resetEditingPeriodicBillingFlag: () => void

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: string
  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object
  @Getter('getSelectedRegisterPeriodicBillingData', { namespace: selectedRegisterModule })
  periodicBillingData: {}
  @Getter('getIsPortalUser', { namespace: authModule })
  isPortalUser: boolean
  @Getter('isEditingPeriodicBilling', { namespace: configurationModule })
  isEditingPeriodicBilling: boolean
  @Getter('getPeriodicBillingVisibleTabStatus', { namespace: configurationModule })
  getPeriodicBillingVisibleTabStatus: boolean
  @Getter('getPeriodicBillingEnabledTabStatus', { namespace: configurationModule })
  getPeriodicBillingEnabledTabStatus: boolean
  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  getCustomerData: any
  @Getter('getCurrentStageId', { namespace: expedientsModule })
  currentStageIdGetter: string
  @Getter('getCompanyDefaultStage', { namespace: authModule })
  hasCompanyDefaultStage: boolean

  isSavingForm = false

  newForm = false

  disableStageSelector = false

  pendingBillingContext: string = ContextName.PERIODIC_BILLING_TAB

  triggerPeriodicBillingAlert = false

  expedientContext = ContextName.EXPEDIENTS

  // $refs!: {
  //   tabsComponent: InstanceType<typeof TabsComponent>
  // }

  @Watch('duplicateExpedientId')
  async onDuplicateExpedient() {
    await this.saveSelectedRegisterId({ id: this.duplicateExpedientId, context: this.expedientContext })
  }

  get showHeaderTitleKebabMenu(): boolean {
    return !this.isPortalUser && !this.isNewRegister
  }

  get buttons() {
    const { canDelete, canSave } = this.checkEntityPermissionsGetter(this.entityType)

    const sendInternNotification = {
      icon: Icons.SEND_EMAIL,
      tooltip: this.$t('action_buttons.send_intern_notification'),
      action: ActionName.SEND_INTERN_NOTIFICATION,
      class: 'lf-mail-class-selector',
      isContextMenuOption: true,
      enabled:
        this.showRelatedForm(this.getContext) ||
        (!this.checkIfFormIsValid(this.getContext) && this.selectedTabInfo.objectType === ConfigurationElements.FORM) ||
        !this.buttonsEnabled
          ? false
          : true
    }

    const sendCustomerNotification = {
      icon: Icons.ALERT,
      tooltip: this.$t('action_buttons.send_customer_notification'),
      action: ActionName.SEND_EMAIL,
      class: 'lf-mail-class-selector',
      isContextMenuOption: true,
      enabled:
        this.showRelatedForm(this.getContext) ||
        (!this.checkIfFormIsValid(this.getContext) && this.selectedTabInfo.objectType === ConfigurationElements.FORM) ||
        !this.buttonsEnabled
          ? false
          : true
    }

    const saveButton = {
      icon: Icons.CHECK,
      tooltip: this.$t('action_buttons.save'),
      class: 'green-color',
      action: ActionName.SAVE,
      enabled:
        this.showRelatedForm(this.getContext) ||
        (!this.checkIfFormIsValid(this.getContext) &&
          this.selectedTabInfo.objectType !== ConfigurationElements.EXPEDIENT_INSURERS &&
          !this.checkIfFormIsValid(this.pendingBillingContext)) ||
        !this.buttonsEnabled
          ? false
          : true,
      hidden: !canSave
    }

    const closeButton = {
      icon: Icons.CLOSE,
      tooltip: this.$t('action_buttons.close'),
      class: 'red-color',
      variant: LabelButtonVariant.OUTLINED,
      action: ActionName.CLOSE,
      enabled: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true
    }

    const removeButton = {
      icon: Icons.REMOVE,
      tooltip: this.$t('action_buttons.remove'),
      action: ActionName.REMOVE,
      enabled: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true,
      hidden: !canDelete,
      isContextMenuOption: true
    }

    const duplicateButton = {
      icon: Icons.DOCUMENTS,
      tooltip: this.$t('action_buttons.duplicate_expedient'),
      action: ActionName.DUPLICATE_EXPEDIENT,
      enabled: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true,
      hidden: !canSave,
      isContextMenuOption: true
    }

    if (this.isPortalUser) {
      return [closeButton]
    }

    if (this.existSelectedRegister) {
      return [duplicateButton, sendInternNotification, sendCustomerNotification, removeButton, closeButton, saveButton]
    }

    return [closeButton, saveButton]
  }

  get currentUser() {
    return (store.state as any).authModule.authData.user
  }

  get breadcrumbsText() {
    if (this.getSelectedRegisterData) {
      const { description, idOwnFile, customer } = this.selectedRegisterData(this.context)
      return description ? `${customer} · ${idOwnFile} · ${description}` : this.breadcrumbText
    }
    return ''
  }

  async fetchExpedientStagesLogic() {
    const expedientId = Number(this.$route.params.selectedRegisterId)
    // Evaluamos si el param de la ruta es 'nuevo' para no pedir las etapas
    if (!isNaN(expedientId)) {
      await this.fetchExpedientStagesAction({ expedientId })
      const stageId = this.currentStageIdGetter && this.hasCompanyDefaultStage ? this.currentStageIdGetter : '0'
      this.setExpedientStageStateAction(stageId)
    }
  }

  async created() {
    this.saveMenuParentCategoryName(ParentCategory.EXPEDIENTS)
  }

  mounted() {
    this.resetEditingPeriodicBillingFlag()
    this.fetchExpedientStagesLogic()
  }

  async onInitForm(params: any) {
    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)
    const { schema, formData, isNewForm } = params
    this.disableStageSelector = false
    if (isNewForm) {
      this.isNewRegister = isNewForm
      this.disableStageSelector = true
      this.newForm = true
      formData.idUser = [String(this.currentUser.id)]
      formData.idUserTitular = [String(this.currentUser.id)]
      const filter = { idUser: this.currentUser.id }
      formData.idOwnFile = await this.expedientMaxCode({ filter })
    }

    const subjectField = schema.find((item: any) => {
      return item.id === ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID
    })

    const expedientStatusFildset = schema.filter(
      (item: any) => item.fieldsetId === ExpedientFields.EXPEDIENT_STATUS_FIELDSET
    )[0]

    if (expedientStatusFildset && this.isPortalUser) {
      const hidden: boolean = '1' !== String(formData[ExpedientFields.ARCHIVED])
      expedientStatusFildset.fields
        .filter(
          (item: any) => item.id === ExpedientFields.ARCHIVE_DATE || item.id === ExpedientFields.ARCHIVE_REFERENCE
        )
        .map((item: any) => {
          item.hidden = hidden
          item.disable = true
        })
    }

    if (subjectField && canSave) {
      subjectField.disabled =
        'undefined' === typeof formData[ExpedientFields.EXPEDIENT_TYPE_ID] ||
        null === formData[ExpedientFields.EXPEDIENT_TYPE_ID]

      subjectField.filteredValue = formData[ExpedientFields.EXPEDIENT_TYPE_ID]
        ? formData[ExpedientFields.EXPEDIENT_TYPE_ID].toString()
        : formData[ExpedientFields.EXPEDIENT_TYPE_ID]
    }

    const billingPermission = this.checkEntityPermissionsGetter(InvoiceEntityId.CUSTOMER_INVOICES)
    if (!billingPermission.canSave) {
      const expedientDataFildset = schema.filter(
        (item: any) => item.fieldsetId === ExpedientFields.EXPEDIENT_DATA_FIELDSET
      )[0]
      if (expedientDataFildset) {
        const billingField = expedientDataFildset.fields.find((item: any) => {
          return item.id === ExpedientFields.PERIODIC_BILLING
        })
        billingField.hidden = true
      }
    }

    this.formLoaded = true
  }

  beforeSetInitialTab() {
    const expedient: any = this.selectedRegisterData(this.context)
    const periodicBillingSwitch = expedient[ExpedientFields.PERIODIC_BILLING]

    const showPeriodicBillingTab: boolean = periodicBillingSwitch && Object.keys(this.periodicBillingData).length !== 0

    this.showOrHidePeriodicBillingTab({
      context: this.context,
      tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
      status: showPeriodicBillingTab
    })

    if (showPeriodicBillingTab) {
      this.enableOrDisablePeriodicBillingTab({
        context: this.context,
        tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
        status: true
      })
    }

    this.formLoaded = true
  }

  async onExpedientGlobalVisionMounted() {
    if (this.showSpinnerLayer) {
      this.hideSpinnerLayerAction()
    }
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.context).type).get,
      context: this.context
    })
    this.beforeSetInitialTab()
  }

  checkIfIdCustomerFieldIsRequired(schema: any, periodicBillingSwitchValue: number) {
    const customerFactField = schema.find((item: any) => {
      return item.id === ExpedientFields.CUSTOMER_FACT
    })

    if (periodicBillingSwitchValue) {
      customerFactField.validationRules = ValidationRules.REQUIRED
    } else {
      customerFactField.validationRules = null
      delete customerFactField[ValidationRules.VALIDATION_RULES]
    }
  }

  onFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (this.isSavingForm) {
      return
    }

    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)

    if (ExpedientFields.ARCHIVED === field.id) {
      const archived: boolean = '1' === String(value)
      const hidden: boolean = !archived

      const expedientStatusFildset = schema.filter(
        (item: any) => item.fieldsetId === ExpedientFields.EXPEDIENT_STATUS_FIELDSET
      )[0]

      expedientStatusFildset.fields
        .filter((item: any) => {
          return item.id === ExpedientFields.ARCHIVE_DATE || item.id === ExpedientFields.ARCHIVE_REFERENCE
        })
        .map((item: any) => {
          item.hidden = hidden
          item.disable = hidden && canSave
        })

      if (value === '0') {
        formData[ExpedientFields.ARCHIVE_DATE] = new Date()
      }

      // FILEREF FIELD LOGIC
      if (archived && !formData.fileReference) {
        this.checkArchivedMaxCode(formData)
      }
    }

    if (ExpedientFields.USER_ID === field.id) {
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.EXPEDIENT_CATEGORY_ID === field.id) {
      const typeField = schema.find((item: any) => item.id === ExpedientFields.EXPEDIENT_TYPE_ID)
      const subjectField = schema.find((item: any) => item.id === ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID)
      
      typeField.filterField = field.filterField
      typeField.filteredValue = value
      this.$set(formData, ExpedientFields.EXPEDIENT_TYPE_ID, null)

      subjectField.disabled = true
      this.$set(formData, ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID, null)

      this.checkMaxCode(formData)
    }

    if (ExpedientFields.EXPEDIENT_TYPE_ID === field.id) {
      const subjectField = schema.find((item: any) => {
        return item.id === ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID
      })

      subjectField.filteredValue = value === undefined ? null : value
      subjectField.disabled = value === null
      this.$set(formData, ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID, null)
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.CUSTOMER_FACT === field.id && !this.isPortalUser) {
      if (formData[ExpedientFields.PERIODIC_BILLING]) {
        this.enableOrDisablePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: true
        })
      }
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.PERIODIC_BILLING === field.id && !this.isPortalUser) {
      this.setEditingPeriodicBillingFlag()

      const valueToNumber = Number(value)

      this.checkIfIdCustomerFieldIsRequired(schema, valueToNumber)

      if (this.periodicBillingData && Object.keys(this.periodicBillingData).length) {
        if (!valueToNumber) {
          this.triggerPeriodicBillingAlert = true
        }
        this.showOrHidePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: !!valueToNumber
        })
        if (valueToNumber) {
          this.enableOrDisablePeriodicBillingTab({
            context: this.context,
            tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
            status: true
          })
        }
      } else {
        this.triggerPeriodicBillingAlert = true
        this.showOrHidePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: !!valueToNumber
        })
      }
      if (this.triggerPeriodicBillingAlert) {
        const expedientDataFildset = schema.filter(
          (item: any) => item.fieldsetId === ExpedientFields.EXPEDIENT_DATA_FIELDSET
        )[0]

        const periodicBillingAlertInfo = expedientDataFildset.fields.find((item: any) => {
          return item.id === ExpedientFields.PERIODIC_BILLING_ALERT_INFO
        })
        const periodicBillingAlertWarning = expedientDataFildset.fields.find((item: any) => {
          return item.id === ExpedientFields.PERIODIC_BILLING_ALERT_WARNING
        })
        periodicBillingAlertInfo.hidden = !valueToNumber
        periodicBillingAlertWarning.hidden = valueToNumber
      }
    }
  }

  async save(redirectToGridTable = true, resolve: any = null, idObjectType = '') {
    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)

    this.resetEditingPeriodicBillingFlag()

    if (canSave) {
      this.isSavingForm = true

      let formData: any = this.getFormData
      if (!formData || Object.keys(formData).length === 0) {
        formData = this.getSelectedRegisterData
      }
      this.prepareFormData(formData)

      if (Object.keys(formData).length !== 0) {
        // formData[ExpedientFields.ACTIVE_STAGES] = (this as any).expedientStageState[ExpedientFields.ACTIVE_STAGES]
        if (!formData[ExpedientFields.TAGS] && idObjectType !== ConfigurationElements.EXPEDIENT_INSURERS) {
          formData[ExpedientFields.TAGS] = []
        }

        if (typeof formData[ExpedientFields.USER_ID] === 'string') {
          formData[ExpedientFields.USER_ID] = [formData[ExpedientFields.USER_ID]]
        }

        if (!this.isPortalUser) {
          await this.saveRegisterFormData({
            endpoint: this.endpoints(this.entity(this.getContext).type).save,
            idSelectedRegister: this.entity(this.getContext).id,
            goToURL: redirectToGridTable ? this.redirect() : null,
            context: this.getContext
          })
        }
      } else if (redirectToGridTable) {
        this.$router.push({ name: this.menuName })
      }
    }

    this.isSavingForm = false
    this.disableStageSelector = false
    this.newForm = false

    if (!redirectToGridTable) {
      this.setPeriodicBillingVisibility()
    }

    if (resolve) {
      resolve()
    }
  }

  redirect() {
    if (window.history.length > 1) {
      return -1
    } else {
      return this.menuName
    }
  }

  async checkArchivedMaxCode(formData: any) {
    const filter = {
      idUser: formData.idUser[0],
      idExpedientType: formData.idExpedientType,
      idCustomer: formData.customerfact && formData.customerfact.id ? formData.customerfact.id : null,
      archived: 1
    }
    const maxCode: any = await this.expedientMaxCode({ filter })
    if (maxCode !== '') {
      formData.fileReference = maxCode
    }
  }

  async checkMaxCode(formData: any) {
    if (this.newForm) {
      const filter = {
        idUser: formData.idUser[0],
        idExpedientType: formData.idExpedientType,
        idCustomer: formData.customerfact && formData.customerfact.id ? formData.customerfact.id : null
      }
      const maxCode: any = await this.expedientMaxCode({ filter })
      if (maxCode !== '') {
        formData.idOwnFile = maxCode
      }
    }
  }

  async onAfterSaveTabsVisibility(currentTab: Tab) {
    // Obtener el tabComponent
    const tabComponent: any = this.$refs.tabsComponent

    if (!tabComponent) {
      return
    }

    // Apagar el renderizado de los componentes
    tabComponent.setEverythingLoaded(false)

    // Pedir la configuración reactiva
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.EXPEDIENT,
      context: ContextName.EXPEDIENTS
    })

    // Obtener el í­ndice de la nueva configuración
    let index = this.getCurrentViewTabs(this.context).findIndex((tab: Tab) => tab.name === currentTab!.name)

    if (index < 0) {
      // La pestaña actual no es visible
      index = this.getCurrentViewTabs(this.context).findIndex((tab: Tab) => tab.name === TabNames.GENERAL_DATA)
    }

    // Situar la pestaña actual en el nuevo í­ndice
    tabComponent.setSelectedTabIndex(index)
    tabComponent.setSelectedCurrentTabIndex(index)

    // Establecer la pestaña actual
    const tab: Tab = this.getCurrentViewTabs(this.context)[index]
    tabComponent.setCurrentTab(tab)

    this.$nextTick()

    // Encender el renderizado de los componentes
    tabComponent.setEverythingLoaded(true)

    // Mostrar / ocultar la pestaña de igualas
    this.setPeriodicBillingVisibility()
  }

  // Control sobre la visiblidad de la pestaña de igualas una vez guardada la configuración de pestañas
  async setPeriodicBillingVisibility() {
    // Si está editando un expediente
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.context).type).get,
      context: this.context
    })
    let expedient = this.selectedRegisterData(ContextName.EXPEDIENTS)

    if (!expedient || Object.keys(expedient).length <= 0) {
      // Si está creando un expediente
      expedient = this.getFormData
    }

    if (!expedient) {
      return
    }

    const enabled: boolean = (expedient as any).periodicBillingSwitch && (expedient as any).periodicBillingSwitch === 1

    this.showOrHidePeriodicBillingTab({
      context: ContextName.EXPEDIENTS,
      tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
      status: enabled
    })

    if (enabled) {
      this.enableOrDisablePeriodicBillingTab({
        context: ContextName.EXPEDIENTS,
        tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
        status: enabled
      })
    }
  }

  async onSendCustomerNotificationClick() {
    const formData: any = this.getFormData
    await this.save(false)
    let { idCustomer } = this.selectedRegisterData(this.getContext)
    let customerData = null
    if (formData && formData.customerfact) {
      idCustomer = formData.customerfact.id
    } else if (formData && formData.customerfact === null) {
      idCustomer = null
    }
    if (idCustomer) {
      const contactEndpoint = (endpointsTypes as any).contactsGeneric
      const endpointContactGet = String.format(
        contactEndpoint.get,
        ConfigurationAlias.CONTACT_ALL,
        formData.customerfact ? formData.customerfact.id : idCustomer
      )
      await this.fetchCustomerData({
        url: endpointContactGet,
        filterData: { source: 'checkPortal' }
      })
      customerData = this.getCustomerData
    }
    if (customerData && customerData.idPortal) {
      this.sendEmailDialog()
    } else if (customerData && customerData.idPortal === null) {
      getDialogError({ message: this.$t('components.alerts.send_customer_notification_portal_error').toString() })
    } else {
      getDialogError({ message: this.$t('components.alerts.send_customer_notification_error').toString() })
    }
  }

  async onSendInternNotificationClick() {
    this.showCustomDialog({
      name: CustomDialogComponentName.NOTIFICATION_INTERN,
      props: {
        id: this.selectedRegisterId(this.context),
        name: this.getSelectedRegisterData.description,
        context: this.context
      }
    })
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        trackEvent(TrackerEvents.REMOVE_EXPEDIENT)
        return this.remove()
      case ActionName.DUPLICATE_EXPEDIENT:
        trackEvent(TrackerEvents.DUPLICATE_EXPEDIENT)
        return this.duplicateExpedient()
      case ActionName.SEND_INTERN_NOTIFICATION:
        return this.onSendInternNotificationClick()
      case ActionName.SEND_EMAIL:
        return this.onSendCustomerNotificationClick()
    }
    this.executeExtraActions(actionName)
  }
}
</script>
