import { Module } from 'vuex'
import { getters } from '@/store/modules/dashboardWidgets/dashboardWidgetsGetters'
import { actions } from '@/store/modules/dashboardWidgets/dashboardWidgetsActions'
import { mutations } from '@/store/modules/dashboardWidgets/dashboardWidgetsMutations'
import { DashboardWidgetsState, ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: DashboardWidgetsState = {
  comeFromDashboardWidgetFlag: false,
  actionWidget: {
    status: ListStatus.LOADING,
    list: [],
  },
  counterWidget: {
    status: ListStatus.LOADING,
    list: [],
  }
}

const namespaced: boolean = true

export const dashboardWidgetsModule: Module<DashboardWidgetsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
