<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PivotViewPlugin, Toolbar, FieldList, ExcelExport } from '@syncfusion/ej2-vue-pivotview'
import { ContextMenuPlugin, ToolbarPlugin } from '@syncfusion/ej2-vue-navigations'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { numberToHourFormat } from '@/helpers/helpers'
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export'
import { mttMilano } from '@/fonts/mtt-milano'
import { FilterSettings, filterSettingsTypesOperator } from '@/components/grids/GridTable/types/GridTableTypes'

Vue.use(ContextMenuPlugin)
Vue.use(ToolbarPlugin)
Vue.use(PivotViewPlugin)

import { setCulture, setCurrencyCode, loadCldr } from '@syncfusion/ej2-base'
import currencies from 'cldr-data/main/es/currencies.json'
import currencyData from 'cldr-data/supplemental/currencyData.json'
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import gregorian from 'cldr-data/main/es/ca-gregorian.json'
import numbers from 'cldr-data/main/es/numbers.json'
import timeZoneNames from 'cldr-data/main/es/timeZoneNames.json'
import weekData from 'cldr-data/supplemental/weekData.json'
setCulture('es')
setCurrencyCode('EUR')
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData, currencyData, currencies)

const entitiesModule = ModuleNamespaces.ENTITIES

const toolbarItems = [
  'Grid',
  'Chart',
  'SubTotal',
  'GrandTotal',
  'Load',
  'Save',
  'SaveAs',
  'Rename',
  'Remove',
  'FieldList'
]

const chartTypesOptions = ['Column', 'Bar', 'Line', 'Area', 'Doughnut']

const displayOptions = { view: 'Both' }

@Component({
  provide: {
    pivotview: [Toolbar, FieldList, ExcelExport]
  }
})
export default class GridPivotTableTemplatesMixin extends Vue {
  @Prop({
    type: [Array, Object]
  })
  itemsData!: []

  @Prop({
    type: [Array, Object]
  })
  itemsList!: []

  @Prop({
    type: Array,
    default: () => toolbarItems
  })
  toolbarOptions!: () => []

  @Prop({
    type: Object,
    default: () => displayOptions
  })
  displayOption!: () => {}

  @Prop({
    type: Array,
    default: () => chartTypesOptions
  })
  chartTypes!: () => {}

  @Prop({
    type: String
  })
  title!: string

  @Prop({
    type: String,
    default: 'es'
  })
  locale!: string

  @Prop({
    type: Boolean,
    default: false
  })
  enablePersistence!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  allowPdfExport!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  allowExcelExport!: boolean

  @Prop({
    type: String,
    default: 'EUR'
  })
  currencyCode!: string

  @Getter('getSelectedEntityName', { namespace: entitiesModule }) selectedEntityName: string

  @Getter('getMenuParentCategoryName', { namespace: entitiesModule }) menuParentCategoryName: string

  gridElement: any = null

  frozenCols: number = 1

  chartSettings: any = {
    tooltip: {
      enableMarker: true,
      textStyle: { fontFamily: mttMilano, color: '#FFF' },
      fill: '#001978',
      opacity: 1,
      border: { color: '#001978' }
    },
    textStyle: { fontFamily: mttMilano }
  }

  checkboxFilter: object = {
    type: 'CheckBox'
  }

  spinnerTemplate: any = `
    <div class='grid-table-spinner-container'>
      <div class='grid-table-spinner-background'></div>
      <span class='spinner-lefebvre spinner-md'></span>
    </div>
`
  filterSettings: FilterSettings = {
    type: 'Menu',
    ignoreAccent: true,
    columns: [],
    operators: {
      stringOperator: filterSettingsTypesOperator
    }
  }

  selectedRegister: any = null

  get bothGridsSharedProps(): object {
    return {
      allowFiltering: true,
      allowPaging: true,
      allowPdfExport: true,
      allowReordering: true,
      allowResizing: true,
      allowSorting: true,
      currencyCode: this.currencyCode,
      enablePersistance: true,
      excelExportComplete: this.showActionsColumn,
      frozenColumns: this.frozenCols,
      pdfExportComplete: this.pdfExportComplete,
      pdfQueryCellInfo: this.formatZeroValues,
      rowHeight: '50',
      rowSelected: this.onRowSelected,
      expandAll: false,
      enableSorting: true,
      allowValueFilter: true
    }
  }

  formatZeroValues(args: any) {
    if (args.column.format === 'C2' && args.value === '') {
      args.value = '00,00 €'
    }
  }

  getCustomAttributes(customAttributes: object) {
    return customAttributes ? customAttributes : null
  }

  onRowSelected(args: any) {
    this.selectedRegister = args.data
  }

  iconColumnTemplate() {
    return {
      template: Vue.component('iconColumnTemplate', {
        name: 'iconColumnTemplate',
        template: `<span :class="[getIcon, 'cell-icon']"></span>`,
        data() {
          return {
            data: {}
          }
        },
        computed: {
          iconClassName() {
            return (this as any).data.column.customAttributes.iconClassName
          },
          columnValue() {
            return (this as any).data.iconColumnValue === '1'
          },
          getIcon() {
            return (this as any).columnValue ? (this as any).iconClassName : ''
          }
        }
      })
    }
  }

  contextMenuColumnTemplate() {
    return {
      template: Vue.component('contextMenuColumnTemplate', {
        name: 'contextMenuColumnTemplate',
        template: `
          <div>
            <span class='lf-icon-kebab-menu context-menu-launcher'></span>
          </div>
        `
      })
    }
  }

  columnStatusTemplate() {
    return {
      template: Vue.component('columnStatusTemplate', {
        name: 'columnStatusTemplate',
        template: `
            <div id="customers-table-status">
              <span :class="getTextColor(getText)">{{ getText }}</span>
            </div>`,
        data() {
          return {
            data: {}
          }
        },
        computed: {
          getText() {
            return (this as any).data.statePortalText || (this as any).data.state
          }
        },
        methods: {
          getTextColor(status: string): string {
            switch (status) {
              case 'Activado':
                return 'green status'
              case 'Realizada':
                return 'green status'
              case 'Bloqueado':
                return 'red status'
              case 'Planificada':
                return 'red status'
              case 'Pendiente':
                return 'status'
              default:
                return ''
            }
          }
        }
      })
    }
  }

  dataToHourTemplate() {
    return {
      template: Vue.component('dataToHourTemplate', {
        name: 'dataToHourTemplate',
        template: `
            <div id="customers-time-status">
              <span>{{ getTime }}</span>
            </div>`,
        data() {
          return {
            data: {}
          }
        },
        computed: {
          getTime() {
            return numberToHourFormat((this as any).data.duration)
          }
        }
      })
    }
  }

  showActionsColumn(status: boolean = true) {
    this.gridElement.getColumns()[0].visible = status
  }

  async pdfExportComplete(args: { promise: Promise<any> }) {
    const event = await args.promise
    const blobURL = URL.createObjectURL(event.blobData)
    window.open(blobURL)
  }

  exportToPDF() {
    let obj: any = document.getElementById('pivotview')
    if (obj) {
      obj = obj.ej2_instances[0]
      const pdfExportProperties: any = {
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        exportType: 'CurrentPage',
        header: {
          fromTop: 0,
          height: 30,
          contents: [
            {
              type: 'Text',
              value: this.title,
              position: { x: 0, y: 10 },
              style: { textBrushColor: '#000000', fontSize: 13 }
            }
          ]
        },
        theme: {
          caption: { font: new PdfTrueTypeFont(mttMilano, 12) },
          header: { font: new PdfTrueTypeFont(mttMilano, 10) },
          record: { font: new PdfTrueTypeFont(mttMilano, 9) },
          footer: { font: new PdfTrueTypeFont(mttMilano, 10) }
        }
      }

      obj.pdfExport(pdfExportProperties)
    }
  }

  exportToExcel() {
    let obj: any = document.getElementById('pivotview')
    if (obj) {
      obj = obj.ej2_instances[0]
      obj.excelExport()
    }
  }

  exportToCSV() {
    let obj: any = document.getElementById('pivotview')
    if (obj) {
      obj = obj.ej2_instances[0]
      obj.csvExport()
    }
  }
}
</script>

<style lang="scss">
.yes-color {
  color: $success-color;
}

.no-color {
  color: $error-color;
}

.cell-icon {
  @include flex;
}
</style>
