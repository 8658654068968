<template lang="pug">

section(class="lexnet-container")
  HeaderComponent
  router-view

</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { mixins } from 'vue-class-component'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Mutation } from 'vuex-class'

const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    HeaderComponent
  }
})
export default class LexnetView extends mixins(LexnetMixin) {
  @Mutation('SHOW_SPINNER', { namespace: spinnerModule })
  showSpinner: (showText: boolean) => void

  async created() {
    this.showSpinner(true)
    await this.getLexNetToken()
    this.openLexnetApp()
  }
}
</script>

<style lang="scss" scoped>
.lexnet-container {
  @include external-view-layout;
}
</style>
