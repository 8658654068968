import { ActionTree } from 'vuex'
import {
  alertsMessagesMutationTypes,
  AlertsMessagesState,
} from '@/store/modules/alertsMessages/alertsMessagesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { alertMessageListAdapter } from './alertsMessagesAdapters'

export const actions: ActionTree<AlertsMessagesState, RootState> = {
  async fetchAlertsMessagesList({ commit }) {
    try {
      commit(alertsMessagesMutationTypes.RESET_ALERT_MESSAGES_LIST)
      const { data } = await new MainService().getData(`v2/alert`)
      commit(alertsMessagesMutationTypes.UPDATE_ALERTS_MESSAGES, alertMessageListAdapter(data))
    } catch (error) {
      commit(alertsMessagesMutationTypes.SET_LOAD_ALERT_MESSAGES_LIST_ERROR)
    }
  },
  async fetchFilteredList({ commit, state }) {
    try {
      commit(alertsMessagesMutationTypes.RESET_FILTERED_LIST)
      const { data } = await new MainService().getData(`v2/alert`, {
        params: { filter: state.filter }
      })
      commit(alertsMessagesMutationTypes.UPDATE_FILTERED_LIST, alertMessageListAdapter(data))
    } catch (error) {
      commit(alertsMessagesMutationTypes.SET_LOAD_FILTERED_LIST_ERROR)
    }
  },
  async changeVisibility({ dispatch }, alertMessage) {
    const formData: FormData = new FormData()
    formData.append('visibility', alertMessage.visibility)
    await new MainService().putFormData(`v2/alert/${ alertMessage.id }/visibility`, formData)
    await dispatch('fetchAlertsMessagesList')
    await dispatch('fetchFilteredList')
  },
  async setFilter({ commit }, filter) {
    commit(alertsMessagesMutationTypes.SET_FILTER, filter)
  },
  async checkAlertsMessages({ commit }) {
    try {
      const { data } = await new MainService().getData(`v2/alert/check`)
      commit(alertsMessagesMutationTypes.SET_HAS_ALERTS, data.hasAlert)
    } catch (error) {}
  }
}
