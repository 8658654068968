import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsData, AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

export interface AlertWithoutType {
  message: string | null
  componentWhereIsRendered?: ComponentWhereIsRendered
}

interface ReturnUseFeedback {
  showFeedback: ({ type, message, componentWhereIsRendered }: AlertsData) => Promise<void>
  showFeedbackError: ({ message, componentWhereIsRendered }: AlertWithoutType) => Promise<void>
  showFeedbackSuccess: ({ message, componentWhereIsRendered }: AlertWithoutType) => Promise<void>
  showFeedbackInfo: ({ message, componentWhereIsRendered }: AlertWithoutType) => Promise<void>
  showFeedbackWarning: ({ message, componentWhereIsRendered }: AlertWithoutType) => Promise<void>
}

export default function useFeedback(): ReturnUseFeedback {
  const showFeedback = async ({ type, message, componentWhereIsRendered = ComponentWhereIsRendered.MAIN }: AlertsData) => {
    store.dispatch(`${ModuleNamespaces.ALERTS}/showAlert`, { type, message, componentWhereIsRendered })
  }

  const showFeedbackError = async ({ message, componentWhereIsRendered = ComponentWhereIsRendered.MAIN }: AlertWithoutType) => {
    const type = AlertsTypes.ERROR
    showFeedback({ type,  message, componentWhereIsRendered })
  }

  const showFeedbackSuccess = async ({ message, componentWhereIsRendered = ComponentWhereIsRendered.MAIN }: AlertWithoutType) => {
    const type = AlertsTypes.SUCCESS
    showFeedback({ type,  message, componentWhereIsRendered })
  }

  const showFeedbackInfo = async ({ message, componentWhereIsRendered = ComponentWhereIsRendered.MAIN }: AlertWithoutType) => {
    const type = AlertsTypes.INFO
    showFeedback({ type,  message, componentWhereIsRendered })
  }

  const showFeedbackWarning = async ({ message, componentWhereIsRendered = ComponentWhereIsRendered.MAIN }: AlertWithoutType) => {
    const type = AlertsTypes.WARNING
    showFeedback({ type,  message, componentWhereIsRendered })
  }

  return {
    showFeedback,
    showFeedbackError,
    showFeedbackSuccess,
    showFeedbackInfo,
    showFeedbackWarning
  }
}
