<template lang="pug">

  LfGrayCounterComponent(:info="counter" class="profitability-component-container")

</template>

<script lang="ts">
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { formatNumber } from '@/helpers/numeric'
import LfGrayCounterComponent from '@/components/counters/LfGrayCounterComponent.vue'

@Component({
  components: {
    LfGrayCounterComponent
  }
})
export default class ProfitabilityComponent extends Vue {
  get formatQty() {
    if (!this.$attrs.value) {
      return '0 €'
    }
    const value = Number(this.$attrs.value)
    const roundedValue = Number(value.toFixed(2))
    const formattedNumber = formatNumber(roundedValue, 0, 'de-DE')
    return `${formattedNumber} €`
  }

  get counter() {
    return {
      title: this.$attrs.label,
      qty: this.formatQty
    }
  }
}
</script>

<style lang="scss" scoped>
.profitability-component-container {
  &.lf-counter-wrapper {
    flex-direction: column !important;
    margin-bottom: $spacing-md;
  }
}
</style>
