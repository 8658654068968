import { Module } from 'vuex'
import { getters } from '@/store/modules/sub-actions/subActionsGetters'
import { actions } from '@/store/modules/sub-actions/subActionsActions'
import { mutations } from '@/store/modules/sub-actions/subActionsMutations'
import { RootState } from '@/store/types/storeGlobalTypes'
import { SubActionsState } from '@/store/modules/sub-actions/subActionsTypes'

export const state: SubActionsState = {
  subAction: null,
  parentAction: null,
  subActionlist: null,
  actionlist: null,
  selectedSubActionType: '',
  chronometerNewActionInfo: {
    start: '',
    end: '',
    duration: 0
  }
}

const namespaced: boolean = true

export const subActionsModule: Module<SubActionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
