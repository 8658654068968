<template lang="pug">
div
  LfHeaderTitleComponent(:title="texts.headerTitle" class="header-title")
  LfTotalsComponent(
    v-if="totals && totals.length"
    :items="totals"
  )
  GridTable(
    v-if="renderGrid && !showSpinnerLayer"
    ref="grid"
    :itemsData="isPortalUser ? listItems : serverSideData"
    :gridConfiguration="listConfig"
    :listName="listName"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="invoiceContextMenuItems"
    :context="context"
    :archivedFilter="archivedSelectedOption"
    :disabledGridCellClicked="getDisableGridProperty"
    :activeOnClickCell="selectedOnlyOneInvoice"
    :checkboxSelectColumn="hasCompanyTemplateInvoice"
    :showFirstColumn="!hasCompanyTemplateInvoice"
    :frozenColumns="isPortalUser ? 0 : 2"
    :commandClick="onCommandClick"
    @contextMenuClicked="onContextMenuClicked"
    @contextMenuBeforeOpen="onContextMenuBeforeOpen"
    @toolbarClicked="onToolbarClicked"
    @selectedRecords="onSelectedRecords"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    :excelExportedFileName="fileName"

    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    :useServerPagination="useServerPagination"
    :usePersistSelection="usePersistSelection"
    :itemsDataExport="serverSideExportData"
    @cellClicked="onCellClicked"
  )

  PrintOrSaveInvoicesDialog(
    v-if="renderPrintOrSaveInvoicesDialog"
    :showDialog="showTemplateDialog"
    :idEntityType="idEntityType"
    @closePrintOrSaveInvoiceDialog="closeTemplateDialog"
    @generateTemplateDialog="generateTemplateDialog"
  )

  TemplateSelectorComponent(
    v-if="renderTemplateSelectorComponent"
    :showDialog="showTemplateDialog"
    :idEntityType="idEntityType"
    @closeTemplateDialog="closeTemplateDialog"
    @generateTemplateDialog="generateTemplateDialog"
  )
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { URLS } from '@/router/routes/urlRoutes'
import PrintOrSaveInvoicesDialog from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialog.vue'
import { UserType } from '@/store/modules/auth/authTypes'
import { InvoiceEntityId } from '@/general/entityIds'
import { MAX_SELECTED_INVOICE_PRINT } from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import { InvoiceGenerated, InvoiceStatus, InvoiceTypesNames } from '@/store/modules/invoices/invoicesTypes'
import { MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ContextMenuClickEventArgs, DataResult } from '@syncfusion/ej2-vue-grids'
import useGridSaveUserConf from '@/composables/useGridSaveUserConf'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
import { resetLoadedCustomMultiselectFlags } from '@/components/grids/CustomFilters'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { InvoiceMaxNumberFormFields } from '@/mixins/InvoicesMaxNumberFormFieldsLogic/types/InvoicesMaxNumberFormFieldsLogicMixinTypes'

const billingModule: string = ModuleNamespaces.BILLING
const invoicesModule = ModuleNamespaces.INVOICES
const alertsModule = ModuleNamespaces.ALERTS
const authModule = ModuleNamespaces.AUTH
const configurationModule = ModuleNamespaces.CONFIGURATION

const { parseUserConfig } = useGridSaveUserConf()

@Component({
  components: {
    TemplateSelectorComponent,
    GridTable,
    LfHeaderTitleComponent,
    LfTotalsComponent,
    PrintOrSaveInvoicesDialog
  }
})
export default class ProformaInvoicesView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin,
  PortalUserMixin
) {
  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Action('fetchProformaInvoiceList', { namespace: billingModule })
  fetchProformaInvoiceList: (filter: {}) => Promise<void>

  @Action('fetchProformaInvoiceTotals', { namespace: billingModule })
  fetchProformaInvoiceTotals: (filter: {}) => Promise<void>

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('convertProformaToCustomerInvoice', { namespace: invoicesModule })
  convertProformaToCustomerInvoice: (idInvoice: number) => Promise<string>

  @Action('fetchInvoices', { namespace: invoicesModule })
  fetchPortalInvoices: (filter: {}) => []

  @Action('billingRemove', { namespace: billingModule })
  removeProformaInvoice: (url: string) => { success: boolean; message: string }

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getBillingData', { namespace: billingModule })
  invoiceData: []

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Mutation('ADD_GENERATE_INVOICE', { namespace: invoicesModule })
  addGenerateInvoice: (selectedInvoices: InvoiceGenerated[]) => void

  useServerPagination: boolean = true
  usePersistSelection: boolean = true

  serverSideData: DataResult = {} as DataResult
  serverSideExportData = []
  gridFilters: any = { page: 0, pageSize: 10, columnOrder: [] }

  fileName = InvoiceTypesNames.PROFORMA

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.proforma.type
  selectedInvoices = []

  texts = {
    headerTitle: this.$t('views.billing_proforma_invoices.title').toString(),
    openNewWindow: this.$t('components.context_menu.open_window'),
    printButton: this.$t('components.context_menu.generate_invoice')
  }

  get renderPrintOrSaveInvoicesDialog(): boolean {
    return this.hasCompanyTemplateInvoice && !this.isPortalUser
  }

  get renderTemplateSelectorComponent(): boolean {
    return this.hasCompanyTemplateInvoice && this.isPortalUser
  }

  get context() {
    return ContextName.BILLING
  }

  /** @override **/
  get printButton() {
    return {
      iconCss: this.isPortalUser ? Icons.OPEN_WINDOW : Icons.DOCUMENT_VALIDATE,
      text: this.isPortalUser ? this.texts.openNewWindow : this.texts.printButton
    }
  }

  get listConfig() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const index = config.columns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      config.columns[index].visible = this.hasCompanyTemplateInvoice
    }
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_KEBAK_MENU,
        type: 'None',
        title: 'Open',
        buttonOption: {
          iconCss: Icons.KEBAB,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  get listName() {
    return (this as any).listConfiguration['Alias']
  }

  get toolbarOptionsCustom() {
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(!this.isPortalUser
        ? [
            {
              id: 'add',
              text: this.$t('components.grid_table.tooltip.new_proforma_invoice'),
              tooltipText: this.$t('components.grid_table.tooltip.new_proforma_invoice'),
              cssClass: 'lf-btn-model2',
              align: 'Right'
            }
          ]
        : []),
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectArchivedOption
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get invoiceContextMenuItems() {
    if (this.isPortalUser) {
      return [this.printButton]
    }

    const items = this.contextMenuItemsDefault
    const getPrintContextMenuItems = this.getPrintContextMenuItems(items)
    const { canSave } = this.checkEntityPermissionsGetter(InvoiceEntityId.PROFORMA_INVOICES)

    if (!!canSave) {
      getPrintContextMenuItems.splice(2, 0, {
        iconCss: Icons.COIN,
        text: this.$t('action_buttons.convert_to_customer_invoice')
      })
    }

    return this.getPrintContextMenuItems(items)
  }

  get filters() {
    const filters = {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }

    return this.isPortalUser ? { ...filters, ...this.portalFilters } : filters
  }

  get selectedOnlyOneInvoice() {
    return this.selectedInvoices.length <= 1
  }

  async created() {
    await this.rememberUserCustomConfigurationList(true)
    await this.fetchCurrentListConfiguration(ListNames.PROFORMA_INVOICES)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.getFilterParams()
    this.fetchGridItems()
    this.fetchInvoiceTotals()

    this.saveSelectedEntityName(BillingTypes.PROFORMA_INVOICES)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchPortalInvoices({
        archived: this.invoicesDefaulArchivedOption,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.CUSTOMER_PROFORMA_INVOICES
      })
      return
    }

    await this.fetchProformaInvoiceList({
      archived: this.archivedSelectedOption,
      ...this.filters
    })
    this.serverSideData = this.formatDataPaginated(this.invoiceData)
  }

  fetchInvoiceTotals() {
    this.fetchProformaInvoiceTotals(this.filters)
  }

  async getFilterParams() {
    const listConfig = JSON.parse((this as any).listConfiguration['Config'])
    this.archivedSelectedOption = listConfig.archived || 0
    this.gridFilters = await parseUserConfig(listConfig)
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item.id === ActionName.EDIT || args.item.id === ActionName.OPEN_WINDOW) {
      trackEvent(TrackerEvents.EDIT_INVOICE)
    }

    const isPrintButton =
      args.item.text === this.texts.printButton || (this.isPortalUser && args.item.text === this.texts.openNewWindow)
    const isConvertButton = args.item.text === this.$t('action_buttons.convert_to_customer_invoice')
    const isRemoveButton = args.item.id === ActionName.REMOVE

    if (isPrintButton && this.selectedInvoices.length > MAX_SELECTED_INVOICE_PRINT) {
      this.showDialog({
        type: DialogTypes.WARNING,
        hideSecondaryButton: true,
        message: this.$t('components.dialog.exceeded_number_of_selected_invoices', {
          selectedInvoicesLength: this.selectedInvoices.length,
          maxInvoiceSelected: MAX_SELECTED_INVOICE_PRINT
        })
      })
      args.cancel = true
      return
    } else if (isPrintButton && this.hasCompanyTemplateInvoice && !this.isPortalUser) {
      this.idEntity = selectedRegister.id
      this.showTemplateDialog = true
      const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
        return {
          idEntity: idInvoice,
          idEntityType: this.idEntityType,
          numberInvoice,
          status: InvoiceStatus.LOADING
        }
      })
      this.addGenerateInvoice(selectedInvoices)
    } else if (isPrintButton && this.hasCompanyTemplateInvoice && this.isPortalUser) {
      this.showTemplateDialog = this.checkIfOnlyOneInvoice(selectedRegister.id, this.idEntityType)
      return
    } else if (isPrintButton && !this.hasCompanyTemplateInvoice) {
      this.printInvoiceOld(selectedRegister.id, entity.invoices.proforma.alias)
    } else if (isConvertButton) {
      this.convertToCustomerInvoice(selectedRegister.id)
    } else if (isRemoveButton) {
      args.cancel = true
      this.confirmDelete(selectedRegister.id, selectedRegister.description)
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onContextMenuBeforeOpen(component: any, grid: any, selectedRegister: any) {
    const disabledBtn: boolean = !Boolean(selectedRegister.billed)
    component.ej2Instances.enableItems([this.$t('action_buttons.convert_to_customer_invoice')], disabledBtn)
    grid.enableItems([this.$t('action_buttons.convert_to_customer_invoice')], disabledBtn)

    grid.items.forEach((item: MenuItemModel) => {
      const enable = !(item.text !== this.texts.printButton && !this.selectedOnlyOneInvoice)
      component.enableItems([item.text], enable)
      grid.enableItems([item.text], enable)
    })
  }

  convertToCustomerInvoice(id: number) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.associated_invoice'),
      action: () => this.convertingToCustomerInvoice(id)
    })
  }

  async convertingToCustomerInvoice(id: number) {
    try {
      const newCustomerInvoiceId = await this.convertProformaToCustomerInvoice(id)

      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.$t('components.alerts.associated_invoice_success'),
        componentWhereIsRendered: ComponentWhereIsRendered.GRID_TABLE
      })

      const routeData = this.$router.resolve({
        name: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
        params: { selectedRegisterId: newCustomerInvoiceId }
      })

      window.open(routeData.href, '_blank')

      this.fetchGridItems()
    } catch (error) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.assocciated_invoice_error')
      })
    }
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
    const { item } = args
    if (item.id === ActionName.ADD) {
      trackEvent(TrackerEvents.CREATE_INVOICE)
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
    await this.fetchInvoiceTotals()
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedInvoices = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }

  open(id: string, target = '_self') {
    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.PROFORMA_INVOICES}`,
      params: { selectedRegisterId: id }
    })

    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  print(id: string) {
    if (!this.hasCompanyTemplateInvoice) {
      this.idEntity = id
      this.showTemplateDialog = true
      const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
        return {
          idEntity: idInvoice,
          idEntityType: this.idEntityType,
          numberInvoice,
          status: InvoiceStatus.LOADING
        }
      })
      this.addGenerateInvoice(selectedInvoices)
    } else {
      this.printInvoiceOld(id, entity.invoices.proforma.alias)
    }
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { id, description } = rowData as any
    switch (action) {
      case ActionName.REMOVE:
        this.confirmDelete(id, description)
        break
      case ActionName.PRINT:
        this.print(id)
        break
      case ActionName.OPEN_NEW_TAB:
        this.open(id, '_blank')
        break
    }
  }

  confirmDelete(id: string, description: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: description,
        text: this.$t('components.dialog.proforma_invoices_literal')
      }),
      action: async () => {
        await this.remove(id, description)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async remove(id: string, description: string) {
    const url = `/invoices/proforma/${id}/delete`
    const { success } = await this.removeProformaInvoice(url)
    if (success) {
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.proforma_invoices_literal'),
          register: description
        })
      })
      return
    }
    this.showDialogAction({
      type: DialogTypes.ERROR,
      message: this.$t('components.dialog.error_remove_invoice')
    })
  }

  onCustomToolbarContextMenuClick(args: ContextMenuClickEventArgs) {
    const { item } = args
    const { id } = item

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onInvoicesArchivedClick(args)
      return
    }
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  gridActionChange(serverSideParams: string) {
    const { page, pageSize, columnOrder, search, filter } = JSON.parse(serverSideParams)
    this.gridFilters = {
      ...this.filters,
      page,
      pageSize,
      columnOrder,
      filter,
      search
    }
    this.fetchGridItems()
    this.fetchInvoiceTotals()
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchProformaInvoiceList(ssp)
    this.serverSideExportData = this.formatData(this.billingData)
  }

  onCellClicked() {
    trackEvent(TrackerEvents.EDIT_INVOICE)
  }

  beforeDestroy() {
    resetLoadedCustomMultiselectFlags([InvoiceMaxNumberFormFields.SEQUENCE])
  }
}
</script>
