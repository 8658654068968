<template lang="pug">
  ValidationProvider(
    :rules="validationRules"
    v-slot="{ errors }"
    tag="div"
    :vid="$attrs.id"
    )
    div(
      :class="[{ 'error-styles': errors[0] },'lf-switch-container', { 'disabled-field': disabled , 'lf-custom-switch-style': customStyle }]"
    )
      div(v-if="customStyle" class="lf-custom-switch-style-container")
        span(:class="[ customStyle.icon, 'lf-custom-switch-style-icon' ]")
        span(class="lf-custom-switch-style-text" v-html="customStyle.text")
      v-spacer(v-if="customStyle")
      div
        div(v-if="label" class="lf-switch label-container" @click="emitClickEvent")
          v-switch(
            v-bind="$attrs"
            v-model="innerValue"
            :id="id"
            :label="label"
            :disabled="disabled"
            :class="innerValue ? 'on' : 'off'"
            @change="emitValue"
            :ripple="false"
          )
          span(
            v-if="requiredField"
            class="required-field") *
          v-tooltip(
              v-if="showInfo"
              top
              color="primary"
              dark
            )
            template(v-slot:activator="{ on, attrs }")
              span(v-bind="attrs" v-on="on" :class="[icons.info, 'label-icon']")
            span(class="show-info-message") {{ showInfo }}
        div(class="messages-container")
          span(v-if="errors.length" class="alert-message") {{ errors[0] }}
          span(v-else class="help-message") {{ helpText }}
</template>

<script lang="ts">
import { Getter } from 'vuex-class'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Icons } from '@/icons/icons'

const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonSwitchComponent extends Vue {
  @Getter('getCustomConfigText', { namespace: configurationModule })
  getCustomConfigText: string

  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  showInfo!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({
    type: [String, Number, Boolean],
    default: ''
  })
  value!: string | number | boolean

  @Prop({
    type: Boolean,
    default: false
  })
  fullWidth!: boolean

  @Prop({
    type: String,
    default: 'text'
  })
  type!: string

  @Prop({
    type: [Boolean, Object],
    default: false
  })
  customStyle!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  returnBoolean!: boolean

  icons = {
    clearIcon: Icons.CLOSE_ROUND,
    info: Icons.INFO_FULL
  }

  innerValue: boolean = false

  @Emit()
  emitValue(value: any) {
    const val: string = value ? '1' : '0'
    return this.$emit('input', val)
  }

  @Emit()
  emitClickEvent() {
    return this.innerValue
  }

  @Watch('value')
  changedVal() {
    this.changeValue()
  }

  @Watch('innerValue')
  changedValue(newVal: boolean) {
    const val: string = newVal ? '1' : '0'
    const finalValue = this.returnBoolean ? Boolean(Number(val)) : val
    this.$emit('input', finalValue)
  }

  @Watch('customConfigText')
  changedCustomConfigTextValue(newVal: boolean) {
    ;(this as any).customStyle.text = newVal
  }

  get customConfigText() {
    return this.getCustomConfigText
  }

  created() {
    this.changeValue()
    if (this.customStyle !== false) {
      this.checkCustomStyleText()
    }
  }
  checkCustomStyleText() {
    if (this.getCustomConfigText !== '') {
      ;(this as any).customStyle.text = this.customConfigText
    }
  }
  changeValue() {
    let value = this.value
    if ('string' === typeof this.value) {
      value = Number(this.value)
    }
    this.innerValue = !!value
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }
}
</script>

<style lang="scss" scoped>
.lf-switch-container {
  @include flex($justify-content: flex-start);
  @include textfield-messages;
  width: 100%;

  ::v-deep .v-messages {
    display: none;
  }

  ::v-deep .v-input {
    &.v-input--selection-controls {
      margin-top: 25px;
    }

    .v-input--selection-controls__input {
      position: relative;

      .v-input--switch__thumb {
        position: absolute;
        top: 6px;
        left: 3px;
        width: 12px;
        height: 12px;
        box-shadow: none;
        background-color: $main-1000;
      }

      .v-input--switch__track {
        @include borders;
        position: absolute;
        top: 2px;
        left: 0;
        width: $input-size;
        height: 20px;
        border-radius: 20px;
        opacity: 1;
        background-color: $neutral-white-000;
      }
      &:hover {
        .v-input--switch__track {
          background-color: $neutral-grey-200;
        }
      }
    }

    .v-label {
      @include milano-regular-16;
      color: $neutral-grey-800;
      text-align: center;
    }

    &.on {
      .v-input--selection-controls__input {
        .v-input--switch__thumb {
          background-color: $neutral-grey-200;
        }

        .v-input--switch__track {
          background-color: $main-1000;
        }
      }
    }
  }

  &.disabled-field {
    ::v-deep .v-input {
      .v-input--selection-controls__input {
        .v-input--switch__thumb {
          background-color: $neutral-grey-400;
        }

        .v-input--switch__track {
          border-color: $neutral-grey-400;
        }
      }

      &.on {
        .v-input--switch__thumb {
          background-color: $neutral-grey-400;
        }

        .v-input--switch__track {
          background-color: $neutral-grey-200;
          border-color: $neutral-grey-400;
        }
      }
    }
  }

  .label-container {
    @include flex;
  }
}

.compressed .lf-switch-container ::v-deep .messages-container {
  display: none;
}
</style>
