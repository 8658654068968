import { SecurityPermissions } from '@/store/modules/security/securityTypes'

export const authLexonToken = 'AUTH-LEXON-TOKEN'
export const authLexonPartnerId = 'LEXON-PARTNER-ID'

export const AREA_FIRMA = 33
export const AREA_TANDEM = 99999
export const DOCS_IA = [60, 113]

export enum UserType {
  UNDEFINED,
  LEXON,
  PORTAL,
  LEXON_DMS
}

export interface Company {
  idCompany: number
  name: string
  database: string
  navisionId: string
}
export interface UserInfo {
  companies: Company[]
  company: Company
  customerId: number
  dni?: string | null
  doubleOptin: boolean
  email: string | null
  firstName: string
  hasAccessToLegalProducts: boolean
  id: number
  idProUser: string | null
  isPortalUser: boolean
  nif: string
  mobilePhone: string
  lastName: string
  permissions: SecurityPermissions
  type: number
  subscription: number[]
  encryptedUserId: string
  permission: PermissionItem[] | null
  lastAccess: string | null
}

export interface AuthData {
  accessToken: string
  refreshToken: string
  user: UserInfo
}

export interface PreAuthData {
  preAuthToken: string
  user: UserInfo
}

export interface AuthState {
  rememberCredentials: boolean
  salt: number
  preAuthData: PreAuthData | null
  authData: AuthData | null
  companyName: string
  cancelTokens: any[]
  encryptIdProUser: string
  lastRefreshTokenTime: number
  tools: UserTool []
}

export interface Credentials {
  email: string
  password: string
}

export const authMutationTypes = {
  ADD_CANCEL_TOKEN: 'ADD_CANCEL_TOKEN',
  CLEAR_CANCEL_TOKENS: 'CLEAR_CANCEL_TOKENS',
  ENCRYPT_ID_PRO_USER: 'ENCRYPT_ID_PRO_USER',
  LOGOUT: 'LOGOUT',
  NAVISION_LOGOUT: 'NAVISION_LOGOUT',
  SET_AUTH_DATA: 'SET_AUTH_DATA',
  SET_PRE_AUTH_DATA: 'SET_PRE_AUTH_DATA',
  SET_LAST_REFRESH_TOKEN_TIME: 'SET_LAST_REFRESH_TOKEN_TIME',
  SET_USER_NIF: 'SET_USER_NIF',
  SET_USER_TOOLS: 'SET_USER_TOOLS',
  RESET_PRE_AUTH_DATA: 'RESET_PRE_AUTH_DATA'
}

export interface PermissionItem {
  // entity: {
  //   id: number,
  //   name: string
  // }
  idEntityType: number
  permission: PermissionVerbs
}

export interface PermissionVerbs {
  canSave: number
  canDelete: number
  canView: number
  // canSave: boolean
  // canDelete: boolean
  // canView: boolean
}

export interface LogoutArgs {
  isNavision: boolean
}

export interface UserTool {
  icon: string,
  name: string,
  url: string
}

