import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import useRouter from '@/composables/useRouter'
import { computed } from 'vue'

const authModule: string = ModuleNamespaces.AUTH;
const entitiesModule: string = ModuleNamespaces.ENTITIES;

export default function usePermissions() {

  // ROUTER
  const { route } = useRouter()

  function checkEntityPermissionsGetter(entityId: number): PermissionVerbs {
    return store.getters[`${authModule}/getPermission`](entityId)
  }

  const selectedIdEntityTypeGetter = computed(() => store.getters[`${entitiesModule}/getSelectedIdEntityType`])

  const viewPermission = computed(() => {
    return checkEntityPermissionsGetter(route?.meta.entityType || selectedIdEntityTypeGetter.value)
  })

  const userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails = computed(() => {
    const actionTypes = [
      entity.actions.calls.type,
      entity.actions.emails.type,
      entity.actions.meetings.type,
      entity.actions.others.type,
      entity.actions.procedures.type
    ];
    return actionTypes.some((actionType) => checkEntityPermissionsGetter(actionType).canSave);
  });

  const userHasPermissionToSaveAtLeastOneActionType = computed(() => {
    const actionTypes = [
      entity.actions.calls.type,
      entity.actions.meetings.type,
      entity.actions.others.type,
      entity.actions.procedures.type
    ];
    return actionTypes.some((actionType) => checkEntityPermissionsGetter(actionType).canSave)
  });

  const userHasPermissionToInvoiceAtLeastOneInvoiceType = computed(() => {
    const invoiceTypes = [
      entity.invoices.customers.type,
      entity.invoices.orderForms.type,
      entity.invoices.proforma.type,
      entity.invoices.providers.type
    ]
    return invoiceTypes.some((type) => checkEntityPermissionsGetter(type).canSave)
  });

  const userHasPermissionToViewAgenda = computed(() => {
    const agendaPermissions = checkEntityPermissionsGetter(entity.agenda.type)
    return !!agendaPermissions.canView
  })

  const userHasPermissionToViewCustomerInvoices = computed(() => {
    const customerInvoicesPermissions = checkEntityPermissionsGetter(entity.invoices.customers.type)
    return !!customerInvoicesPermissions.canView
  });

  const userHasPermissionToViewActions = computed(() => {
    const actionsAllPermission = checkEntityPermissionsGetter(entity.actions.type)
    return !!actionsAllPermission.canView
  });

  const userHasPermissionToViewExpedients = computed(() => {
    const expedientsPermission = checkEntityPermissionsGetter(entity.expedients.type)
    return !!expedientsPermission.canView
  })

  function checkFormPermissions(schema: any, entityId: number): any {
    const { canSave } = checkEntityPermissionsGetter(entityId)
    if (!canSave) {
      schema.map((item: any) => {
        if (item.fields) {
          item.fields.map((i: any) => (i.disabled = true))
        }
        item.disabled = true
      })
    }
    return schema
  }

  const permissionsEntity = route?.meta!.entityType || selectedIdEntityTypeGetter.value

  return {
    checkEntityPermissionsGetter,
    checkFormPermissions,
    permissionsEntity,
    userHasPermissionToInvoiceAtLeastOneInvoiceType,
    userHasPermissionToSaveAtLeastOneActionType,
    userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails,
    userHasPermissionToViewActions,
    userHasPermissionToViewAgenda,
    userHasPermissionToViewCustomerInvoices,
    userHasPermissionToViewExpedients,
    viewPermission
  }
}

