<template lang="pug">

section(
  v-if="selectedExpedientTopBarData && selectedExpedientTopBarData.length && !showSpinner"
  class="expedient-global-vision-header"
)

  div(
    v-for="(item, index) in selectedExpedientTopBarData"
    :key="index"
    :class="[item.class, 'header-item']"
  )
    span {{ item.label.toLowerCase() }}
    span(class="data") {{ item.data }}

SpinnerLayerComponent(v-else)

</template>

<script lang="ts">
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ExpedientProfitabilityWarningTooltipComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientProfitabilityWarningTooltipComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    ExpedientProfitabilityWarningTooltipComponent,
    SpinnerLayerComponent
  }
})
export default class ExpedientGlobalVisionTopBarComponent extends Vue {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}
  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  contexts = {
    topBarContext: ContextName.EXPEDIENT_GLOBAL_VISION_TOP_BAR,
    expedientContext: ContextName.EXPEDIENTS
  }

  showSpinner = true

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get selectedExpedientTopBarData() {
    return (this as any).selectedRegisterDataGetter(this.contexts.topBarContext).globalVisionTopBar
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.fetchGlobalVisionTopBarInfo()
  }

  async created() {
    await this.fetchGlobalVisionTopBarInfo()
  }

  async fetchGlobalVisionTopBarInfo() {
    this.showSpinner = true

    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    await this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.topBarContext,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.topBarContext,
        idStage: this.expedientStageStateGetter
      }
    })

    this.showSpinner = false
  }
}
</script>

<style lang="scss" scoped>
.expedient-global-vision-header {
  display: flex;
  justify-content: flex-end;
  padding: $spacing-md;
  align-items: flex-start;
  align-self: stretch;
  border-radius: $cornerRadius-md;
  background: $neutral-grey-100;
  gap: $spacing-xxl;

  .header-item {
    @include milano-bold-14;
    color: $neutral-grey-800;

    &:first-letter {
      text-transform: capitalize;
    }

    .data {
      @include milano-regular-14;
      margin-left: $spacing-xxs;
    }
  }
}
</style>
