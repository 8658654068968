<template lang="pug">
  div(class="expedient-actions")

    SpinnerLayerComponent(v-show="showSpinner" class="spinner-layer")

    div(v-show="!showSpinner")

      template(v-if="!showAddAction")
        LfTotalsComponent(
          v-if="totals && totals.length"
          :items="parsedTotal"
        )
        GridTableComponent(
          v-if="gridConfiguration"
          ref="grid"
          :itemsData="gridData"
          :gridConfiguration="gridConfiguration"
          :title="title"
          :toolbarOptions="customToolbarOptions"
          :contextMenuItems="contextMenuItemsAction"
          :listName="listName"
          :context="actionContext"
          :showHeader="false"
          isInTab
          checkboxSelectColumn
          :showFirstColumn="false"
          :commandClick="onCommandClick"
          @cellClicked="onCellClicked"
          @contextMenuBeforeOpen="customOnContextMenuBeforeOpen"
          @contextMenuClicked="onContextMenuClicked"
          @toolbarClicked="onToolbarClicked"
          @gridTableFilter="onGridTableFilter"
          @selectedRecords="onSelectedRecords"
        )

      div(v-else)
        TabHeaderComponent(
          @closeForm="onCloseForm"
          @saveForm="onSaveForm"
          @removeItem="onRemoveItem"
          @executeAction="executeAction"
          :buttons="buttons"
          :showKebabMenu="showKebabMenu"
          :title="getTitle"
        )
        TabsComponent(
          ref="tabsComponent"
          :tabs="tabs"
          :context="actionContext"
          :entity="entity(actionContext)"
          :autoRefreshForm="autoRefreshForm"
          :permissionsEntityProp="permissionsEntity"
          :simpleGridContextMenuItemsProp="simpleGridContextMenuItems"
          class="tabs-secondary-styles"
          @save-on-tab-change="saveOnTabChange"
          @form-created="onCreatedForm"
          @form-mounted="onInitActionForm"
          @field-changed="onActionFieldChanged"
          @changed-tab="onTabChange"
          :beforeSetComponentProperties="onBeforeSetComponentProperties"
        )

      ejs-contextmenu(
        id="expedientActionsContextMenuComponent"
        class="context-menu-component"
        :items="actionsContextMenuItems"
        :select="onActionAddClick"
      )

</template>

<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionFields } from '@/views/actions/Actions/types/ActionDetailViewTypes'
import { ActionName, ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Action as ActionInterface, ActionTypes, setBasePathByActionType } from '@/store/modules/actions/actionsTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  CommandClickEventArgs,
  CommandModel,
  ActionName as GridActionName
} from '@/components/grids/LfGrid/LfGridTypes'
import {
  ConfigurationAlias,
  ConfigurationAppointmentVersion,
  ConfigurationElements,
  ConfigurationTypes,
  ListNames
} from '@/store/modules/configuration/configurationTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { entity, entityKeyFields } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import store from '@/store/store'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import { MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { commandButtonType } from '@/components/grids/LfGrid/LfGridTypes'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import {
  columnsChooserToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'
import RemoveActionMixin from '@/mixins/RemoveActionMixin.vue'
import { TabNames } from '@/store/modules/tabs/tabsTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const formsModule: string = ModuleNamespaces.FORMS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    GridTableComponent,
    SpinnerLayerComponent,
    LfTotalsComponent,
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue')
  }
})
export default class ExpedientActionsComponent extends Mixins(
  ActionsMixin,
  RemoveActionMixin,
  GridContextMenuOptionsMixin,
  GridToolbarOptionsMixin,
  LexnetMixin
) {
  @Prop({
    type: Number,
    required: true
  })
  expedientId!: string

  @Prop({
    type: Object,
    default: () => {}
  })
  columns!: {}

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Action('createEntity', { namespace: entitiesModule })
  createEntity: ({}) => Promise<void>

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<void>

  @Action('fetchCurrentListConfigurationContext', { namespace: configurationModule })
  fetchCurrentListConfigurationContext: ({alias, context}: {alias: string, context: string}) => Promise<void>

  @Action('fetchExpedientsActionsSummary', { namespace: expedientsModule })
  fetchExpedientsActionsSummary: ({}) => void

  @Action('fetchExpedientsActions', { namespace: expedientsModule })
  fetchExpedientsActions: ({}) => void

  @Action('setExpedientStageStateForm', { namespace: expedientsModule })
  setExpedientStageStateForm: ({}) => void

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialogAction: ({}: CustomDialogData) => {}

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => Promise<[]>

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: string

  @Getter('getExpedientStageStateForm', { namespace: expedientsModule })
  expedientStageStateForm: string

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  gridData: []

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any

  @Getter('getSelectedActionType', { namespace: actionsModule })
  selectedActionType: string

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: object

  @Getter('getCurrentListConfigurationContext', { namespace: configurationModule })
  listConfiguration: (context: string) => {}

  @Getter('showRelatedForm', { namespace: formsModule })
  showRelatedForm: (context: string) => boolean

  @Getter('isOpenFileManagerEditComponent', { namespace: fileManagerModule })
  isOpenFileManagerEditComponent: boolean

  @Getter('getExpedientActionsSummary', { namespace: expedientsModule })
  totals: []

  @Getter('getHasStages', { namespace: expedientsModule })
  expedientHasStagesGetter: Boolean

  entityType: number = 0

  showAddAction: boolean = false
  buttonsEnabled: boolean = true

  stageField: { field: any; index: number } = { field: null, index: 0 }

  autoRefreshForm: boolean = false

  contextMenuSelectedRegister: any = null

  lastObjectType: string = ConfigurationElements.FORM

  gridColumns = this.columns

  refreshTabFlag = false

  showSpinner: boolean = false

  reloadGridItemsFlag: boolean = false

  selectedActionId: number | null = null

  entityId: number = 0

  selectedActions: any[] = []

  get tabs() {
    const tabConfig = this.getCurrentViewTabs(this.actionContext)

    if (this.isSubAction) {
      tabConfig.forEach((tab: any) => {
        if (tab.name === TabNames.SUB_ACTION) {
          tab.disabledTab = true
        }
      })
    }

    return tabConfig
  }

  get isSubAction(): boolean {
    return Boolean((this.selectedRegisterData(this.context) as ActionInterface)?.parentId)
  }

  get selectedActionIds() {
    return this.selectedActions.map((action) => action.actionId)
  }

  get selectedMoreThanOneAction() {
    return this.selectedActions.length > 1
  }

  get simpleGridContextMenuItems() {
    const { editItem, lookItem, removeItem, openWindow } = this.contextMenuOptionsTexts
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    return [
      {
        text: canSave ? editItem : lookItem,
        iconCss: canSave ? Icons.EDIT : Icons.VISIBLE
      },
      {
        text: openWindow,
        iconCss: Icons.OPEN_WINDOW
      },
      ...(canDelete
        ? [
            {
              text: removeItem,
              iconCss: Icons.REMOVE,
              action: this.showDialog
            }
          ]
        : [])
    ]
  }

  get gridConfiguration() {
    if (!this.gridColumns) {
      return ''
    }
    const config = this.gridColumns as any
    const { canDelete } = this.viewPermission
    const { associateAction, moveAction } = this.contextMenuOptionsTexts
    const commandButtons: CommandModel[] = [
      ...(!this.isPortalUser
        ? [
            {
              id: GridActionName.ASSOCIATE_ACTION,
              type: commandButtonType.NONE,
              title: associateAction.toString(),
              buttonOption: {
                iconCss: Icons.FOLDER_OPEN,
                cssClass: 'custombutton'
              }
            },
            {
              id: GridActionName.MOVE_ACTION,
              type: commandButtonType.NONE,
              title: moveAction.toString(),
              buttonOption: {
                iconCss: Icons.FOLDER,
                cssClass: 'custombutton'
              }
            }
          ]
        : []),
      ...(canDelete
        ? [
            {
              id: GridActionName.REMOVE,
              type: commandButtonType.NONE,
              title: this.$t('action_buttons.remove').toString(),
              buttonOption: {
                iconCss: Icons.REMOVE,
                cssClass: 'custombutton'
              }
            }
          ]
        : [])
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  get parsedTotal() {
    return this.totals.map((item: any) => {
      return {
        ...item,
        totalAmount: item.total
      }
    })
  }

  get currentId() {
    if (this.selectedRegisterData(this.actionContext) && this.entity(this.actionContext)) {
      return (this as any).selectedRegisterData(this.actionContext)[this.entity(this.actionContext).keyId]
    }
    return 0
  }

  get idStage(): string | null {
    const stage: any = this.expedientStageState
    return stage || null
  }

  get getColumns() {
    if (this.columns) {
      return this.columns
    }
    return ''
  }

  get getTitle() {
    if (this.currentId) {
      const name = (this.selectedRegisterData(this.actionContext) as any)[this.entity(this.actionContext).keyName]
      return this.$t('forms.actions.edit_title', { name })
    }
    switch (this.selectedActionType) {
      case ActionTypes.CALLS:
        return this.$t('forms.actions.new_call_title')
      case ActionTypes.EMAILS:
        return this.$t('forms.actions.new_email_title')
      case ActionTypes.MEETINGS:
        return this.$t('forms.actions.new_meeting_title')
      case ActionTypes.OTHERS:
        return this.$t('forms.actions.new_other_title')
      case ActionTypes.PROCEDURES:
        return this.$t('forms.actions.new_procedure_title')
      case ActionTypes.TASK:
        return this.$t('forms.actions.new_task_title')
      case ActionTypes.INTERNAL_MANAGEMENT:
        return this.$t('forms.actions.new_internal_management_title')
    }
  }

  get typeId() {
    return (this.selectedRegisterData(this.actionContext) as any)?.typeId
  }

  get showKebabMenu() {
    return this.typeId === ActionTypes.LEXNET
  }

  get buttons() {
    if (this.currentId) {
      const buttons = [
        {
          icon: Icons.LEXNET,
          tooltip: this.contextMenuOptionsTexts.openLexnet,
          action: ActionName.OPEN_IN_LEXNET,
          enabled: true,
          hidden: !(this.typeId === ActionTypes.LEXNET),
          isContextMenuOption: this.typeId === ActionTypes.LEXNET
        },
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.cancel'),
          class: 'red-color',
          action: ActionName.CLOSE,
          variant: LabelButtonVariant.TEXT,
          enabled: !(
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled ||
            this.isOpenFileManagerEditComponent
          )
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          enabled: !(
            !this.checkIfFormIsValid(this.actionContext) ||
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled
          ),
          hidden: !this.checkEntityPermissionsGetter(this.permissionsEntity).canSave,
          variant: LabelButtonVariant.OUTLINED
        },
        {
          icon: Icons.REMOVE,
          tooltip: this.typeId === ActionTypes.LEXNET ? this.$t('action_buttons.remove') : '',
          prependIcon: Icons.REMOVE,
          action: ActionName.REMOVE,
          enabled: !(
            this.showRelatedForm(this.actionContext) ||
            !this.buttonsEnabled ||
            this.isOpenFileManagerEditComponent
          ),
          hidden: !this.checkEntityPermissionsGetter(this.permissionsEntity).canDelete,
          variant: LabelButtonVariant.TEXT,
          isContextMenuOption: this.typeId === ActionTypes.LEXNET
        }
      ]
      return buttons.filter((button: ActionBarButton) => !button.hidden)
    }

    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.cancel'),
        class: 'red-color',
        action: ActionName.CLOSE,
        variant: LabelButtonVariant.TEXT,
        enabled: !(this.showRelatedForm(this.actionContext) || !this.buttonsEnabled)
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: !(
          !this.checkIfFormIsValid(this.actionContext) ||
          this.showRelatedForm(this.actionContext) ||
          !this.buttonsEnabled
        )
      }
    ]
  }

  get renderingFromTheGlobalVisionNextEventsWidget(): boolean {
    return !!this.$route.query.action
  }

  get contextMenuItemsAction() {
    // El texto cambia por ver o editar en la función onContextMenuBeforeOpen del ActionMixin
    const { editItem, removeItem, lookItem } = this.contextMenuOptionsTexts
    return [
      {
        text: editItem,
        iconCss: Icons.EDIT
      },
      {
        text: lookItem,
        iconCss: Icons.VISIBLE
      },
      {
        text: removeItem,
        iconCss: Icons.REMOVE,
        action: this.showDialog
      }
    ]
  }

  get customToolbarOptions() {
    const { add } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      ...(this.userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails
        ? [
            {
              id: 'add',
              text: add,
              tooltipText: add,
              align: 'right',
              cssClass: 'lf-btn-model4'
            }
          ]
        : []),
      columnsChooserToolbarItem,
      kebakMenuToolbarItem
    ]
  }

  get permissionsEntity() {
    return this.entityId
  }

  @Watch('expedientStageState')
  changeStage() {
    this.refreshExpedientActionsSummary()
  }

  @Watch('$route', { immediate: true })
  async onRouteChange(to: any) {
    if (to.query.action) {
      this.actionContext = ContextName.EXPEDIENT_ACTIONS
      this.onActionAddClick(to.query.action)
    }
  }

  @Watch('showAddAction')
  async emitDisabledTabs(disable: boolean) {
    if (disable) {
      this.$emit('disabledTabs')
    } else {
      this.refreshExpedientActionsSummary()
      this.$emit('enabledTabs')
    }
  }

  created() {
    this.actionContext = ContextName.EXPEDIENT_ACTIONS
    this.$emit('setDisabledTabsMessage', this.$t('components.alerts.close_action_form'))
    // this.refreshExpedientActionsSummary()
  }

  mounted() {
    this.checkIfOpenFormOnFirstLoad()
    // await this.fetchCurrentListConfigurationContext('listExpedientsActions')
  }

  beforeDestroy() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.$router.push(this.$route.path)
    }
    this.$emit('setDisabledTabsMessage', '')
  }

  onInitActionForm(params: any) {
    const { schema, formData } = params
    this.toggleStageField(schema)

    const isNew = !this.entity(this.actionContext).id

    if (isNew) {
      formData[ActionFields.STAGE] = this.idStage ? this.idStage : null
      formData[ActionFields.ACTION_TYPE] = this.selectedActionType
      formData[ActionFields.EXPEDIENT_ID] = this.expedientId
    }
    const expedientIdField: any = schema.find(({ id }: any) => id === ActionFields.EXPEDIENT_ID)
    if (expedientIdField) {
      expedientIdField!.disabled = isNew
    }

    const stageField: any = schema.find(({ id }: any) => id === ActionFields.STAGE)
    if (stageField) {
      stageField.disabled = this.isSubAction
    }

    this.onInitForm(params)
  }

  onActionFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (ActionFields.STAGE === field.id) {
      this.setExpedientStageStateForm(value)
    }

    this.onFieldChanged(schema, formData, field, value)

    if (ActionFields.ACTION_TYPE === field.id) {
      this.checkFormPermissions(schema, this.entityType)
    }
  }

  onBeforeSetComponentProperties(component: any, _tab: any, _tabIndex: number) {
    component.basePath = setBasePathByActionType(this.getSelectedActionType)
  }

  toggleStageField(schema: any) {
    if (!this.showAddAction) {
      return true
    }

    const stageIdField: any = schema.find(({ id }: any) => id === ActionFields.STAGE)
    stageIdField!.hidden = !this.expedientHasStagesGetter
  }

  executeAction(actionName: string) {
    if (ActionName.OPEN_IN_LEXNET === actionName) {
      this.openInLexnet(this.selectedRegisterData(this.actionContext))
    }
  }

  async onActionAddClick(args: any) {
    trackEvent(TrackerEvents.CREATE_ACTION)
    if (args.item.actionType === ActionTypes.LEXNET) {
      this.openLexnetInNewWindow()
      return
    }
    if (
      args.item.actionType === ActionTypes.EMAILS &&
      this.companyMailVersion === ConfigurationAppointmentVersion.LEXBOX_VERSION
    ) {
      this.$tracker.trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
    this.$emit('openForm')
    let actionType = args
    if (typeof args === 'object') {
      actionType = args.item.actionType
      this.entityId = args.item.entityId
    }
    await this.saveSelectedRegisterId({
      id: 0,
      context: this.actionContext
    })
    this.contextMenuSelectedRegister = null
    await this.openAction(actionType)
  }

  async onCellClicked(selectedRegister: any) {
    trackEvent(TrackerEvents.EDIT_ACTION)
    this.contextMenuSelectedRegister = selectedRegister
    this.entityId = selectedRegister.idEntity
    this.$emit('openForm')
    if (this.isPortalUser) {
      return
    }

    if (this.selectedActionId !== selectedRegister.actionId) {
      this.selectedActionId = selectedRegister.actionId
      await this.onEditAction(selectedRegister)
    }
  }

  async onEditAction(selectedRegister: any) {
    this.setExpedientStageStateForm(selectedRegister.idStage)
    await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
  }

  async onRemoveAction(selectedRegister: any) {
    const entityType = this.getEntityTypeByActionType(selectedRegister.typeId)

    await this.createEntity({
      entityType,
      idEntity: selectedRegister[entityKeyFields.actions.id],
      context: this.actionContext
    })

    await this.saveSelectedRegisterId({
      id: selectedRegister[entityKeyFields.actions.id],
      context: this.actionContext
    })

    await this.showRemoveSelectedActionDialog()
  }

  async showRemoveSelectedActionDialog() {
    // NOTE: necesario para saber si tiene subactuaciones y mostrar los mensajes oportunos
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.actionContext).type).get,
      context: this.actionContext,
      post: { idFile: this.expedientId }
    })
    const action = this.selectedRegisterData(this.actionContext) as ActionInterface
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.getRemoveConfirmMessageFromAction(action),
      action: async () => {
        await this.removingAction(action)
        await this.refreshActionList()
        this.refreshExpedientActionsSummary()
        this.showAddAction = false
        this.$emit('closeForm')
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  getEntityTypeByActionType(actionType: ActionTypes) {
    switch (actionType) {
      case ActionTypes.CALLS:
        return entity.actions.calls.type
      case ActionTypes.MEETINGS:
        return entity.actions.meetings.type
      case ActionTypes.LEXNET:
        return entity.actions.lexnet.type
      case ActionTypes.PROCEDURES:
        return entity.actions.procedures.type
      case ActionTypes.OTHERS:
        return entity.actions.others.type
      case ActionTypes.EMAILS:
        return entity.actions.emails.type
    }
  }

  async openAction(actionType: ActionTypes, idEntity: number = 0, target: string = '_self') {
    const configurationType: string = ConfigurationTypes.VIEW
    let configurationAlias: string = ''

    switch (actionType) {
      case ActionTypes.CALLS:
        this.entityType = entity.actions.calls.type
        this.saveSelectedActionType(ActionTypes.CALLS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.MEETINGS:
        this.entityType = entity.actions.meetings.type
        this.saveSelectedActionType(ActionTypes.MEETINGS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.PROCEDURES:
        this.entityType = entity.actions.procedures.type
        this.saveSelectedActionType(ActionTypes.PROCEDURES)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.OTHERS:
        this.entityType = entity.actions.others.type
        this.saveSelectedActionType(ActionTypes.OTHERS)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.LEXNET:
        this.entityType = entity.actions.lexnet.type
        this.saveSelectedActionType(ActionTypes.LEXNET)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.EMAILS:
        if (idEntity > 0) {
          if (target === '_blank') {
            const routeData = this.$router.resolve({
              name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
              params: { selectedRegisterId: idEntity as any, from: URLS.ACTION_ALL }
            })
            window.open((routeData as any).href, '_blank')
          } else {
            this.entityType = entity.actions.emails.type
            this.saveSelectedActionType(ActionTypes.EMAILS)
            configurationAlias = ConfigurationAlias.ACTION
          }
        } else {
          let url = `/${URLS.EMAIL}/${this.expedientId}`
          target = '_blank'
          if (this.contextMenuSelectedRegister) {
            url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
          }
          window.open(`${url}`, target)
          return true
        }
        break
      case ActionTypes.TASK:
        this.entityType = entity.actions.task.type
        this.saveSelectedActionType(ActionTypes.TASK)
        configurationAlias = ConfigurationAlias.ACTION
        break
      case ActionTypes.INTERNAL_MANAGEMENT:
        this.entityType = entity.actions.internalManagement.type
        this.saveSelectedActionType(ActionTypes.INTERNAL_MANAGEMENT)
        configurationAlias = ConfigurationAlias.ACTION
        break
    }

    await this.createEntity({
      entityType: this.entityType,
      idEntity,
      context: this.actionContext
    })

    this.autoRefreshForm = true

    await this.saveSelectedRegisterId({
      id: idEntity,
      context: this.actionContext
    })

    if (idEntity) {
      this.autoRefreshForm = false

      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.entity(this.actionContext).type).get,
        context: this.actionContext,
        post: { idFile: this.expedientId }
      })
    }

    await this.fetchCurrentViewConfiguration({
      objectType: configurationType,
      alias: configurationAlias,
      context: this.actionContext
    })

    this.showAddAction = true
  }

  async onTabChange(tabIndex: number, objectType: number, forceSave: boolean) {
    if (tabIndex !== 0) {
      this.refreshTabFlag = true
      this.reloadGridItemsFlag = true
    }

    if (this.currentId) {
      if (this.lastObjectType !== ConfigurationElements.FORM && (0 === tabIndex || forceSave)) {
        await this.resetFormData()
        await this.fetchSelectedRegisterData({
          endpoint: this.endpoints(this.entity(this.actionContext).type).get,
          context: this.actionContext,
          post: { idFile: this.expedientId }
        })
      }

      if (0 === tabIndex && this.refreshTabFlag && this.$children && this.$children.length > 1) {
        // Refresca el componente dinamico creado en el TabsComponent
        if (typeof (this as any).$children[2].refreshComponentManually === 'function') {
          ;(this as any).$children[2].refreshComponentManually()
        }
      }

      this.lastObjectType = objectType.toString()
    }
  }

  async saveOnTabChange(_redirectToGridTable = true, resolve: any = null) {
    this.save(false, resolve)
  }

  async save(closeForm: boolean = true, resolve: any = null) {
    await this.saveSelectedRegisterId({
      id: this.entity(this.actionContext).id,
      context: this.actionContext
    })

    this.isSavingForm = true

    this.regularizeFieldsOnSave(this.selectedActionType)

    await this.$nextTick()

    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.actionContext).type).save,
      idSelectedRegister: this.entity(this.actionContext).id,
      goToURL: null,
      context: this.actionContext
    })

    this.isSavingForm = false

    if (closeForm) {
      await this.refreshActionList()
      this.showAddAction = false
      this.showAlertResult(this.$t('components.alerts.register_save'), AlertsTypes.SUCCESS)
    }

    if (resolve) {
      resolve()
    }
  }

  async onSaveForm() {
    this.selectedActionId = null
    await this.save()
    this.$emit('closeForm')
  }

  async onCloseForm() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.$router.push(this.$route.path)
      return
    }

    this.showSpinner = true

    if (this.reloadGridItemsFlag && !this.renderingFromTheGlobalVisionNextEventsWidget) {
      await this.fetchExpedientsActions({
        selectedRegisterId: this.selectedRegisterId('expedients')
      })
      this.reloadGridItemsFlag = false
    }
    await this.fetchCurrentListConfigurationContext({ alias: ListNames.EXPEDIENT_ACTIONS, context: ListNames.EXPEDIENT_ACTIONS})
    this.gridColumns = this.listConfiguration(ListNames.EXPEDIENT_ACTIONS)
    this.showAddAction = false
    this.selectedActionId = null
    this.showSpinner = false
    this.$emit('closeForm')
  }

  onRemoveItem() {
    trackEvent(TrackerEvents.REMOVE_ACTION)
    this.showRemoveSelectedActionDialog()
    this.$emit('closeForm')
  }

  async refreshActionList() {
    const filter: any = {
      idStage: this.idStage
    }

    await this.removeListItems()
    await this.fetchCurrentListConfigurationContext({ alias: ListNames.EXPEDIENT_ACTIONS, context: ListNames.EXPEDIENT_ACTIONS})
    await this.$store.dispatch(`expedientsModule/fetchExpedientsActions`, {
      selectedRegisterId: this.expedientId,
      listName: this.listName,
      filter
    })

    this.gridColumns = this.listConfiguration(this.listName)
  }

  downloadActions() {
    if (this.$refs.grid) {
      ;(this.$refs.grid as any).excelExport()
    }
  }

  showAlertResult(message: any, alertType: AlertsTypes) {
    store.dispatch(
      `${ModuleNamespaces.ALERTS}/showAlert`,
      {
        type: alertType,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      },
      { root: true }
    )
  }

  refreshExpedientActionsSummary(filters: any = {}) {
    this.fetchExpedientsActionsSummary({
      idExpedient: this.expedientId,
      idStage: this.idStage,
      ...filters
    })
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    const { editItem, lookItem, removeItem, openLfMail, openLexnet, openWindow, associateAction, moveAction } =
      this.contextMenuOptionsTexts
    if (args.item) {
      if (args.item.text === editItem || args.item.text === lookItem) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        this.onEditAction(selectedRegister)
      }
      if (args.item.text === removeItem) {
        trackEvent(TrackerEvents.REMOVE_ACTION)
        await this.onRemoveAction(selectedRegister)
      }
      if (args.item.text === openLfMail) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        trackEvent(TrackerEvents.OPEN_LEFEBVRE_MAIL)
        let url = `/${URLS.EMAIL}`
        const target = '_blank'
        if (this.contextMenuSelectedRegister) {
          url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
        }
        window.open(`${url}`, target)
      }
      if (args.item.text === openLexnet) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        this.openInLexnet(selectedRegister)
      }
      if (args.item.text === openWindow) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId, '_blank')
      }
      if (args.item.text === associateAction) {
        this.associateAction()
      }
      if (args.item.text === moveAction) {
        this.moveAction()
      }
    }
    args.cancel = true
  }

  associateAction() {
    this.showCustomDialogAction({
      name: CustomDialogComponentName.ASSOCIATE_ACTION,
      styles: {
        maxWidth: '750px'
      },
      props: {
        selectedActionIds: this.selectedActionIds
      }
    })
  }

  moveAction() {
    this.showCustomDialogAction({
      name: CustomDialogComponentName.MOVE_ACTION,
      styles: {
        maxWidth: '750px'
      },
      props: {
        selectedActionIds: this.selectedActionIds,
        filter: {
          idStage: this.idStage
        }
      }
    })
  }

  checkIfOpenFormOnFirstLoad() {
    if (this.renderingFromTheGlobalVisionNextEventsWidget) {
      this.openAction(this.$route.query.action as ActionTypes)
    }
  }

  onGridTableFilter(filters: any) {
    this.refreshExpedientActionsSummary(filters)
  }

  customOnContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, selectedRegister: any) {
    this.onContextMenuBeforeOpen(contextMenuModule, contextMenuGridObject, selectedRegister)
    this.enableContextMenuItems(contextMenuModule, contextMenuGridObject)
  }

  enableContextMenuItems(contextMenuModule: any, contextMenuGridObject: any): void {
    contextMenuGridObject.items.forEach((item: MenuItemModel) => {
      const enable =
        !this.selectedMoreThanOneAction ||
        item.text === this.contextMenuOptionsTexts.moveAction ||
        item.text === this.contextMenuOptionsTexts.associateAction
      contextMenuModule.enableItems([item.text], enable)
      contextMenuGridObject.enableItems([item.text], enable)
    })
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedActions = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { typeId, actionId } = rowData as any
    switch (action) {
      case GridActionName.REMOVE:
        this.onRemoveAction(rowData)
        break
      case GridActionName.ASSOCIATE_ACTION:
        this.associateAction()
        break
      case GridActionName.MOVE_ACTION:
        this.moveAction()
        break
      case GridActionName.OPEN_NEW_TAB:
        this.openAction(typeId, actionId, '_blank')
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.expedient-actions {
  .spinner-layer {
    width: 100%;
    height: 100%;
    min-height: 470px;
    background-color: $white-01;
  }

  .tab-header-container {
    border: none;
    margin-bottom: $spacing-md;
    padding-bottom: 0;
  }

  ::v-deep #file-manager_layout {
    border: 1px solid $corporate-color !important;
  }

  ::v-deep .tabs-container .tab-content {
    border: none;
    padding: 0;
  }
}
</style>
