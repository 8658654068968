<template>
  <div>
    <h1>TOGGLE TABS COMPONENT</h1>
    <button @click="handleDisableTabs">DESHABILITAR / HABILITAR TABS</button>
  </div>
</template>

<script setup lang="ts">
// TODO ALE - Este componente es para pruebas. ELiminar cuando todo esté funcional
import useTabs from '@/components/tabs/LfTabs/composables/useTabs'
import { defineEmits, ref } from 'vue'

const { showTabSpinner } = useTabs()

const emit = defineEmits(['disable-tabs', 'hide-tabs-spinner'])

const status = ref(false)

showTabSpinner()

function handleDisableTabs() {
  status.value = !status.value
  emit('disable-tabs', status.value)
}

setTimeout(() => {
  emit('hide-tabs-spinner')
}, 500)
</script>
