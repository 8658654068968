import useFormSchema from '@/composables/useFormSchema'
import { RenderedForm } from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export default function useBankdraftForm() {
  const bankdraftFormContext = RenderedForm.CUSTOMER_BANKDRAFTS_DETAIL_FORM
  const { formSchema, getFormSchema } = useFormSchema({
    formAlias: bankdraftFormContext,
    context: bankdraftFormContext
  })
  const bankdraftFormSchema = formSchema
  const loadBankdraftFormSchema = getFormSchema
  const saveBankdraftForm = (bankdraft: object, customerInvoiceId: number) => store.dispatch(
    `${ ModuleNamespaces.INVOICES }/saveInvoiceDetail`, {
      endpoint: '/invoices/customers/bankdraft/save',
      formData: {
        ...bankdraft,
        idInvoice: String(customerInvoiceId)
      }
    }
  )
  return {
    bankdraftFormContext,
    bankdraftFormSchema,
    loadBankdraftFormSchema,
    saveBankdraftForm
  }
}
