import { Module } from 'vuex'
import { getters } from '@/store/modules/configurationPanel/configurationPanelGetters'
import { actions } from '@/store/modules/configurationPanel/configurationPanelActions'
import { mutations } from '@/store/modules/configurationPanel/configurationPanelMutations'
import { ConfigurationPanelState } from '@/store/modules/configurationPanel/configurationPanelTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ConfigurationPanelState = {

}

const namespaced: boolean = true

export const configurationPanelModule: Module<ConfigurationPanelState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
