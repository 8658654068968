import { computed, ComputedRef, Ref } from "vue"
import { GridComponent as EjsGrid, PageSettingsModel, SortDescriptorModel } from '@syncfusion/ej2-vue-grids'
import { useGridStore } from "@/stores/grid/useGridStore"
import { ColumnModel, CommandModel, GridConfiguration, columnWidth, columnActions as columnActionDefault } from "../LfGridTypes"
import { ref } from "vue"

interface PropsUseGridConfig {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null> | ComputedRef<InstanceType<typeof EjsGrid> | null>
  alias: string
  commandButtons?: CommandModel[]
  rememberUserConfig: boolean
}

interface ResponseUseGridConfig {
  saveGridState: () => void
  saveGridDB: () => void
  getGridConfiguration: () => Promise<GridConfiguration>
  gridConfiguration: any
}

interface FilterColumn {
  field: string
  operator: string
  value: string
}

export const defaultPageSize: number = 10
// - [x,x] columns
//    - [x] FilterColumns
// - [x,x] pageSettings
// - [x,x] searchSettings
// - [x] filterSettings
// - [x,x] sortSettings

// - [ ] archived
// - [ ] groupSettings
// - [ ] MSOffice

export default function useGridConfig(props: PropsUseGridConfig): ResponseUseGridConfig {
  const { gridRef, alias, rememberUserConfig } = props

  const gridConfiguration = ref({} as GridConfiguration)

  const gridStore = useGridStore()

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  const getGridConfiguration = async () => {
    if (!alias) {
      return {} as GridConfiguration
    }

    await gridStore.fetchListConfiguration(alias)
    gridConfiguration.value = userConfiguration()

    if (!props.commandButtons) {
      return gridConfiguration.value as GridConfiguration
    }

    const column = gridConfiguration.value.columns.find((item) => Boolean(item.commands))

    if (column === undefined) {
      const columnActions = { ...columnActionDefault }
      columnActions.width = getColumnActionWidth()
      columnActions.maxWidth = getColumnActionWidth()
      columnActions.commands = props.commandButtons as CommandModel[]
      gridConfiguration.value.columns.push(columnActions)
    }

    return gridConfiguration.value as GridConfiguration
  }

  const getColumnActionWidth = (): number => {
    return columnWidth[commandButtonsLength()]
  }

  const commandButtonsLength = (): number => {
    if (!props.commandButtons) {
      return 0
    }
    return props.commandButtons.length
  }

  const userConfiguration = (): GridConfiguration => {
    const grid = gridStore.getListConfiguration(alias).value
    if (!rememberUserConfig) {
      const { columns, pageSettings } = grid
      return { columns, pageSettings } as GridConfiguration
    }
    return grid
  }

  const getGridConfig = (): GridConfiguration => {
    const columns = getColumns()
    const pageSettings = getPageSettings()
    const sortSettings = getSortSettings()
    const filterSettings = getFilterSettings()
    const searchSettings = getSearchSettings()

    return {
      columns,
      pageSettings,
      sortSettings,
      searchSettings,
      filterSettings
    }
  }

  const saveGridState = () => {
    if (!alias) {
      console.error('alias is required')
      return
    }
    const config = getGridConfig()
    gridStore.setListConfiguration(alias, config)
  }

  const saveGridDB = () => {
    if (!alias) {
      console.warn('alias is required')
      return
    }
    const config = getGridConfig()
    gridStore.saveListConfiguration(alias, config)
  }

  const getColumns = (): ColumnModel[] => {
    const columns = gridInstance.value.getColumns()
    return serializeColumns(columns)
  }

  const getPageSettings = (): PageSettingsModel => {
    return gridInstance.value.pageSettings.properties
  }

  const getSearchSettings = (): string => {
    return gridInstance.value.searchSettings.key
  }

  const getSortSettings = (): SortDescriptorModel[] => {
    return gridInstance.value.properties.sortSettings.properties.columns.map(({ field, direction }: SortDescriptorModel) => ({
      field,
      direction
    }))
  }

  const getFilterSettings = (): FilterColumn[] => {
    return gridInstance.value.filterSettings.columns.map(({ field, operator, predicate, value }: any) => {
      if (!field) {
        return
      }
      return {
        field,
        operator,
        predicate,
        value
      }
    })
  }

  return {
    gridConfiguration,
    saveGridState,
    saveGridDB,
    getGridConfiguration
  }
}

export const serializeColumns = (columns: ColumnModel[]): ColumnModel[] => {
  return columns.map((item: ColumnModel) => {
    const {
      disableHtmlEncode,
      allowSorting,
      allowResizing,
      allowFiltering,
      allowGrouping,
      allowReordering,
      allowEditing,
      headerText,
      field,
      isCheckboxType,
      showColumnMenu,
      enableGroupByFormat,
      showInColumnChooser,
      allowSearching,
      autoFit,
      sortDirection,
      clipMode,
      commands,
      filterModule,
      format,
      headerTemplate,
      headerTextAlign,
      isPrimaryKey,
      minWidth,
      maxWidth,
      textAlign,
      type,
      visible,
      width,
      template,
      templateName,
      freeze,
      foreignKeyField,
      index
    } = item
    const nameOfTemplate = (typeof item?.template === 'function') ? item.template.name : templateName
    // console.log({ item, templateName, template: item?.template?.name })
    if (item.filter?.type === 'Menu') {
      item.filter = undefined
    }

    return {
      disableHtmlEncode,
      allowSorting,
      allowResizing,
      allowFiltering,
      allowGrouping,
      allowReordering,
      allowEditing,
      allowSearching,
      templateName: nameOfTemplate,
      field,
      headerText,
      isCheckboxType,
      showColumnMenu,
      enableGroupByFormat,
      showInColumnChooser,
      autoFit,
      sortDirection,
      clipMode,
      commands,
      filterModule,
      foreignKeyField,
      format,
      headerTemplate,
      headerTextAlign,
      isPrimaryKey,
      minWidth,
      maxWidth,
      textAlign,
      type,
      visible,
      width,
      template,
      freeze,
      index
    }
  })
}
