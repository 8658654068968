<template lang="pug">
  div(class="no-preview-content")
    ClosePreviewComponent(
      @close-viewer-event="closeViewerEvent"
    )
    span(class="advise") {{mainAdvise}}
</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import ClosePreviewComponent from '@/components/FileManager/Preview/components/ClosePreviewComponent.vue'

@Component({
  components: {
    ClosePreviewComponent
  }
})
export default class NotPreviewContentComponent extends Vue {
  iconPreview = Icons.VISIBLE
  mainAdvise = this.$t('components.file_manager.advises.preview_not_valid')
  secondaryAdvise = this.$t('components.file_manager.advises.select_preview_file')

  @Emit()
  closeViewerEvent() {}

  preview() {
    return null
  }
}
</script>

<style lang="scss" scoped>
.no-preview-content {
  @include flex($flex-direction: column);
  font-family: $corporate-font;
  width: 100%;
  .advise {
    font-size: 16px;
    color: $neutral-grey-800;
  }
}
</style>
