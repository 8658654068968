import { MutationTree } from 'vuex'
import { SpinnerState } from '@/store/modules/spinner/spinnerTypes'

export const mutations: MutationTree<SpinnerState> = {
  SHOW_SPINNER(state: SpinnerState, showMessage: boolean) {
    state.spinner = {
      show: true,
      message: showMessage
    }
  },
  HIDE_SPINNER(state: SpinnerState) {
    state.spinner = {
      show: false,
      message: false
    }
  },
  SHOW_SPINNER_LAYER(state: SpinnerState) {
    state.showSpinnerLayer = true
  },
  HIDE_SPINNER_LAYER(state: SpinnerState) {
    state.showSpinnerLayer = false
  }
}
