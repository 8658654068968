<template lang="pug">
ValidationProvider(
  ref="searchFieldValidator"
  :rules="validationRules"
  name="searchField"
  v-slot="{ errors }"
  tag="div"
  class="search-component-wrapper"
)
  div(
    :class="[{ 'error-styles': applyErrorStyles },  'search-field-container']"
  )
    input(
      v-model="searchTerm"
      type="text"
      class="search-field"
      @keyup.enter="searchOrClearTerm"
      placeholder="Buscar un elemento"
    )
    span(class="clear-icon lf-icon-close" @click.stop="clearSearch" v-if="searchTerm")
    span(class="search-icon" :class="searchIconType" @click.stop="searchOrClearTerm")

  div(v-if="showErrors" class="messages-container")
    span(class="alert-message") {{ errors[0] }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { decodeSearchTerm } from '@/helpers/codeTerm'

const limitOfMinCharacters: number = 3

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonSearchFieldComponent extends Vue {
  @Prop({
    type: String,
    default: `searchLimit|min:${limitOfMinCharacters}`
  })
  validationRules!: string

  searchTerm: string = ''
  searchOldTerm: string = ''
  newSearch = true

  showErrors: boolean = false

  searchIconType: string = Icons.SEARCH

  $refs!: {
    searchFieldValidator: InstanceType<typeof ValidationProvider>
  }

  @Watch('$route')
  clearSearchText() {
    if (!this.isSearchView) {
      this.searchTerm = ''
      this.showErrors = false
    }
    this.searchTerm = decodeSearchTerm(this.$route.params.searchTerm)
    this.searchOldTerm = this.searchTerm
  }

  @Watch('searchTerm')
  onSearchTermChanged(newValue: string) {
    const hasEmptySearch = newValue === ''
    this.updateSearch(hasEmptySearch)
  }

  get isSearchView(): boolean {
    return this.$route.name === URLS.SEARCH
  }

  get applyErrorStyles() {
    return this.showErrors && this.searchTerm.length < limitOfMinCharacters
  }

  get searchError() {
    return this.$refs.searchFieldValidator.$data.errors.length
  }

  get isNewSearch() {
    return this.searchOldTerm !== this.searchTerm
  }

  mounted() {
    if (this.isSearchView) {
      this.searchTerm = decodeSearchTerm(this.$route.params.searchTerm)
      this.searchOldTerm = this.searchTerm
    }
  }

  clearSearch() {
    this.searchTerm = ''
  }

  async searchOrClearTerm() {
    await this.checkIfSearchIfValid()

    if (!this.isNewSearch) {
      return
    }

    if (!this.showErrors) {
      this.$emit('searchTerm', this.searchTerm)
    }
  }

  async checkIfSearchIfValid() {
    const fieldValidation = await this.$refs.searchFieldValidator.validate()
    const fieldIsValid = fieldValidation.valid

    if (!fieldIsValid) {
      this.showErrors = true
      setTimeout(() => {
        this.showErrors = false
      }, 3000)
      return
    }
    this.showErrors = false
  }

  updateSearch(hasNoContent: boolean): void {
    if (hasNoContent) {
      this.showErrors = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search-component-wrapper {
  @include textfield-messages;
  position: relative;

  .messages-container {
    display: flex;
    height: auto;
    max-width: 200px;
    padding: $spacing-xs;
    align-items: flex-start;
    border-radius: $cornerRadius-sm;
    background: $main-050;
    position: absolute;
    bottom: -82px;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0 2px 4px rgba(113, 113, 113, 0.25));

    .alert-message {
      @include milano-regular-14;
      color: $neutral-grey-800;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -10px;
      border-style: solid;
      border-width: 0 16px 16px 16px;
      border-color: transparent transparent $main-050 transparent;
      transform: translateX(-50%);
    }
  }
}

.search-field-container {
  display: flex;
  width: 400px;
  height: $input-size;
  padding: $spacing-xs;
  align-items: center;
  gap: $spacing-xs;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: $cornerRadius-sm;
  border: 1px solid $neutral-grey-400;
  background: $neutral-white-000;

  &:hover {
    background-color: $main-050;
  }

  &:focus-within {
    @include borders;
    background-color: $neutral-white-000;
  }

  &.error-styles {
    border-color: $error-color;
    color: $error-color;
  }

  .search-field {
    @include arial-regular-14;
    width: 100%;
    color: $neutral-grey-600;
  }

  .search-icon {
    @include flex;
    @include border($direction: left, $color: $neutral-grey-400);
    font-size: 16px;
    padding: 0 0 0 8px;
    cursor: pointer;
    color: $neutral-grey-600;
  }

  .clear-icon {
    @include flex;
    font-size: 12px;
    cursor: pointer;
    color: $neutral-grey-600;
  }
}

@include untilTablet {
  .search-field-container {
    margin-right: 5px;
  }
}

// @include desktopLarge {
//   .search-field-container {
//     width: 360px;
//   }
// }
</style>
