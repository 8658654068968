<template>
  <ValidationProvider :rules="props.validationRules" class="component-container" v-slot="{ errors }">
    <div class="input-container">
      <label :for="props.id">{{ props.placeholder }}</label>
      <div class="input-field" :class="{ 'error-style': errors[0] }">
        <div class="prepend-icon">
          <span :class="props.prependIcon" />
        </div>
        <input
          :value="props.value"
          :type="inputType"
          :placeholder="props.placeholder"
          :id="props.id"
          :name="props.id"
          @input="emitValue"
          :autocomplete="props.autocompleteType"
        />
        <div class="append-icon">
          <span :class="props.appendIcon" @click="toggleVisible" />
        </div>
      </div>
    </div>
    <div class="alert-container" v-if="errors"><span class="alert-text" /> {{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script lang="ts" setup>
import { defineProps, PropType, ref } from 'vue'
import { ValidationProvider } from 'vee-validate'

const props = defineProps({
  validationRules: {
    type: String as PropType<string>,
    required: true
  },
  id: {
    type: String as PropType<string>,
    required: true
  },
  value: {
    type: [String, Number] as PropType<string | number>,
    default: ''
  },
  type: {
    type: String as PropType<string>,
    default: 'text'
  },
  placeholder: {
    type: String as PropType<string>
  },
  prependIcon: {
    type: String as PropType<string>
  },
  appendIcon: {
    type: String as PropType<string>
  },
  autocompleteType: {
    type: String as PropType<string>,
    default: ''
  }
})

// DATA
const inputType = ref(props.type)

// EVENTS
const emit = defineEmits(['input'])

// METHODS
function emitValue(event: Event) {
  const target = event.target as HTMLInputElement
  emit('input', target.value)
}

function toggleVisible() {
  inputType.value = inputType.value === 'password' ? 'text' : 'password'
}
</script>

<style lang="scss" scoped>
.component-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // gap: var(--spacing-xxs, 4px);
  align-self: stretch;

  label {
    @include poppins-regular-14;
    display: flex;
    align-items: baseline;
    gap: $spacing-xxs;
    align-self: stretch;
    color: $neutral-grey-700;
    margin-bottom: $spacing-xxs;
    text-transform: capitalize;

    &::after {
      @include poppins-regular-14;
      content: '*';
      color: $subject-fireEngineRed;
    }
  }

  .input-container {
    width: 100%;
    height: 65px;

    .input-field {
      display: flex;
      height: var(--Spacing-xxxl, 40px);
      padding: 10px;
      width: 100%;
      align-items: center;
      gap: var(--spacing-xs, 8px);
      align-self: stretch;
      border-radius: $cornerRadius-sm;
      border: 1px solid $neutral-grey-400;
      background: $neutral-white-000;

      &.error-style {
        border-color: $tertiary-persianRed-200;
      }

      .prepend-icon,
      .append-icon {
        @include flex;
        color: $main-1000;

        span::before {
          width: 25px;
          height: 25px;
        }
      }

      .append-icon {
        cursor: pointer;
      }

      input {
        @include milano-regular-18;
        color: $neutral-grey-800;
        width: 100%;
      }
    }
  }

  .alert-container {
    @include poppins-regular-12;
    color: $tertiary-persianRed-200;
    display: inline-block;
    margin: 1px 0 0 1px;
    min-height: $spacing-lg;
  }

  ::placeholder {
    color: $neutral-grey-500;
    text-transform: capitalize;
  }
}
</style>
