<template>
  <div class="notes-container">
    <ReminderNoteComponent
      v-for="(note, index) in props.notes"
      :key="index"
      :note="note"
      @edit-note="editNote"
      @show-remove-dialog="showRemoveDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import { Note } from './ExpedientReminderNotesComponentTypes'
import { PropType } from 'vue/types/v3-component-props'
import ReminderNoteComponent from '@/components/widgets/ExpedientWidgets/ExpedientReminderNotes/ReminderNoteComponent.vue'

// PROPS
const props = defineProps({
  notes: {
    type: Array as PropType<Note[]>,
    required: true
  }
})

// EVENTS
const emit = defineEmits(['edit-note', 'show-remove-dialog'])

// METHODS
function editNote(note: Note) {
  emit('edit-note', note)
}

function showRemoveDialog(note: Note) {
  emit('show-remove-dialog', note)
}
</script>

<style lang="scss" scoped>
.notes-container {
  @include scroll-styles;
  width: 100%;
  max-height: 300px;
  padding-right: $spacing-xs;
}
</style>
