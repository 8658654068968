<template lang="pug">

  div(class="lexon-numeration-component")
    input(type="hidden", v-model="innerValue", v-bind="$attrs")
    SpinnerLayerComponent(
      v-if="showGeneralSpinner"
      class="general-spinner"
    )
    div(v-else v-resize="calculateCustomFieldsHeadersWidth"  class="playground-area")
      //- HELP TEXT
      p(class="info-text") {{ texts.infoText }}
      //- HEADERS
      div(class="headers")
        span(
          v-for="(header, headerIndex) in headers"
          class="header"
        ) {{ header }}
      //- CUSTOM FIELDS LIST
      CustomFieldsListComponent(
        v-if="numerationPattern && numerationPattern.customFields && numerationPattern.customFields.length"
        v-model="numerationPattern.customFields"
        :showButtons="newMode"
        @remove-field="removeField"
        class="custom-fields-list"
      )
      LexonAutocompleteComponent(
        v-if="newMode"
        :key="componentKey"
        class="add-new-autocomplete"
        :prop-items="getPatternTypes",
        :placeholder="texts.addNewPattern",
        :clearable="false",
        @change="addNumerationPattern($event)"
      )

</template>

<script lang="ts">
import { Getter, Mutation } from 'vuex-class'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { uuid } from '@/helpers/helpers'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomFieldsConfig, customFieldTypesEnum } from '@/store/modules/customFields/customFieldsTypes'
import CustomFieldsListComponent from '@/components/customFields/customFieldsPlayground/CustomFieldsList/CustomFieldsListComponent.vue'
import {
  NumerationPatternTypes,
  NumerationMaintenanceTypes
} from '@/components/forms/fields/LexonNumeration/types/LexonNumerationComponentTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS
const numerationsModule: string = ModuleNamespaces.NUMERATIONS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    CustomFieldsListComponent,
    SpinnerLayerComponent,
    LexonAutocompleteComponent: () =>
      import('@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue')
  }
})
export default class LexonNumerationComponent extends Vue {
  @Mutation('FETCH_CUSTOM_FIELDS_CONFIG', { namespace: customFieldsModule })
  setCustomFields: ({}) => void
  @Mutation('FETCH_NUMERATIONS_VALUES', { namespace: numerationsModule })
  setNumerationValues: ({}) => void
  @Mutation('ADD_CUSTOM_FIELD', { namespace: customFieldsModule })
  addCustomFieldAction: (customFieldInfo: object) => void
  @Mutation('RESET_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  resetSelectedRegisterData: (context: string) => void

  @Getter('getNumerationMaintenance', { namespace: numerationsModule })
  getNumerationMaintenance: (context: string) => []
  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  numerationPatternConfig: CustomFieldsConfig
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  @Prop({
    type: [Array]
  })
  value!: []

  context: string = ContextName.NUMERATION

  texts = {
    addNewPattern: this.$t('components.numeration.add_pattern'),
    pattertTypes: {
      date: this.$t('components.numeration.pattern_types.date'),
      text: this.$t('components.numeration.pattern_types.text'),
      client: this.$t('components.numeration.pattern_types.client'),
      placeholderClient: this.$t('components.numeration.pattern_types.placeholder_client'),
      placeholderCode: this.$t('components.numeration.pattern_types.placeholder_code'),
      placeholderText: this.$t('components.numeration.pattern_types.placeholder_text')
    },
    infoText: this.$t('components.numeration.info_text'),
    typeText: this.$t('components.numeration.type_text'),
    typeClient: this.$t('components.numeration.type_client'),
    typeCode: this.$t('components.numeration.type_code'),
    typeDate: this.$t('components.numeration.type_date')
  }

  headers = [
    this.$t('components.numeration.headers.type'),
    this.$t('components.numeration.headers.format'),
    this.$t('components.numeration.headers.value'),
    this.$t('components.numeration.headers.separator')
  ]

  innerValue: any = null
  showGeneralSpinner = true
  patternTypes = [
    {
      text: this.texts.pattertTypes.date,
      value: NumerationPatternTypes.DATE
    },
    {
      text: this.texts.pattertTypes.text,
      value: NumerationPatternTypes.TEXT
    },
    {
      text: this.texts.pattertTypes.client,
      value: NumerationPatternTypes.CLIENT
    }
  ]
  patternRemoved: any = {}
  pattern: any = []
  componentKey: number = 0

  @Watch('pattern', { deep: true })
  changedValue(newVal: string | string[]) {
    this.setNumerationValues(newVal)
  }

  get getPatternTypes() {
    return this.patternTypes
  }
  get newMode() {
    return !this.selectedRegisterId(this.context)
  }
  async created() {
    if (this.selectedRegisterId(this.context)) {
      this.pattern = []
      this.value.forEach((val: any) => {
        this.pattern.push({
          uuid: uuid(),
          idNumerationType: val.idNumerationType,
          fields: this.getFieldPattern(val.idNumerationType, val),
          idCustomFieldType: customFieldTypesEnum.NUMERATION
        })
      })
    } else {
      this.pattern = [
        {
          uuid: uuid(),
          idNumerationType: NumerationPatternTypes.CODE,
          fields: this.getFieldPattern(NumerationPatternTypes.CODE),
          idCustomFieldType: customFieldTypesEnum.NUMERATION
        }
      ]
    }

    this.setCustomFields({
      customFields: this.pattern,
      idEntityType: 1
    })
    this.showGeneralSpinner = false
  }

  get numerationPattern() {
    return this.numerationPatternConfig
  }

  getFieldPattern(numerationType: NumerationPatternTypes, val: any = null) {
    const params = {
      type: {
        name: 'tipo',
        value: '',
        disabled: true
      },
      format: {
        name: 'formato',
        value: 0,
        placeholder: '',
        disabled: this.newMode ? false : true
      },
      val: {
        name: 'valor',
        disabled: this.newMode ? false : true,
        value: '',
        placeholder: '',
        type: customFieldTypesEnum.TEXT
      },
      separator: {
        name: 'separador',
        value: this.numerationMaintenance(NumerationMaintenanceTypes.SEPARATORS, null, true),
        disabled: this.newMode ? false : true
      }
    }
    switch (numerationType) {
      case NumerationPatternTypes.TEXT:
        params.type.value = (this as any).texts.typeText
        params.format.disabled = true
        params.val.placeholder = (this as any).texts.pattertTypes.placeholderText
        if (val !== null) {
          params.val.value = val.value
          params.separator.value = val.idSeparator
        }
        break
      case NumerationPatternTypes.CODE:
        params.type.value = (this as any).texts.typeCode
        params.val.type = customFieldTypesEnum.NUMERIC
        params.val.placeholder = (this as any).texts.pattertTypes.placeholderCode
        if (val !== null) {
          params.separator.value = val.idSeparator
          params.val.value = val.value
          params.format.value = val.idNumerationFormat
        } else {
          params.format.value = this.numerationMaintenance(NumerationMaintenanceTypes.FORMATS, numerationType, true)
        }
        break
      case NumerationPatternTypes.DATE:
        params.type.value = (this as any).texts.typeDate
        params.val.disabled = true
        if (val !== null) {
          params.separator.value = val.idSeparator
          params.format.value = val.idNumerationFormat
        } else {
          params.format.value = this.numerationMaintenance(NumerationMaintenanceTypes.FORMATS, numerationType, true)
        }
        break
      case NumerationPatternTypes.CLIENT:
        params.type.value = (this as any).texts.typeClient
        params.format.disabled = true
        params.val.disabled = true
        params.val.placeholder = (this as any).texts.pattertTypes.placeholderClient
        if (val !== null) {
          params.val.value = val.value
          params.separator.value = val.idSeparator
        }
        break
      default:
        return
    }
    return [
      {
        name: params.type.name,
        uuid: uuid(),
        required: false,
        value: params.type.value,
        disabled: params.type.disabled,
        idCustomFieldType: customFieldTypesEnum.TEXT
      },
      {
        name: params.format.name,
        uuid: uuid(),
        required: false,
        listOptions: this.numerationMaintenance(NumerationMaintenanceTypes.FORMATS, numerationType),
        value: params.format.value,
        disabled: params.format.disabled,
        idCustomFieldType: customFieldTypesEnum.LIST,
        multipleSelection: 0
      },
      {
        name: params.val.name,
        uuid: uuid(),
        required: false,
        disabled: params.val.disabled,
        idCustomFieldType: params.val.type,
        placeholder: params.val.placeholder,
        validationRules: 'onlyCharnumber',
        value: params.val.value
      },
      {
        name: params.separator.name,
        uuid: uuid(),
        required: false,
        listOptions: this.numerationMaintenance(NumerationMaintenanceTypes.SEPARATORS),
        value: params.separator.value,
        disabled: params.separator.disabled,
        idCustomFieldType: customFieldTypesEnum.LIST,
        multipleSelection: 0
      }
    ]
  }

  numerationMaintenance(
    type: NumerationMaintenanceTypes,
    filter: NumerationPatternTypes | null = null,
    getDefault: boolean = false
  ) {
    let maint: any = this.getNumerationMaintenance(type)
    if (filter !== null) {
      maint = maint.filter((el: any) => {
        return el.idNumerationType === filter.toString()
      })
    }
    if (getDefault) {
      const def = maint.filter((item: any) => item.default === '1')
      return def[0].id
    }
    return maint
  }

  async addNumerationPattern(e: any) {
    if (e === NumerationPatternTypes.CLIENT) {
      this.patternRemoved = this.patternTypes.pop()
    }

    this.componentKey++
    this.addCustomFieldAction({
      formData: {
        id: null,
        uuid: uuid(),
        idNumerationType: e,
        fields: this.getFieldPattern(e)
      },
      idCustomFieldType: customFieldTypesEnum.NUMERATION
    })
  }

  removeField(item: any) {
    if (item === NumerationPatternTypes.CLIENT) {
      this.patternTypes.push(this.patternRemoved)
    }
  }

  calculateCustomFieldsHeadersWidth() {
    const textField = this.$el.querySelector('.lf-textfield')
    const textFieldWidth = textField!.getClientRects()[0].width
    const headers = this.$el.querySelectorAll('.header')
    headers.forEach((header) => ((header as HTMLElement).style.width = `${textFieldWidth}px`))
  }

  beforeDestroy() {
    this.resetSelectedRegisterData(this.context)
  }
}
</script>

<style lang="scss" scoped>
.lexon-numeration-component {
  .playground-area {
    position: relative;
    width: 100%;

    .info-text {
      @include milano-regular-14;
      color: $neutral-grey-800;
    }

    @media (max-width: 1900px) {
      .info-text {
        margin-bottom: 15px;
      }
    }

    @media (min-width: 1600px) {
      .headers {
        top: 30px !important;
      }
    }

    .custom-fields-list {
      padding-top: 10px;

      ::v-deep .custom-field-container {
        border-bottom: 1px dashed $gray-03;

        .custom-field {
          margin-bottom: 0;
          padding-bottom: 10px;
          margin-top: 10px;

          & > div {
            display: grid;
            grid-template-columns: repeat(3, 1fr) 140px;
            grid-gap: 20px;
            width: 100%;

            .fieldset-container {
              @include flex;

              .lf-textfield-container,
              .lf-autocomplete-container {
                .messages-container {
                  height: 0px;
                }
              }
            }
          }
        }

        .actions-container {
          align-self: center;
        }
      }
    }

    .add-new-autocomplete {
      width: 400px;
      margin-top: 18px;
    }

    .headers {
      @include flex($justify-content: 'flex-start');
      position: absolute;
      z-index: 1;
      top: 44px;

      .header {
        @include milano-regular-16;
        color: $neutral-grey-800;

        &:not(:first-of-type) {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
