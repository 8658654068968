<template>
  <component :is="component" :item="component" @disable-tabs="toggleDisabledTabs" @hide-tabs-spinner="hideTabSpinner" />
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { VueComponentType } from '@/types/SharedVueTypes'
import useTabs from '@/components/tabs/LfTabs/composables/useTabs'

// PROPS =============================================================================================================
const { component } = defineProps({
  component: {
    type: Object as PropType<VueComponentType>,
    required: true
  }
})

// COMPOSABLES  =============================================================================================================
const { hideTabSpinner, toggleDisabledTabs } = useTabs()
</script>
