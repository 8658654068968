import { GetterTree } from 'vuex'
import {
  ExpedientsState,
  Expedient,
  ProfitabilityDetail,
  Stage,
  ExpedientCustomerFact
} from '@/store/modules/expedients/expedientsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

export const getters: GetterTree<ExpedientsState, RootState> = {
  getAllExpedients(state): Expedient[] {
    return state.expedients
  },
  getTotalNumberOfExpedients(state): string {
    return state.total
  },
  getExpedientsListWithFormattedDates(state) {
    return formatFieldsTypeDateEPOCH(state.expedients)
  },
  getExpedientStageState(state): string {
    return state.stageStatus
  },
  getStages(state): Stage[] {
    return state.stages
  },
  getCurrentStageId(state) {
    const currentStage = state.stages.find((item) => String(item.current) === '1')
    return currentStage?.id
  },
  getHasStages(state): boolean {
    return state.stages.length >= 1
  },
  getExpedientStageStateForm(state) {
    return state.stageStatusForm
  },
  getExpedientSummary(state) {
    return state.summary
  },
  getExpedientActionsSummary(state) {
    return state.actionsSummary
  },
  getLastExpedientSaved(state): number | null {
    return state.lastExpedientSaved
  },
  getTaxesItems(state) {
    return state.taxesItems
  },
  getSimpeGridItems(state) {
    return state.economicDataSimpleGridItems
  },
  getSimpleGridItemsWithFormattedDates(_state, getters) {
    const listItems = getters.getSimpeGridItems
    const fieldsTypeDateEPOCH = listItems[0] ? Object.keys(listItems[0]).filter((key) => key.includes('EPOCH')) : null
    return fieldsTypeDateEPOCH ? formatFieldsTypeDateEPOCH(listItems) : listItems
  },
  getSelectedExpedientInsurer(state) {
    return state.selectedExpedientInsurer
  },
  getLastListExpedientFilter(state) {
    return state.lastListExpedientFilter
  },
  getExpedientSectionsToDuplicate(state) {
    return state.sectionsToDuplicate
  },
  getDuplicateExpedientId(state) {
    return state.duplicateExpedientId
  },
  getExpedientProfitabilityDetail(state): ProfitabilityDetail | {} {
    return state.expedientProfitabilityDetail
  },
  getExpedientCustomerFact(state): ExpedientCustomerFact | null {
    return state.expedientCustomerFact
  }
}
