<template>
  <header class="header-title">
    <span class="header-text">{{ props.title }}</span>
    <span v-if="props.showClose" @click="close" :class="[closeIcon, 'icon-close']" />
  </header>
</template>

<script setup lang="ts">
import { defineProps, PropType } from 'vue'
import { Icons } from '@/icons/icons'

const closeIcon = Icons.CLOSE

const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true
  },
  showClose: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})

// EVENTS
const emit = defineEmits(['close'])

function close() {
  emit('close')
}
</script>

<style scoped lang="scss">
.header-title {
  display: flex;
  min-height: 46px;
  padding: $spacing-xs;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: $cornerRadius-sm;
  background: $main-1000;
  margin-bottom: $spacing-xl;

  .header-text {
    @include milano-bold-18;
    overflow: hidden;
    color: $neutral-white-000;
    text-overflow: ellipsis;
    padding: 0 $spacing-xs;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .icon-close {
    color: $neutral-white-000;
    padding: 0 $spacing-xs;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
