<template>
  <div class="remember-container">
    <!-- TODO: Remembre check -->
    <!--  <div class="checkbox-container">
      <input class="remember-checkbox" type="checkbox" id="remember" name="remember" v-model="rememberPassword" />
      <label for="remember">{{ text.remember }}</label>
    </div> -->
    <a class="forget-container" :href="rememberUrl" target="_blank">{{ text.forget }}</a>
  </div>
</template>

<script lang="ts" setup>
// import { ref } from 'vue'
import { useI18n } from 'vue-i18n-composable'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  forget: t('views.login.forget') as string,
  remember: t('views.login.remember') as string
}

// DATA
const rememberUrl = process.env.VUE_APP_REMEMBER_PASS_URL
// const rememberPassword = ref(false)
</script>

<style lang="scss" scoped>
.remember-container {
  display: flex;
  padding: 10px 0px;
  // TODO: Remember check -> justify-content: space-between;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;

  label {
    text-transform: capitalize;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    flex: 1 0 0;

    .remember-checkbox {
      display: none;
    }

    .remember-checkbox + label {
      @include flex;
      @include poppins-regular-14;
      position: relative;
      padding-left: 22px;
      cursor: pointer;
      color: $neutral-grey-700;
    }

    .remember-checkbox + label:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 1px;
      width: 16px;
      height: 16px;
      border: 1px solid $neutral-grey-800;
    }

    .remember-checkbox:checked + label:after {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 16px;
      position: absolute;
      font-family: $lf-icons-font;
      color: $corporate-color;
      background-color: $neutral-white-000;
      content: '\e92b';
    }
  }

  .forget-container {
    @include poppins-regular-14;
    display: flex;
    width: 183px;
    height: 16px;
    color: $main-1000;
    justify-content: center;
    align-items: center;
    margin: 0;
    text-decoration: none;
  }
}
</style>
