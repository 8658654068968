<template lang="pug">
div(class="navigation-container")
  AppBarComponent(
    :window-width="windowWidth"
  )
  ConfigurationDrawerComponent(
    v-if="configurationDrawerStatus"
    :window-width="windowWidth"
    :isFooterVisible="isFooterVisible"
  )
  DrawerComponent(
    v-else
    :mini="drawerInMiniStatus"
    :window-width="windowWidth"
    :collapseButtonIsClicked="collapseButtonIsClicked"
    :isFooterVisible="isFooterVisible"
    :drawerHeight="drawerHeight"
    @toogle-navigation-drawer="changeDrawerStatus"
  )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import AppBarComponent from '@/components/Navigation/AppBar/AppBarComponent.vue'
import DrawerComponent from '@/components/Navigation/Drawer/DrawerComponent.vue'
import ConfigurationDrawerComponent from '@/components/Navigation/Drawer/ConfigurationDrawerComponent.vue'
import { Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const menusModule = ModuleNamespaces.MENUS

@Component({
  components: {
    AppBarComponent,
    DrawerComponent,
    ConfigurationDrawerComponent
  }
})
export default class NavigationContainerComponent extends Vue {
  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  @Getter('getConfigurationDrawerStatus', { namespace: menusModule })
  configurationDrawerStatus: boolean

  @Mutation('TOOGLE_DRAWER_STATUS', { namespace: menusModule })
  toogleDrawerStatus: () => boolean

  @Prop({
    type: Boolean
  })
  isFooterVisible!: boolean

  @Prop({
    type: String
  })
  drawerHeight: string

  windowWidth = window.innerWidth

  collapseButtonIsClicked = false

  async changeDrawerStatus() {
    this.toogleDrawerStatus()
    this.collapseButtonIsClicked = true
    setTimeout(() => {
      this.collapseButtonIsClicked = false
    }, 500)
  }

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-container {
  font-family: $secondary-font;
  font-size: 300px;
  color: $corporate-color;
  font-size: 14px;
  z-index: 99998;
}
</style>
