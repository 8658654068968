<script lang="ts">
import { InvoicesFormFields } from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const formsModule = ModuleNamespaces.FORMS

@Component
export default class BillingMixin extends Mixins(PermissionsMixin) {
  @Action('prepareRegisterFormDataToSave', { namespace: formsModule }) prepareFormData: ({}) => void
  @Getter('getRegisterFormData', { namespace: formsModule }) getFormData: () => object

  regularizeFieldsOnSave() {
    const formData: any = this.getFormData

    if (formData.idPaymentType && formData.idPaymentType.id) {
      formData.idPaymentType = formData.idPaymentType.id
    }

    this.prepareFormData(formData)
  }

  chargedSwitchLogic(schema: any, formData: any, _field: any, value: any) {
    const chargeDateInput = schema.find((item: any) => {
      return item.id === InvoicesFormFields.CHARGE_DATE
    }) as any

    if (chargeDateInput) {
      if (Number(value)) {
        chargeDateInput.disabled = false
        chargeDateInput.validationRules = 'required'
      } else {
        chargeDateInput.disabled = true
        chargeDateInput.validationRules = null
        Vue.delete(formData, InvoicesFormFields.CHARGE_DATE)
      }

      if (!this.viewPermission.canSave) {
        chargeDateInput.disabled = true
      }
    }
  }

  paidSwitchLogic(schema: any, formData: any, _field: any, value: any) {
    const paidDateInput = schema.find((item: any) => {
      return item.id === InvoicesFormFields.PAYMENT_DATE
    }) as any

    if (paidDateInput) {
      if (Number(value)) {
        paidDateInput.disabled = false
        paidDateInput.validationRules = 'required'
      } else {
        paidDateInput.disabled = true
        paidDateInput.validationRules = null
        Vue.delete(formData, InvoicesFormFields.PAYMENT_DATE)
      }
    }
  }

  paymentSwitchLogic(schema: any, formData: any, _field: any, value: any) {
    const chargeDateInput = schema.find((item: any) => {
      return item.id === InvoicesFormFields.PAYMENT_DATE
    }) as any

    if (chargeDateInput) {
      if (Number(value)) {
        chargeDateInput.disabled = false
        chargeDateInput.validationRules = 'required'
      } else {
        chargeDateInput.disabled = true
        chargeDateInput.validationRules = null
        Vue.delete(formData, InvoicesFormFields.PAYMENT_DATE)
      }

      if (!this.viewPermission.canSave) {
        chargeDateInput.disabled = true
      }
    }
  }
}
</script>
