import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { UserType } from '@/store/modules/auth/authTypes'

export const dmsGuard = (to: Route, _from: Route, next: any): void => {
  const userType: UserType = store.getters[`${ModuleNamespaces.AUTH}/getUserType`]
  const isDMSUser: boolean = UserType.LEXON_DMS === userType  
  const toRepository: boolean = ![
    URLS.DASHBOARD,
    URLS.REPOSITORY,
    URLS.DOUBLE_OPTIN,
    URLS.LOGIN,
    URLS.EXTERNAL_LOGOUT,
    URLS.SEARCH,
    URLS.MAINTENANCE_DOCUMENTS,
    `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_DOCUMENTS_CATEGORY}`
  ].includes((to as any).name)
  
  if (isDMSUser && toRepository && to.name) {
    next(`/${URLS.REPOSITORY}`)
  } else if (isDMSUser && to.name) {
    store.dispatch(`${ModuleNamespaces.SPINNER}/hideSpinnerLayer`)
  }
  
  next()
}


