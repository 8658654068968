import { ActionName, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'

export enum ConfigurationPanelFormAlias {
  COLORS = 'maintenanceColors',
  FORM_COST = 'maintenanceUserCost',
  FORM_GROUPING = 'maintenanceFormGrouping',
  FORM_INVOICES = 'maintenanceFormInvoices',
  FORM_NAME = 'maintenanceFormName',
  FORM_DESCRIPTION = 'maintenanceFormDescription',
  FORM_SUBJECT = 'maintenanceFormSubject',
  FORM_TARIFFS = 'maintenanceFormTariffsProfessionals',
  FORM_PROFESSIONALS = 'maintenanceInvoiceProfessionals',
  FORM_TAXES = 'maintenanceFormTaxes',
  FORM_INVOICES_CONCEPTS = 'maintenanceFormInvoicesConcepts',
  FORM_MODULES = 'maintenanceFormModule',
  FORM_CONCEPTS = 'maintenanceFormConcept',
  FORM_EXPEDIENTS_TYPES = 'maintenanceFormExpedientsTypes',
}

export enum CustomerPortalGeneralFields {
  ACTIVATE_CUSTOMER_PORTAL_SWITCH = 'activated',
  DOMAIN = 'domain',
  URL_FIELDSET = 'URL'
}

export enum BillingCostFields {
  ADMINISTRATIVE_STAFF = 'administrativeStaff',
  ADVERTISING = 'advertising',
  COMPUTER_EQUIPMENT = 'computerEquipment',
  DIRECT_COST = 'directCost',
  FINANCE_EXPENSES = 'financeExpenses',
  HOUR_COST =  'hourCost',
  INSURANCES = 'insurances',
  OFFIVE_SUPPLIES = 'officeSupplies',
  OTHERS = 'others',
  RENTING = 'renting',
  SUPPLIES = 'supplies',
  TOTAL_INDIRECT_COSTS = 'totalIndirectCosts',
  WORK_HOURS = 'workHours'
}

export const CONFIGURATION_PANEL_MENU_NAMES = {
  USER_MANAGEMENT: i18n.t('navigation.drawer-menu-items.user_management').toString(),
  CUSTOMER_PORTAL: i18n.t('navigation.drawer-menu-items.customer_portal').toString(),
  EMAIL_TEMPLATES: i18n.t('navigation.drawer-menu-items.email_templates').toString(),
  CODIFICATION: i18n.t('navigation.drawer-menu-items.codification').toString(),
  CUSTOM_FIELDS: i18n.t('navigation.drawer-menu-items.custom_fields').toString(),
  AUTOMATIC_NUMBERING: i18n.t('navigation.drawer-menu-items.automatic_numbering').toString(),
  DOCUMENT_TEMPLATES: i18n.t('navigation.drawer-menu-items.document_templates').toString(),
}

export const commandColumnConfigurationPanel: CommandModel[] = [
  {
    id: ActionName.REMOVE,
    type: 'None',
    title: 'Eliminar',
    buttonOption: {
      iconCss: Icons.REMOVE,
      cssClass: 'custombutton'
    }
  }
]
