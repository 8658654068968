<template lang="pug">

div(v-if="expedientProfitabilityDetailGetter && Object.keys(expedientProfitabilityDetailGetter).length" class="profitability-dialog-container")

  //- HEADER
  CustomDialogHeaderComponent(:headerTitle="texts.title")

  div(class="body-container")
    //- TABLES
    div(
      class="profitability-tables-container"
    )
      HTMLTableComponent(
        v-for="table in expedientProfitabilityDetailGetter.tables"
        :key="table.name"
        :tableData="table"
      )

    //- SUMMARY
    GlobalVisionProfitabilitySummaryFormulaComponent(
      :summary="summary"
    )

  //- BUTTON
  CustomDialogActionButtonsComponent(:secondaryButtonText="texts.buttonText")

SpinnerLayerComponent(
  v-else
  class="spinner-layer-component"
)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import HTMLTableComponent from '@/components/HTMLTable/HTMLTableComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ProfitabilityDetail } from '@/store/modules/expedients/expedientsTypes'
import GlobalVisionProfitabilitySummaryFormulaComponent from '@/components/Dialog/customDialogs/globalVisionProfitability/GlobalVisionProfitabilitySummaryFormulaComponent.vue'

const expedientsModule = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    HTMLTableComponent,
    SpinnerLayerComponent,
    GlobalVisionProfitabilitySummaryFormulaComponent
  }
})
export default class GlobalVisionProfitabilityDialogComponent extends Vue {
  @Getter('getExpedientProfitabilityDetail', { namespace: expedientsModule })
  expedientProfitabilityDetailGetter: () => ProfitabilityDetail | {}

  texts = {
    buttonText: this.$t('action_buttons.close'),
    title: this.$t('components.widgets.global_vision_profitability.details')
  }

  get summary() {
    return (this as any).expedientProfitabilityDetailGetter.summary
  }
}
</script>

<style lang="scss" scoped>
.profitability-dialog-container {
  @include flex($align-items: flex-start, $justify-content: space-between);
  flex-direction: column;
  height: 650px;
  width: 100%;
  overflow-y: hidden;

  .body-container {
    @include scroll-styles;
    width: 100%;
    height: 90%;
    padding-right: $spacing-xs;
  }

  ::v-deep .custom-dialog-action-buttons-container,
  .custom-dialog-header-container {
    width: 100%;
  }

  ::v-deep .custom-dialog-action-buttons-container {
    margin-top: $spacing-xl;
  }

  ::v-deep .table-profesionales {
    .body-item,
    .summary-item {
      width: 20%;
    }
  }

  ::v-deep table:not(.table-profesionales) {
    .summary-item {
      width: 25%;
    }
  }
}

.spinner-layer-component {
  --spinner-layer-min-height: 100px;
  --spinner-layer-background: $white-01;
  --spinner-layer-position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
