import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed } from 'vue'

export interface SelectedRegisterToLoadInterface {
  endpoint: string,
  context: string
}

export default function useSelectedRegister({entityName}: {entityName: string}) {

  const selectedRegisterId = computed(() => store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](entityName).toString())

  const selectedRegisterData = computed(() =>
    store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`](entityName)
  )

  const getSelectedRegisterByEntity = (entityId: string) => {
    return store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`](entityId)
  }

  const getSelectedRegisterId = (
    context: string
  ) => store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](context)


  const loadSelectedRegister = (
    selectedRegisterToLoad: SelectedRegisterToLoadInterface
  ) => store.dispatch(`${ ModuleNamespaces.SELECTED_REGISTER }/fetchSelectedRegisterData`, selectedRegisterToLoad)

  const saveSelectedRegisterData = (selectedRegisterData: any, context: string = entityName) => {
    store.dispatch(`${ ModuleNamespaces.SELECTED_REGISTER }/saveSelectedRegisterData`, {selectedRegisterData, context})
  }

  return {
    getSelectedRegisterByEntity,
    getSelectedRegisterId,
    loadSelectedRegister,
    saveSelectedRegisterData,
    entityName,
    selectedRegisterId,
    selectedRegisterData
  }
}
