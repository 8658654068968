<template>
  <div :class="['selected-register-container', 'lexon-view-container']">
    <LfHeaderTitleComponent class="mb-xxl" :title="title" :buttons="buttons" :executeAction="onExecuteAction" />

    <div class="alerts-messages-container">
      <TabsComponent v-if="tabs" class="tabs-other-styles" :tabs="tabs" :context="context" @changed-tab="onChangeTab" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n-composable'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import useRouter from '@/composables/useRouter'
import TabsComponent from '@/components/tabs/TabsComponent/TabsComponent.vue'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { computed, ref } from 'vue'
import { defaultFilter } from '@/store/modules/alertsMessages/alertsMessagesTypes'
import useActionsFilteredAlertsMessages from '@/components/AlertsMessages/composables/useActionsFilteredAlertsMessages'
import useTabConfiguration from '@/composables/useTabConfiguration'
import { TabModel } from '@/components/tabs/TabsComponent/TabsComponentTypes'

// TRANSLATIONS
const { t } = useI18n()

const text = {
  actions: t('views.alerts_messages.actions'),
  actionsAlerts: t('views.alerts_messages.actions_alerts'),
  agenda: t('views.alerts_messages.agenda'),
  agendaAlerts: t('views.alerts_messages.agenda_alerts'),
  all: t('views.alerts_messages.all'),
  allAlerts: t('views.alerts_messages.all_alerts'),
  billingAlerts: t('views.alerts_messages.billing_alerts'),
  billings: t('views.alerts_messages.billings'),
  closeBtn: t('action_buttons.close'),
  expedientAlerts: t('views.alerts_messages.expedients_alerts'),
  expedients: t('views.alerts_messages.expedients'),
  igualaAlerts: t('views.alerts_messages.igualas_alerts'),
  igualas: t('views.alerts_messages.igualas'),
  lexnet: t('views.alerts_messages.lexnet'),
  lexnetAlerts: t('views.alerts_messages.lexnet_alerts')
}

const context = ContextName.ALERTS_MESSAGES
const allTabIndex: number = 0
const selectedTabIndex = ref<number>(allTabIndex)
const { goBack } = useRouter()
const { tabConfiguration, getTabConfiguration } = useTabConfiguration({
  aliasName: 'alerts',
  context
})
const { setFilter } = useActionsFilteredAlertsMessages()

const tabs = ref<TabModel[] | undefined>(undefined)
const texts = {
  buttons: {
    close: text.closeBtn
  }
}

const title = computed(() => {
  if (tabs.value === undefined) {
    return ''
  }
  return tabs.value[selectedTabIndex.value].title as string
})

const buttons: ActionBarButton[] = [
  {
    tooltip: texts.buttons.close,
    action: ActionName.CLOSE,
    enabled: true
  }
]

const onExecuteAction = async (actionName: string) => {
  ActionName.CLOSE === actionName ? goBack() : null
}

const onChangeTab = async (tabIndex: number, _objectType: string, _resolve: any) => {
  selectedTabIndex.value = tabIndex
  if (tabs.value !== undefined) {
    await setFilter({
      type: tabs.value[tabIndex].items[0].alertMessageType,
      page: 1
    })
  }
}

async function init() {
  await getTabConfiguration()
  tabs.value = tabConfiguration.value
  await setFilter(defaultFilter)
}

init()
</script>

<style scoped lang="scss">
.alerts-messages-container {
  ::v-deep .tab-content {
    @include flex;
    width: 100%;
    padding: $spacing-sm;
    gap: 8px;
    border-radius: $cornerRadius-md;
    border: 1px solid $main-200;
    min-height: 450px;

    .dynamic-component-wrapper {
      align-self: flex-start;
      width: 100%;
    }
  }
}
</style>
