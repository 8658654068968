<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="bankdrafts"
      :commandClick="onCommandClick"
      :contextMenuItems="contextMenuItems"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import useGridConfiguration from '@/composables/useGridConfiguration'
import useContactBankdrafts from './composables/useContactBankdrafts'
import { computed } from 'vue'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import {
  CommandClickEventArgs,
  CommandColumnModel,
  ContextMenuClickEventArgs,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    default: 'contactBankdrafts'
  }
})

const commandsBts: CommandColumnModel[] = [
  {
    title: 'Abrir nueva pestaña',
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]

const contextMenuItems = [
  {
    id: ActionName.EDIT,
    text: t('action_buttons.edit').toString(),
    iconCss: Icons.EDIT
  },
  {
    id: ActionName.OPEN_NEW_TAB,
    text: t('action_buttons.open_new_window').toString(),
    iconCss: Icons.OPEN_WINDOW
  }
]

// COMPOSABLES   ======================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons: commandsBts
})

const { bankdrafts, read, open } = useContactBankdrafts({
  listName: 'listCustomerCustomerBankdrafts'
})

// STORE GETTERS ======================================================================================================
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])

const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)
// COMPUTEDS ==========================================================================================================

// EVENTS =============================================================================================================
const onRowClick = (args: RecordClickEventArgs) => {
  const { rowData } = args
  const { idEntity, idBankdraft } = rowData as any
  open(idEntity, idBankdraft)
}

const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { idEntity, idBankdraft } = rowData as any
  open(idEntity, idBankdraft, '_blank')
}

const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  const { rowData } = rowInfo as any
  const { idEntity, idBankdraft } = rowData as any
  open(idEntity, idBankdraft, '_blank')
}

// HOOKS =============================================================================================================
async function init() {
  await read(selectedRegisterId.value)
}

init()
</script>
