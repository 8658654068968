import { columnActions as columnActionDefault, ColumnModel, columnWidth, CommandModel, GridConfiguration } from "@/components/grids/LfGrid/LfGridTypes"

export const gridConfigurationWithActionColumn = (gridConfiguration: GridConfiguration, commandButtons: CommandModel[] = []) => {
  const column = gridConfiguration.columns.find((item: ColumnModel) => item.commands)
  if (column !== undefined) {
    return gridConfiguration
  }
  const columnActions: ColumnModel = {...columnActionDefault}
  columnActions.width = columnWidth[commandButtons.length]
  columnActions.commands = commandButtons
  gridConfiguration.columns.push(columnActions)
  return gridConfiguration
}
