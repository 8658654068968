<template lang="pug">
  div(
    :class="getClasses"
    )
    header
      AlertComponent(
        :whereIsRendered="componentWhereIsRenderedAlertComponent"
      )
      LfHeaderTitleComponent(:title="title" class="header-title")
    div(class="file-manager-alone")
      FileManager(
        :basePath="basePath"
        :idEntityTypeBase="idEntityTypeBase"
        :context="context"
        mainFolderDescription="Documentos"
        :initialBasePath="initialBasePath"
        :initialPath="initialPath"
        height="650"
        :canDropFilesOnMainFolder="false"
        :canCreateFoldersOnMainFolder="false"
        :permissionsEntity="idEntityTypeBase"
      )

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import FileManager from '@/components/FileManager/FileManagerComponent.vue'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter } from 'vuex-class'
import { UserType } from '@/store/modules/auth/authTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const authModule: string = ModuleNamespaces.AUTH
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    AlertComponent,
    FileManager,
    LfHeaderTitleComponent
  }
})
export default class RepositoryView extends Mixins(PermissionsMixin) {
  @Getter('getUserType', { namespace: authModule })
  userType: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  context: string = ContextName.REPOSITORY
  title: string = this.$t('views.repository.title').toString()
  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.TABS_VIEW
  idEntityTypeBase = entity.documents.type

  get initialBasePath(): string {
    if (this.$route.query.basePath) {
      const basePath: string = this.$route.query.basePath.toString()
      return atob(decodeURI(basePath))
    }
    return '/'
  }

  get initialPath(): string {
    if (this.$route.query.path) {
      const path: string = this.$route.query.path.toString()
      return atob(decodeURI(path))
    }
    return '/'
  }

  get basePath(): string {
    switch (this.userType) {
      case UserType.LEXON:
      case UserType.PORTAL:
        return 'documentos'
      case UserType.LEXON_DMS:
        return 'documentos/general'
    }
    return 'documentos'
  }

  get getClasses() {
    return [
      { 'lexon-view-container': !this.showSpinnerLayer },
      { 'repository-hidden': this.showSpinnerLayer },
      'repository-container'
    ]
  }
}
</script>
