<template lang="pug">

div(
  :style="`backgroundColor: ${color};`"
  class="color-circle"
)

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'colorsColumnTemplate'
})
export default class ColorsColumnTemplateComponent extends Vue {
  data: any

  get color() {
    return this.data.color
  }
}
</script>

<style lang="scss" scoped>
.color-square {
  height: 18px;
  width: 18px;
  margin-right: 11px;
  border-radius: 4px;
}
.color-circle {
  height: 18px;
  width: 18px;
  margin-right: 11px;
  border-radius: 50%;
}
</style>
