import { LocaleMessages, TranslateResult } from 'vue-i18n'

export interface ActionBarButton {
  icon?: string
  class?: string
  mode?: string
  tooltip: TranslateResult | string | LocaleMessages
  action: ActionName
  enabled: boolean | (() => void)
  hidden?: boolean
}

export enum ActionBarButtonMode {
  ROUND = 'round',
  SQUARE = 'square'
}

export enum ActionName {
  CANCEL = 'cancel',
  CLOSE = 'close',
  CONVERT_TO_CUSTOMER_INVOICE = 'converToCustomerInvoice',
  CUSTOM_OPTION = 'customOption',
  DISCARD = 'discard',
  DUPLICATE_EXPEDIENT = 'duplicateExpedient',
  EDIT = 'edit',
  FILTER_REPORT = 'filterReport',
  OPEN_IN_LEXNET = 'openInLexnet',
  PRINT = 'printInvoice',
  REMOVE = 'remove',
  SAVE = 'save',
  SEND_EMAIL = 'sendEmail'
}
