import { ActionTree } from 'vuex'
import { ProfessionalsState, professionalsMutationTypes } from '@/store/modules/professionals/professionalsTypes'
import { ContextName, ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ProfessionalsService } from '@/services/ProfessionalsService'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'
import { AxiosError } from 'axios'

export interface StoreResponse {
  success: boolean
  message?: string
}

export const actions: ActionTree<ProfessionalsState, RootState> = {
  async fetchListProfessionals({ commit, rootGetters }) {
    try {
      const selectedRegisterDataGetter = `${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`
      const selectedProfessionalData = rootGetters[selectedRegisterDataGetter](ContextName.MAINTENANCE_USERS_CONFIG)
      const { id: selectedProfessionalId } = selectedProfessionalData
      const url = `user/professionals/?idUser=${selectedProfessionalId}`
      const { data } = await new ProfessionalsService().getData(url)
      commit(professionalsMutationTypes.FETCH_PROFESSIONALS, data)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) { }
  },
  async saveProfessionalAccess({ }, formData) {
    try {
      const url = '/user/professionals'
      const response = await new MainService().postData(url, formData)
      return { success: Boolean(response) }
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc }
    }
  },
  async removeProfessionalAccess({ }, id): Promise<StoreResponse> {
    try {
      const url = `user/professionals/${id}`
      const response = await new MainService().deleteData(url)
      return { success: Boolean(response) }
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc }
    }
  }
}
