
import { ConfigurationTypes } from "@/store/modules/configuration/configurationTypes"
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { TabModel } from "@/components/tabs/TabsComponent/TabsComponentTypes"
import { Ref, ref } from "vue"

export interface PropsUseTabConfiguration {
  aliasName: string
  context: string
}

export interface ReturnUseTabConfiguration {
  getTabConfiguration: () => Promise<void>
  tabConfiguration: Ref<TabModel[]>
}

export default function useTabConfiguration(props: PropsUseTabConfiguration): ReturnUseTabConfiguration {
  const tabConfiguration = ref()

  const getTabConfiguration = async () => {
    await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentViewConfiguration`, {
      objectType: ConfigurationTypes.VIEW,
      alias: props.aliasName,
      context: props.context
    })

    tabConfiguration.value = store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentViewTabs`](props.context)
  }

  return {
    getTabConfiguration,
    tabConfiguration
  }
}
