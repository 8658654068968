import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const cancelRequestGuard = (to: Route, from: Route, next: any): void => {
  if (from.name !== to.name) {
    store.dispatch(`${ ModuleNamespaces.AUTH }/cancelPendingRequests`)
  }
  next()
}


