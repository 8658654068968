<template>
  <button :title="props.title" :class="buttonVariantClass" :disabled="props.disabled" @click="clickHandler">
    <span v-if="props.prependIcon" :class="[props.prependIcon, iconSizeClass]" />
    <span v-if="getText" class="text">{{ getText }}</span>
    <span v-if="props.appendIcon" :class="[props.appendIcon, iconSizeClass]" />
  </button>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType, ref } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { LabelButtonSize, LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { createEnumValidator } from '@/helpers/validators'

const props = defineProps({
  action: {
    type: Function as PropType<(payload: MouseEvent) => void>,
    default: () => {}
  },
  appendIcon: {
    type: String as PropType<string>,
    required: false
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  prependIcon: {
    type: String as PropType<string>,
    required: false
  },
  size: {
    type: String as PropType<LabelButtonSize>,
    default: LabelButtonSize.MD,
    validator: createEnumValidator(LabelButtonSize)
  },
  text: {
    type: String as PropType<string | TranslateResult>,
    required: false
  },
  title: {
    type: String as PropType<string>,
    default: undefined
  },
  variant: {
    type: String as PropType<LabelButtonVariant>,
    default: LabelButtonVariant.SOLID,
    validator: createEnumValidator(LabelButtonVariant)
  },
  clickOnce: {
    type: Boolean as PropType<boolean>,
    default: true
  }
})

const variantToClassSuffix = {
  [LabelButtonVariant.SOLID]: '__solid',
  [LabelButtonVariant.OUTLINED]: '__outlined',
  [LabelButtonVariant.TEXT]: '__text',
  [LabelButtonVariant.GHOST]: '__ghost'
}

const sizeToClassSuffix = {
  [LabelButtonSize.MD]: '__md',
  [LabelButtonSize.SM]: '__sm'
}

// REFS
const clickedOnce = ref(false)

// COMPUTED
const buttonVariantClass = computed(() => {
  const baseClass = 'lf-label-button'
  const variantSuffix = variantToClassSuffix[props.variant] || ''
  const sizeSuffix = sizeToClassSuffix[props.size] || ''
  return `${baseClass}${variantSuffix}${sizeSuffix}`
})

const iconSizeClass = computed(() => {
  const baseClass = 'lf-icon'
  const sizeSuffix = sizeToClassSuffix[props.size] || ''
  return `${baseClass}${sizeSuffix}`
})

const clickHandler = computed(() => {
  return props.clickOnce && !clickedOnce.value
    ? (event: MouseEvent) => {
        clickedOnce.value = true
        props.action(event)
      }
    : props.action
})

const getText = computed(() => {
  return props.text ?? ''
})
</script>

<style lang="scss" scoped>
@import '~@/components/forms/buttons/label/styles/label-button';
</style>
