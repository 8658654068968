import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { menusMutationTypes } from '@/store/modules/menus/menusTypes'

export const menuGuard = async (to: Route, _from: Route, next: any): Promise<void> => {
  try {
    const configMenuStatus: boolean = to.meta?.isConfigurationPanel || false
    const menusModule = ModuleNamespaces.MENUS
    store.commit(`${menusModule}/${menusMutationTypes.CONFIG_DRAWER_STATUS}`, configMenuStatus)
  } catch (error) {
    console.error('Error en el guard de menú:', error)
  }
  next()
}


