<template>
  <div class="toast-container">
    <img class="toast-icon" :src="toastIcon(toast.icon)" alt="icon" />
    <div>
      <div class="toast-title">{{ toast.title }}</div>
      <div class="toast-message">{{ toast.message }}</div>
    </div>
    <img
      class="close-icon"
      src="@/assets/images/svg/close-icon.svg"
      alt="close icon"
      @click="removeToastLogic(toast)"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import useToasts from './composables/useToasts'
import { Toast } from './types/toastsTypes'

// PROPS
const { toast } = defineProps({
  toast: {
    type: Object as PropType<Toast>,
    required: true
  }
})

const { removeToast, storageToast } = useToasts()

// METHODS
function toastIcon(icon: string) {
  return require(`@/assets/images/svg/${icon}`)
}

function removeToastLogic(toast: Toast) {
  removeToast(toast)
  toast.stored && storageToast(toast)
}
</script>

<style scoped lang="scss">
.toast-container {
  display: flex;
  width: 503px;
  min-width: 503px;
  height: 88px;
  padding: $spacing-xs $spacing-sm;
  gap: $spacing-xs;
  border-radius: $cornerRadius-sm;
  color: $neutral-grey-800;
  background: $tertiary-byzantineBlue-050;
  margin-bottom: 4px;
  z-index: 9999;

  .toast-icon {
    width: 22px;
    height: 22px;
  }

  .toast-title {
    @include milano-bold-16;
  }

  .toast-message {
    @include milano-regular-14;
    margin-top: 5px;
    padding-right: 20px;
  }

  .close-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
</style>
