<template lang="pug">
  div(v-if="showDialog" class="dialog-container")
    div(class="dialog-background")
    div(class="dialog-message-container")
      header(:class="['header-container', getType]")
        img(:src="getIcon" alt="icon")
      div(class="message-container")
        p(class="message" v-html="dialog.message")
      div(class="actions-container")
        template(v-if="!buttons")
          LfLabelButtonComponent(
            v-if="checkIfShowSecondaryButton"
            class="button"
            :text="secondaryButtonText"
            :action="() => checkCloseDialog()"
            variant="outlined"
          )
          LfLabelButtonComponent(
            class="button"
            :text="mainButtonText"
            :action="() => makeAction()"
            variant="solid"
          )
        template(v-else)
          LfLabelButtonComponent(
            v-for="(button, index) in buttons"
            :key="index"
            @click.stop="getAction(button)"
            :class="`button ${button.style}`"
          ) {{ button.text }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogData, DialogTypes, DialogButtonTypes, DialogButton } from '@/store/modules/dialog/dialogTypes'
import { TranslateResult } from 'vue-i18n'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'

const dialogModule: string = ModuleNamespaces.DIALOG
const errorsModule: string = ModuleNamespaces.ERROR

@Component({
  components: {
    LfLabelButtonComponent
  }
})
export default class DialogComponent extends Vue {
  @Getter('showDialog', { namespace: dialogModule })
  showDialog: boolean
  @Getter('getDialogData', { namespace: dialogModule })
  dialog: DialogData

  @Action('closeDialog', { namespace: dialogModule })
  closeDialog: () => {}
  @Action('clearEndpointError', { namespace: errorsModule })
  clearEndpointError: () => {}

  get mainButtonText() {
    let text: TranslateResult = ''
    if (this.dialog.mainButtonText) {
      text = this.dialog.mainButtonText
    } else {
      text = this.$t('action_buttons.accept')
    }
    return text
  }

  get secondaryButtonText() {
    let text: TranslateResult = ''
    if (this.dialog.secondaryButtonText) {
      text = this.$t(`${this.dialog.secondaryButtonText}`)
    } else {
      text = this.$t('action_buttons.cancel')
    }
    return text
  }

  get checkIfShowSecondaryButton() {
    const { type, hideSecondaryButton } = this.dialog
    if (hideSecondaryButton) {
      return false
    } else if (type === DialogTypes.INFO || type === DialogTypes.WARNING || type === DialogTypes.CUSTOM_DIALOG) {
      return true
    }
    return false
  }

  get getType() {
    switch (this.dialog.type) {
      case DialogTypes.INFO:
        return DialogTypes.INFO
      case DialogTypes.SUCCESS:
        return DialogTypes.SUCCESS
      case DialogTypes.ERROR:
        return DialogTypes.ERROR
      case DialogTypes.WARNING:
        return DialogTypes.WARNING
      case DialogTypes.CUSTOM_DIALOG:
        return DialogTypes.CUSTOM_DIALOG
    }
  }

  get getIcon() {
    switch (this.dialog.type) {
      case DialogTypes.INFO:
        return require('@/assets/images/svg/info_xl.svg')
      case DialogTypes.SUCCESS:
        return require('@/assets/images/svg/success_xl.svg')
      case DialogTypes.ERROR:
        return require('@/assets/images/svg/error_xl.svg')
      case DialogTypes.WARNING:
        return require('@/assets/images/svg/warning_xl.svg')
      case DialogTypes.CUSTOM_DIALOG:
        return this.dialog.icon
    }
  }

  get buttons() {
    return this.dialog.buttons
  }

  mounted() {
    document.body.appendChild(this.$el)
  }

  makeAction() {
    if (this.dialog.action) {
      this.dialog.action()
    }
    this.checkCloseDialog()
  }

  checkCloseDialog() {
    if (this.dialog.type === DialogTypes.ERROR) {
      this.clearEndpointError()
    }

    this.closeDialog()
  }

  getAction(button: DialogButton) {
    if (button.type === DialogButtonTypes.CLOSE) {
      this.checkCloseDialog()
    } else if (button.action) {
      button.action()
      this.checkCloseDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 99999;

  .dialog-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $dialog-background-color;
  }

  .dialog-message-container {
    position: absolute;
    display: flex;
    width: 648px;
    padding: $spacing-sm;
    flex-direction: column;
    align-items: flex-end;
    gap: $spacing-xl;
    border-radius: $cornerRadius-md;
    background: $neutral-white-000;
    box-shadow: $shadow-md;

    .header-container {
      width: 100%;
      color: $neutral-white-000;
      display: flex;
      padding: $spacing-xs $spacing-sm;
      align-items: center;
      justify-content: center;
      gap: $spacing-xs;
      align-self: stretch;
      border-radius: $cornerRadius-sm;

      &.info-text,
      &.custom-dialog-text {
        background: $tertiary-byzantineBlue-050;
      }

      &.error-text {
        background-color: $tertiary-persianRed-050;

        .header-icon {
          background-color: $tertiary-persianRed-200;
          color: $tertiary-persianRed-050;
        }
      }

      &.success-text {
        background-color: $tertiary-seaGreen-050;
      }

      &.warning-text {
        background-color: $tertiary-saffron-050;
      }

      .close-icon {
        @include flex;
        width: 35px;
        height: 35px;
        cursor: pointer;
      }
    }

    .message-container {
      background-color: $neutral-white-000;
      padding: $spacing-md;
      width: 100%;

      .message {
        @include milano-regular-16;
        color: $neutral-grey-800;
        text-align: center;
        margin: 0;
        padding: 0 20px;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .actions-container {
      @include flex($justify-content: flex-end);
      background-color: $white-01;

      .main-action-button {
        @include main-action-button--rectangle;
      }

      .secondary-action-button {
        @include secondary-action-button--rectangle;
      }

      .button ~ .button {
        margin-left: $spacing-xs;
      }
    }
  }
}
</style>
